nwuiCarouselNavigation.factory('NwuiCarouselNavigationFactory', [function() {
	
	var defaults = {
		carousel_id: undefined, //req
		count: undefined, //req
		current: 0,
		onChange: function() {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCarouselNavigationFactory");
		if(obj.carousel_id === undefined) return console.error("Missing required instantiation object variable 'carousel_id' in NwuiCarouselNavigationFactory");
		if(obj.count === undefined) return console.error("Missing required instantiation object variable 'count' in NwuiCarouselNavigationFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);