nwuiChartLine.directive('nwuiChartLine', [
	'$window', 
	'$timeout', 
	'$filter', 
	'$location', 
	'$locale',
	'NwuiTooltipV2Factory',
	'NwuiRecommendationFactory',
	'NwuiOverlayFactory',
	function(
		$window, 
		$timeout, 
		$filter, 
		$location, 
		$locale,
		NwuiTooltipV2Factory,
		NwuiRecommendationFactory,
		NwuiOverlayFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/chart/line/template.html',
			scope: {
				name: '=',
				series: '=',
				height_ratio: '=heightRatio',
				range: "=",
				units: "=",
				trim_dates: '=trimDates',
				number_filter: '=numberFilter',
				horiz_axis_labels: '=horizAxisLabels'
			},
			link: function(scope, element, attrs) {
				
				scope.style = {};
				scope.active = false;
				scope.no_data = false;
				scope.loaded = false;
				
				scope.absUrl = $location.absUrl();
				
				scope.modules = {
					overlay: new NwuiOverlayFactory({
						id: 'overlay-line-chart-' + name,
						message_i18n: 'nwui.common.line_graph.loading_message',
						color: 'alternate',
						icon: {
							name: 'timeline'
						},
						block_state_change: true
					}),
					tooltip: new NwuiTooltipV2Factory({
						text: '',
						invert: false,
						style: {}
					}),
					recommendation: new NwuiRecommendationFactory({
						type: 'warning',
						icon: {
							name: 'timeline'
						},
						text_i18n: "nwui.common.line_graph.empty_message"
					})
				}
				
				var key;
				var width;
				var is_mobile;
				var height;
				var margin = 35;
				var xAxis;
				var yAxis;
				var x;
				var y;
				var area;
				var line;
				var focus;
				var end;
				var defs;
				var zero;
				var d_prev;
				var zero_prev = 0;
				var x_min = 0;
				var is_tablet;
				
				var d3 = $window.d3;
				var data = scope.series;
				var data_original = angular.copy(data);
				var range = scope.range;
				var date = new Date();
				var data_range;
				var drag = d3.behavior.drag()
					.on("drag", dragmove)
					.on("dragstart", function() {
						//d3.event.sourceEvent.stopPropagation(); // silence other listeners
					})
	
				var parseDate = d3.time.format("%Y-%m-%d").parse;
				var bisectDate = d3.bisector(function(d) { return d[key]; }).left;
		
				var block_tooltip = false;
		
				function getDivider(diff) {
					for(var i = 12; i >= -2; i--) {
						if(Math.abs(diff * 0.5) >= Math.pow(10,i)) {
							return Math.pow(10,i);
						}
					}
				}
				
				function calibratePerformance(index_st) {
					
					////console.log(zero_prev);
					
					//if(scope.key == 'percent') {
						/*
						var start = (index_st-1 < 0) ? 0 : index_st-1;
						var zero = data[start].value - data[0].value - zero_prev,
						var principle = data_original[start].value;
						
						data.forEach(function(o,i) {
							o.value -= zero;
							o.value -= zero;
						});
						
						//console.log('principle', principle, data[start].value, data_original[start].value, data_original[data_original.length-1].value);
						
						zero_prev = zero;
						*/
					//}
					
				}
				
				function generateXTicks(data_range) {

					var length = data_range.length;
					var optimal_ticks = 8;
					var tick_values = [];
					
					if(scope.horiz_axis_labels === false) return tick_values;
					
					//optimal number for mobile
					if(is_mobile)
						optimal_ticks = Math.floor(optimal_ticks*0.5)
					else if(is_tablet)
						optimal_ticks = Math.floor(optimal_ticks*0.5)
					
					//calc optimal spacing
					var spacing = Math.floor(data_range.length/optimal_ticks);
					
					if(spacing < 1)
						spacing = 1;
						
					//add first tick
					tick_values.push(data_range[0].index);
					
					//generate ticks
					for(var i = spacing; i < data_range.length; i = i + spacing)
						tick_values.push(data_range[i].index);
					
					return tick_values;
					
				}
				
				function generateXFormat(d, i) {
					
					var label;
					var length = data_range.length;
					var major = 'MMM';
					var minor = 'D';
					var defaultFormat = typeof $locale.MOMENT_CUSTOM_DATE_FORMATS !== "undefined" ? $locale.MOMENT_CUSTOM_DATE_FORMATS.monthDay : "MMM D";
					var template = function(major, minor) {
						if(arguments.length == 2)
							return defaultFormat;
						else return major + ' ' + minor;
					}
					
					//year format
					if(length >= 240) {
						major = '\'YY';
						minor = 'MMM';
						template = function(major, minor) {
							return minor + ' ' + major;
						}
					} 
					
					//6 month format
					else if(length >= 100) {
						major = '';
						minor = defaultFormat;
						template = function(major, minor) {
							return major + '' + minor;
						}
					}
					
					label = 
						(i == 0 ||
						(d_prev && moment(data[d_prev].date).format(major) != moment(data[d].date).format(major)))
						? moment(data[d].date).format(template(major, minor))
						: moment(data[d].date).format(minor);
						
					d_prev = d;
					
					return label;
						
				}
				
				function update(new_value, duration) {
					
					if(!data || data.length == 0 || x === undefined) return;
					
					var index_st_flag = false;
					var index_en_flag = false;
					var index_st = 0;
					var index_en = data.length-1;
					for(var i = 0; i < data.length; i++) {
						if(moment(data[i].date).format('YYYY-MM-DD') >= moment(new_value.date_st).format('YYYY-MM-DD') && !index_st_flag) {
							index_st = i
							index_st_flag = true;
						}
						if(moment(data[i].date).format('YYYY-MM-DD') > moment(new_value.date_en).format('YYYY-MM-DD') && !index_en_flag) {
							index_en = i-1
							index_en_flag = true;
						}
					}
					
					data_range = data.slice(index_st, index_en+1);
					
					calibratePerformance(index_st);
					
					if(scope.trim_dates) {
						x.domain([data_range[0].index, data_range[data_range.length-1].index])
					} else {
						x.domain([moment(data_range[0].date), moment(data_range[data_range.length-1].date)])
					}

					var min = d3.min(data_range, function(d) { return d.value; });
					var max = d3.max(data_range, function(d) { return d.value; });

					if (min === max) {
						min *= 0.9;
						max *= 1.1;

						if (min === 0) {
							min = -1;
							max = 1;
						}
					}

					var divider = getDivider(max-min);
					min = Math.floor(min/divider) * divider;
					max = Math.ceil(max/divider) * divider;

					var valDiff = max - min;

					if(valDiff <= 300 && scope.units == "currency"){
						min -= 300;
						max += 300;
					}else if (valDiff <= 1 && scope.units == "%"){
						min -=1;
						max +=1;
					}

					y
						.domain([min, max])
						//.domain([min, max])
					
					if(scope.trim_dates) {
						
						xAxis
							.tickValues(generateXTicks(data_range))
							.tickFormat(generateXFormat)
					
					} else {
						
						xAxis
							//.tickValues(generateXTicks(data_range))
							//.tickFormat(generateXFormat)
							
					}
					
					svg.select(".x-axis.front").transition().duration(duration).call(xAxis);
					svg.select(".x-axis.outline").transition().duration(duration).call(xAxis);
					svg.select(".y-axis.front").transition().duration(duration).call(yAxis);
					svg.select(".y-axis.outline").transition().duration(duration).call(yAxis);
					svg.select(".area").transition().duration(duration).attr("d", area);
					svg.select(".line").transition().duration(duration).attr("d", line);
					
					
					/*
					svg
						.select('.zero').transition().duration(duration)
						.attr("d", "M" + (x_min) + " " + (y(0)-1) + " L" + (width-margin) + " " + (y(0)-1))
					*/

					svg
						.select('.end-1').transition().duration(duration)
						.attr("cx", x(data[data.length-1][key]))
						.attr("cy", y(data[data.length-1].value))

					svg
						.select('.end-2').transition().duration(duration)
						.attr("cx", x(data[0][key]))
						.attr("cy", y(data[0].value))
					
					svg
						.select('.gradient-1').transition().duration(duration)
						.attr("x", x(data[data.length-1][key])-89)
						.attr("height", height)
						
					svg
						.select('.gradient-2').transition().duration(duration)
						.attr("x", x(data[0][key]))
						.attr("height", height)
					
					////console.log('nwui-chart-line', 'select-test', d3.selectAll('.horiz-line')[0])
					
					//var lines = d3.selectAll('.horiz-line')[0];
					
					// svg
					// 	.selectAll('.y-axis.front line')
					// 	.each(function(d,i) {
					// //svg
					// 	//.selectAll('.horiz-line')
					// 	//.each(function(d,i) {
					// 		//console.log('nwui-chart-line', 'y-axis-each', this, d, i);
							
					// 		svg
					// 			.select('.horiz-line-'+i)
					// 			.attr("d", "M" + (x_min) + " " + (y(d)) + " L" + (width-margin) + " " + (y(d)))
							
					// 	})
					yAxis.scale().ticks(yAxis.ticks()[0]).forEach(function(val,i) {

						var selection = svg.select('.horiz-line-'+i);

						//console.log('goalsLineChart', 'yAxis', 'update', i, val, selection[0][0]);

						if(selection[0][0] === null) {
							svg
								.insert('path', ':first-child')
								.attr("class", 'horiz-line horiz-line-'+i)
								.attr("d", "M" + (x_min) + " " + (y(val)) + " L" + (width-margin) + " " + (y(val)))
								.attr("stroke-dasharray", "5,5")
						} else {
							svg
								.select('.horiz-line-'+i)
								.transition()
								.duration(duration)
								.attr("d", "M" + (x_min) + " " + (y(val)) + " L" + (width-margin) + " " + (y(val)))
						}

					});

					var lines = svg.selectAll('.horiz-line')[0].length;
					var ticks = yAxis.scale().ticks(yAxis.ticks()[0]).length;
					if(lines > ticks) {
						for(var i = ticks; i < lines; i++) {
							//console.log('goalsLineChart', 'trim', i);
							svg
								.select('.horiz-line-'+i)
								.remove();
						}
					}
						
					//$(element).removeClass("active");
					scope.active = false;
					block_tooltip = true;
					
					scope.$apply();
					
					$timeout(function() {
						block_tooltip = false;
					}, duration+1);
					
				}
				
				function dragmove(d) {
					
					////console.log('x', d3.event.x);
					
				}
			
				function mousemove() {
					////console.log(block_tooltip)
					if(block_tooltip) return;
							
					//$(element).addClass("active");
					scope.active = true;
					
					svg
						.select('.circle')
						.transition()
						.ease('elastic')
						.duration(500)
						.delay(0)
						.attr("r", 6);
						
					var x0 = x.invert(d3.mouse(this)[0]),
						i = bisectDate(data_range, x0, 1) >= data_range.length ? data_range.length-1 : bisectDate(data_range, x0, 1),
						d0 = data_range[i - 1],
						d1 = data_range[i],
						d = x0 - d0.index > d1.index - x0 ? d1 : d0;
					
					focus
						.select("circle.circle")
						.attr("transform", "translate(" + x(d[key]) + "," + y(d.value) + ")");

					var left = x(d[key]);
					var calc = 0; //(x(d.index)/x(data_range[data_range.length-1].index)) * 30 - 15;

					scope.modules.tooltip.invert = (x(d[key]) > width*0.75) ? true : false;
					scope.modules.tooltip.style.top = (y(d.value) - $(element).find('.nwui-tooltip-v2').height() * 0.5 - 1) + "px";
					scope.modules.tooltip.style.left = (left-calc) + "px";
					scope.modules.tooltip.text = '<nobr>' + $filter('date')(d.date, 'mediumDate') + '</nobr> ';
					if(scope.units == 'currency') {
						scope.modules.tooltip.text += '<strong>' + $filter(scope.number_filter || 'currency')(d.value) + '</strong>';
					} else {
						scope.modules.tooltip.text += '<strong>' + $filter("NwuiPercentWithDelta")(d.value) + '</strong>';
					}

					scope.$apply();
						
				}
					
				var svg = d3.select(element[0])
					.select('.inner')
					.append("svg");
				
				window.onresize = function() {
					scope.$apply();
				};
				
				scope.$watch(function() {
					return angular.element($window)[0].innerWidth;
				}, function() {
					$timeout(function() {
						scope.render();
					}, 0);
				});
				
				scope.render = function() {
					
					key = (scope.trim_dates)
						? 'index'
						: 'date';
					
					width = $(element).width();
					height = $(element).outerHeight(true);
					
					is_tablet = width <= 600;
					is_mobile = width <= 450;
				 
					if(!data || data.length == 0) return;
	
					var x_max = margin*1
					x_min = margin*2.5
					var y_max = margin;
					var y_min = scope.horiz_axis_labels !== false ? margin*2 : margin;
					
					if(scope.trim_dates) {
						
						x = d3.scale.linear()
							.range([x_min, width-x_max])
					
					} else {
						
						x = d3.time.scale()
							.range([x_min, width-x_max])
						
					}
						
					y = d3.scale.linear()
						.range([height-y_min, y_max])
						//.nice()
					
					var index_st_flag = false;
					var index_en_flag = false;
					var index_st = 0;
					var index_en = data.length-1;
					for(var i = 0; i < data.length; i++) {
						if(moment(data[i].date).format('YYYY-MM-DD') == moment(scope.range.date_st).format('YYYY-MM-DD') && !index_st_flag) {
							index_st = i
							index_st_flag = true;
						}
						if(moment(data[i].date).format('YYYY-MM-DD') == moment(scope.range.date_en).format('YYYY-MM-DD') && !index_en_flag) {
							index_en = i-1
							index_en_flag = true;
						}
					}
					
					data_range = data.slice(index_st, index_en+1);
					
					calibratePerformance(index_st);
					
					if(scope.trim_dates) {
						x.domain([data_range[0].index, data_range[data_range.length-1].index])
					} else {
						//console.log('line boom');
						x.domain([moment(data_range[0].date), moment(data_range[data_range.length-1].date)])
					}
					
					var min = d3.min(data_range, function(d) { return d.value; });
					var max = d3.max(data_range, function(d) { return d.value; });

					if (min === max) {
						min *= 0.9;
						max *= 1.1;

						if (min === 0) {
							min = -1;
							max = 1;
						}
					}

					var divider = getDivider(min-max);
					min = Math.floor(min/divider) * divider;
					max = Math.ceil(max/divider) * divider;

					var valDiff = max - min;

					if(valDiff <= 300 && scope.units == "currency"){
						min -= 300;
						max += 300;
					}else if (valDiff <= 1 && scope.units == "%"){
						min -=1;
						max +=1;
					}
					y
						.domain([min, max])

					xAxis = d3.svg.axis()
						.scale(x)
						.orient("top")
						
					if(scope.trim_dates) {
						
						xAxis
							.tickPadding(0)
							.tickValues(generateXTicks(data_range))
							.tickFormat(generateXFormat)
					
					} else {
						xAxis
							.tickPadding(0) 
							.ticks(8)
							.tickFormat(function(d){
								if(d.getSeconds())
									return moment(d).format("ss");
								else if(d.getMinutes())
									return moment(d).format("h:mm");
								else if(d.getHours())
									return moment(d).format("h:mm");
								else if(d.getDay() && d.getDate() != 1)
									return(moment(d).format("ddd MMM"));
								else if(d.getDate() != 1)
									return(moment(d).format(typeof $locale.MOMENT_CUSTOM_DATE_FORMATS !== "undefined" ? $locale.MOMENT_CUSTOM_DATE_FORMATS.monthDay : "MMM D"))
								else if(d.getMonth())
									return moment(d).format("MMM");
								else {
									return moment(d).format("YY");
								}
							});
					}
					
					var y_num_ticks = Math.ceil(height/75) + 1
					y_num_ticks = y_num_ticks > 6 ? 6 : y_num_ticks;
			
					yAxis = d3.svg.axis()
						.scale(y)
						.orient("right")
						.ticks(y_num_ticks, 0)
						.tickSize(-width, 0)
						.tickFormat(function(d) { 
							
							if(scope.units == 'currency') {
								return $filter('NwuiCurrencyAbbrFilter')(d, 3, 0, true);
							} else if(scope.units == '%') {
								return $filter('NwuiPercent')(d,1);
							} else {
								return $filter('NwuiNumberAbbrFilter')(d, 3);
							}
							
						})
						.tickPadding(0)
						//.tickValues(y.ticks(5))
						//.tickValues(y.ticks(y_num_ticks));
						//.tickValues(y.ticks(y_num_ticks).concat(y.domain()[0]));
					
					area = d3.svg.area()
						.interpolate("monotone")
						.x(function(d) { return x(d[key]); })
						.y0(height)
						.y1(function(d) { return y(d.value); });
					   
					line = d3.svg.line()
						.interpolate("monotone")
						.x(function(d) { return x(d[key]); })
						.y(function(d) { return y(d.value); });
	
					svg
						.attr('width', width)
						.attr('height', height)
						.attr("viewBox", "0 0 " + width + " " + height)
						.attr('xmlns', "http://www.w3.org/2000/svg")
						.attr('version', "1.1")
						.attr('xmlns:xlink', "http://www.w3.org/1999/xlink")
						.selectAll('*')
						.remove();
						
					defs = svg
						.append('defs')
					
					svg
						.selectAll('.y-axis.front line')
						.each(function(d,i) {
							//console.log('nwui-chart-line', 'y-axis-each', this, d, i);
							
							if(i==0) {
								svg
									.append('path')
									.attr("class", 'horiz-line horiz-line-'+i+' zero')
									.attr("d", "M" + (x_min) + " " + (y(d)-1) + " L" + (width-margin) + " " + (y(d)-1))
									.attr("stroke-dasharray", "5,5")
							} else {
								svg
									.append('path')
									.attr("class", 'horiz-line horiz-line-'+i)
									.attr("d", "M" + (x_min) + " " + (y(d)-1) + " L" + (width-margin) + " " + (y(d)-1))
									.attr("stroke-dasharray", "5,5")
							}
							
						})

					svg
					.append("path")
					.attr("class", "line")
						.datum(data)
						.attr("d", line);

					svg
						.append('circle')
						.attr('class', 'end-1')
						.attr("r", 3)
						.attr('fill', 'black')
						.attr("cx", x(data[data.length-1][key]))
						.attr("cy", y(data[data.length-1].value))

					svg
						.append('circle')
						.attr('class', 'end-2')
						.attr("r", 3)
						.attr('fill', 'black')
						.attr("cx", x(data[0][key]))
						.attr("cy", y(data[0].value))
						
					svg
						.append("g")
						.attr("class", "x-axis outline")
						.attr("transform", "translate(0," + (height-20) + ")")
						.call(xAxis)
						
					svg
						.append("g")
						.attr("class", "x-axis front")
						.attr("transform", "translate(0," + (height-20) + ")")
						.call(xAxis)
					
					svg
						.append("g")
						.attr("class", "y-axis outline")
						.attr("transform", "translate(" + (margin) + ",0)")
						.call(yAxis)
					
					svg
						.append("g")
						.attr("class", "y-axis front")
						.attr("transform", "translate(" + (margin) + ",0)")
						.call(yAxis)
					
					//console.log('nwui-chart-line', 'y-axis-select', svg.selectAll('.y-axis.front line'));
					
					focus = svg
						.append("g") 
						.attr('class', 'focus')	
						
					focus
						.append('circle')
						.attr("class", "circle")
						.attr("r", 0);
						
					svg
						.append("rect")
						.attr("x", 0)
						.attr("y", 0)
						.attr('class', 'box')
						.attr("width", width)
						.attr("height", height)
						.on("mouseover", function() {
							if(block_tooltip) return;
						})
						.on("mousemove", mousemove)
						//.call(drag)
						
					$(element).hover(function() {}, function() {
						//$(element).removeClass("active");
						scope.active = false;
							
						svg
							.select('.circle')
							.transition()
							.duration(500)
							.delay(0)
							.attr("r", 0);
							
						scope.$apply();
					})
					
					update(scope.range, 0);
							
				}
				
				scope.$watch('range', function(new_value, old_value) {
					$timeout(function() {
						update(new_value, 1000);
					}, 0);
				});
				
				scope.$watch('series', function(new_value, old_value) {
	
					if(new_value === undefined) return;
					
					scope.loaded = true;
					if(new_value.length > 1) {
						scope.no_data = false;
						
						data = new_value.map(function(d,i) {
					return {
						index: i,
								date: parseDate(d.date),
								value: d.value
							}
						});

						//console.log('nwuiChartLine', 'watch series', data);
						
						data_original = angular.copy(data);
						
						$timeout(function() {
							scope.render();
						}, 0);
					
					} else {
						scope.no_data = true;
					}
					
				})
				
				scope.$watch('height_ratio', function(new_val, old_val) {
					
					if(new_val !== undefined) {
						
						scope.style = {
							'padding-bottom': new_val + '%'
						}
						
						$timeout(function() {
							scope.render();	
						}, 0)
						
					}
					
				});
				
			}
		}
	}
]);