angular.module('nwui-input-radio-group.directives', [])

.directive('nwuiInputRadioGroup', [
	'$sce',
	'$timeout',
	'NwuiInitModuleFactory',
	'NwuiInputService',
	'NwuiValidationService',
	'NwuiValidationErrorService',
	'NwuiFormService',
	
	'NwuiInputRadioGroupRadioFactory', 
	function(
		$sce, 
		$timeout, 
		NwuiInitModuleFactory,
		NwuiInputService, 
		NwuiValidationService, 
		NwuiValidationErrorService,
		NwuiFormService,
		
		NwuiInputRadioGroupRadioFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/input/radio-group/template.html',
			require: '?^nwuiFormV3',
			scope: {
				step_index: '=stepIndex',
				form_name: '=formName',
				id: '=',
				type: '=',
				options: '=',
				label: '=',
				post_var: '=postVar',
				model: '=',
				required: '=',
				stretch: '=',
				color: '=',
				size: '=',
				onChange: '=',
				default_value: '<defaultValue',
				notification: '=',
				hide: '='
			},
			link: function(scope, element, attrs, nwuiFormV3Ctrl) {
				
				scope = NwuiInitModuleFactory({
					options: undefined, //req
					label: '',
					type: '',
					default_value: '',
					required: false,
					error: false,
					notification: {}
				}, scope);

				if(nwuiFormV3Ctrl)
					nwuiFormV3Ctrl.registerInput(scope.step_index, scope);
				if(angular.isDefined(scope.form_name))
					NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);

				var block = false;
				var focussed = false;

				var locks = {
					options: false,
					model: false
				}

				var clear = function() {

					//console.log('nwuiInputRadioGroup', 'clear');

					scope.model = scope.data.model = scope.default_value;

					if(scope.onChange)
						scope.onChange(scope.model);
				}
						
				var validate = function() {

					//console.log('nwuiInputRadioGroup', 'validate')

					var valid = true;

					if(scope.required)
						valid = NwuiValidationService['blank'](scope.model);

					if(valid !== true) {
						scope.error = true;
						scope.notification.error = true;
						scope.notification.text = $sce.trustAsHtml(NwuiValidationErrorService[valid](scope.label));
						scope.notification.show = true;
					}
					
					return valid;
					
				}

				scope.data = {
					model: scope.model
				}
						
				scope.change = function(radio) {
					
					//console.log('nwuiInputRadioGroup', 'scope.change', radio);
					
					// var is_new = true;

					scope.model = radio.value;
					scope.error = false;
					scope.notification.show = false;

					if(scope.onChange)
						scope.onChange(scope.model);

					if(nwuiFormV3Ctrl)
						nwuiFormV3Ctrl.setDirty(scope.id)
					else
						NwuiFormService.setDirty(scope.id);

					//console.log('nwuiInputRadioGroup', 'scope.change', scope.model);

				}

				scope.keyPress = function(event) {

					//console.log('nwuiInputRadioGroup', 'keyPress', focussed, event.keyCode, event.which);

					if(focussed === true) {
						if(event.which === 13) {
							if(nwuiFormV3Ctrl)
								nwuiFormV3Ctrl.enterKeySubmit(scope.id, event)
							else
								NwuiFormService.enterKeySubmit(scope.id, event);
						}
					}

				}

				scope.onFocus = function(event) {

					//console.log('nwuiInputRadioGroup', 'onFocus');
					focussed = true;

				}

				scope.onBlur = function(event) {

					//console.log('nwuiInputRadioGroup', 'onBlur');
					focussed = false;

				}
						
				NwuiInputService.register(scope, validate, clear);
						
				//watch for updates to the radio options
				scope.$watch('options', function(new_val, old_val) {
					
					//console.log('nwuiInputRadioGroup', 'watch options', old_val, '=>', new_val);

					if(new_val === undefined || locks.options === true) return;
					
					locks.options = true;
					// locks.model = true;

					scope.options = new_val.map(function(option,i) {
						option.id = scope.id + '-' + i;
						option.label_safe = $sce.trustAsHtml(option.label);
						return new NwuiInputRadioGroupRadioFactory(option);
					});
					
					$timeout(function() {
						locks.options = false;
						// locks.model = false;
					}, 100);
					
				});

				if(scope.default_value) {
					clear();
				}

				scope.$watch('default_value', function(new_val, old_val) {
					
					//console.log('nwuiInputRadioGroup', 'watch default_value', old_val, '=>', new_val);

				});
				
			}
		}
	}
]);