nwuiHotkeys.factory('NwuiHotkeysFactory', [function() {
	
	var defaults = {
		keys: undefined, //req array
		cb: undefined //req function
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiHotkeysFactory");
		if(obj.keys === undefined) return console.error("Missing required instantiation object variable 'keys' in NwuiHotkeysFactory");
		if(obj.cb === undefined) return console.error("Missing required instantiation object variable 'cb' in NwuiHotkeysFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);