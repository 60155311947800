nwuiCompoundClientGoal.directive('nwuiCompoundClientGoal', [
	'$timeout', 
	'$q',
	'$state',
	'$filter',
	'NwuiCardHeaderFactory',
	'NwuiChartLineFactory',
	'NwuiCardTableFactory',
	'NwuiModalFactory',
	'NwuiCompoundGoalTrackerFactory',
	'NwuiAccordionFactory',
	'NwuiToastFactory',
	
	'NwButtonService',
	'NotificationService',
	'ClientService',
	'NwuiInputService',
	'NwuiToastManagerService',
	'NwuiSession',
	function(
		$timeout,
		$q,
		$state,
		$filter,
		NwuiCardHeaderFactory,
		NwuiChartLineFactory,
		NwuiCardTableFactory,
		NwuiModalFactory,
		NwuiCompoundGoalTrackerFactory,
		NwuiAccordionFactory,
		NwuiToastFactory,
		
		NwButtonService,
		NotificationService,
		ClientService,
		NwuiInputService,
		NwuiToastManagerService,
		NwuiSession
	) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/compound/client-goal/template.html',
        scope: {
	        user_uuid: '=userUuid',
	        client_id: '=clientId',
	        goal_id: '=goalId',
	        has_blind: '=hasBlind',
	        has_header_pill: '=hasHeaderPill'
        },
        link: function(scope, element, attrs) {

			var user = NwuiSession.user
			var goal_detail = undefined;
			
			var loadGoalTarget = function() {
				
				ClientService.checkOnTrack(scope.client_id, scope.goal_id).then(function(obj) {
					
					console.log('checkOnTrack', obj.data);
					var goal_target = obj.data;
					
					scope.cards.goal.accordions[0].target = {
						status: (goal_target.not_set) ? 'error' : (goal_target.on_track) ? 'done' : 'off-track',
						text: (goal_target.not_set) ? 'Not set' : (goal_target.on_track) ? 'On track' : 'Off track'
					}
					
					scope.cards.goal.accordions[0].button = (!goal_target.not_set)
						? {
							id: scope.goal_id + 'open-communcation-modal',
							text: '',
							icon_only: true,
							icon: {
								name: 'email'
							},
							onClick: function() {
								NwuiInputService.inputs[scope.goal_id + 'tracker-input-send-communication-title'].model
									= (goal_target.on_track)
										? "You're doing great!"
										: "You have an off track goal.";
								NwuiInputService.inputs[scope.goal_id + 'tracker-input-send-communication-text'].model
									= (goal_target.on_track)
										? "All your goals are on track. Keep up the good work."
										: "I noticed your goal is no longer on-track and wondered if you were interested in having a quick conversation about your options.";
								scope.modals.communication.open = true;
							}
						} : undefined
					
					//from target
					scope.cards.goal.tracker.not_set = goal_target.not_set;
					scope.cards.goal.tracker.on_track = goal_target.on_track;
					scope.cards.goal.tracker.delta = goal_target.delta;
					scope.cards.goal.tracker.adjust_monthly = goal_target.monthly_fix;
					scope.cards.goal.tracker.adjust_lump_sum = goal_target.lump_sum;
					scope.cards.goal.tracker.adjust_horizon = goal_target.new_horizon;
					
					//from detail
					scope.cards.goal.tracker.target_amount = goal_detail.target_amount;
					scope.cards.goal.tracker.current_amount = goal_detail.holdings;
					
				});
				
			}
			
			var loadGoal = function() {
				
				var deferred = $q.defer()
				
				scope.cards.goal.perf_table.loaded = false;
				
				ClientService.getGoalDetail(scope.client_id, scope.goal_id).then(function(obj) {
					console.log('getGoalDetail', obj.data.goal);
					
					goal_detail = obj.data.goal;
					
					NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-name'].model = goal_detail.name;
					NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-target-amount'].model = goal_detail.target_amount;
					NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-yearly-contribution'].model = goal_detail.yearly_contribution;
					NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-target-date'].model = goal_detail.target_date;
					
					scope.cards.goal.header.name = goal_detail.name;
					
					scope.cards.goal.perf_chart.series = goal_detail.performance.series.map(function(item,i) {
						return {
							value: item.close.percent,
							date: item.date
						}
					})
						
					scope.cards.goal.perf_table.rows = goal_detail.allocation.map(function(item,i) {
				        return {
					        columns: [{
						    	text: item.name
					        }, {
						    	text: (item.fund == 'none') ? '' : item.fund
					        }, {
						    	number: item.percent + '%'
					        }, {
						    	number: item.value
					        }, {
						    	number: item.gains_losses
					        }]
				        }
			        });
			        
			        scope.cards.goal.accounts_table.rows = goal_detail.accounts.map(function(account,i) {
				        return {
					        columns: [{
						    	text: account.type
					        }, {
						    	text: account.nbcn_account_id
					        }, {
						    	number: account.holdings
					        }]
				        }
			        });
			        
			        loadGoalTarget();
			        
			        deferred.resolve(obj)
				
				});
				
				return deferred.promise
				
			}
			
			scope.data = {
				header_pill: undefined
			};
			
			scope.compounds = {
				risk_profile: undefined
			}
			
			scope.cards = {
				goal: {
					header: new NwuiCardHeaderFactory({
				        name: 'Loading...',
				        icon: {
					        name: 'flag'
				        },
				        button: (user.permissions.goals.indexOf('update') != -1) ? {
					        //id: button_id,
					        text: 'Modify Goal',
					        onClick: function() {
						        
						        scope.modals.edit_goal.open = true;
						        //NwuiButtonV2Service.buttons[button_id].processing = true;
						        
					        }
				        } : undefined
					}),
					accordions: [
						new NwuiAccordionFactory({
							name: 'Goal Tracking'
						}), 
						new NwuiAccordionFactory({
							name: 'Performance',
							expanded: true
						}),
						new NwuiAccordionFactory({
							name: 'Loading...'
						}),
						new NwuiAccordionFactory({
							name: 'Accounts'
						})
					],
					tracker: new  NwuiCompoundGoalTrackerFactory({
						on_track: undefined,
						delta: undefined,
						adjust_monthly: undefined,
						adjust_lump_sum: undefined,
						adjust_horizon: undefined 
					}),
			        perf_chart: new NwuiChartLineFactory({
				        series: undefined,
						height_ratio: 30,
						trim_dates: true,
						units: '%'
			        }),
			        perf_table: new NwuiCardTableFactory({
				        name: scope.goal_id + 'client-detail-goal-table-perf',
				        columns: [{
					        name: 'Asset Class',
					        directive: 'text',
							sortable: false
				        }, {
					        name: 'Symbol',
					        width: 70,
					        directive: 'text',
							sortable: false
				        }, {
					        name: 'Weight',
					        width: 120,
					        directive: 'number',
							sortable: false
				        }, {
					        name: 'Value',
					        width: 120,
					        directive: 'currency',
							sortable: false
				        }, {
					        name: 'Gain/loss',
					        width: 140,
					        directive: 'currency-delta',
							sortable: false
				        }],
				        rows: undefined
				    }),
				    accounts_table: new NwuiCardTableFactory({
				        name: scope.goal_id + 'client-detail-goal-table-accounts',
				        columns: [{
					        name: 'Account Type',
					        directive: 'text',
							sortable: false
				        }, {
					        name: 'Custodian #',
					        width: 110,
					        directive: 'text',
							sortable: false
				        }, {
					        name: 'Holdings',
					        width: 120,
					        directive: 'currency',
							sortable: false
				        }],
				        rows: undefined
				    })
				}
			}
			
			scope.modals = {
				edit_goal: new NwuiModalFactory({
				    open: false,
				    wide: true,
			        directive: 'form',
			        data: {
				        header: {
					    	name: 'Modify Goal',
					    	icon: {
						    	name: 'create'
					    	}
				        },
				        name: scope.goal_id + 'goal-edit-form',
				        onSubmit: function() {
					        var data = {
						        name: NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-name'].model,
								target_amount: NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-target-amount'].model,
								yearly_contribution: NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-yearly-contribution'].model,
								target_date: NwuiInputService.inputs[scope.goal_id + 'goal-edit-form-input-target-date'].model
					        }
					        console.log(data);
							return ClientService.setGoalTarget(scope.client_id, scope.goal_id, data.name, data.target_amount, data.yearly_contribution, data.target_date).then(function() {
								loadGoal();
								return;
							});
				        },
					    onComplete: function() {
							scope.modals.edit_goal.open = false;
							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text: 'Goal updated',
									icon: {
										name: 'check'
									}
								}
							));
					    },
				        columns: [{
					        directive: 'input',
					        width: 60,
					        data: {
						        directive: 'text',
						        data: {
							        label: 'Goal Name',
							        model: '',
							        required: true,
							        id: scope.goal_id + 'goal-edit-form-input-name',
							        placeholder: "My Retirement Goal"
						        }
					        }
				        },{
					   		directive: 'input',
					   		width: 40,
					   		data: {
						   		directive: 'text',
						        data: {
							        label: 'Goal Target',
							        model: '',
							        mask: 'currency',
							        required: true,
							        id: scope.goal_id + 'goal-edit-form-input-target-amount',
							        placeholder: "$225,000"
						        }
						   	}
				   		},{
					   		directive: 'input',
					   		width: 40,
					   		data: {
						   		directive: 'text',
						        data: {
							        label: 'Yearly Contribution',
							        model: '',
							        mask: 'currency',
							        required: true,
							        id: scope.goal_id + 'goal-edit-form-input-yearly-contribution',
							        placeholder: "$50,000"
						        }
						   	}
				   		},{
					   		directive: 'input',
					   		width: 35,
					   		data: {
						   		directive: 'text',
						        data: {
							        label: 'Target Date',
							        model: '',
							        mask: 'date',
							        required: true,
							        id: scope.goal_id + 'goal-edit-form-input-target-date',
							        placeholder: moment(new Date()).format('YYYY/MM/DD')
						        }
						   	}
				   		},{
					   		directive: 'button',
					   		width: 25,
					   		data: {
						        stretch: true,
						        text: 'Update',
						        onClick: function() { return; }
					        }
					   	}]
			        }
			    }),
				communication: new NwuiModalFactory({
			        open: false,
				    wide: true,
			        directive: 'form',
			        data: {
				        header: {
					    	name: 'Send Communication',
					    	icon: {
						    	name: 'send'
					    	}
				        },
				        name: scope.goal_id + 'tracker-send-communication-form',
				        onSubmit: function() {
					        var data = {
						        subject: NwuiInputService.inputs[scope.goal_id + 'tracker-input-send-communication-title'].model,
						        text: NwuiInputService.inputs[scope.goal_id + 'tracker-input-send-communication-text'].model,
						        email: true//NwuiInputService.inputs[scope.goal_id + 'tracker-input-send-communication-email'].model
					        }
					        return NotificationService.create(scope.user_uuid, data.subject, data.text, data.email).then(function(obj) {
						        return true;
					        });
				        },
					    onComplete: function() {
						    scope.modals.communication.open = false;
						    NwuiToastManagerService.register(
								new NwuiToastFactory({
									text: 'Communication sent',
									icon: {
										name: 'check'
									}
								}
							));
					    },
				        columns: [{
							directive: 'input',
							width: 100,
					        data: {
						        directive: 'text',
						        data: {
							        label: 'Subject',
							        model: '',
							        id: scope.goal_id + 'tracker-input-send-communication-title',
							        stretch: true,
							        placeholder: "You're doing great!"
							    }
					        }
					    }, {
						    directive: 'input',
							width: 100,
					        data: {
						        directive: 'textarea',
						        data: {
							        model: '',
							        id: scope.goal_id + 'tracker-input-send-communication-text',
							        label: 'Message body',
							        placeholder: 'All your goals are on track. Keep up the good work.'
							    }
							}
						}, {
							directive: 'button',
							width: 40,
					        data: {
						        id: scope.goal_id + 'tracker-form-send-communication-button',
						        text: 'Send',
								onClick: function() {},
						        stretch: true,
						        processing: false,
						        disabled: false
					        }
					    }/*, {
							directive: 'input',
							width: 60,
					        data: {
						        directive: 'toggle',
						        data: {
							        label: 'Send communication to email?',
							        model: true,
							        id: 'tracker-input-send-communication-email',
							        stretch: true,
							        size: 'small'
							    }
					        }
					    }*/]
			        }
		        })
			}
			
			scope.$watchGroup(['client_id', 'goal_id'], function(new_val) {
				
				console.log('clientDetail', new_val);
				if(new_val[0] !== undefined && new_val[1] !== undefined) {
					loadGoal();
				}
				
			});
			
			scope.$watchGroup(['has_header_pill', 'data.header_pill'], function(new_val, old_val) {
				
				if(new_val[0] === true) {
					scope.cards.goal.header.pill = angular.copy(new_val[1]);
				} else {
					delete scope.cards.goal.header.pill;
				}
				
			})
			
			scope.$watch('compounds.risk_profile', function(new_val) {
				
				console.log('compounds.risk_profile', new_val)
				
				if(new_val !== undefined) {
					
					var current_date = moment();
					
					//pm approved date
					var approved_date = moment(new_val.kyc_instance.pm_approved_date)
					
					//1 year from pm approved date
					var expiry_date = moment(new_val.kyc_instance.pm_approved_date).add(1, 'year')
					
					//distance to expiry in unix
					var diff = expiry_date.unix() - current_date.unix()
					
					var normal = 60 * 60 * 24 * 30; //1 month (2,592,000)
					var warning = 60 * 60 * 24 * 10; //10 days (864,000)
					
					console.log('rp_exp', diff, approved_date.unix(), expiry_date.unix());
					
					//1467676800-1436054400
					//31,622,400
					
					scope.cards.goal.accordions[2].name = 'Risk Profile - ' + new_val.kyc_instance.score.toFixed(2);
					scope.cards.goal.accordions[2].target = {
						status: (diff < warning) ? 'error' : (diff < normal) ? 'off-track' : 'on-track',
						text: (diff <= 0) ? 'Expired ' + expiry_date.fromNow() : 'Expires ' + expiry_date.fromNow()
					}
					scope.cards.goal.accordions[2].button = {
						id: scope.goal_id + 'unlock-risk-profile',
						text: '',
						icon_only: true,
						icon: {
							name: 'create'
						},
						onClick: function() {
							NwButtonService.buttons[scope.goal_id + 'unlock-risk-profile'].processing = true;
							
							$state.go('users.risk_profiles.view', { goal_id: scope.goal_id });
						}
					} 
					
				}
				
			});
						
	    }
	}
}]);