nwuiCompoundGoalRiskProfile.directive('nwuiCompoundGoalRiskProfile', [
	'$timeout',
	
	'NwuiBlindFactory',
	'NwuiAllocationFactory',
	'NwuiFormFactory',
	
	'GoalService',
	'UserService',
	function(
		$timeout,
		
		NwuiBlindFactory,
		NwuiAllocationFactory,
		NwuiFormFactory,
			
		GoalService,
		UserService
	) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/compound/goal-risk-profile/template.html',
        scope: {
	        goal_id: '=goalId',
	        user_id: '=userId',
	        has_blind: '=hasBlind',
	        risk_profile_obj: '=riskProfile'
        },
        link: function(scope, element, attrs) {
			
			var loadActiveRiskProfileByGoal = function() {
				
				GoalService.getActiveRiskProfiles(scope.goal_id).then(function(obj) {
					//console.log('GoalService.getActiveRiskProfiles', obj.risk_profile);
					loadActiveRiskProfile(obj.risk_profile)
				});
				
			}
			
			var loadActiveRiskProfileByUser = function() {
				
				UserService.getRiskProfile(scope.user_id).then(function(obj) {
					//console.log('UserService.getRiskProfile', obj.data);
					loadActiveRiskProfile(obj.data);
				});
				
			}
			
			var loadActiveRiskProfile = function(risk_profile) {
				
				var active_risk_profile = risk_profile;
				var columns = [];
				
				scope.risk_profile_obj = active_risk_profile;
				
				//trim
				active_risk_profile.kyc_instance.steps[0].rows[0].fields.splice(0,3)
			    active_risk_profile.kyc_instance.steps.splice(active_risk_profile.kyc_instance.steps.length-1,1);
			    
			    scope.risk_profile.form.name = 'goal-risk-profile-' + scope.goal_id;
			    
			    //format for allocation
			    scope.risk_profile.allocation.series = active_risk_profile.pie_data.data.map(function(datum,i) {
				    return {
					    name: datum.key,
						percent: datum.y,
						type: datum.type
				    }
			    });
			    
			    //convert inputs to form
			    active_risk_profile.kyc_instance.steps.forEach(function(step,i) {
			        step.rows.forEach(function(row,j) {
				    	row.fields.forEach(function(field,k) {
					    	
					    	var value = undefined;
					    	
					    	if(field.input.input_type == 'select' || field.input.input_type == 'radio') {
						    	field.input.options.forEach(function(option,i) {
							    	if(option.value == active_risk_profile.kyc_answers[field.input.model])
							    		value = option.text;
						    	});
					    	}
					    	
					    	columns.push({
						        width: (field.width > 50) ? 50 : field.width,
						        directive: 'input',
						        data: {
							        directive: 'text',
							        data: {
								        label: field.label,
								        model: value || active_risk_profile.kyc_answers[field.input.model],
								        id: 'goal-' + scope.goal_id + '-risk-profile-input-' + field.input.model,
								        placeholder: 'Not set',
								        disabled: true,
								        size: 'short'
								    }
							    }
					        });
													    	
					    })
					})
				});
				
				scope.risk_profile.form.columns = columns;
				
			}
			
			scope.risk_profile = {
		        blind: new NwuiBlindFactory({
			    	open: false,
			    	closed_height: 50,
			    	text_open: 'Show risk profile Q&A',
			    	text_close: 'Hide risk profile Q&A'
		        }),
		        allocation: new NwuiAllocationFactory({
			        series: [],
			        decimal: 0
		        }),
		        form: new NwuiFormFactory({
			    	name: 'client-detail-risk-profile-form',
			        onSubmit: function() {
				    },
			        onComplete: function() {
				    },
				    columns: []
				})
			}
			
			scope.modals = {}
			
			$timeout(function() {
				
				scope.$watch('goal_id', function(new_val) {
					
					if(new_val !== undefined) {
						loadActiveRiskProfileByGoal();
					}
					
				});
				
				scope.$watch('user_id', function(new_val) {
					
					if(new_val !== undefined) {
						loadActiveRiskProfileByUser();
					}
					
				});
			
			}, 250);
						
	    }
	}
}]);