nwuiDividerV2.directive('nwuiDividerV2', [
	'$timeout',
	'NwuiIconV2Factory',
	function(
		$timeout,
		NwuiIconV2Factory
	) {
		return {
			restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/divider/v2/template.html',
      scope: {
        name: '=?',
        name_i18n: '=?nameI18n',
        icon: '=?'
      },
      compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) {},
					post: function(scope, element, attrs) {}
				}
			}
		}
	}
]);