nwuiCompoundModalCreateEft.directive('nwuiCompoundModalCreateEft', [
	'$timeout',
	'$filter',
	'$stateParams',
	'$state',
	'FundFactsService',
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	'NwuiButtonV3Factory',
	'NwuiSigningService',
	'NwuiPageScrollService',

	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'DATE_FORMAT',
	'NWUI_SELECT_OPTIONS',
	'ApiAccountsService',
	'locale',
	function(
		$timeout,
		$filter,
		$stateParams,
		$state,
		FundFactsService,
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		NwuiButtonV3Factory,

		NwuiSigningService,
		NwuiPageScrollService,

		NwuiToastManagerService,
		NwuiInputService,
		NwuiFormV3Service,
		DATE_FORMAT,
		NWUI_SELECT_OPTIONS,
		ApiAccountsService,
		locale
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/create-eft/template.html',
			scope: {
				open: '=',
				accounts: '=accounts',
				formResource: '=formResource',
				accounts_efts: '=accountsEfts',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {

				var terms_open = false;

				var supports_eft_source_page = (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.eft_sources &&
					organization_data.port_data.transfers.eft_sources.create &&
					organization_data.port_data.transfers.eft_sources.create.version === 'page'
				)

				var submitCb = function(data) {
					scope.submitCb(data);
				}

				var updateToOptions = function(account_id) {
					//console.log('nwuiCompoundModalCreateEft', 'updateToOptions', account_id, scope.accounts_efts)

					//var account_id = NwuiInputService.inputs['create-eft-form-input-to-account'].model;

					NwuiInputService.inputs['create-eft-form-input-from-account'].model = '';
					NwuiInputService.inputs['create-eft-form-input-from-account'].options = [];

					var first;
					scope.accounts_efts.forEach(function(account_eft, k) {
						if (account_id === account_eft.id) {
							account_eft.EFTs.forEach(function(eft, l) {
								//console.log('nwuiCompoundModalCreateEft', 'Found account_eft', account_eft);
								if (!first) {
									first = eft.id;
								}
								if(account_eft.custodian_slug == 'fcc') {
									if(!eft.custodian_code) return;
								}
								NwuiInputService.inputs['create-eft-form-input-from-account'].options.push({
									id: 'eft-' + k + '-' + l,
									// text: eft.web_data,
									text_i18n: 'nwui.common.input.source_account.option',
									text_i18n_data: {
										accountWebData: eft.web_data
									},
									value: eft.id
								});
							})
						}
					});

					NwuiInputService.inputs['create-eft-form-input-from-account'].options.push({
						id: 'eft-add-new',
						// text: '+ Add new transfer source',
						text_i18n: 'nwui.common.input.source_account.new_account',
						value: 'add-new'
					});

					if(first) {
						$timeout(function() {
							NwuiInputService.inputs['create-eft-form-input-from-account'].model = first;
						}, 0)
					}

				}

				scope.openTerms = function() {
					//console.log('nwuiCompoundModalCreateEft', 'openTerms');
					terms_open = true;
					scope.open = false;

					$timeout(function() {
						scope.modals.eft_terms.open = true;
					}, 250);
				}

				var steps = [{
					columns: [{
						directive: 'input',
						width: 100,
						data: {
							directive: 'select',
							data: {
								// label: 'Destination account',
								label_i18n: 'nwui.common.input.destination_account.label',
								model: $stateParams.account_id || '',
								id: 'create-eft-form-input-to-account',
								stretch: true,
								required: true,
								// placeholder: "Transfer to account...",
								placeholder_i18n: 'nwui.common.input.destination_account.placeholder',
								options: scope.accounts,
								post_var: 'to_account',
								onChange: function(val) {
									if(val == undefined || val == '')
										return false;
									if(organization_data &&
									organization_data.port_data &&
									organization_data.port_data.transfers &&
									organization_data.port_data.transfers.efts &&
									organization_data.port_data.transfers.efts.fund_facts &&
									organization_data.port_data.transfers.efts.fund_facts.show === true) {
										ApiAccountsService.getAllocation( {id: val} ).then(function(res) {
											FundFactsService.populateFundFactStepInForm(res, scope.modals.create_eft.form);
										});
									}

									$timeout(function() {
										updateToOptions(val);
									}, 0);
								}
							}
						}
					}, {
						directive: 'input',
						width: 100,
						data: {
							directive: 'select',
							data: {
								// label: 'Source account',
								label_i18n: 'nwui.common.input.source_account.label',
								model: -1,
								id: 'create-eft-form-input-from-account',
								stretch: true,
								required: true,
								// placeholder: "Transfer from account...",
								placeholder_i18n: 'nwui.common.input.source_account.placeholder',
								options: [],
								post_var: 'from_account',
								onChange: function() {
									////console.log('change', NwuiInputService.inputs['create-eft-form-input-from-account'].model);
									if(NwuiInputService.inputs['create-eft-form-input-from-account'].model === 'add-new') {

										if(supports_eft_source_page) {
											$state.go('app.manage.transfer_sources.new');
										} else {
											$state.go('app.manage.transfer_sources.list', { modal: true });
										}

									}
								}
							}
						}
					}]
				}, {
					description_i18n: 'nwui.modal.create_eft.disclaimer',
					columns: [{
						directive: 'input',
						width: 100,
						data: {
							directive: 'select',
							data: {
								// label: 'Frequency',
								label_i18n: 'nwui.common.input.frequency.label',
								model: 0,
								id: 'create-eft-form-input-frequency',
								stretch: true,
								required: true,
								// placeholder: "Select frequency...",
								placeholder_i18n: 'nwui.common.input.frequency.placeholder',
								post_var: 'frequency',
								options: organization_data.port_data.transfers.efts.frequency.map(function(f){
									return {
										id: f.value,
										value: f.name,
										text_i18n: 'nwui.common.form.select.option.frequency.' + f.slug
									}
								}),
								onChange: function(val) {

									if(!NwuiInputService.inputs['create-eft-form-input-date-start']) return;

									if (val === 'one time') {
										NwuiInputService.inputs['create-eft-form-input-date-start'].disabled = true;
										NwuiInputService.inputs['create-eft-form-input-date-start'].label_i18n = 'nwui.common.input.date_start.label_ad_hoc';
										NwuiInputService.inputs['create-eft-form-input-date-start'].model = moment().add(1, 'day').format(DATE_FORMAT.digit);
										NwuiInputService.inputs['create-eft-form-input-date-start'].model_text = moment().add(1, 'day').format('YYYY/MM/DD');
										NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future'];

									} else {
										NwuiInputService.inputs['create-eft-form-input-date-start'].disabled = false;
										NwuiInputService.inputs['create-eft-form-input-date-start'].label_i18n = 'nwui.common.input.date_start.label';
										NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future_in_businessdays'];
									}
								}
							}
						}
					}, {
						directive: 'input',
						width: 100,
						data: {
							directive: 'text',
							data: {
								// label: 'Amount',
								label_i18n: 'nwui.common.input.amount.label',
								model: '',
								id: 'create-eft-form-input-amount',
								stretch: true,
								mask: 'currency',
								required: true,
								post_var: 'amount',
								validators: ['eft_max_amount'],
								min_val: function() {
									if(organization_data &&
									organization_data.port_data &&
									organization_data.port_data.transfers &&
									organization_data.port_data.transfers.efts &&
									organization_data.port_data.transfers.efts.min_dollar_amount) {
										return organization_data.port_data.transfers.efts.min_dollar_amount;
									}
									else {
										return undefined;
									}
								}(),
								// placeholder: "$25,000"
								placeholder_i18n: 'nwui.common.input.amount.placeholder'
							}
						}
					}, {
						directive: 'input',
						width: 100,
						data: {
							directive: 'text',
							data: {
								// label: 'Start date',
								label_i18n: 'nwui.common.input.date_start.label',
								model: moment().add(1, 'day').format(DATE_FORMAT.digit),
								id: 'create-eft-form-input-date-start',
								stretch: true,
								mask: 'date',
								validators: ['date_future_in_businessdays'],
								required: true,
								post_var: 'start_date',
								placeholder: DATE_FORMAT.short
							}
						}
					}]
				}]

				//if has eft terms confirm check
				if(
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.efts &&
					organization_data.port_data.transfers.efts.show_terms_link === true
				) {
					steps.push({
						description: "Please review and acknowledge the <a ng-click='descriptionFunction();'>Electronic Transfer (EFT) Terms and Conditions</a> before submitting your funds transfer request.",
						descriptionFunction: scope.openTerms,
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'checkbox',
								data: {
									// label_i18n: 'nwui.common.input.eft_terms_and_conditions.label',
									id: 'create-eft-form-input-confirmations',
									stretch: true,
									required: true,
									options: [{
										label_i18n: 'nwui.common.input.eft_terms_and_conditions.check_0',
										model: "terms_and_conditions",
										value: 1,
										required: true
									}],
									size: '',
									post_var: 'user_confirmations'
								}
							}
						}]
					})
				}

				// Only add borrow to invest steps if the tenant has borrow to invest enabled
				if(
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.accounts &&
					organization_data.port_data.accounts.borrow_to_invest &&
					organization_data.port_data.accounts.borrow_to_invest.enabled === true
				) {
					steps.push({
						description_i18n: 'nwui.forms.questions.AHBorrowedToInvest.tooltip.content', // copy tooltip from onboarding
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'radio',
								data: {
									id: 'borrowed_to_invest',
									label_i18n: 'nwui.modal.borrowed_to_invest.label',
									// label: 'Have you borrowed money to invest in this account?',
									model: '',
									stretch: true,
									required: true,
									post_var: 'borrowed_to_invest',
									default_value: 'No',
									onChange: (val) => {
										if(NwuiInputService.inputs['borrowed_amount'])
											NwuiInputService.inputs['borrowed_amount'].hide = val === 'Yes' ? false : true;
									},
									options: [{
										label_i18n: 'nwui.modal.borrowed_to_invest.option.Yes',
										// label: "Yes",
										value: "Yes"
									}, {
										label_i18n: 'nwui.modal.borrowed_to_invest.option.No',
										// label: "No",
										value: "No"
									}]
								}
							}
						}, {
							directive: 'input',
							width: 100,
							data: {
								directive: 'text',
								data: {
									post_var: 'borrowed_amount',
									hide: true,
									// label: 'Borrowed Amount (CAD)',
									label_i18n: 'nwui.common.input.borrowed_amount.label',
									model: '',
									id: 'borrowed_amount',
									stretch: true,
									mask: 'currency',
									required: true,
									validators: ['greater_than'],
									min_val: function() {
										if(organization_data &&
											organization_data.port_data &&
											organization_data.port_data.transfers &&
											organization_data.port_data.transfers.efts.borrowed_amount_min &&
											organization_data.port_data.transfers.efts.borrowed_amount_min) {
												return organization_data.port_data.transfers.efts.borrowed_amount_min;
										} else {
											return 1;
										}
									}()
								}
							}
						}]
					});
				}

				scope.modals = {
					create_eft: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							// name: 'Fund Account',
							name_i18n: 'nwui.modal.create_eft.title',
							icon: {
								name: 'attach_money'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'create-eft-form',
							onSubmit: function(post_data) {
								//console.log('nwuiCompoundModalCreateEft', 'form post_data', post_data);
								post_data.confirmed_contribution_room = true;
								return scope.formResource(post_data);
							},
							onComplete: function(obj) {

								scope.open = false;

								if(obj.data.error) {
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											// text: 'Your transfer could not be submitted',
											text_i18n: 'nwui.modal.create_eft.error',
											icon: {
												name: 'clear'
											}
										}
									));

									submitCb(obj.data);
								} else {
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											// text: 'Your transfer has been submitted successfully',
											text_i18n: 'nwui.modal.create_eft.success',
											icon: {
												name: 'check'
											}
										}
									));

									if (obj.data.envelope_id) {
										//console.log('nwuiCompoundModalCreateEft', 'has envelope_id', obj.data.envelope_id);
										$timeout(function() {
											NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelope_id);
											NwuiSigningService.setSubmitCb('global-document-signing', function(obj2) {
												//console.log('nwuiCompoundModalCreateEft', 'NwuiSigningService.setSubmitCb', obj2);
												if (obj2.is_signed) {
													submitCb(obj.data);
												}
											});

											NwuiPageScrollService.open('global-document-signing');
										}, 0);
										submitCb(obj.data);
									} else {
										submitCb(obj.data);
									}
								}

							},
							steps: steps
						})
					}),
					eft_terms: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.eft_terms.title',
							icon: {
								name: 'assignment'
							}
						}),
						buttons: {
							close: new NwuiButtonV3Factory({
								id: 'eft-terms-button-close',
								// text: 'Return to Transfer',
								text_i18n: 'nwui.common.modal.eft_terms.button',
								color: '',
								icon: {
									name: 'undo'
								},
								stretch: true,
								onClick: function() {

									scope.modals.eft_terms.open = false;

								}
							})
						}
					})
				}

				scope.$watch('modals.eft_terms.open', function(new_val) {

					if(new_val === undefined) return;

					if(new_val === false && terms_open === true) {
						terms_open = false;

						$timeout(function() {
							scope.open = true;
						}, 250);
					}

				})

				scope.$watch('open', function(new_val) {

					//console.log('nwuiCompoundModalCreateEft', 'watch open', new_val);
					if(new_val === undefined) return;

					if(new_val === false) {
						if(terms_open === true) return;
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.create_eft.form.name);
							NwuiInputService.inputs['create-eft-form-input-to-account'].model = $stateParams.account_id || '';
							NwuiInputService.inputs['create-eft-form-input-date-start'].model = moment().add(1, 'day').format(DATE_FORMAT.digit);
							updateToOptions(NwuiInputService.inputs['create-eft-form-input-to-account'].model);
							scope.modals.create_eft.form.active_step_index = 0;
						}, 750)
					} else if(new_val === true) {
						if (
							NwuiInputService.inputs['create-eft-form-input-to-account'] &&
							NwuiInputService.inputs['create-eft-form-input-to-account'].model
						)
							updateToOptions(NwuiInputService.inputs['create-eft-form-input-to-account'].model);

						NwuiFormV3Service.focus(scope.modals.create_eft.form.name);
					}

					scope.modals.create_eft.open = new_val;

				});

				scope.$watch('accounts_efts', function(new_val) {
					//console.log('nwuiCompoundModalCreateEft', 'watch accounts_efts', new_val);
					if(new_val === undefined || new_val.length === 0) return;

					updateToOptions(NwuiInputService.inputs['create-eft-form-input-to-account'].model);
				})

			}
		}
	}
]);
