angular.module('nwui-compound-modal-info-confirm.directives', [])

.directive('nwuiCompoundModalInfoConfirm', [
	'NwuiModalFactory',
	function(
		NwuiModalFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/info-confirm/template.html',
			scope: {
				open: '=',
				title_i18n: '=?titleI18n',
				description_i18n: '=?descriptionI18n',
				primary_button: "=?primaryButton",
				secondary_button: "=?secondaryButton"
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						scope.col_width = 12;
						scope.open = false;

					},
					post: function(scope, element, attrs) {
						
						scope.modal = new NwuiModalFactory({
							open: false,
							wide: false,
							header: {
								level: 2,
								icon: {
									name: 'info'
								}
							}
						})

						scope.$watch('open', function(new_val, old_val) {
					
							if(new_val === undefined || new_val === old_val) return;
							
							scope.modal.open = new_val;
							
						});

					}
				}
			}
		}
	}
]);