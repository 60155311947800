nwuiCardTabs.factory('NwuiCardTabsFactory', [function() {
	
	var defaults = {
		name: undefined, //req
		tabs: undefined //req
	}
	
	return function(obj) {
		//console.log('NwuiCardTabsFactory', obj);
		if(typeof obj === undefined) return console.error("Missing instantiation object for nwuiCardTabsFactory");
		if(obj.name === undefined) return console.error("Missing required instantiation object variable 'name' in nwuiCardTabsFactory");
		if(obj.tabs === undefined) return console.error("Missing required instantiation object variable 'tabs' in nwuiCardTabsFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);