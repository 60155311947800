nwuiDrawerV1NotificationText.directive('nwuiDrawerV1NotificationText', [
	'$timeout',
	'$sce',
	function(
		$timeout,
		$sce
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/drawer/v1/notification/text/template.html',
			scope: {
				lead: '=',
				lead_i18n: '=leadI18n',
				lead_i18n_data: '=leadI18nData'
			},
			link: function(scope, element, attrs) {

				scope.$watch('lead', function(new_val) {

					if(new_val === undefined) return;

					// scope.lead_safe = $sce.trustAsHtml(new_val);
					scope.lead_safe = new_val;

				})

			}
		}
	}
]);