nwuiCardTableV3.service('NwuiCardTableV3Service', ['NwuiCardTableV3Factory', function(NwuiCardTableV3Factory) {
	
	var self = this;
	
	this.tables = {};
	
	this.register = function(id, reloadFn) {
		self.tables[id] = {
			reload: reloadFn
		};
	}
    
}]);