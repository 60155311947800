nwuiUtilClasses.directive('nwuiUtilClassBody', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				
				//console.log('nwuiUtilClassBody', 'init', $(element), ('ontouchstart' in document));

				// window.onload = function() {
					
					// //console.log('nwuiUtilClassBody', 'onload', ('ontouchstart' in document));
 
				$timeout(function() {

					if('ontouchstart' in document) {
						//console.log('nwuiUtilClassBody', $(element), 'has touch');
						$(element).addClass('touch');
					} else {
						//console.log('nwuiUtilClassBody', $(element), 'no touch');
						$(element).addClass('no-touch');
					}

				}, 0);
					
				// }
				
			}
		}
	}
]);