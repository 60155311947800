nwuiButton.service('NwuiButtonService', [function() {
	
	var self = this;	
	this.buttons = {};
	
	this.unregister = function(button_id) {
		delete self.buttons[button_id];
	}
	
	this.register = function(button) {
		if(button.id)
			self.buttons[button.id] = button;
	}
	
}]); 