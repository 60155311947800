nwuiCardTableV2HeaderColumn.factory('NwuiCardTableV2HeaderColumnFactory', [function() {
	
	var defaults = {
		name: undefined,
		name_i18n: undefined,
		width: 'auto',
		directive: 'text',
		sort: 0,
		sortable: true,
		clickEvent: undefined
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableV2HeaderColumnFactory");
		if(obj.name === undefined && obj.name_i18n === undefined) return console.error("Missing required instantiation object variable 'name' or 'name_i18n' in NwuiCardTableV2HeaderColumnFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);