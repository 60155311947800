nwuiDrawerV1Notification.directive('nwuiDrawerV1Notification', [
	'$timeout',
	'$state',
	'$filter',
	'locale',
	'DATE_FORMAT',

	'NwuiOverlayService',
	'NwuiDrawerV1Service',
	'NwButtonService',

	'NwuiIconV2Factory',
	'NwuiTargetFactory',
	'NwuiDrawerV1NotificationTextFactory',
	'NwuiDrawerV1NotificationLinkFactory',
	function(
		$timeout,
		$state,
		$filter,
		locale,
		DATE_FORMAT,

		NwuiOverlayService,
		NwuiDrawerV1Service,
		NwButtonService,

		NwuiIconV2Factory,
		NwuiTargetFactory,
		NwuiDrawerV1NotificationTextFactory,
		NwuiDrawerV1NotificationLinkFactory
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/drawer/v1/notification/template.html',
	        scope: {
	        	type: '=',
	        	icon: '=',
	        	date: '=',
	        	read: '=',
	        	important: '=',
	        	data: '='
	        },
	        link: function(scope, element, attrs) {

	        	scope.modules = {
	        		target: undefined
	        	}

	        	scope.$watch('read', function(new_val) {

	        		if(new_val === undefined) return;

	        		scope.modules.target = new NwuiTargetFactory({
	        			status: 'done',
	        			text: ''
	        		});

	        	});

		        scope.$watch('date', function(new_val) {

		        	if(new_val === undefined) return;

		        	scope.date_formatted = moment(new_val).utc().format(DATE_FORMAT.human);

		        });

	        	scope.$watchGroup(['type', 'data'], function(new_val) {

	        		var type = new_val[0];
	        		var raw_data = new_val[1];

	        		if(type === undefined || raw_data === undefined) return;

	        		var data = {};

	        		switch(type) {
	        			case 'general':
	        				data.lead = raw_data.meta.message;
	        				data.lead_i18n = raw_data.meta.message_i18n;
	        				data.meta = [{
	        					label: 'Marc Whitbread'
	        				}];

	        				scope.icon = new NwuiIconV2Factory({
	        					name: 'chat'
	        				})
	        				scope.text = new NwuiDrawerV1NotificationTextFactory(data);
	        				break;
	        			case 'account_link':
	        				data.lead = raw_data.notification.message;
	        				data.lead_i18n = raw_data.notification.message_i18n;
	        				data.meta = []

	        				switch(raw_data.meta.type) {
	        					case 'DRIP':
			        				data.meta = [{
			        					state: 'app.accounts.view',
			        					params: { account_id: raw_data.meta.account_id }, 
			        					label: raw_data.meta.account_type
			        				},{
		        						label: raw_data.meta.asset,
		        						value: $filter('NwuiNumberDelta')(raw_data.meta.quantity, undefined, (raw_data.meta.quantity === 1) ? locale.getString("nwui.common.drawer.notification.share") : locale.getString("nwui.common.drawer.notification.shares"), 0)
		        					},{
			        					label: 'Cash',
			        					value: $filter('NwuiCurrencyDeltaAbbrFilter')(raw_data.meta.amount)
			        				}]
			        				break;
			        			case 'BUY':
			        				data.meta = [{
			        					state: 'app.accounts.view',
			        					params: { account_id: raw_data.meta.account_id },
			        					label: raw_data.meta.account_type 
			        				}]

			        				var cash_amount = 0;
			        				raw_data.meta.transactions.forEach(function(transaction,i) {
			        					cash_amount += transaction.amount;
			        					data.meta.push({
			        						label: transaction.asset,
			        						value: $filter('NwuiNumberDelta')(transaction.quantity, undefined, (transaction.quantity === 1) ? locale.getString("nwui.common.drawer.notification.share") : locale.getString("nwui.common.drawer.notification.shares"), 0)
			        					})
			        				});

			        				data.meta.push({
			        					label: 'Cash',
			        					value: $filter('NwuiCurrencyDeltaAbbrFilter')(cash_amount)
			        				});
			        				break;
			        			case 'CONT':
			        				data.meta = [{
			        					state: 'app.accounts.view',
			        					params: { account_id: raw_data.meta.account_id }, 
			        					label: raw_data.meta.account_type 
			        				}, {
			        					label: 'Cash',
			        					value: $filter('NwuiCurrencyDeltaAbbrFilter')(raw_data.meta.amount)
			        				}]
			        				break;
			        		}

			        		/*
	        				data.button = {
	        					id: 'notification-button-' + raw_data.id,
	        					text: 'View Account',
	        					color: (scope.important === true) ? 'error' : '',
	        					icon: {
	        						name: 'account_balance'
	        					},
	        					onClick: function() {

	        						if($state.includes('accounts', { account_id: raw_data.meta.account_id })) {
	        							NwuiDrawerV1Service.close();
	        							return;
	        						}

	        						NwuiOverlayService.overlays['global-overlay'].icon.name = "account_balance";
									NwuiOverlayService.overlays['global-overlay'].message = "Loading Accounts...";
									NwuiOverlayService.overlays['global-overlay'].show = true;

									NwButtonService.buttons['notification-button-' + raw_data.id].processing = true;

									$timeout(function() {
		        						$state.go('app.accounts.view', { account_id: raw_data.meta.account_id });
		        					}, 750);

	        					}
	        				}
	        				*/
	        				
		        			scope.link = new NwuiDrawerV1NotificationLinkFactory(data);
		        			break;
	        			case 'activity_link':
	        				data.lead = raw_data.notification.message;
	        				data.lead_i18n = raw_data.notification.message_i18n;

	        				data.meta = []

	        				switch(raw_data.meta.type) {
			        			case 'DIV':
			        			case 'TXPDDV':
			        				//quantity: total units producing dividend
			        				//amount: total dividend paid
			        				//asset: asset paid on dividend
			        				data.meta = [{
			        					state: 'app.accounts.view',
			        					params: { account_id: raw_data.meta.account_id },
			        					label: raw_data.meta.account_type,
			        				}, {
			        					label: 'Cash',
			        					value: $filter('NwuiCurrencyDeltaAbbrFilter')(raw_data.meta.amount)
			        				}]
			        				break;
			        		}

			        		/*
	        				data.button = {
	        					id: 'notification-button-' + raw_data.id,
	        					text: 'View Activities',
	        					color: (scope.important === true) ? 'error' : '',
	        					icon: {
	        						name: 'event_note'
	        					},
	        					onClick: function() {

	        						if($state.includes('activity')) {
	        							NwuiDrawerV1Service.close();
	        							return;
	        						}

	        						NwuiOverlayService.overlays['global-overlay'].icon.name = "event_note";
									NwuiOverlayService.overlays['global-overlay'].message = "Loading Activity...";
									NwuiOverlayService.overlays['global-overlay'].show = true;

									NwButtonService.buttons['notification-button-' + raw_data.id].processing = true;

									$timeout(function() {
		        						$state.go('app.activity', { transaction_id: raw_data.meta.transaction_id });
	        						}, 750);

	        					}
	        				}
	        				*/

		        			scope.link = new NwuiDrawerV1NotificationLinkFactory(data);
	        				break;
	        			//case 'download_link':
	        				//break;
	        			case 'risk_profile_link':
	        				data.lead = raw_data.notification.message;
	        				data.lead_i18n = raw_data.notification.message_i18n;
	        				data.meta = [];
	        				scope.important = true;
	        				if (raw_data.meta.goal_id) {
	        					// data.meta = [{
	        					// 	state: 'app.manage.risk_profiles.view',
	        					// 	params: {
	        					// 		goal_id: raw_data.meta.goal_id
	        					// 	},
	        					// 	label: 'View Risk Profile'
	        					// }]

	        					data.button = {
		        					id: 'risk-profile-button-' + raw_data.id,
		        					// text: 'View Risk Profile',
		        					text_i18n: 'nwui.common.drawer.notification.risk_profile_unlocked_button',
		        					color: (scope.important === true) ? 'warning' : '',
		        					icon: {
		        						name: 'assignment_late'
		        					},
		        					onClick: function() {

		        						if($state.includes('app.manage.risk_profiles.view')) {
		        							NwuiDrawerV1Service.close();
		        							return;
		        						} else {
													$state.go('app.manage.risk_profiles.view', { goal_id: raw_data.meta.goal_id })
		        						}

		        					}
		        				}
	        				}
	        				scope.link = new NwuiDrawerV1NotificationLinkFactory(data);
	        				break;
	        			default:
	        				data.lead = raw_data.notification.message;
									data.lead_i18n = raw_data.notification.message_i18n;
	        				scope.text = new NwuiDrawerV1NotificationTextFactory(data);
	        				break;
	        		}

	        	})

		    }
		}
	}
]);