nwuiFormV3StepColumn.directive('nwuiFormV3StepColumn', [
	'$timeout', 
	
	'NwuiFormService', 
	'NwuiInitModuleFactory',
	'NwuiInputFactory', 
	'NwuiButtonFactory', 
	function(
		$timeout, 
		NwuiFormService, 
		NwuiInitModuleFactory,
		NwuiInputFactory, 
		NwuiButtonFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/form/v3/step/column/template.html',
			scope: { 
				form_name: '<formName',
				step_index: '<stepIndex',
				directive: '<',
				width: '<',
				hidden: '<nwHidden',
				data: '<'
			},
			link: function(scope, element, attrs) {
				
				scope = NwuiInitModuleFactory({
					form_name: undefined, //req
					directive: undefined, //req
					data: undefined, //req
					width: 100
				}, scope);

				// var calibrate = function() {

				// 	if(scope.directive) {
				// 		switch(scope.directive) {
				// 			// case 'input':
				// 			// 	scope.input = scope.data = new NwuiInputFactory(scope.data);
				// 			// 	break;
				// 			case 'button':
				// 				scope.data.onClick = NwuiFormService.submit;
				// 				// scope.data.stretch = true; //override
				// 				scope.button = scope.data = new NwuiButtonFactory(scope.data);
				// 				NwuiFormService.registerSubmitButton(scope.form_name, scope.button);
				// 				break;
				// 			case 'message':
				// 				//TODO
				// 				break;
				// 		}
				// 	}
					
				// }
				
				scope.style = {
					width: scope.width + '%'
				};
				
				// scope.$watch('width', function(new_val) {
					
				// 	if(new_val !== 0) {
				// 		scope.style = {
				// 			width: new_val + '%'
				// 		}
				// 	}
					
				// });
				
				// scope.$watch('directive', function(new_val, old_val) {
				// 	//console.log('nwuiFormV3StepColumn', 'watch', 'directive', old_val, '=>', new_val);

				// 	if(new_val === undefined) return;
				// 	calibrate();
					
				// });
				
			}
		}
	}
]);