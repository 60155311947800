nwuiHotkeys.directive('nwuiHotkeys', [
	'$timeout', 
	
	'NwuiHotkeysService',
	function(
		$timeout,
		
		NwuiHotkeysService
	) {
		return {
			restrict: 'A',
	        link: function(scope, element, attrs) {
		        
		        element.bind("keypress", function(e) {
			        NwuiHotkeysService.keyPress(e);
		        });
		        
		        element.bind("keyup", function(e) {
			        NwuiHotkeysService.keyUp();
		        });
		        
		    }
		}
	}
]);