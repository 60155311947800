angular.module('nwui-page-scroll.directives', [])

.directive('nwuiPageScroll', [
	'NwuiPageScrollService',
	function(
		NwuiPageScrollService
	) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				NwuiPageScrollService.registerMaster(element);
			}
		}
	}
]);