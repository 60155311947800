nwuiToast.factory('NwuiToastFactory', [function() {
	
	var defaults = {
		text: undefined,
		text_i18n: undefined,
		text_i18n_data: {},
		icon: undefined,
		type: '',
		show: true,
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiToastFactory");
		if(obj.text === undefined && obj.text_i18n === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiToastFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);