nwuiCardTableV3BodyRow.directive('nwuiCardTableV3BodyRow', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/v3/body/row/template.html',
			scope: {
				columns: '=',
				sub_columns: '=subColumns',
				row: '=',
				widths: '=',
				is_odd: '=isOdd'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 

						scope.data = {
							show_sub: false,
							accordion_toggle: {
								name: 'keyboard_arrow_down'
							},
							has_accordion: (scope.row.sub_rows && scope.row.sub_rows.length > 0)
						}

						scope.no_transition = false;
						scope.accordion_style = {}

					},
					post: function(scope, element, attrs) {

						//saving selections for later
						var container = $(element).find('.sub');
						var contents = $(element).find('.sub > .inner');

						scope.accordionToggle = function() {
							if(scope.data.has_accordion) {
								// scope.data.show_sub = (!scope.data.show_sub) ? true : false;

								//disable initial transition for height set (safari fix)
								scope.no_transition = true;

								scope.accordion_style = {
									height: scope.data.show_sub
										? contents.outerHeight(true) + 'px'
										: 0
								};
								
								//reenable transition for animating to new height (safari fix)
								$timeout(function() {
									scope.no_transition = false;
									
									//toggle expanded
									scope.data.show_sub = !scope.data.show_sub;
									scope.data.accordion_toggle.name = scope.data.show_sub ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
									scope.row.columns[scope.row.columns.length-1].icon.name = scope.data.show_sub ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
									
									//set new height
									$timeout(function() {
										scope.accordion_style = scope.data.show_sub
											? {}
											: {height: 0}
									}, 50);

								}, 100);
							}
						}

					}
				}
			}
		}
	}
]);