nwuiForm.directive('nwuiForm', [
	'$timeout', 
	'$window', 
	
	'NwuiFormService', 
	
	'NwuiFormColumnFactory', 
	function(
		$timeout, 
		$window,
		
		NwuiFormService, 
		
		NwuiFormColumnFactory
	) {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: '/form/template.html',
	        scope: {
		        name: '=',
		        columns: '=',
		        onSubmit: '=',
		        onComplete: '='
	        },
	        link: function(scope, element, attrs) {
		        
		        var lock = false;
		        
		        scope.version = '';
		        
		        scope.setTemplate = function() {
			        scope.version = ($(element).width() <= 450) ? 'stacked' : 
			        	($(element).width() <= 600) ? 'split' : '';
		        }
		        
		        scope.$watch('name', function(new_val, old_val) {
			        if(new_val !== undefined)
				        NwuiFormService.registerForm(scope.name, scope.onSubmit, scope.onComplete, scope.onSecondaryAction);
				        
			    });
		        
		        scope.$watch('columns', function(new_val, old_val) {
					
			        if(new_val !== undefined && !lock) {
				        
				        lock = true;
				        
				        var columns = [];
				        angular.forEach(new_val, function(column, i) {
					        columns.push(
					        	new NwuiFormColumnFactory({ 
						        	directive: column.directive,
						        	form_name: scope.name,
						        	width: column.width,
						        	data: column.data
						    	})
						    );
				        });
				        
				        scope.columns = columns;
				        
				        $timeout(function() {
					        lock = false;
				        }, 150);
				        
			        }
			        
		        });
		        
		        window.onresize = function() {
					scope.$apply();
				};
				
				scope.$watch(function() {
					return angular.element($window)[0].innerWidth;
				}, function(new_val, old_val) {
					if(new_val === old_val) return;

					$timeout(function() {
						scope.setTemplate();
					}, 0);
				});
		        
	    }
		}
	}
]);