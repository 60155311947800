nwuiCardTableBodyRowColumnToggle.directive('nwuiCardTableBodyRowColumnToggle', ['$timeout', 'NwuiInputToggleFactory', function($timeout, NwuiInputToggleFactory) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/card/table/body/row/column/toggle/template.html',
		scope: {
			toggle: '='
		},
		link: function(scope, element, attrs) {
			
			var lock = false;
			
			scope.$watch('toggle', function(new_val) {
				
				if(!lock && new_val !== undefined) {
					
					lock = true;
					
					//new_val.stretch = true; //override
					new_val.size = 'short'; //override
					scope.toggle = new NwuiInputToggleFactory(new_val);
					
					$timeout(function() {
						lock = false;    
					}, 100);
				
				}
				
			});
			
		}
	}
}]);