var nwuiInput = angular.module('nwui-input', [
	'nwui-input-checkbox-group',
	'nwui-input-drop-list',
	'nwui-input-label',
	'nwui-input-notification',
	'nwui-input-radio-group',
	'nwui-input-select',
	'nwui-input-slider',
	'nwui-input-text',
	'nwui-input-textarea',
	'nwui-input-toggle',
	'nwui-input-upload',
	'nwui-input-subheading',
	'nwui-input-disclaimer'
]);