nwuiCardTableV2Footer.directive('nwuiCardTableV2Footer', [
	'$timeout',
	
	'NwuiButtonV2Factory',
	
	'NwuiButtonV2Service',
	function(
		$timeout,
		
		NwuiButtonV2Factory,
		
		NwuiButtonV2Service
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/card/table/v2/footer/template.html',
	        scope: {
		        table_id: '=tableId',
		        total_items: '=totalItems',
		        query: '=',
				current_page: '=currentPage',
				page_size: '=pageSize'
	        },
	        link: function(scope, element, attrs) {
				
				scope.total_pages = '?';
				
				scope.hasPrev = function() {
					return scope.current_page > 1;
				}
					
				scope.hasNext = function() {
					return scope.current_page < scope.total_pages;
				}
					
				scope.buttons = {
					prev_page: new NwuiButtonV2Factory({
						id: scope.table_id + '-prev-page-button',
						text_i18n: 'nwui.common.table.footer.button_previous',
						size: 'short',
						stretch: true,
						onClick: function() {
							
							if(NwuiButtonV2Service.buttons[scope.table_id + '-prev-page-button'].processing || !scope.hasPrev()) return;
							
							NwuiButtonV2Service.buttons[scope.table_id + '-prev-page-button'].processing = true;
							
							scope.current_page--;
								
						}
					}),
					next_page: new NwuiButtonV2Factory({
						id: scope.table_id + '-next-page-button',
						text_i18n: 'nwui.common.table.footer.button_next',
						size: 'short',
						stretch: true,
						onClick: function() {
							
							if(NwuiButtonV2Service.buttons[scope.table_id + '-next-page-button'].processing || !scope.hasNext()) return;
							
							NwuiButtonV2Service.buttons[scope.table_id + '-next-page-button'].processing = true;
							
							scope.current_page++;
								
						}
					})
				}
				
				scope.$watch('current_page', function(new_val) {
					
					if(new_val === undefined) return;
						
					if(NwuiButtonV2Service.buttons[scope.table_id + '-prev-page-button']) {
						NwuiButtonV2Service.buttons[scope.table_id + '-prev-page-button'].processing = false;
						NwuiButtonV2Service.buttons[scope.table_id + '-next-page-button'].processing = false;
					}
					
				});
				
				scope.$watchGroup(['total_items', 'page_size'], function(new_val) {
					if(new_val[0] !== undefined && new_val[1] !== undefined)
						scope.total_pages = Math.ceil(new_val[0]/new_val[1]);
				});
				
		    }
		}
	}
]);