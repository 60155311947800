nwuiPill.directive('nwuiPill', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/pill/template.html',
        scope:{
	        text: '=',
	        size: '='
        },
        link: function(scope, element, attrs) {}
	}
}]);