nwuiInputCheckboxGroup.factory('NwuiInputCheckboxGroupFactory', [function() {
	
	var defaults = {
		options: undefined, //req
		label: '',
		type: '',
		required: false
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputCheckboxGroupFactory");
		if(obj.options === undefined) return console.error("Missing required instantiation object variable 'options' in NwuiInputCheckboxGroupFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);