nwuiOverlay.service('NwuiOverlayService', [
	'$rootScope',
	'$timeout',
	'locale',
	function(
		$rootScope,
		$timeout,
		locale
	) {
		
		var self = this;	
		this.overlays = {};
		
		this.unregister = function(id) {
			delete self.overlays[id];
		}
		
		this.register = function(overlay) {
			self.overlays[overlay.id] = overlay;
		}

		this.stateEnter = function(toState) {
			if(toState.data && toState.data.icon_name && (toState.data.page_title || toState.data.page_title_i18n)) {

				if(!this.overlays['global-overlay']) return;

				if(toState.data.page_title_i18n)
					this.overlays['global-overlay'].label_i18n = toState.data.page_title_i18n;
				else if(toState.data.page_title)
					this.overlays['global-overlay'].label = toState.data.page_title;
				
				this.overlays['global-overlay'].icon.name = toState.data.icon_name;
				this.overlays['global-overlay'].message = '';
				this.overlays['global-overlay'].show = true;

			}
		}
		
		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			for(var id in self.overlays) {
				if(self.overlays[id].block_state_change !== true) {
					self.overlays[id].show = false;
				}
			}
		});
				
	}
]);