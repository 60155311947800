nwuiTarget.factory('NwuiTargetFactory', [function() {
	
	var defaults = {
		text_i18n: undefined,
		text: undefined, //req
		status: 'done'
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiTargetFactory");
	//	if(obj.text === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiTargetFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);