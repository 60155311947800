nwuiTag.directive('nwuiTag', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/tag/template.html',
        scope:{
	        status: '=',
	        tooltip: '='
        },
        link: function(scope, element, attrs) {
			
			scope.has_action = false;
			
			scope.mouseover = function() {
				if(!scope.tooltip) return;
				
				scope.tooltip.show = true;
			}
			
			scope.mouseleave = function() {
				if(!scope.tooltip) return;
				
				scope.tooltip.show = false;
			}
			
			scope.$watch('tooltip', function(new_val) {
				
				//console.log('tooltip new_val', new_val)
				
				if(new_val && new_val.content)
					scope.has_action = true;
				else
					scope.has_action = false;
				
			});
			
	    }
	}
}]);