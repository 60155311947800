angular.module('nwui-analytics.service', [])
  .service('NwuiAnalyticsService', [
    '$rootScope',
    '$window',
    'NwuiSession',
    'NWUI_AUTH_EVENTS',
    'NWUI_ANALYTICS_EVENTS',
    'NWUI_ANALYTICS_PPI_KEYS',
    'segment',
    function (
      $rootScope,
      $window,
      NwuiSession,
      NWUI_AUTH_EVENTS,
      NWUI_ANALYTICS_EVENTS,
      NWUI_ANALYTICS_PPI_KEYS,
      segment
    ) {

      var self = this;

      self.reset = function () {
        if (segment && segment.reset){
          segment.reset();
        }
      }

      self.alias = function (id) {
        if (segment && segment.alias){
          segment.alias(id || '');
        }
        self.identify();
      }

      self.identify = function () {
        if (NwuiSession.user) {
          if (segment && segment.identify) {
            segment.identify(NwuiSession.user.uuid, {
              user_id: NwuiSession.user.id,
              language: NwuiSession.user.language,
              organization: NwuiSession.user.org && NwuiSession.user.org.name,
              role: NwuiSession.user.Role && NwuiSession.user.Role.name,
              org_slug: org_slug
            });
          }
        }
      }

      self.track = function (id, data) {
        if (segment && segment.track && $window.Fides && $window.Fides.consent.analytics) {
          var eventId = self.getEvent(id || '', '_');
          var cleanedData = self.removePII(data || {});
          segment.track(eventId, data || {});
        }
      }

      self.trackPage = function (url) {
        if (segment && segment.page && $window.Fides && $window.Fides.consent.analytics) {
          segment.page(url);
        }
        self.pushToDataLayer({
          is_first: false,
          url: (url || '').replace(/\b\/[a-f\d-]{36}\b/, '')
        });
      }

      self.getEvent = function (id, separator) {
        try {
          var cleanedId = id.replace(/[\W_]+/g, separator).toLowerCase();
          var eventsMap = angular.merge(
            NWUI_ANALYTICS_EVENTS.default, 
            NWUI_ANALYTICS_EVENTS[org_slug] || {}
          );
          return eventsMap[cleanedId] || cleanedId;
        } catch (err) {
          return id;
        }
      }

      self.removePII = function(data) {
        if(angular.isObject(data)) {
          Object.keys(data).forEach(function(key) {
            if (
              NWUI_ANALYTICS_PPI_KEYS.indexOf((key || '').toLowerCase()) > -1 
              || data[key] === undefined 
              || (key || '').indexOf('FirstName') > -1
              || (key || '').indexOf('LastName') > -1
              || (key || '').indexOf('SIN') > -1
              || (key || '').indexOf('Sin') > -1
            ) {
              delete data[key];
            }
          })
        }
        return data;
      }

      self.pushToDataLayer = function(data) {
        if(dataLayer) {
          var dataObject = self.getdataLayerObject(NwuiSession.user, data);
          dataLayer.push(dataObject);
        }
      }

      self.getdataLayerObject = function(user, data) {
        const page = self.getEvent(data.url, ':');
        const isOnboarding = page.indexOf('onboarding') > -1;
        const isTransfer = page.indexOf('transfer') > -1;

        if (org_slug === 'nbf_r2' && page) {
          return {
            "event": "trackPageView",
            "eventInfo": {
              "ucsId": 'UCS-00' + (isOnboarding ? '7' : (isTransfer ? '8' : '2')) + '-NatGo',
              "ucsState": "pv",
              "eventId": data.is_first ? 'LC-NatGo' : "PL-NatGo",
              "eventName": data.is_first ? 'loginComplete' : "pageLoad",
              "eventSource": "sbip",
              "eventSourceDetail": "NatGo",
              "eventTime": Date.now()
            },
            "page": {
              "pageName": 'sbip:natgo' + page,
              "partnerId": "Nest",
              "language": user && user.language && user.language.replace('-CA', ''),
              "loB": "personal"
            },
            "step": {
              "stepName": page.split(':').pop().toUpperCase().replace(/[\W_]+/g, '_'),
              "stepId": 'natgo' + page,
              "formId": "NatGo"
            },
            "product": {
              "productCategory": "Investment",
              "productName": "NatGo"
            },
            "user": {
              "bncId": user && user.investor_id,
              "loggedStatus": user ? "True" : "False",
              "loginMethod": "email"
            }
          }
        }
        return  {};
      }

      // identify segment user
      $rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
        self.identify();
        self.pushToDataLayer({ is_first: true, url: '/login/2' });
      });
      $rootScope.$on(NWUI_AUTH_EVENTS.authDone, self.identify);

      // reset segment
      $rootScope.$on(NWUI_AUTH_EVENTS.logoutSuccess, self.reset);
      $rootScope.$on(NWUI_AUTH_EVENTS.loginFailed, self.reset);
      $rootScope.$on(NWUI_AUTH_EVENTS.notAuthenticated, self.reset);
      $rootScope.$on(NWUI_AUTH_EVENTS.notAuthorized, self.reset);
      $rootScope.$on(NWUI_AUTH_EVENTS.sessionTimeout, self.reset);
      $rootScope.$on(NWUI_AUTH_EVENTS.authInactivityTimeout, self.reset);
    }
  ]);
