angular.module('nwui-toolkit').directive('nwuiCompoundModalAdditionalVerificationRequired', [
  '$timeout',
  '$filter',
  '$q',
  '$http',
  'NwuiModalV2Factory',
  'NwuiCardTableV2Factory',
  'NwuiToastFactory',
  'NwuiCardHeaderV2Factory',
  'NwuiFormV3Factory',
  'NwuiSigningService',
  'NwuiPageScrollService',
  'NwuiTooltipV2Factory',
  'NwuiToastManagerService',
  'NwuiInputService',
  'NwuiFormV3Service',
  'FundFactsService',
  'ApiAccountsService',
  'ApiClientsService',
  'NwuiAllocationFactory',
  'locale',
  'INTERCOM_APPID',
  'NwuiSession',
  'IdVerificationService',
  'DATE_FORMAT',
  function(
    $timeout,
    $filter,
    $q,
    $http,
    NwuiModalV2Factory,
    NwuiCardTableV2Factory,
    NwuiToastFactory,
    NwuiCardHeaderV2Factory,
    NwuiFormV3Factory,
    NwuiSigningService,
    NwuiPageScrollService,
    NwuiTooltipV2Factory,
    NwuiToastManagerService,
    NwuiInputService,
    NwuiFormV3Service,
    FundFactsService,
    ApiAccountsService,
    ApiClientsService,
    NwuiAllocationFactory,
    locale,
    INTERCOM_APPID,
    NwuiSession,
    IdVerificationService,
    DATE_FORMAT
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/additional-verification-required/template.html',
      scope: {
        resourceCb: '=resourceCb',
        submitCb: '=submitCb'
      },
      link: function(scope, element, attrs) {
        scope.open = false;
        scope.verificationId = undefined;

        scope.submitButton = {
          id: 'aml-verification-upload-submit-button',
          stretch: false,
          color: '',
          disabled: true,
          text_i18n: 'nwui.modal.upload_aml_verification.submit',
          onClick: function() {
            // var post_data = {};
            // post_data.documentIds = scope.documents.map(function(document) {
            //    return document.id;               
            //  })
            
            return IdVerificationService.requestVerificationApproval(scope.verificationId).then(function(){
              NwuiToastManagerService.register(
                new NwuiToastFactory({
                  text_i18n: 'nwui.modal.upload_aml_verification.success',
                  icon: {
                    name: 'check'
                  }
                }
              ));

              // submitCb(true);
              // scope.open = false;
              getUserVerificationStatus();
              
            })
            .catch(function(err){
              NwuiToastManagerService.register(
                new NwuiToastFactory({
                  text_i18n: "onboarding.aml_verification.errors." + err.data.nw_error_code,
                  type: 'error',
                  icon: {
                    name: 'close'
                  }
                }
              ));
            });
          }
        };

        var submitCb = function(data) {
          scope.submitCb(data);
        }

        scope.has_chat = (INTERCOM_APPID !== undefined && INTERCOM_APPID !== '') ? true : false;

        scope.has_zendesk = (zendesk !== undefined) ? true : false;
        
        scope.has_calendly = !!Calendly;

        scope.openScheduler = function(e) {
					Calendly.showPopupWidget('https://calendly.com/' + calendly);
					e.stopPropagation();
					e.preventDefault();
        }
        
        scope.openZendesk = function(e) {
					zEmbed.activate({ hideOnClose: true });
				}

				scope.openIntercom = function(e) {
					e.stopPropagation();
					e.preventDefault();
					scope.overlay = true;
					if(window.Intercom) {
						window.Intercom('show');
					}
        }

        scope.documents = [];
        scope.availableDocumentTypes = [];
        
        scope.data = {
          needs_additional_info: true
        }

        scope.tooltip = {
					show: false,
					flip: false,
					invert: false,
					shrink: false,
					transclude: true,
					xwide: false
				}
        
        // scope.cardHeader = new NwuiCardHeaderV2Factory({
        //   name_i18n: "nwui.modal.additional_verification_required.title",
        //   level: 2,
        //   icon: {
        //     name: 'info'
        //   }
        // });

        scope.modal = new NwuiModalV2Factory({
          open: false,
          wide: true,
          locked: true,
          header: new NwuiCardHeaderV2Factory({
            name_i18n: "nwui.modal.additional_verification_required.title",
            level: 2,
            icon: {
              name: 'info'
            }
          }),
          form: undefined
        });

        function createForm() {

          return new NwuiFormV3Factory({
            name: 'update-admin-user-modal-form',
            submit_button: false,
            onSubmit: function() {},
            onComplete: function() {},
            onError: function(res) {

              var message = {
                icon: {
                  name: 'error',
                  color: 'error'
                }
              };

              if(res.data.nw_error_message)
                message.text = res.data.nw_error_message;
              else
                message.text_i18n = 'nwui.modal.update_admin.messages.failure';

              NwuiFormV3Service.showMessage('update-admin-user-modal-form', message);

            },
            steps: [{
              columns: [{
                directive: 'input',
                width: 100,
                data: {
                  directive: 'select',
                  data: {
                    id: 'aml-verification-select-file-type',
                    label_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.label',
                    placeholder_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.placeholder',
                    model: '',
                    placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
                    options: mapToDropdownOptions(scope.availableDocumentTypes),
                    onChange: function(selectedType) {
                      if(selectedType) {
                        NwuiInputService.inputs['aml-verification-upload-file'].hide = false;
                        NwuiInputService.inputs['aml-verification-upload-file'].url = '/api/id-verifications/' + scope.verificationId + '/documents/' + selectedType;
                      }
                    }
                  }
                }
              }, {
                directive: 'input',
                width: 100,
                data: {
                  directive: 'upload',
                  data: {
                    id: 'aml-verification-upload-file',
                    label_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.label',
                    placeholder_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.placeholder',
                    model: '',
                    hide: true,
                    url: '',
                    placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
                    onSuccess: function(res) {
                      filterDropdownOptions();
                      scope.table.reload();
                      NwuiToastManagerService.register(
                        new NwuiToastFactory({
                          text_i18n: "nwui.common.toast.document_upload",
                          icon: {
                            name: 'check'
                          }
                        }
                      ));
                      NwuiInputService.inputs['aml-verification-upload-file'].clearCb();
                    }
                  }
                }
              }]
            }]
          });
        }

        function createTable() {
          scope.table = new NwuiCardTableV2Factory({
            id: 'aml-verification-upload-table',
            empty_text_i18n: 'nwui.modal.upload_aml_verification.empty_text',
            resource: function(){
              return IdVerificationService.getVerificationDocuments(scope.verificationId).then(function(res) {
                return {
                  data: {
                    data: res.data.filter(function(datum){
                      return datum.status !== 'rejected'
                    }),
                    page_data: {}
                  }
                }
              })
            },
            resourceCb: function(obj) {
              scope.documents = obj.data.data;
              scope.submitButton.disabled = (scope.documents.length < 2)
              filterDropdownOptions();
            },
            rowMap: function(document,i) {

              return {
                columns: [ {
                  text: locale.getString('nwui.common.document.' +document.clientDocument.template.prefix)
                }, {
                  text: locale.getString(document.status)
                }, {
                  text: moment(document.clientDocument.uploadedAt).format(DATE_FORMAT.short)
                }]
              }
            },
            columns: [
              {
                name_i18n: 'nwui.modal.upload_aml_verification.table.headings.fileType',
                directive: 'text',
                sortable: false,
              },
              {
                name_i18n: 'nwui.modal.upload_aml_verification.table.headings.status',
                directive: 'text',
                sortable: false
              },
              {
                name_i18n: 'nwui.modal.upload_aml_verification.table.headings.dateUploaded',
                directive: 'text',
                sortable: false
              }
            ],
            rows: undefined
          });
        }

        function filterDropdownOptions() {
          scope.availableDocumentTypes = scope.availableDocumentTypes.filter(function(type) {
            return scope.documents.every(function(document){
              return document.clientDocument.template._id != type.id;
            });
          })
          if(NwuiInputService.inputs['aml-verification-select-file-type']) {
            NwuiInputService.inputs['aml-verification-select-file-type'].model = '';
            NwuiInputService.inputs['aml-verification-select-file-type'].options = mapToDropdownOptions(scope.availableDocumentTypes);
          }
        }

        function mapToDropdownOptions(input) {
          return input.map(function(type, ind){
            return {
              id: type.id,
              text_i18n: 'nwui.common.document.' + type.slug,
              value: type.id
            }
          })
        }

        function getDocumentTypes() {
          return IdVerificationService.getDocumentTypes().then(function(res){
            scope.availableDocumentTypes = res.data;
            filterDropdownOptions();
            return res.data;
          });
        }

        function getUserVerificationStatus() {
          return IdVerificationService.checkIfVerified(NwuiSession.user.uuid).then(function(res) {
            scope.open = !res.data.verified;

            if(res.data.status === 'pending-document-approval') {
              scope.data.needs_additional_info = false;
              return; 
            } else {
              scope.data.needs_additional_info = true;
            }

            if(res.data.id) {
              scope.verificationId = res.data.id;
              getDocumentTypes().then(function(res) {
                scope.modal.form = createForm();
                createTable();
              });
            }
          });
        }

        getUserVerificationStatus()

      }
    }
  }
]);
