nwuiCardHeaderV2.directive('nwuiCardHeaderV2', [
	'$sce', 
	'$timeout', 
	'NwuiInitModuleFactory',
	'NwuiInputFactory', 
	'NwuiIconV2Factory', 
	'NwuiPillFactory',
	'NwuiButtonV3Factory',
	'NwuiTargetFactory', 
	function(
		$sce, 
		$timeout, 
		NwuiInitModuleFactory,
		NwuiInputFactory, 
		NwuiIconV2Factory, 
		NwuiPillFactory,
		NwuiButtonV3Factory,
		NwuiTargetFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/header/v2/template.html',
			scope: {
				name: '<',
				name_i18n: '<nameI18n',
				name_i18n_data: '<nameI18nData',
				level: '<',
				icon: '<',
				input: '<',
				pill: '<',
				button: '<',
				target: '<'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						if(angular.isUndefined(scope.level)) scope.level = 1;

					},
					post: function(scope, element, attrs) {

						var locks = {
							icon: false,
							input: false,
							pill: false,
							target: false
						}
						
						scope.$watch('input', function(new_val, old_val) {
							
							if(new_val !== undefined && !locks.input) {
								
								locks.input = true;
								
								scope.input.data.size = 'short'; //override
								scope.input.data.color = 'alternate'; //override
								scope.input = new NwuiInputFactory(scope.input);
								
								$timeout(function() {
									locks.input = false;    
								}, 100);
							
							}
							
						});
						
						scope.$watch('pill', function(new_val, old_val) {
							
							if(new_val !== undefined && !locks.pill) {
								
								locks.pill = true;
								
								scope.pill = new NwuiPillFactory(scope.pill);
								
								$timeout(function() {
									locks.pill = false;    
								}, 100);
							
							}
							
						});
						
						scope.$watch('target', function(new_val, old_val) {
							
							if(new_val !== undefined && !locks.target) {
								
								locks.target = true;
								
								scope.target = new NwuiTargetFactory(scope.target);
								
								$timeout(function() {
									locks.target = false;    
								}, 100);
							
							}
							
						});
						
					}
				}
			}
		}
	}
]);