angular.module('nwui-compound-modal-create-advisor-code.directives', [])

.directive('nwuiCompoundModalCreateAdvisorCode', [
	'$timeout',
	'$filter',
	'$stateParams',
	'$state',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiFormV3Factory',
	'NwuiButtonV3Factory',
	'NwuiSigningService',
	'NwuiPageScrollService',
	
	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'DATE_FORMAT',
	'NWUI_SELECT_OPTIONS',
	function(
		$timeout,
		$filter,
		$stateParams,
		$state,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiFormV3Factory,
		NwuiButtonV3Factory,

		NwuiSigningService,
		NwuiPageScrollService,
		
		NwuiToastManagerService,
		NwuiInputService,
		NwuiFormV3Service,
		DATE_FORMAT,
		NWUI_SELECT_OPTIONS
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/create-advisor-code/template.html',
			scope: {
				open: '=',
				user: '=',
				org_slug: '=orgSlug',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {

				var submitCb = function(data) {
					scope.submitCb(data);
				}
				
				scope.modals = {
					create_advisor_code: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: {
							name_i18n: 'tools.manage_administrators.detail.cards.advisor_codes.header',
							level: 2,
							icon: {
								name: 'send'
							}
						},
						form: new NwuiFormV3Factory({
							name: 'create-advisor-code-form',
							submit_button: {
								text_i18n: 'nwui.modal.create_advisor_code.buttons.submit'
							},
							onSubmit: function(post_data) {

								post_data.org_id = scope.org_slug;
								post_data.user_uuid = scope.user.uuid;
								return scope.formResource(post_data);
								
							},
							onError: function(res) {
								var message = 'nwui.common.error.unknown';
								if (res.data.nw_error_code) {
									message = 'nestkit.errors.' + res.data.nw_error_code;
								}

								NwuiFormV3Service.showMessage('create-advisor-code-form', {
									text_i18n: message,
									icon: {
										name: 'error',
										color: 'error'
									}
								});
								
							},
							onComplete: function(obj) {

								submitCb(obj.data);
								scope.open = false;

								if(obj.data.error) {
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.modal.create_advisor_code.messages.success',
											icon: {
												name: 'clear'
											}
										}
									));
								} else {
									//ERROR
								}

							},
							steps: [
								{
									columns: function(){
										var returnArr = [];

										returnArr.push({
											directive: 'input',
											width: 100,
											data: {
												directive: 'text',
												data: {
													label_i18n: 'nwui.modal.create_advisor_code.labels.advisor_code',
													model: '',
													required: true,
													id: 'create-advisor-code-input-code',
													placeholder_i18n: 'nwui.modal.create_advisor_code.labels.advisor_code_placeholder',
													post_var: 'code'
												}
											}
										});
										if (typeof scope.user.registration_types !== "undefined" && scope.user.registration_types.length > 0){
											returnArr.push({
												directive: 'input',
												width: 100,
												data: {
													directive: 'select',
													data: {
														label_i18n: 'nwui.modal.create_advisor_code.labels.registration_type',
														options: scope.user.registration_types.map(function(type, ind){
															return {
																id: type,
																text: type,
																value: type
															}
														}),
														model: '',
														required: true,
														id: 'create-advisor-code-input-type',
														placeholder_i18n: 'nwui.modal.create_advisor_code.labels.registration_type_placeholder',
														post_var: 'registration_type'
													}
												}
											});
										}

										returnArr.push({
											directive: 'input',
											width: 100,
											data: {
												directive: 'textarea',
												data: {
													label_i18n: 'nwui.modal.create_advisor_code.labels.disclosure_text',
													model: '',
													required: false,
													id: 'create-advisor-code-input-disclosure-text',
													placeholder_i18n: 'nwui.modal.create_advisor_code.labels.disclosure_text_placeholder',
													post_var: 'disclosure_text'
												}
											}
										});

										return returnArr;
									}()
								}
							]
						})
					})
				}
				
				scope.$watch('open', function(new_val, old_val) {

					if(new_val === undefined) return;
					
					if(new_val === false) {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.create_advisor_code.form.name);
						}, 750)
					}

					scope.modals.create_advisor_code.open = new_val;
					
				});

			}
		}
	}
]);