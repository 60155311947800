nwuiInput.directive('nwuiInput', [
	'NwuiInputTextV2Factory', 
	'NwuiInputSelectV2Factory', 
	'NwuiInputUploadFactory', 
	'NwuiInputTextareaFactory', 
	'NwuiInputToggleFactory',
	'NwuiInputCheckboxGroupFactory',
	'NwuiInputRadioGroupFactory',
	'NwuiFormService', 
	function(
		NwuiInputTextV2Factory, 
		NwuiInputSelectV2Factory, 
		NwuiInputUploadFactory, 
		NwuiInputTextareaFactory, 
		NwuiInputToggleFactory,
		NwuiInputCheckboxGroupFactory,
		NwuiInputRadioGroupFactory,
		NwuiFormService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/input/template.html',
			require: '?^nwuiFormV3',
			scope: {
				form_name: '<formName',
				step_index: '<stepIndex',
				directive: '<',
				data: '<'
			},
			link: function(scope, element, attrs, nwuiFormV3Ctrl) {

				if(scope.data){
					scope.data.step_index = scope.step_index;
					scope.data.form_name = scope.form_name;
				}				

				scope.$watch("directive",function(){
					if(scope.directive === 'address') {
						scope.default_field = scope.data.defaultField;
						scope.address_selected = function(data) {
							if (scope.data && typeof scope.data.address_selected_cb === 'function') {
								scope.data.address_selected_cb(data);
							}
						}
					}
				});

				scope.$watch("scope.data",function(){
					if(!scope.data) scope.data = {};

					scope.data.step_index = scope.step_index;
					scope.data.form_name = scope.form_name;

					scope.useDropListInput = false;
					if(scope.data.autocomplete_select_cb || scope.data.autocomplete_resource || scope.data.autocomplete_resource_formatter || scope.data.autocomplete_item_map){
						scope.useDropListInput = true;
					}
				});
				scope.formctrl = nwuiFormV3Ctrl;
				
			}
		}
	}
]);