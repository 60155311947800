angular.module('nwui-form-v3.step.factories', [])

.factory('NwuiFormV3StepFactory', [function() {
	
	var defaults = {
		name: undefined, //req
		showing: false,
		submit_button: {},
		secondary_button: {},
		onSubmit: undefined,
		onComplete: undefined,
		onSecondaryAction: undefined,
		onError: undefined,
		description: undefined,
		message: undefined,
		recommendation: undefined,
		columns: []
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiFormV3StepFactory");
		if(obj.name === undefined) return console.error("Missing required instantiation object variable: 'object.name'");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);