nwuiCardTableV2BodyRowColumnRadioGroup.directive('nwuiCardTableV2BodyRowColumnRadioGroup', [function() {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/card/table/v2/body/row/column/radio-group/template.html',
        scope: {
	        input: '='
        },
        link: function(scope, element, attrs) {
            scope.radioInput = {
                id: scope.input.data.id,
                options: scope.input.data.options,
                label: scope.input.data.label,
                post_var: scope.input.data.post_var,
                model: scope.input.data.model,
                required: scope.input.data.required,
                stretch: scope.input.data.stretch,
                color: scope.input.data.color,
                size: scope.input.data.size,
                onChange: scope.input.data.onChange,
                default_value: scope.input.data.default_value,
                notification: scope.input.data.notification,
                hide: scope.input.data.hide,
                type: scope.input.data.type
            }
        }
	}
}]);