nwuiNumberClimber.directive('nwuiNumberClimber', ['$filter', '$timeout', function($filter, $timeout) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/number-climber/template.html',
        scope:{
	        number: '=',
	        filter: '='
        },
        link: function(scope, element, attrs) {
	        
	        var timeouts = [];
	        var number_saved = 0;
	        var frame_rate = 30/1000;
	        var duration = 1250;
	        var frames = Math.floor(duration*frame_rate);
	        var steps;
	        var first_run = true;
	        var val = {
		        from: 0,
		        to: 0
	        }
	        
	        var clearTimeouts = function() {
		        for(var i = 0; i < timeouts.length; i++)
		        	clearTimeout(timeouts[i])
		        timeouts = [];
	        }
	        
	        var frame = function(val, delay) {
		        timeouts.push($timeout(function() {
			        if(attrs.filter)
			        	val = $filter(attrs.filter)(val)
					element[0].innerHTML = val;
				}, delay));
	        }
	        
	        var easing = {
		        easeOutCirc: function(t,b,c,d) {
			        t /= d/2;
					if (t < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
					t -= 2;
					return c/2 * (Math.sqrt(1 - t*t) + 1) + b;
		        },
		        easeOutQuint: function(t,b,c,d) {
			        t /= d;
					t--;
					return c*(t*t*t*t*t + 1) + b;
		        }
	        }
	        
	        var change = function(to_val) {
		        
		        first_run = false;
		        
		        clearTimeouts();
		        
		        val.from = parseFloat(number_saved);
				val.to = to_val;
			    val.diff = val.to - val.from;
		        steps = [];
		        ////console.log('change', number_saved, val.to)
				
				for(var i = 0; i <= frames; i++) {
					steps.push(easing.easeOutCirc(i, val.from, val.diff, frames).toFixed(2))
				}
				
				for(var i = 0; i < steps.length; i++)
					frame(steps[i], duration/frames * i);
					
				number_saved = val.to;
				
	        }
	        
	        scope.$watch('number', function(new_val, old_val) {
		        if((new_val !== undefined && new_val != number_saved) || parseInt(new_val, 10) === 0) {
		        	change(parseFloat(new_val));
		        }
	        });
	        
	        if(scope.number)
		        change(scope.number);
	        	
	        ////console.log('scope.number', scope.number);
        }
	}
}]);