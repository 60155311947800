nwuiCardHeader.directive('nwuiCardHeader', ['$sce', '$timeout', 'NwuiInputFactory', 'NwuiIconFactory', 'NwuiPillFactory', 'NwuiButtonV2Factory', 'NwuiTargetFactory', function($sce, $timeout, NwuiInputFactory, NwuiIconFactory, NwuiPillFactory, NwuiButtonV2Factory, NwuiTargetFactory) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/header/template.html',
        scope: {
	        name: '=',
					name_i18n: '=nameI18n',
	        level: '=',
	        icon: '=',
	        input: '=',
	        pill: '=',
	        button: '=',
	        target: '='
        },
        link: function(scope, element, attrs) {

	        var locks = {
		        icon: false,
		        input: false,
		        pill: false,
		        button: false,
		        target: false
		    }
	        
	        scope.$watch('name', function(new_val, old_val) {
		        
		        //console.log('name', new_val)
		        
		    	if(new_val !== undefined) {
			    	// scope.safe_name = $sce.trustAsHtml(new_val);
			    	scope.safe_name = new_val;
		    	}    
		        
		    });

				scope.$watch('name_i18n', function(new_val, old_val) {
		      console.log(new_val);
		    });
		    
		    scope.$watch('input', function(new_val, old_val) {
			    
			    if(new_val !== undefined && !locks.input) {
				    
				    locks.input = true;
				    
				    scope.input.data.size = 'short'; //override
				    scope.input.data.color = 'alternate'; //override
				    scope.input = new NwuiInputFactory(scope.input);
				    
				    $timeout(function() {
						locks.input = false;    
				    }, 100);
				
				}
			    
		    });
		    
		    scope.$watch('button', function(new_val, old_val) {
			    
			    if(new_val !== undefined && !locks.button) {
				    
				    locks.button = true;
				    
				    scope.button.size = 'small'; //override
				    scope.button.stretch = false; //override
				    scope.button.icon_only_mobile = true; //override
				    scope.button = new NwuiButtonV2Factory(scope.button);
				    
				    $timeout(function() {
						locks.button = false;    
				    }, 100);
				
				}
			    
		    });
		    
		    scope.$watch('pill', function(new_val, old_val) {
			    
			    //console.log('pill', new_val);
			    if(new_val !== undefined && !locks.pill) {
				    
				    locks.pill = true;
				    
				    scope.pill = new NwuiPillFactory(scope.pill);
				    
				    $timeout(function() {
						locks.pill = false;    
				    }, 100);
				
				}
			    
		    });
		    
		    scope.$watch('icon', function(new_val, old_val) {
			    
			    if(new_val !== undefined && !locks.icon) {
				    
				    locks.icon = true;
				    
				    scope.icon = new NwuiIconFactory(scope.icon);
				    
				    $timeout(function() {
						locks.icon = false;    
				    }, 100);
				
				}
			    
		    });
		    
		    scope.$watch('target', function(new_val, old_val) {
			    
			    if(new_val !== undefined && !locks.target) {
				    
				    locks.target = true;
				    
				    scope.target = new NwuiTargetFactory(scope.target);
				    
				    $timeout(function() {
						locks.target = false;    
				    }, 100);
				
				}
			    
		    });
	        
        }
	}
}]);