nwuiCompoundModalSetPassword.directive('nwuiCompoundModalSetPassword', [
  '$timeout',
  '$sce',
  '$q',
  '$state',
  '$cookies',
  'NwuiModalV2Factory',
  'NwuiCardHeaderV2Factory',
  'NwuiCardTableFactory',
  'NwuiFormV3Factory',
  'NwuiButtonV3Factory',
  'NwuiToastFactory',

  'NwuiFormV3Service',
  'NwButtonService',
  'NwuiToastManagerService',
  'NwuiInputService',
  'NwuiValidationErrorService',
  function(
    $timeout,
    $sce,
    $q,
    $state,
    $cookies,
    NwuiModalV2Factory,
    NwuiCardHeaderV2Factory,
    NwuiCardTableFactory,
    NwuiFormV3Factory,
    NwuiButtonV3Factory,
    NwuiToastFactory,

    NwuiFormV3Service,
    NwButtonService,
    NwuiToastManagerService,
    NwuiInputService,
    NwuiValidationErrorService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/set-password/template.html',
      scope: {
        open: '=',
        user: '=',
        formResource: '=formResource',
        submitCb: '=submitCb',
        linkExpired: '=linkExpired'
      },
      link: function(scope, element, attrs) {

        var set_password_challenge = $cookies.get('set-password-challenge');

        var has_pass_challenge = (
          organization_data &&
          organization_data.join_data &&
          organization_data.join_data.auth &&
          organization_data.join_data.auth.set_password_challenge &&
          window.show_challenge === 'true'
        )

        var columns = [{
          directive: 'input',
          width: 100,
          data: {
            directive: 'text',
            data: {
              label_i18n: 'nwui.common.input.set_password.label',
              model: '',
              type: 'password',
              id: 'set-password-form-password-1',
              stretch: true,
              required: true,
              validators: ['password'],
              placeholder_i18n: 'nwui.common.input.set_password.placeholder',
              post_var: 'password_1'
            }
          }
        }, {
          directive: 'input',
          width: 100,
          data: {
            directive: 'text',
            data: {
              label_i18n: 'nwui.common.input.password_confirm.label',
              model: '',
              type: 'password',
              id: 'set-password-form-password-2',
              stretch: true,
              required: true,
              validators: ['password'],
              placeholder_i18n: 'nwui.common.input.password_confirm.placeholder',
              post_var: 'password_2'
            }
          }
        }]

        scope.$watch('open', function(new_val) {

          //console.log('nwuiCompoundModalSetPassword', 'watch', 'open', new_val);
          if(new_val === undefined) return;

          scope.modals.set_password.open = new_val;

        });


        if(has_pass_challenge) {

          switch(organization_data.join_data.auth.set_password_challenge) {
            case 'MCP':
              if(!set_password_challenge) {
                break;
              }
              columns.splice(0, 0, {
                directive: 'input',
                width: 40,
                data: {
                  directive: 'text',
                  data: {
                    label_i18n: 'nwui.common.input.postal_code.label',
                    model: '',
                    type: 'text',
                    id: 'set-password-form-postal',
                    stretch: true,
                    required: true,
                    mask: 'postal',
                    validators: ['postal'],
                    placeholder_i18n: "nwui.common.input.postal_code.placeholder",
                    post_var: 'postal_code'
                  }
                }
              }, {
                directive: 'input',
                width: 60,
                data: {
                  directive: 'text',
                  data: {
                    label_i18n: 'nwui.common.input.date_of_birth.label',
                    model: '',
                    type: 'text',
                    id: 'set-password-form-dob',
                    stretch: true,
                    required: true,
                    mask: 'date_dashed',
                    validators: ['date_past'],
                    placeholder_i18n: "nwui.common.input.date_of_birth.placeholder",
                    post_var: 'dob'
                  }
                }
              }, {
                directive: 'input',
                width: 100,
                data: {
                  directive: 'text',
                  data: {
                    label_i18n: 'nwui.common.input.email.label',
                    model: '',
                    type: 'text',
                    id: 'set-password-form-email',
                    stretch: true,
                    required: true,
                    mask: '',
                    validators: ['email'],
                    placeholder_i18n: "nwui.common.input.email.placeholder",
                    post_var: 'email'
                  }
                }
              })

              break;
            case 'SIN':

              if(set_password_challenge) {

                // new_val.set_password_challenge = '3';
                var sin_start = +set_password_challenge + 1;

                switch(sin_start) {
                  case 1:
                    sin_start = '1st';
                    break;
                  case 2:
                    sin_start = '2nd';
                    break;
                  case 3:
                    sin_start = '3rd';
                    break;
                  default:
                    sin_start = sin_start + 'th';
                }

                columns.push({
                  directive: 'input',
                  width: 100,
                  data: {
                    directive: 'text',
                    data: {
                      label: '3 digits of your SIN beginning with the ' + sin_start + '\u00A0digit',
                      model: '',
                      type: 'text',
                      id: 'set-password-form-sin',
                      stretch: true,
                      required: true,
                      mask: 'bank_institution',
                      validators: ['bank_institution'],
                      placeholder: "123",
                      post_var: 'sin'
                    }
                  }
                })
              }

              break;
          }

        }


        scope.modals = {
          set_password: new NwuiModalV2Factory({
            open: false,
            wide: false,
            locked: true,
            header: new NwuiCardHeaderV2Factory({
              name_i18n: 'nwui.modal.set_password.title',
              level: 2,
              icon: {
                name: 'lock'
              }
            }),
            form: new NwuiFormV3Factory({
              name: 'set-password-form',
              submit_button: {
                color: organization_data.theme.form.submit.auth_color
              },
              onSubmit: function(post_data) {

                //console.log('nwuiCompoundModalSetPassword', 'form post_data', post_data);

                //special validation for field match
                if(post_data.password_1 != post_data.password_2) {

                  var deferred = $q.defer();

                  NwuiInputService.inputs['set-password-form-password-2'].error = true;
                  NwuiInputService.inputs['set-password-form-password-2'].notification.error = true;
                  NwuiInputService.inputs['set-password-form-password-2'].notification.text = $sce.trustAsHtml(NwuiValidationErrorService['password_match']());
                  NwuiInputService.inputs['set-password-form-password-2'].notification.show = true;

                  $timeout(function() {
                    deferred.resolve(false);
                  }, 250);

                  return deferred.promise;

                } else {

                  var data = {
                    password: post_data.password_1,
                    password_challenge_answers: {
                      sin: post_data.sin,
                      email: post_data.email,
                      dob: post_data.dob,
                      postal_code: post_data.postal_code
                    }
                  }

                  // if(post_data.password_challenge_answer)
                  // 	data.password_challenge_answer = post_data.password_challenge_answer;

                  return scope.formResource(data);

                }

              },
              onComplete: function(obj) {
                if(obj !== false) {

                  // if(analytics)
                  // 	analytics.track('Password Set');

                  scope.submitCb(obj);
                  scope.open = false;
                }

              },
              onError: function(res) {

                var message_i18n = 'nwui.common.error.unknown';
                var message = 'Something went wrong.';
                var message_i18n_data = {};

                if(res.message) {
                  message = res.message;
                  message_i18n = undefined;
                }

                if(res.nw_error_code) {
                  switch(res.nw_error_code) {
                    case 4429:
                      message_i18n = 'nwui.modal.auth.form.login.status.error_4429';
                      message_i18n_data = {
                        waitTime : moment.duration({ seconds: res.i18n_data.waitTimeSeconds }).humanize()
                      }
                      break;
                    case 4551: 
                      message_i18n = 'nwui.modal.set_password.error_4551';
                      break;
                    default:
                      message_i18n = 'nwui.common.error.unknown';
                  }
                }

                NwuiFormV3Service.showMessage('set-password-form', {
                  text: message,
                  text_i18n: message_i18n,
                  text_i18n_data: message_i18n_data,
                  icon: {
                    name: 'error',
                    color: 'error'
                  }
                })

              },
              steps: [{
                description_i18n: 'nwui.modal.set_password.password_requirements',
                columns: columns
              }]
            })
          })
        };

        scope.expiredLinkRecommendation = {
          text_i18n: 'nwui.modal.set_password.error_expired',
          type: 'error',
          icon: {
            name: 'error',
            color: 'error'
          },
          link: {
            text_i18n: 'nwui.modal.set_password.login_link',
            onClick: function() {
              $state.go('login');
            }
          }
        };

      }
    }
  }
]);