angular.module('nwui-form-v3.directives', [])

.directive('nwuiFormV3', [
	'$timeout', 
	'$window', 
	
	'NwuiFormService', 
	'NwuiFormV3Service',
	'NwuiInitModuleFactory',
	'NwuiFormV3StepFactory', 
	function(
		$timeout, 
		$window,
		
		NwuiFormService, 
		NwuiFormV3Service,
		NwuiInitModuleFactory,
		NwuiFormV3StepFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/form/v3/template.html',
			scope: {
				name: '<',
				submit_button: '<submitButton',
				secondary_button: '<secondaryButton',
				steps: '<',
				active_step_index: '=activeStepIndex',
				onSubmit: '=',
				onComplete: '=',
				onSecondaryAction: '=',
				onError: '=',
				dynamicStepAdded: '=',
				disableAutomaticButtonProcessing: '=',
				manualButtonProcessing: '='
			},
			controller: 'nwuiFormV3Ctrl',
			
			link: function(scope, element, attrs) {

				scope = NwuiInitModuleFactory({
					name: undefined, //req
					steps: undefined, //req
					onSubmit: undefined, //req
					onComplete: undefined, //req
					onSecondaryAction: undefined, //req
					submit_button: {},
					secondary_button: undefined,
					active_step_index: 0
				}, scope);

				var locks = {
					// steps: false
				};
				
				var goToNextStep = function() {
					scope.active_step_index++;
					// NwuiFormService.setStepIndex(scope.name, scope.active_step_index);
				}
				
				var goToPrevStep = function() {
					scope.active_step_index--;
					// NwuiFormService.setStepIndex(scope.name, scope.active_step_index);
				}
				
				scope.slider = {
					style: {}
				}
				
				scope.in_transition = false;
				
				scope.version = '';
				
				scope.setTemplate = function() {
					scope.version = ($(element).width() <= 450) ? 'stacked' : 
						($(element).width() <= 600) ? 'split' : '';
					
					delete scope.slider.style.height;
				}
				
				scope.goToPrevStep = goToPrevStep;

				scope.submit = function(event) {
					//console.log('nwuiFormV3', 'submit', event)

					//hide mobile keyboard
					document.activeElement.blur();

					//prevent native form submit cascade
					event.preventDefault();
					event.stopPropagation();
					return false;
				}

				scope.$watch('dynamicStepAdded', function(new_val) {
					if(new_val == true) {
						scope.steps.forEach(function(step) {
							step.updateButtons = true;
						})
					}
					
				});
				
				scope.$watch('name', function(new_val, old_val) {
					if(new_val !== undefined) {
						NwuiFormService.registerForm(
							scope.name, 
							scope.onSubmit, 
							scope.onComplete, 
							scope.onSecondaryAction, 
							scope.onError, 
							function() { scope.active_step_index = 0; }, 
							goToNextStep
						);
					}
						
				});
				
				scope.$watch('steps', function(new_val) {
					
					if(new_val === undefined || new_val.length === 0) return;
					
					scope.steps[0].showing = true;
					
				});
				
				scope.$watch('active_step_index', function(new_val, old_val) {
					//console.log('nwuiFormV3', 'watch', 'active_step_index', scope.name, old_val, '=>', new_val);

					if(new_val === undefined) {
						new_val = 0;
					}
					
					if(new_val === old_val && scope.steps[new_val]) {
						scope.active_step_index_animate = new_val;
						scope.steps[new_val].showing = true;
						return;
					}
					
					var from_height = $(element).find('.nwui-form-v3-step').eq(old_val).height()
				
					//disable initial transition for height set (safari fix)
					scope.no_transition = true;
					
					//set height to current auto height and change tab
					$timeout(function() {
						scope.slider.style.height = from_height + 'px'
						
						//reenable transition for animating to new height (safari fix)
						$timeout(function() {
							scope.no_transition = false;
							scope.in_transition = true;

							for(var i = 0; i < scope.steps.length; i++) {
								scope.steps[i].showing = (i == new_val);
							}
							
							//set new height
							$timeout(function() {
								scope.active_step_index_animate = new_val;
								
								var to_height = $(element).find('.nwui-form-v3-step').eq(new_val).height()

								//console.log('nwuiFormV3', 'height values', from_height, '=>', to_height);

								$timeout(function() {
									scope.slider.style.left = (-100 * new_val) + '%';
									scope.slider.style.height = to_height + 'px'
									
									$timeout(function() {
										scope.in_transition = false;

										$timeout(function() {
											delete scope.slider.style.height;
										}, 150);
									}, 850);
								}, 150);
							}, 150);
						}, 150);
					}, 150);	    	
					 
				});
				
				$timeout(function() {
					window.onresize = function() {
						scope.$apply();
					};
					
					scope.$watch(function() {
						return angular.element($window)[0].innerWidth;
					}, function(new_val) {
						$timeout(function() {
							scope.setTemplate();
						}, 0);
					});
				}, 0);
				
			}
		}
	}
]);