angular.module('nwui-cache.services', [])

.service('NwuiCacheService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout', 
	'$stateParams',
	'NwuiSession', 
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;

		var boilerplate = {
			data: {},
			get: function(key) {
				//console.log('NwuiCacheService', 'cache', 'get', key, this.data[key]);
				return this.data[key];
			},
			set: function(key, data) {
				//console.log('NwuiCacheService', 'cache', 'set', key, data);
				return this.data[key] = data;
			},
			clear: function(key) {
				//console.log('NwuiCacheService', 'cache', 'clear', key);
				if(key !== undefined)
					return this.data[key] = undefined;
				
				for(var key in this.data) {
					this.data[key] = undefined;
				}

				return true;
			}
		}

		var cache = {};

		this.register = function(obj) {
			
			//console.log('NwuiCacheService', 'register', obj);

			cache[obj.id] = angular.copy(boilerplate);

			for(var key in obj) {
				cache[obj.id].data[key] = undefined;
			}

			return cache[obj.id];

		}

		this.destroy = function(obj) {}

	}
]);