nwuiInputTextV2.factory('NwuiInputTextV2Factory', [function() {
	
	var defaults = {
		model: undefined, //req
    id: undefined, //req
    type: 'text',
    default_value: '',
    label: undefined,
    disabled: false,
    required: true,
    placeholder: '',
    post_var: undefined,
    size: '',
    color: '',
    icon: undefined,
    validators: [],
    mask: undefined,
    error: false,
    notification: {},
    drop_list: undefined,
    autocomplete_resource: undefined,
    autocomplete_resource_formatter: undefined,
    autocomplete_item_map: undefined,
    onCut: function() {},
    onCopy: function() {},
    onPaste: function() {},
    onChange: function() {},
    onBlur: function() {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputTextFactory");
		if(obj.model === undefined) return console.error("Missing required instantiation object variable 'model' in NwuiInputTextFactory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiInputTextFactory");
		
		//if one is set, so must the other (autocomplete_resource/autocomplete_item_map)
		if(obj.autocomplete_resource !== undefined && obj.autocomplete_item_map === undefined)
			return console.error("Missing required instantiation object variable 'autocomplete_item_map' in NwuiInputTextFactory");
		if(obj.autocomplete_resource === undefined && obj.autocomplete_item_map !== undefined)
			return console.error("Missing required instantiation object variable 'autocomplete_resource' in NwuiInputTextFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);