nwuiCard.directive('nwuiCard', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/template.html',
        scope: {
	        color: '=',
	        height: '=',
	        width: '='
        },
        link: function(scope, element, attrs) {
	        
	        scope.style = {};
	        
	        scope.$watch('height', function(new_val) {
		       
		    	if(new_val !== undefined) {
			    	
			    	//reduce by margin top/bottom
			    	var diff = $(element).outerHeight(true) - $(element).height()
			    	
			    	scope.style.maxHeight = new_val - diff + 'px';
			    	
		    	} else {
			    	delete scope.style.maxHeight;
		    	}
		        
	        });
	        
	        scope.$watch('width', function(new_val) {
		       
		    	if(new_val !== undefined) {
			    	scope.style.width = new_val + 'px';			    	
		    	} else {
			    	delete scope.style.width;
		    	}
		        
	        });
	        
        }
	}
}]);