nwuiCardTableV2BodyRowColumnToggle.directive('nwuiCardTableV2BodyRowColumnToggle', ['$timeout', 'NwuiInputToggleFactory', function($timeout, NwuiInputToggleFactory) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/card/table/v2/body/row/column/toggle/template.html',
        scope: {
	        toggle: '='
        },
        link: function(scope, element, attrs) {
	        
		    var lock = false;

					function createToggleOpts(new_val) {
						scope.toggleOpts = {
							id : new_val.id,
							disabled : new_val.disabled,
							model: new_val.model,
							stretch: new_val.stretch,
							color: new_val.color,
							size: new_val.size,
							onChange : new_val.onChange
						};
					}

	        scope.$watch('toggle', function(new_val) {
						
		        if(!lock && new_val !== undefined) {
		        	
		        	lock = true;
		        	
		        	//new_val.stretch = true; //override
		        	new_val.size = 'short'; //override
		        	
							createToggleOpts(new_val);

			        $timeout(function() {
				    	lock = false;    
			        }, 100);
			    
			    }
		        
	        });
	        
        }
	}
}]);