nwuiCardLayers.factory('NwuiCardLayersFactory', [function() {
	
	var defaults = {
		name: undefined, //req
		layers: undefined, //req
		active_layer: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCardLayersFactory");
		if(obj.name === undefined) return console.error("Missing required instantiation object variable 'name' in NwuiCardLayersFactory");
		if(obj.layers === undefined) return console.error("Missing required instantiation object variable 'layers' in NwuiCardLayersFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);