nwuiInputSlider.directive('nwuiInputSlider', ['$window', '$timeout', 'DataSharingService' , '$rootScope', function($window, $timeout, DataSharingService, $rootScope) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/input/slider/template.html',
        scope:{
	        context: '=context',
	        disabled: '=disabled',
	        min: '=min',
	        max: '=max',
	        invert: '='
        },
        link: function(scope, element, attrs) {
	        
	        var timeout = undefined;
			var drag = { start_x: 0 }
			var width_px = $(element).width();
			var is_first = true;

	        scope.handle_style = {};
	        scope.fill_style = {};
	        scope.transition = true;
			scope.click = function(e) {
				
				if(scope.disabled) return;
				
				var perc = calc(e.offsetX);
				update(parseInt(perc, 10), 0);
				
			}
			
			scope.mousedown = function(e) {
				
				if(scope.disabled) return;
				
				drag.start_x = e.pageX;
				var perc = (scope.context-scope.min)/(scope.max-scope.min) * 100
				var val = 0;
				scope.transition = false;
				
				$(window).bind('mousemove.inputslider', function(e) {
					val = (e.pageX - drag.start_x)/width_px*100;
					update(parseInt(scope.min+((perc + val)/100)*(scope.max - scope.min), 10), 0);
				});
				
				$(window).bind('mouseup.inputslider', function(e) {
					$(window).unbind('.inputslider');
					scope.transition = true;
				});
				
			}
			
			scope.$watchGroup(['context', 'min', 'max'], function(newVal, oldVal) {
				if(((newVal != oldVal) || is_first) && scope.$parent.data['i18n_key'] !== 'unallocated')
					update(parseInt(newVal[0], 10), 250);
				if(scope.$parent.data['i18n_key'] === 'unallocated' && (newVal != oldVal)){
					var text = (newVal[0] < scope.min) ? scope.min : (newVal[0] > scope.max) ? scope.max : newVal[0];
					var perc = (text-scope.min)/(scope.max-scope.min) * 100;
					scope.handle_style = { left: perc + '%' }
					scope.fill_style = { width: perc + '%' }
					scope.context = text;
					scope.loaded = true;
				}
					
				is_first = false;
				
			});
			
			window.onresize = function() {
				scope.$apply();
			};
			
			scope.$watch(function() {
				return angular.element($window)[0].innerWidth;
			}, function() {
				width_px = $(element).width();
			});
			
			var update = function(val, delay) {
				
				$timeout.cancel(timeout);
				timeout = $timeout(function() {
					
					var text = (val < scope.min) ? scope.min : (val > scope.max) ? scope.max : val;
					var perc = (text-scope.min)/(scope.max-scope.min) * 100;
					
					var prevSliderNumber = DataSharingService.getValue('rpq', scope.$parent.data['i18n_key']);
					prevSliderNumber = prevSliderNumber === null ? 0 : parseInt(prevSliderNumber);
					var tot = (text - prevSliderNumber) + DataSharingService.getTotal('rpq');
					if( tot > 100 )
					return;
					DataSharingService.updateValue('rpq', scope.$parent.data['i18n_key'], text);
					var pieData = DataSharingService.getValue('rpq_pie_data', 'pie_data');
					var newtotal = 0;
					var unallocatedKey = '';
					for(var pie in pieData){
						if(pieData[pie].i18n_key !== 'unallocated'){
							if(pieData[pie].i18n_key === scope.$parent.data['i18n_key']){
								pieData[pie].percent = text;
							}
							newtotal += pieData[pie].percent;
						} else {
							unallocatedKey = pie;
						}
					}
					
					pieData[unallocatedKey].percent = 100 - newtotal;
					DataSharingService.updateValue('rpq_pie_data', 'pie_data', pieData);
					scope.$emit('pieDataUpdate');
					scope.handle_style = { left: text + '%' }
					scope.fill_style = { width: text + '%' }
					scope.context = text;
					scope.loaded = true;
				
				}, delay);
				
			}
			
			var calc = function(val) {
				return (val/width_px)*(scope.max-scope.min) + scope.min;
			}
			
			$rootScope.$on('pieDataReset', function(event){
				var pieData = DataSharingService.getValue('rpq_pie_data', 'pie_data');
				for(var pie in pieData){
					if(pieData[pie].i18n_key === scope.$parent.data['i18n_key'] && scope.$parent.data['i18n_key'] !== 'unallocated'){
						update(parseInt(pieData[pie].percent, 10), 250);
					}
				}
			});
	        
        }
	}
}]);