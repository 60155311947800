nwuiFixed.directive('nwuiFixed', ['$timeout', '$window', function($timeout, $window) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/fixed/template.html',
        scope: {
	        height: '='
        },
        link: function(scope, element, attrs) {
	        
	        scope.calibrate = function() {
		        
		    	scope.height = $(element).height() + 2;   
		        
	        }
	        
	        window.onresize = function() {
				scope.$apply();
			};
			
			scope.$watch(function() {
				return angular.element($window)[0].innerWidth;
			}, function() {
				scope.calibrate();
			});
	        
	    }
	}
}]);