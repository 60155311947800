nwuiMask.service('NwuiMaskService', ['$filter', 'NWUI_MASK', 'locale', function($filter, NWUI_MASK, locale) {
	
	var self = this;
	
	var Error = function(message) {
		this.message = message || 'Something went wrong';
	}
	
	var r = {
		numeric: /[^0-9]+/g,
		alphabetic: /[^A-Z]+/g,
		alphabetic_no_case: /[^a-zA-Z]+/g,
		alphanumeric: /[^0-9a-zA-Z]+/g,
		millennium: /[^1-2]+/g,
		proper_name: /[^a-zA-Z áàâäèéêëîïíìóôœúùûüÿçÁÀÂÄÈÉÊËÎÏÍÌÓÔŒÚÙÛÜŸÇ '-]+/g,
		numeric_decimal: /[^0-9.]+/g,
		numeric_comma_decimal: /[^0-9,]+/g,
	}
	
	var validators = {
		lengthMatch: function(type, input) {
			return (input.length == format.mask[type].pre.length)
				? true
				: new Error('nwui.common.error.exact_match_length');
		}
	}
	
	var format = {
		mask: {
			horizon: {
				pre: [r.numeric, r.numeric],
				post: null
			},
			numeric: {
				pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			transit: {
				pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				validate: validators.lengthMatch
			},
			age: {
				pre: [r.numeric, r.numeric]
			},
			phone: {
				pre: ["(", r.numeric, r.numeric, r.numeric, ")", " ", r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			sin: {
				pre: [r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric],
				post: null
			},
			ssn: {
				pre: [r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, "-", r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			date: {
				pre: [r.millennium, r.numeric, r.numeric, r.numeric, "/", r.numeric, r.numeric, "/", r.numeric, r.numeric],
				post: null
			},
			date_dashed: {
				pre: [r.millennium, r.numeric, r.numeric, r.numeric, "-", r.numeric, r.numeric, "-", r.numeric, r.numeric],
				post: null
			},
			year: {
				pre: [r.millennium, r.numeric, r.numeric, r.numeric],
				post: null
			},
			postal: {
				pre: [r.alphabetic_no_case, r.numeric, r.alphabetic_no_case, " ", r.numeric, r.alphabetic_no_case, r.numeric],
				post: function(input) {
					return input.toUpperCase();
				}
			},
			currency: NWUI_MASK[locale.getLocale()].currency,
			currency_decimal: NWUI_MASK[locale.getLocale()].currency_decimal,
			bank_institution: {
				pre: [r.numeric, r.numeric, r.numeric],
				post: null,
				validate: validators.lengthMatch
			},
			bank_transit: {
				pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				post: null,
				validate: validators.lengthMatch
			},
			bank_account: {
				pre: [r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric],
				post: function(input) {
					return input.toUpperCase();
				}
			},
			percentage: NWUI_MASK[locale.getLocale()].percentage,
			percentage_decimal: NWUI_MASK[locale.getLocale()].percentage_decimal,
			'4_alphanumeric': {
				pre: [r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric],
				post: function(input) {
					return input.toUpperCase();
				}
			},
			'6_alphanumeric': {
				pre: [r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric, r.alphanumeric],
				post: function(input) {
					return input.toUpperCase();
				}
			},
			'uppercase': {
				post: function(input) {
					return input.toUpperCase();
				}
			},
			'3_digit': {
				pre: [r.numeric, r.numeric, r.numeric],
				post: null
			},
			'7_digit': {
				pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			'6_digit': {
				pre: [r.numeric, r.numeric, r.numeric, r.numeric, r.numeric, r.numeric],
				post: null
			},
			proper_name: {
				pre: [r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name, r.proper_name],
				post: null
			},
			yearandmonth: {
				pre: [r.millennium, r.numeric, r.numeric, r.numeric, '-', r.numeric, r.numeric],
				post: null
			},
			four_decimals: NWUI_MASK[locale.getLocale()].four_decimals
		},
		unmask: {
			horizon: r.numeric,
			numeric: r.numeric,
			transit: r.numeric,
			age: r.numeric,
			phone: r.numeric,
			sin: r.numeric,
			ssn: r.numeric,
			date: r.numeric,
			date_dashed: r.numeric,
			year: r.numeric,
			postal: r.alphanumeric,
			currency: r.numeric,
			currency_decimal: locale.getLocale() === "en-CA" ? r.numeric_decimal : r.numeric_comma_decimal,
			bank_institution: r.numeric,
			bank_transit: r.numeric,
			bank_account: r.alphanumeric,
			percentage: r.numeric,
			percentage_decimal: r.numeric_decimal,
			'4_alphanumeric': r.alphanumeric,
			'6_alphanumeric': r.alphanumeric,
			'3_digit': r.numeric,
			'6_digit': r.numeric,
			proper_name: r.proper_name,
			yearandmonth: r.numeric,
			four_decimals : r.four_decimals
		}
	}
	
	this.validate = function(type, input) {
		
		if(!format.mask[type].validate) return true;
		return format.mask[type].validate(type, input);
		
	}

	this.getInputLength = function(type){
		return format.mask[type].pre
			? format.mask[type].pre.length
			: null;
	}
	
	this.mask = function(type, input) {
		
		if(!input) return input;
		
		input = self.unmask(type, input);
		
		var output = "";
		var count = 0;

		if (format.mask[type].pre) {
			for(var i = 0; i < format.mask[type].pre.length; i++) {

				if(count >= input.length) break;

				if(format.mask[type].pre[i] instanceof RegExp) {
					var test = input.substring(count,count+1);

					if(test.match(format.mask[type].pre[i]))
						break;

					output += test;

					count++;
				} else {
					output += format.mask[type].pre[i];
				}

			}
		} else {
			output = input;
		}
		
		if(format.mask[type].post)
			return format.mask[type].post(output);
		
		return output;
		
	}
	
	this.unmask = function(type, input) {
		if(!input) return input;
		return String(input).replace(format.unmask[type], "");
	}


    
}]);