nwuiCompoundClientDocuments.directive('nwuiCompoundClientDocuments', [
	'$timeout', 
	'$state',
	'$window',
	'$filter',
	'UserService',
	'OnboardingService',
	'DocumentService',
	'NwuiInputService',
	'NwButtonService',
	
	'NwuiCardHeaderFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiBlindFactory',
	'NwuiCardTableFactory',
	'NwuiFormFactory',
	'NwuiAccordionFactory',
	'NwuiCompoundClientDocumentsModalFactory',

	'DATE_FORMAT',
	function(
		$timeout,
		$state,
		$window,
		$filter,
		UserService,
		OnboardingService,
		DocumentService,
		NwuiInputService,
		NwButtonService,
		
		NwuiCardHeaderFactory,
		NwuiCardHeaderV2Factory,
		NwuiBlindFactory,
		NwuiCardTableFactory,
		NwuiFormFactory,
		NwuiAccordionFactory,
		NwuiCompoundClientDocumentsModalFactory,

		DATE_FORMAT
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/compound/client-documents/template.html',
			scope: {
				client: '=',
				onboarding_status_detail: '=onboardingStatusDetail',
				has_header_pill: '=hasHeaderPill',
				permissions: '='
			},
			link: function(scope, element, attrs) {
				
				var locks = {
					onboarding_status_detail: false
				}
				
				var map_table = function(table) {
					return function(document, i) {
						return {
							columns: [{
								text: document.client_document.name
							},{
								text: (document.is_complete === true) ? moment(document.completed_at).format(DATE_FORMAT.short) : '--'
							},{
								button: {
									icon: (document.is_complete === true) ? 'download' : 'upload',
									icon_only: true,
									size: 'short',
									processing: false,
									stretch: true,
									color: (document.is_complete === true) ? '' : 'error',
									disabled: document.is_complete !== true && scope.permissions && scope.permissions.write === false,
									onClick: function() {

										if(document.is_complete === true) {

											table.rows[i].columns[2].button.processing = true;

											UserService.getDocument(scope.client.uuid, document.client_document.aws_regex).then(function(obj){

												window.open(obj.data.signed_url)
												table.rows[i].columns[2].button.processing = false;

											})

										} else {

											NwuiInputService.inputs['document-upload-select'].model
												= '/api/admin/users/' + scope.client.uuid + '/documents/' + document.client_document._id;

											scope.compounds.modal.open = true;

										}

									}
								}
							}]
						}
					}
				}
				
				var loadOnboardingStatusDetail = function() {
					
					scope.cards.documents.required_table.loaded = false;
					scope.cards.documents.other_table.loaded = false;
					
					OnboardingService.getUserDetail(scope.client.id).then(function(obj) {
						
						locks.onboarding_status_detail = false;
						scope.onboarding_status_detail = obj.data.onboarding_status_detail;
						
					});
					
				}
				
				scope.data = {
					header_pill: undefined
				};
				
				scope.compounds = {
					modal: new NwuiCompoundClientDocumentsModalFactory({
						client: scope.client,
						onUploadCb: function() {
							loadOnboardingStatusDetail();
						}
					})
				}
				
				scope.cards = {
					documents: {
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'users.sections.documents.documents_card.title',
							icon: {
								name: 'library_books'
							},
							button: $state.includes('users.documents') ?
							{ 
								processing: false,
								text_i18n: 'users.sections.documents.documents_card.buttons.zip',
								id: 'download-required-documents-zip-button',
								icon: {
									name: 'file_download',
								},
								onClick: function(){
									NwButtonService.buttons['download-required-documents-zip-button'].processing = true;

									UserService.zipRequiredDocuments().then(function(res){
										$window.open(res.data.url)
										NwButtonService.buttons['download-required-documents-zip-button'].processing = false;
									}, function(err){
										//console.log(err)
										NwButtonService.buttons['download-required-documents-zip-button'].processing = false;
									})
								}
							} : {
									id: 'view-documents-button',
									text_i18n: 'users.sections.documents.documents_card.buttons.upload',
									icon: {
										name: 'file_upload'
									},
									disabled: scope.permissions && scope.permissions.write === false,
									onClick: function() {

										//NwButtonService.buttons['view-documents-button'].processing = true;

										//$state.go('users.documents', { uuid: scope.client.uuid });

										NwuiInputService.inputs['document-upload-select'].model = '';
										scope.compounds.modal.open = true;

									}
								}
						}),
						accordions: [
							new NwuiAccordionFactory({
								name: 'Loading...',
								expanded: true
							}),
							new NwuiAccordionFactory({
								name: 'Loading...'
							})
						],
						required_table: new NwuiCardTableFactory({
							name: 'client-detail-table-required-documents',
							empty_text_i18n: 'users.sections.documents.documents_card.empty_message',
							columns: [{
								name_i18n: 'users.sections.documents.documents_card.table_headings.document',
								directive: 'text',
								sortable: false
							}, {
								name_i18n: 'users.sections.documents.documents_card.table_headings.date',
								width: 110,
								directive: 'text',
								sortable: false
							}, {
								name: '',
								width: 65,
								directive: 'button',
								sortable: false
							}],
							rows: []
						}),
						other_table: new NwuiCardTableFactory({
							name: 'client-detail-table-other-documents',
							empty_text_i18n: 'users.sections.documents.documents_card.empty_message',
							columns: [{
								name_i18n: 'users.sections.documents.documents_card.table_headings.document',
								directive: 'text',
								sortable: false
							}, {
								name_i18n: 'users.sections.documents.documents_card.table_headings.date',
								width: 110,
								directive: 'text',
								sortable: false
							}, {
								name: '',
								width: 65,
								directive: 'button',
								sortable: false
							}],
							rows: []
						})
					}
				}
				
				$timeout(function() {
					
					scope.$watch('client', function(new_val) {
						
						if(new_val !== undefined && 'id' in new_val) {
							loadOnboardingStatusDetail();
						}
						
					});
					
					scope.$watch('onboarding_status_detail', function(new_val) {
						
						//console.log('onboarding_status_detail child:documents', new_val);
						
						if(new_val !== undefined && !locks.onboarding_status_detail) {
							
							locks.onboarding_status_detail = true;
							
							var onboarding_status_detail = new_val;
							
							onboarding_status_detail.documents.sort(function(a, b) {
								if(a.is_complete && !b.is_complete)
									return 1;
								return -1;
							})
							
							var required_documents = []
							var other_documents = []
							
							onboarding_status_detail.documents.forEach(function(document, i) {
								
								if(document.is_required) {
									required_documents.push(document)
								} else {
									other_documents.push(document)
								}
								
							});
							
							scope.data.header_pill = {
								text: required_documents.reduce(function(documents, document) {
									return documents + (document.is_complete === true);
								}, 0) + '/' + required_documents.length + ' uploaded'
							}
							
							scope.cards.documents.accordions[0].name_i18n = 'users.sections.documents.documents_card.accordion_headings.req_documents';
							scope.cards.documents.accordions[0].name_i18n_data = {
								documentNumber : required_documents.length
							}
							scope.cards.documents.accordions[1].name_i18n = 'users.sections.documents.documents_card.accordion_headings.other_documents';
							scope.cards.documents.accordions[1].name_i18n_data = {
								documentNumber : required_documents.length
							}
							scope.cards.documents.required_table.rows = required_documents.map(map_table(scope.cards.documents.required_table));
							scope.cards.documents.other_table.rows = other_documents.map(map_table(scope.cards.documents.other_table));
							
							$timeout(function() {
								locks.onboarding_status_detail = false;
							}, 4000);
							
						}
						
					});
					
					scope.$watchGroup(['has_header_pill', 'data.header_pill'], function(new_val, old_val) {
						
						if(new_val[0] === true) {
							scope.cards.documents.header.pill = angular.copy(new_val[1]);
						} else {
							delete scope.cards.documents.header.pill;
						}
						
					})
				
				}, 250);
							
			}
		}
	}
]);