angular.module('nwui-filters.currency.filters', [])

.filter('NwuiCurrency', [
	'$filter', 
	'$sce', 
	function(
		$filter,
		$sce
	) {
		return function(input, sign, fraction) {

			if(!input) input = 0;

			if(input >= 100000000) {
				return $filter('currency')(input, '$', 0);
			} else {
				return $filter('currency')(input)
			}

		}
	}
]);