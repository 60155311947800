angular.module('nwui-form-v3.step.directives', [])

.directive('nwuiFormV3Step', [
	'$timeout',
	'$sce',
	'$window',
	'$filter',
	'NwuiInitModuleFactory',
	'NwuiButtonV3Factory',
	'NwuiFormV3StepColumnFactory',
	'NwuiRecommendationFactory',
	'NwuiFormService',
	'locale',
	function(
		$timeout,
		$sce,
		$window,
		$filter,
		NwuiInitModuleFactory,
		NwuiButtonV3Factory,
		NwuiFormV3StepColumnFactory,
		NwuiRecommendationFactory,
		NwuiFormService,
		locale
	) {
		return {
			restrict: 'E',
			replace: true,
			require: '^nwuiFormV3',
			templateUrl: '/form/v3/step/template.html',
			scope: {
				step: '=step',
				name: '=formName',
				submit_button: '=submitButton',
				secondary_button: '=secondaryButton',
				onSubmit: '=',
				onSecondaryAction: '=',
				onComplete: '=',
				onError: '=',
				step_index: '=stepIndex',
				last_step: '=lastStep',
				columns: '=',
				description: '=',
				updateButtons: '=',
				title: '=?',
				title_i18n: '=?titleI18n',
				descriptionFunction: '=descriptionFunction',
				description_i18n: '=descriptionI18n',
				description_i18n_data: '=descriptionI18nData'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						// if(angular.isUndefined(scope.title)) scope.title = undefined;

					},
					post: function(scope, element, attrs, nwuiFormV3Ctrl) {
						
						scope = NwuiInitModuleFactory({
							name: undefined, //req
							showing: false,
							submit_button: {},
							secondary_button: undefined,
							onSubmit: undefined,
							onSecondaryAction: undefined,
							onComplete: undefined,
							onError: undefined,
							description: undefined,
							descriptionFunction: undefined,
							message: undefined,
							recommendation: undefined,
							columns: []
						}, scope);

						var locks = {
							columns: false,
							recommendation: false
						}

						//console.log('RE: nwuiCompoundModalCreateEft', 'descriptionFunction', scope.description, scope.descriptionFunction)

						var toggleMessage = function(expanded, recommendation) {

							//console.log('nwuiFormV3Step', 'toggleMessage', expanded);

							var from_height = $(element).find('.message > .inner').height();

							scope.message.no_transition = true;
							
							//set height to current auto height and change tab
							$timeout(function() {
								scope.message.style.height = from_height + 'px';
								if(expanded)
									scope.recommendation = recommendation;

								//reenable transition for animating to new height (safari fix)
								$timeout(function() {
								
									scope.message.no_transition = false;
									scope.message.expanded = expanded;

									//set new height
									$timeout(function() {
										var to_height = (expanded)
											? $(element).find('.message > .inner').height()
											: 0;
										
										scope.message.style.height = to_height + 'px'
									}, 50);
								}, 100);
							}, 50);

						}

						scope.description_i18n_empty = true;

						scope.message = {
							no_transition: false,
							expanded: false,
							style: {},
							recommendation: undefined
						}
						
						scope.modules = {};
						function createButtons() {
							scope.modules.prev_button = scope.step_index === 0 ? undefined : {
								id: 'prev-page-button-' + scope.step_index + '-' + scope.name,
								text_i18n: 'nwui.common.form.button.back',
								color: 'alternate',
								icon: {
									name: 'arrow_backward'
								},
								icon_only_mobile: true,
								onClick: function() {
									nwuiFormV3Ctrl.goToPrevStep(scope.step_index);
								}
							};
							scope.modules.secondary_button = (scope.secondary_button && scope.last_step) ? {
								id: 'secondary-page-button-' + scope.step_index + '-' + scope.name,
								text_i18n: scope.secondary_button.text_i18n,
								hollow: scope.secondary_button.hollow,
								onClick: function() {
									nwuiFormV3Ctrl.secondaryStep();
								}
							} : undefined;
							scope.modules.next_button = (scope.submit_button === false && scope.last_step) ? undefined : {
								id: 'next-page-button-' + scope.step_index + '-' + scope.name,
								// text: scope.last_step ? scope.submit_button.text || 'Submit' : 'Next',
								text_i18n: scope.last_step ? scope.submit_button.text_i18n || 'nwui.common.form.button.submit' : 'nwui.common.form.button.next',
								color: scope.last_step ? scope.submit_button.color || (organization_data && organization_data.theme.form.submit.default_color) : '',
								size: scope.submit_button.size,
								disabled: scope.last_step ? scope.submit_button.disabled === false ? false : true : false,
								icon: {
									name: scope.last_step ? (scope.submit_button.icon) ? scope.submit_button.icon.name || 'check' : 'check' : 'arrow_forward'
								},
								onClick: function() {
									nwuiFormV3Ctrl.submitStep(this, scope.step_index);
								}
								
							}
						}

						function updateButtons() {
							scope.modules.next_button.text_i18n = scope.last_step ? scope.submit_button.text_i18n || 'nwui.common.form.button.submit' : 'nwui.common.form.button.next';
						}

						createButtons();
						
						scope.$watch('message.recommendation', function(new_val, old_val) {
							
							//console.log('nwuiFormV3Step', 'message.recommendation watch', new_val);

							if(locks.recommendation === true) return;

							if(new_val === undefined) {
								toggleMessage(false);
								return;
							}
									
							locks.recommendation = true;
							
							toggleMessage(true, new_val);

							$timeout(function() {
								locks.recommendation = false;
							}, 150);
							
						});
						
						scope.$watch('updateButtons', function(new_val) {
							if(new_val == true) {
								scope.updateButtons = false;
								updateButtons();
							}
							
						})

						scope.$watch('name', function(new_val, old_val) {
							if(new_val === undefined) return;
							
							NwuiFormService.registerStep(new_val, scope);
							NwuiFormService.registerStepSubmitButton(new_val, scope.step_index, scope.modules.next_button);

							NwuiFormService.registerValidate(new_val, function() {
								return nwuiFormV3Ctrl.validate();
							});
							
						});

						nwuiFormV3Ctrl.registerStep(scope);
						nwuiFormV3Ctrl.registerStepSubmitButton(scope.step_index, scope.modules.next_button);

						scope.$watch('description', function(new_val, old_val) {
							if(new_val === undefined) return;

							scope.description_safe = $sce.trustAsHtml(new_val);

						});

						scope.$watch('description_i18n', function(new_val) {
							if(new_val === undefined) return;

							locale.ready('en-CA').then(function(res) {
								var phrase = $filter('i18n')(new_val);
								scope.description_i18n_empty = (phrase === '');
							});

						});

						//window resize event
						window.onresize = function() {
							scope.$apply();
						};
						
						scope.$watch(function() {
							return angular.element($window)[0].innerWidth;
						}, function(new_val, old_val) {
							if(new_val === old_val) return;

							scope.message.style = {};
						});

					}
				}
			}
		}
	}
]);