nwuiCompoundGoalSummaryV1.directive('nwuiCompoundGoalSummaryV1', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: '/compound/goal-summary/v1/template.html',
	        scope: {},
	        link: function(scope, element, attrs) {
				
				$scope.data = {}
				
				$scope.cards = {}
				
				$scope.modals = {}
							
		    }
		}
	}
]);