nwuiInputSelect.service('NwuiInputSelectService', ['$window', '$rootScope', function($window, $rootScope) {
	
	var self = this;
	var observers = [];
	
	this.clear = function() {
		observers = [];
	}
	
	this.notify = function(apply) {
		angular.forEach(observers, function(cb) {
			cb(apply);
		});
	};
	
	this.register = function(cb) {
		observers.push(cb);
	}
	
	$window.onclick = function() {
		self.notify(true);
	}
	
	$rootScope.$on('$stateChangeStart', function() {
		self.clear();
	});
    
}]);