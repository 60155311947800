nwuiCompoundModalSplitGoal.directive('nwuiCompoundModalSplitGoal', [
	'$timeout',
	'$filter',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	
	'NwuiToastManagerService',
	'NwuiInputService',
	function(
		$timeout,
		$filter,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		
		NwuiToastManagerService,
		NwuiInputService
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/compound/modal/split-goal/template.html',
	        scope: {
		        open: '=',
		        goal: '=',
		        source_goals: '=sourceGoals',
		        formResource: '=formResource',
		        submitCb: '=submitCb'
	        },
	        link: function(scope, element, attrs) {
				
				var submitCb = function(data) {
					scope.submitCb(data);
				}
				
				scope.modals = {
					split_goal: new NwuiModalV2Factory({
						open: false,
					    wide: false,
					    header: new NwuiCardHeaderV2Factory({
					    	name: 'Split Goal Holdings',
					    	icon: {
						    	name: 'call_split'
					    	}
				        }),
				        form: new NwuiFormV3Factory({
					        name: 'split-goal-form',
					        submit_button: {
						        text: 'Split Holdings'
					        },
					        active_step_index: 0,
					        onSubmit: function(post_data) {
						        
						        post_data.goal_id = scope.goal.id;
						        
						        return scope.formResource(post_data);
								
					        },
						    onComplete: function(obj) {
							    
							    obj.data.goal_ids = {
								    'from': NwuiInputService.inputs['split-goal-form-input-source-goal'].model,
								    'to': scope.goal.id
								}
							    
							    submitCb(obj.data);
							    scope.open = false;
								
								//show toast
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text: 'Goal split successfully!',
										icon: {
											name: 'check'
										}
									}
								));
								
						    },
						    steps: [{
						        columns: [{
							        directive: 'input',
							        width: 100,
							        data: {
								        directive: 'select',
								        data: {
									        label: 'Source Goal',
									        model: '',
									        required: true,
									        id: 'split-goal-form-input-source-goal',
									        placeholder: "Select a source goal...",
									        options: [],
									        post_var: 'source_goal_id'
								        }
							        }
						        },{
							   		directive: 'input',
							   		width: 100,
							   		data: {
								   		directive: 'text',
								        data: {
									        label: 'Amount to Split',
									        model: '',
									        mask: 'currency',
									        required: true,
									        id: 'split-goal-form-input-amount',
									        placeholder: "$25,000",
									        post_var: 'amount'
								        }
								   	}
						   		}]
						    }]
				        })
				    })
				}
				
				scope.$watch('open', function(new_val) {
					
					//console.log('nwuiCompoundModalSplitGoal', 'watch open', new_val);
					if(new_val === undefined) return;
					
					if(new_val === true) {
						//console.log('nwuiCompoundModalSplitGoal', 'goal_fields_update');
						//TODO: CLEAR INPUTS
					}
					
					scope.modals.split_goal.form.active_step_index = 0;
					scope.modals.split_goal.open = new_val;
					
				});
				
				scope.$watch('source_goals', function(new_val) {
					
					//console.log('nwuiCompoundModalSplitGoal', 'watch source_goals', new_val);
					
					if(new_val === undefined || new_val.length === 0) return;
					
					NwuiInputService.inputs['split-goal-form-input-source-goal'].options = new_val.map(function(goal,i) {
						return {
							id: i,
							text: goal.description + ' (' + $filter('currency')(goal.holdings) + ')',
							value: goal.id
						}
					});
					
					NwuiInputService.inputs['split-goal-form-input-source-goal'].model = NwuiInputService.inputs['split-goal-form-input-source-goal'].options[0].value;
					
				});
				
			}
		}
	}
]);