nwuiInputUpload.directive('nwuiInputUpload', [
	'NwuiSession', 
	'$sce', 
	'$q', 
	'$timeout', 
	'$http', 
	'Upload',
	'NwuiInitModuleFactory',
	'NwuiFormService',
	'NwuiInputService',
	'NwuiValidationService',
	'NwuiValidationErrorService', 
	'NwuiValidationErrorV2Service',
	'locale',
	function(
		NwuiSession, 
		$sce, 
		$q, 
		$timeout, 
		$http, 
		Upload,
		NwuiInitModuleFactory,
		NwuiFormService,
		NwuiInputService,
		NwuiValidationService,
		NwuiValidationErrorService,
		NwuiValidationErrorV2Service,
		locale
	) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		require: '?^nwuiFormV3',
		scope: {
			step_index: '=stepIndex',
			form_name: '=formName',
			model: '=',
			id: '=',
			url: '=',
			hide: '=',
			post_var: '=postVar',
			placeholder: '=',
			placeholder_i18n: '=placeholderI18n',
			label: '<',
			label_i18n: '<labelI18n',
			sub_label_i18n: '<subLabelI18n',
			notification: '=',
			file_type_pattern: '=fileTypePattern',
			required: '=',
			onSuccess: '=onSuccess',
			onError: '=onError',
			onUpload: '=onUpload'
		},
		templateUrl: '/input/upload/template.html',
		link: function(scope, element, attrs, nwuiFormV3Ctrl) {

			scope = NwuiInitModuleFactory({
				model: undefined, //req
				id: undefined, //req
				url: undefined, //req
				placeholder: undefined,
				label: undefined,
				required: false,
				post_var: undefined,
				file_type_pattern: '.pdf,.tiff,.gif,.jpg,.jpeg,.png',
				onSuccess: undefined,
				onError: function() {},
				onUpload: function() {},
				notification: {}
			}, scope);

			if(nwuiFormV3Ctrl)
				nwuiFormV3Ctrl.registerInput(scope.step_index, scope);
			if(angular.isDefined(scope.form_name))
				NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);

			var submitted_count = 0;

			scope.processing = false;
			scope.filled = false;
			scope.button = {
				size: 'small',
				icon: {
					name: 'file_upload'
				},
				processing: false,
				icon_only: true,
				onClick: function() {}
			}
			scope.notification = {
				text: '',
				invert_x: false,
				success: false,
				show: false
			}

			var clear = function() {
				//console.log('nwuiInputUpload', 'clear', submitted_count);
				scope.filled = false;
				submitted_count = 0;
			}

			var validate = function() {
				//console.log('nwuiInputUpload', 'validate', submitted_count);

				var valid = true;

				if(scope.required) {
					valid = NwuiValidationService['upload'](submitted_count);
				}

				if(valid !== true) {
					scope.error = true;
					scope.notification.success = false;
					scope.notification.error = true;

					if (scope.label) {
						scope.notification.text = $sce.trustAsHtml(NwuiValidationErrorService[valid](scope.label));
					} else if(scope.label_i18n) {
						scope.notification.text_i18n = NwuiValidationErrorV2Service[valid]();
						scope.notification.text_i18n_data = {
							label: locale.getString(scope.label_i18n)
						}
					}

					scope.notification.show = true;

					var offset = $('#'+scope.id).offset();
					if (offset) {
						$('html, body').animate({
							scrollTop: offset.top - (window.innerHeight * 0.5)
						}, 1000);
					}
					
				}

				return valid;
			}

			var readFile = function(file) {

				// console.log('nwuiInputUpload', 'readFile', file);

				var deferred = $q.defer();

				var reader = new FileReader();
				reader.onload = function(e) {
					deferred.resolve(e.target.result);
				};
				reader.onerror = function(e) {
					deferred.reject(e);
				};

				if(file)
					reader.readAsDataURL(file);

				return deferred.promise;

			}

			var dataURItoBlob = function ( dataURI ) {
				// convert base64/URLEncoded data component to raw binary data held in a string
				var byteString;
				if ( dataURI.split( ',' )[ 0 ].indexOf( 'base64' ) >= 0 )
					byteString = atob( dataURI.split( ',' )[ 1 ] ); else
					byteString = unescape( dataURI.split( ',' )[ 1 ] );

				// separate out the mime component
				var mimeString = dataURI.split( ',' )[ 0 ].split( ':' )[ 1 ].split( ';' )[ 0 ];

				//console.log('mimeString', mimeString)

				scope.type = mimeString.split('/')[1];


				// write the bytes of the string to a typed array
				var ia = new Uint8Array( byteString.length );
				for ( var i = 0; i < byteString.length; i++ )
				{
					ia[ i ] = byteString.charCodeAt( i );
				}
				return new Blob( [ ia ], {type : mimeString} );
			}

			var uploadFileToUrl = function(file, uploadUrl, filename,callback){
				var fd = new FormData();

				fd.append('file', file, filename);
				fd.append('type', file.type)
				fd.append('filename', filename)

				//intentionally left headers content-type to undefined
				//angular selects and sets accordingly
				$http
					.post(uploadUrl, fd, {
						transformRequest: angular.identity,
						headers: {'Content-Type': undefined }
					})
					.then(function(data){
						//console.log('nwuiInputUpload', 'post upload', data);
						scope.processing = false;
						scope.button.processing = false;
						scope.filled = true;
						scope.show_thumb = true;
						scope.file = undefined;

						if(scope.onSuccess)
							scope.onSuccess(data);
						else {
							scope.notification.show = true;
							scope.notification.text = $sce.trustAsHtml('Upload successful');
						}
						
						submitted_count++;

						// $timeout(function() {
						// 	scope.filled = false;
						// }, 500)
					}, function(error) {
						scope.processing = false;
						scope.button.processing = false;
						scope.file = undefined;
						scope.onError(error);
					});
			}

			scope.onClick = function(e) {
				//console.log('nwuiInputUpload', 'onClick', e);

				scope.error = false;
				scope.notification.show = false;
			}

			scope.upload = function(file, err) {

				console.log('nwuiInputUpload', 'upload', file, err);

				if(err && err.length > 0) {
					scope.error = true;
					scope.notification.error = true;
					scope.notification.show = true;
					var error_code = err[0].$error;

					switch(error_code) {
						case 'maxSize':
							scope.notification.text_i18n = 'nwui.common.input.file_upload.error_max_size';
							scope.notification.text_i18n_data = { size: err[0].$errorParam }
							break;
						default:
							scope.notification.text_i18n = 'nwui.common.input.file_upload.error_unknown';
					}

					return;
				}

				if(!file)
					return;

				scope.onUpload(file);
				scope.processing = true;
				scope.button.processing = true;
				//var files = event.files;
				readFile(file).then(function(result){
					var blob = dataURItoBlob(result)
					var filename = file.name
					var uploadUrl = scope.url;
					
					scope.model = filename;
					scope.filled = true;
					scope.error = false;
					scope.notification.error = false;
					scope.notification.success = true;
					
					//console.log('scope.url', scope.url);
					uploadFileToUrl( blob, uploadUrl, filename, function ( data ) {
						//console.log(data)
					})
				}, function(error) {
				})
				
			};

			scope.$watch('model', function(new_val) {

				if(new_val === undefined) return;

				if(new_val === '') {
					scope.filled = false;
					scope.error = false;
					scope.notification.show = false;
				} else {
					scope.filled = true;
					submitted_count++;
				}

			});
			
			NwuiInputService.register(scope, validate, clear);
					
		}
	}
}]);