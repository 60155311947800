//select input directive
nwuiInputCheckboxGroup.directive('nwuiInputCheckboxGroup', [
	'$sce',
	'$timeout',
	'NwuiInitModuleFactory',
	'NwuiInputService',
	'NwuiValidationService',
	'NwuiValidationErrorService',
	'NwuiFormService',
	
	'NwuiInputCheckboxGroupCheckFactory', 
	function(
		$sce, 
		$timeout, 
		NwuiInitModuleFactory,
		NwuiInputService, 
		NwuiValidationService, 
		NwuiValidationErrorService,
		NwuiFormService,
		
		NwuiInputCheckboxGroupCheckFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/input/checkbox-group/template.html',
			require: '?^nwuiFormV3',
			scope: {
				step_index: '=stepIndex',
				form_name: '=formName',
				id: '=',
				type: '=',
				options: '=',
				label: '=',
				label_i18n: '=labelI18n',
				post_var: '=postVar',
				model: '=',
				hide: '=',
				required: '=',
				stretch: '=',
				color: '=',
				size: '=',
				onChange: '=',
				notification: '=',
				hide: '=',
				disabled: '='
			},
			link: function(scope, element, attrs, nwuiFormV3Ctrl) {

				scope = NwuiInitModuleFactory({
					options: undefined, //req
					label: '',
					type: '',
					required: false
				}, scope);

				if(nwuiFormV3Ctrl)
					nwuiFormV3Ctrl.registerInput(scope.step_index, scope);
				else if(angular.isDefined(scope.form_name))
					NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);

				var block = false;
				var focussed = false;

				var locks = {
					options: false,
					model: false
				}

				var clear = function() {

					//console.log('nwuiInputCheckboxGroup', 'clear')
					// scope.model.forEach(function(option,i) {
					// 	option.value = option.default_value;
					// });

					
					scope.options.forEach(function(option,i) {
						option.value = option.default_value;
					});

				}
						
				var validate = function() {
					
					var valid = true;
					var agg_valid = true;
					var agg_valid_flag = (scope.required === true) ? false : true;

					//iterate over required checkboxes
					scope.options.forEach(function(option,i) {

						//check if at least a single selection is required
						if(scope.required === true && valid === true && agg_valid_flag === false) {
							agg_valid = NwuiValidationService['checkbox'](option.value);
							agg_valid_flag = (agg_valid === true);
							//console.log('nwuiInputCheckboxGroup', 'validate agg', agg_valid_flag, agg_valid);
						}

						//check if required
						if(option.required === true && valid === true) {
							//if not true, invalid
							valid = NwuiValidationService['checkbox'](option.value);

							if(valid !== true) {
								option.error = true;
								option.notification.text = $sce.trustAsHtml(NwuiValidationErrorService[valid](option.label));
								option.notification.show = true;
							}
						}
					})

					//if no option errors but none are selected and required
					if(valid === true && agg_valid_flag !== true) {
						scope.error = true;
						scope.options[0].notification.text = $sce.trustAsHtml(NwuiValidationErrorService[agg_valid](scope.label));
						scope.options[0].notification.show = true;
					}
					
					return valid;
					
				}

				scope.error = false;

				scope.change = function(checkbox) {
					
					//console.log('nwuiInputCheckboxGroup', 'scope.change', checkbox);

					var is_new = true;

					if(scope.error === true) {
						scope.error = false;
						scope.options[0].error = false;
						scope.options[0].notification.show = false;
					}
					
					checkbox.error = false;
					checkbox.notification.show = false;

					scope.model.forEach(function(option,i) {
						if(option.model === checkbox.model) {
							option.value = checkbox.value;
							is_new = false;
						}
					});

					if(is_new) {
						scope.model.push({
							value: checkbox.value,
							model: checkbox.model
						});
					}

					if(scope.onChange)
						scope.onChange(scope.model);

					if(nwuiFormV3Ctrl)
						nwuiFormV3Ctrl.setDirty(scope.id);
					else
						NwuiFormService.setDirty(scope.id);

					//console.log('nwuiInputCheckboxGroup', 'scope.change', scope.model);

				}

				scope.keyPress = function(event) {

					//console.log('nwuiInputCheckboxGroup', 'keyPress', focussed, event.keyCode, event.which);

					if(focussed === true) {
						if(event.which === 13) {
							if(nwuiFormV3Ctrl)
								nwuiFormV3Ctrl.enterKeySubmit(scope.id, event);
							else
								NwuiFormService.enterKeySubmit(scope.id, event);
						}
					}

				}

				scope.onFocus = function(event) {

					//console.log('nwuiInputCheckboxGroup', 'onFocus');
					focussed = true;

				}

				scope.onBlur = function(event) {

					//console.log('nwuiInputCheckboxGroup', 'onBlur');
					focussed = false;

				}
						
				NwuiInputService.register(scope, validate, clear);
						
				//watch for updates to the checkbox options
				scope.$watch('options', function(new_val, old_val) {
					
					//console.log('nwuiInputCheckboxGroup', 'watch options', scope.label, locks.options, old_val, '=>', new_val);

					if(new_val === undefined || new_val.length === 0 || locks.options === true) return;
					
					locks.options = true;
					locks.model = true;

					scope.options = new_val.map(function(option,i) {
						option.id = scope.id + '-' + i;
						option.label_safe = $sce.trustAsHtml(option.label);
						return new NwuiInputCheckboxGroupCheckFactory(option);
					});

					scope.model = scope.options.map(function(option,i) {
						return {
							model: option.model,
							value: option.value || false
						}
					});
					
					$timeout(function() {
						locks.options = false;
						locks.model = false;
					}, 100);
					
				});
						
				scope.$watch('model', function(new_val, old_val) {
					
					//console.log('nwuiInputCheckboxGroup', 'watch model', locks.model, old_val, '=>', new_val);

					if(locks.model === true) return;

					if(new_val === undefined) {
						scope.model = [];
					} else {}

					locks.model = true;
			
					$timeout(function() {
						locks.model = false;
					}, 100);

				}, true);
				
			}
		}
	}
]);