nwuiCompoundMenuTertiaryItem.directive('nwuiCompoundMenuTertiaryItem', [
	'$rootScope',
	'$sce',
	'$state',
	'$timeout',
	
	'NwuiPillFactory',
	'NwuiIconV2Factory',
	'NwuiTooltipV2Factory',
	
	'NwuiOverlayService',
	function(
		$rootScope,
		$sce,
		$state,
		$timeout,
		
		NwuiPillFactory,
		NwuiIconV2Factory,
		NwuiTooltipV2Factory,
		
		NwuiOverlayService
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/compound/menu/tertiary/item/template.html',
	        scope: {
		        active: '=',
		        state: '=',
		        active_state: '=activeState',
		        text: '=',
		        text_i18n: '=textI18n',
		        icon: '=',
		        pill: '='
	        },
	        link: function(scope, element, attrs) {
				
				var locks = {
					tooltip: false,
					pill: false,
					icon: false
				}

				scope.current_state = $state;
				//console.log('state', scope.current_state.includes(scope.state), scope.current_state, scope.state);
				scope.active = false;
				
				// scope.stateChange = function() {
				// 	//console.log('nwuiCompoundMenuTertiaryItem', 'stateChange', $state, scope.state);

				// 	if($state.current.name == scope.state) return;
					
				// 	$timeout(function() {
				// 		$state.go(scope.state);
				// 	}, 750);
					
				// }
				
				scope.mouseenter = function() {
					
					if(!('ontouchstart' in document))
						scope.tooltip.show = true;
					
				}
				
				scope.mouseleave = function() {
					
					if(!('ontouchstart' in document))
						scope.tooltip.show = false;
					
				}
				
				scope.$watch('tooltip', function(new_val, old_val) {
				    
				    //console.log('tooltip', new_val);
				    if(!locks.tooltip) {
					    
					    locks.tooltip = true;
					    
					    scope.tooltip = {
						    show: false,
						    text: scope.text,
						    text_i18n: scope.text_i18n
						}
						
					    // scope.tooltip = new NwuiTooltipV2Factory(scope.tooltip);
					    
					    //console.log('scope.tooltip', scope.tooltip)
					    
					    $timeout(function() {
							locks.tooltip = false;
					    }, 100);
					
					}
				    
			    });
			    
				scope.$watch('pill', function(new_val, old_val) {
				    
				    //console.log('pill', new_val);
				    if(new_val !== undefined && !locks.pill) {
					    
					    locks.pill = true;
					    
					    scope.pill = new NwuiPillFactory(scope.pill);
					    
					    $timeout(function() {
							locks.pill = false;    
					    }, 100);
					
					}
				    
			    });
			    
			    scope.$watch('icon', function(new_val, old_val) {
				    
				    if(new_val !== undefined && !locks.icon) {
					    
					    locks.icon = true;
					    
					    scope.icon = new NwuiIconV2Factory(scope.icon);
					    
					    $timeout(function() {
							locks.icon = false;    
					    }, 100);
					
					}
				    
			    });
			   
			  scope.$watch('state', function(new_val, old_val) {

			  	if(new_val === undefined) return;

			  	scope.id = new_val.replace(/\.+/g , "_");

			  })

				scope.$watch('text', function(new_val, old_val) {
			        
			        //console.log('text', new_val)
			        
			    	if(new_val !== undefined) {
				    	scope.safe_text = $sce.trustAsHtml(new_val);
			    	}    
			        
			    });
	
		    }
		}
	}
]);