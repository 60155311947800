nwuiCardTableV3BodyRowColumnGlyph.directive('nwuiCardTableV3BodyRowColumnGlyph', [
	'$timeout',
	'NwuiIconV2Factory',
	function(
		$timeout,
		NwuiIconV2Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/card/table/v3/body/row/column/glyph/template.html',
			scope: {
				icon: '='
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 

						//just in case
						if(angular.isUndefined(scope.icon)) scope.icon = {};

						scope.icon.size = 'small';

					},
					post: function(scope, element, attrs) {}
				}
			}
			// link: function(scope, element, attrs) {

			// 	var locks = {
			// 		icon: false
			// 	}

			// 	scope.$watch('icon', function(new_val, old_val) {
					
			// 		if(new_val === undefined) return;

			// 		if(locks.icon === true) return;
					
			// 		locks.icon = true;
			// 		scope.icon = new NwuiIconV2Factory(new_val);
						
			// 		$timeout(function() {
			// 			locks.icon = false;
			// 		});
				
			// 	});

			// }
		}
	}
]);