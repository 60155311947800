nwuiCardTableV2BodyRowColumnNumber.directive('nwuiCardTableV2BodyRowColumnNumber', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/v2/body/row/column/number/template.html',
        scope: {
	        number: '='
        },
        link: function(scope, element, attrs) {}
	}
}]);