nwuiCardTableV3BodyRowColumnProgress.directive('nwuiCardTableV3BodyRowColumnProgress', ['$filter', function ($filter) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/card/table/v3/body/row/column/progress/template.html',
		scope: {
			prefix: '=',
			numerator: '=',
			denominator: '=',
			percentInput: '=percent'
		},
		link: function (scope, element, attrs) {

			scope.is_complete = false;
			scope.is_coming_along = false;
			scope.percent = 0;
			scope.fill_style = {}
			scope.numerator_text = '0';
			scope.denominator_text = '0';

			scope.$watch('percentInput', function (new_val) {
				if (typeof new_val !== 'undefined') {
					scope.percent = scope.percentInput * 100;
					setPercentStyle(); 
				}

			})

			scope.$watchGroup(['numerator', 'denominator'], function (new_val) {

				if ((typeof new_val[0] !== 'undefined') && (typeof new_val[1] !== 'undefined')) {

					scope.numerator_text = $filter('NwuiNumberAbbrFilter')(new_val[0], undefined, 0);
					scope.denominator_text = $filter('NwuiNumberAbbrFilter')(new_val[1], undefined, 0);
					scope.percent = (new_val[0] / new_val[1] * 100).toFixed(0);
					scope.percent = scope.percent > 100 ? 100 : scope.percent;

					setPercentStyle();

				}

			})

			function setPercentStyle() {
				scope.fill_style = {
					width: scope.percent + '%'
				}

				if (scope.percent == 100)
					scope.is_complete = true;
				else if (scope.percent >= 40)
					scope.is_coming_along = true;
				else {
					scope.is_complete = false;
					scope.is_coming_along = false;
				}
			}

		}
	}
}]);