angular.module('nwui-chart-score.directives', [])

.directive('nwuiChartScore', [
	'$timeout', 
	function(
		$timeout
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/chart/score/template.html',
			scope: {
				score: "<",
				label_i18n: "<?labelI18n"
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						


					},
					post: function(scope, element, attrs) {



					}
				}
			}
		}
	}
]);