nwuiCardTableV2Footer.factory('NwuiCardTableV2FooterFactory', [function() {
	
	var defaults = {
		table_id: undefined,
		total_items: undefined,
		query: '',
		current_page: 1,
		page_size: 10
	}
	
	return function(obj) {
		
		if(!obj) return console.error("Missing instantiation object NwuiCardTableV2FooterFactory");
		if(obj.table_id === undefined) return console.error("Missing required instantiation object variable 'table_id' in NwuiCardTableV2FooterFactory");
		
		angular.extend(this, defaults, obj);
	
	}
	
}]);