nwuiCardTableV3Filters.directive('nwuiCardTableV3Filters', [
  '$timeout',
  '$window',
  function(
    $timeout,
    $window
  ) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: '/card/table/v3/filters/template.html',
      scope: {
        filters: '=?',
        reload: '=?',
        "tableId": '=?'
      },
      link: function(scope, element, attrs) {
        scope.editingIndex = null;

        scope.applyFilter = function(filter) {
          if (!scope.filters.appliedFilters) {
            scope.filters.appliedFilters = [];
          }
          if (scope.editingIndex === null) {
            scope.filters.appliedFilters.push(filter);
            scope.reload();
          } else {
            scope.filters.appliedFilters[scope.editingIndex] = filter;
            scope.editingIndex = null;
            scope.reload();
          }
        };

        scope.onClickEdit = function(index) {
          if (scope.editingIndex === index) {
            scope.currentFilter = {};
            scope.editingIndex = null;
          } else {
            scope.currentFilter = scope.filters.appliedFilters[index];
            scope.editingIndex = index;
            scope.filters.open = true;
          }
        };

        scope.onClickRemove = function(index) {
          scope.filters.appliedFilters.splice(index, 1);
          if (scope.editingIndex === index) {
            scope.editingIndex = null;
            scope.currentFilter = {};
          }
          scope.reload();
        }
      }
    }
  }
]);