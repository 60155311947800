nwuiBreadcrumbs.service('NwuiBreadcrumbsService', ['$state', '$interpolate', function($state, $interpolate) {

  var self = this;
  this.displaynameProperty = undefined;
  this.abstractProxyProperty = undefined;
  this.i18nDisplayNameProperty = undefined;

  this.buildBreadcrumbs = function(displaynameProperty, abstractProxyProperty, i18nDisplayNameProperty) {
    self.displaynameProperty = displaynameProperty;
    self.abstractProxyProperty = abstractProxyProperty;
    self.i18nDisplayNameProperty = i18nDisplayNameProperty;
    return updateBreadcrumbsArray();
  }

  /**
   * uiBreadcrumbs automatic breadcrumbs directive for AngularJS & Angular ui-router.
   *
   * https://github.com/michaelbromley/angularUtils/tree/master/src/directives/uiBreadcrumbs
   *
   * Copyright 2014 Michael Bromley <michael@michaelbromley.co.uk>
   */

  /**
   * Start with the current state and traverse up the path to build the
   * array of breadcrumbs that can be used in an ng-repeat in the template.
   */
  function updateBreadcrumbsArray() {
    var workingState;
    var displayName;
    var i18nDisplayName;
    var breadcrumbs = [];
    var currentState = $state.$current;

    while(currentState && currentState.name !== '') {
      workingState = getWorkingState(currentState);
      if (workingState) {
        displayName = getDisplayName(workingState);
        i18nDisplayName = getI18nDisplayName(workingState);
        if (displayName !== false && !stateAlreadyInBreadcrumbs(workingState, breadcrumbs)) {
          breadcrumbs.push({
            label: displayName,
            label_i18n: i18nDisplayName,
            state: workingState.name
          });
        }
      }
      currentState = currentState.parent;
    }
    breadcrumbs.reverse();
    return breadcrumbs;
  }

  /**
   * Get the state to put in the breadcrumbs array, taking into account that if the current state is abstract,
   * we need to either substitute it with the state named in the `self.abstractProxyProperty` property, or
   * set it to `false` which means this breadcrumb level will be skipped entirely.
   * @param currentState
   * @returns {*}
   */
  function getWorkingState(currentState) {
    var proxyStateName;
    var workingState = currentState;

    if (currentState.abstract === true) {
      if (typeof self.abstractProxyProperty !== 'undefined') {
        proxyStateName = getObjectValue(self.abstractProxyProperty, currentState);
        if (proxyStateName) {
          workingState = angular.copy($state.get(proxyStateName));
          if (workingState) {
            workingState.locals = currentState.locals;
          }
        } else {
          workingState = false;
        }
      } else {
        workingState = false;
      }
    }
    return workingState;
  }

  /**
   * Resolve the displayName of the specified state. Take the property specified by the `displayname-property`
   * attribute and look up the corresponding property on the state's config object. The specified string can be interpolated against any resolved
   * properties on the state config object, by using the usual {{ }} syntax.
   * @param currentState
   * @returns {*}
   */
  function getDisplayName(currentState) {
    var interpolationContext;
    var propertyReference;
    var displayName;

    if (!self.displaynameProperty) {
      // if the displayname-property attribute was not specified, default to the state's name
      return currentState.name;
    }
    propertyReference = getObjectValue(self.displaynameProperty, currentState);

    if (propertyReference === false) {
      return false;
    } else if (typeof propertyReference === 'undefined') {
      return currentState.name;
    } else {
      // use the $interpolate service to handle any bindings in the propertyReference string.
      interpolationContext =  (typeof currentState.locals !== 'undefined') ? currentState.locals.globals : currentState;
      displayName = $interpolate(propertyReference)(interpolationContext);
      return displayName;
    }
  }

  function getI18nDisplayName(currentState) {
    return getObjectValue(self.i18nDisplayNameProperty, currentState);
  }

  /**
   * Given a string of the type 'object.property.property', traverse the given context (eg the current $state object) and return the
   * value found at that path.
   *
   * @param objectPath
   * @param context
   * @returns {*}
   */
  function getObjectValue(objectPath, context) {
    var i;
    var propertyArray = objectPath.split('.');
    var propertyReference = context;

    for (i = 0; i < propertyArray.length; i ++) {
      if (angular.isDefined(propertyReference[propertyArray[i]])) {
        propertyReference = propertyReference[propertyArray[i]];
      } else {
        // if the specified property was not found, default to the state's name
        return undefined;
      }
    }
    return propertyReference;
  }

  /**
   * Check whether the current `state` has already appeared in the current breadcrumbs array. This check is necessary
   * when using abstract states that might specify a proxy that is already there in the breadcrumbs.
   * @param state
   * @param breadcrumbs
   * @returns {boolean}
   */
  function stateAlreadyInBreadcrumbs(state, breadcrumbs) {
    var i;
    var alreadyUsed = false;
    for(i = 0; i < breadcrumbs.length; i++) {
      if (breadcrumbs[i].state === state.name) {
        alreadyUsed = true;
      }
    }
    return alreadyUsed;
  }

}]); 