nwuiChartLine.factory('NwuiChartLineFactory', [function() {
	
	var date = new Date();
	
	var defaults = {
		name: undefined,
		series: undefined,
		height_ratio: 0,
		trim_dates: false,
		horiz_axis_labels: true,
		units: '',
		number_filter: 'currency',
		range: {
			date_st: new Date(date.getFullYear()-20, 0, 0), 
			date_en: date
		}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiChartLineFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);