var nwuiToolkit = angular.module('nwui-toolkit', [
	'nwui-templates',
	'shared-services',
	'nwui-accordion',
	'nwui-allocation',
	'nwui-auth',
	'nwui-blind',
	'nwui-block',
	'nwui-breadcrumbs',
	'nwui-button',
	'nwui-cache',
	'nwui-analytics',
	'nwui-card',
	'nwui-carousel',
	'nwui-chart',
	'nwui-compound',
	'nwui-content',
	'nwui-copy',
	'nwui-cutout',
	'nwui-divider',
	'nwui-drawer',
	'nwui-filters',
	'nwui-fixed',
	'nwui-form',
	'nwui-goal-progress',
	'nwui-grid',
	'nwui-helper',
	'nwui-hotkeys',
	'nwui-icon',
	'nwui-init-module',
	'nwui-input',
	'nwui-loader',
	'nwui-loading',
	'nwui-logo',
	'nwui-map',
	'nwui-mask',
	'nwui-modal-body-auth',
	'nwui-modal-body-info',
	'nwui-modules',
	'nwui-notification-bubble',
	'nwui-number-climber',
	'nwui-overlay',
	'nwui-page-scroll',
	'nwui-page-title',
	'nwui-period',
	'nwui-pill',
	'nwui-progress-bar',
	'nwui-recommendation',
	'nwui-register',
	'nwui-ripple',
	'nwui-signing',
	'nwui-tabs',
	'nwui-tag',
	'nwui-target',
	'nwui-title',
	'nwui-toast',
	'nwui-toggle',
	'nwui-tooltip',
	'nwui-touch-events',
	'nwui-util-classes',
	'nwui-validation',
	'nwui-risk-profile-visualizer',
	'question-pie-legend',
	'question-pie-legend-item'
]);