angular.module('nwui-card-table-v3-body-sub-row.directives', [])

.directive('nwuiCardTableV3BodySubRow', [
	function() {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/v3/body/row/sub-row/template.html',
			scope: {
				columns: '=',
				widths: '=',
				row: '='
			},
			link: function(scope, element, attrs) {}
		}
	}
]);