angular.module('nwui-init-module.factories', [])

.service('NwuiInitModuleFactory', [
	function() {
		return function(defaults, scope) {

			for(var key in defaults) {
				scope[key] = angular.isDefined(scope[key]) ? scope[key] : defaults[key] 
			}

			return scope;

		}
	}
]);