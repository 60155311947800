nwuiCarouselNavigation.directive('nwuiCarouselNavigation', [
	'$timeout', 
	
	'NwuiButtonV3Factory',
	function(
		$timeout,
		
		NwuiButtonV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/carousel/navigation/template.html',
			scope: {
				carousel_id: '=carouselId',
				count: '=',
				current: '=',
				onChange: '=onChange'
			},
			link: function(scope, element, attrs) {
				
				var prev = function() {
					scope.current = ((scope.current-1) + scope.count) % scope.count;
				}

				var next = function() {
					scope.current = ((scope.current+1) + scope.count) % scope.count;
				}
				
				scope.setSlide = function(index) {
					scope.current = index;
				}
				
				scope.indicators = [];
				
				scope.data = {
					buttons: {
						prev: new NwuiButtonV3Factory({
							id: 'carousel-navigation-prev-' + scope.carousel_id,
							icon_only: true,
							size: 'small',
							color: 'alternate',
							icon: {
								name: 'keyboard_arrow_left'
							},
							onClick: function() {
								prev();
							}
						}),
						next: new NwuiButtonV3Factory({
							id: 'carousel-navigation-next-' + scope.carousel_id,
							icon_only: true,
							size: 'small',
							color: 'alternate',
							icon: {
								name: 'keyboard_arrow_right'
							},
							onClick: function() {
								next();
							}
						})
					}
				}
				
				scope.$watch('count', function(new_val) {
					
					if(new_val === undefined) return;
					
					var indicators = [];
					for(var i = 0; i < new_val; i++) {
						indicators.push({});
					}
					
					scope.indicators = indicators;
					
				});
				
				scope.$watch('current', function(new_val) {
					
					if(new_val === undefined) return;
					
					scope.onChange(scope);
					
				});
				
			}
		}
	}
]);