nwuiCompoundGoalRiskProfile.factory('NwuiCompoundGoalRiskProfileFactory', [function() {
	
	var defaults = {
		goal_id: undefined,
		user_id: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundGoalRiskProfileFactory");
		//if(obj.goal_id === undefined) return console.error("Missing required instantiation object variable 'goal_id' in NwuiCompoundGoalRiskProfileFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);