nwuiButton.factory('NwuiButtonFactory', [function() {
	var defaults = {
		onClick: undefined, //req
		id: undefined,
		text: undefined,
		icon: undefined,
		size: undefined,
		stretch: false,
		processing: false,
		disabled: false,
		color: '',
		icon_only: false,
		analytics_tag: false
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiButtonFactory");
		if(obj.onClick === undefined) return console.error("Missing required instantiation object variable 'onClick' in NwuiButtonFactory");
		//if(obj.text === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiButtonFactory");
		
		angular.extend(this, defaults, obj);
	}
}]);