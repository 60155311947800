nwuiCardTableBodyRowColumnCurrency.directive('nwuiCardTableBodyRowColumnCurrency', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/body/row/column/currency/template.html',
        scope: {
	        number: '='
        },
        link: function(scope, element, attrs) {}
	}
}]);