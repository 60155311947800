nwuiCompoundModalAddEftSource.directive('nwuiCompoundModalAddEftSource', [
	'$timeout',
	'$filter',

	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	'NwuiSigningService',
	'NwuiPageScrollService',

	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwButtonService',
	function(
		$timeout,
		$filter,

		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		NwuiSigningService,
		NwuiPageScrollService,

		NwuiToastManagerService,
		NwuiInputService,
		NwuiFormV3Service,
		NwButtonService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/add-eft-source/template.html',
			scope: {
				open: '=',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {

				var uploaded_file = undefined;

				var submitCb = function(data) {
					scope.submitCb(data);
				}

				scope.modals = {
					add_eft_source: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							// name: 'Add Transfer Source',
							name_i18n: 'nwui.modal.add_eft_source.title',
							icon: {
								name: 'create'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'add-eft-source-form',
							active_step_index: 0,
							onSubmit: function(post_data) {

								if(uploaded_file !== undefined)
									post_data.upload = uploaded_file;

								return scope.formResource(post_data);

							},
							onError: function(obj) {
								if(obj.data.code) {
									error_msg = 'nwui.modal.add_eft_source.errors.' + obj.data.code;

									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: error_msg,
											type: 'error',
											icon: {
												name: 'close'
											}
										}
									));
								}
							},
							onComplete: function(obj) {
								submitCb(obj.data);
								scope.open = false;

								//show toast
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										// text: 'Transfer source submitted successfully!',
										text_i18n: 'nwui.modal.add_eft_source.success',
										icon: {
											name: 'check'
										}
									}
								));

								if (obj.data.envelope_id) {
									//console.log('ManageTransferSourcesController', 'has envelope_id', obj.data.envelope_id);
									$timeout(function() {
										NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelope_id);
										NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {
											//console.log('ManageTransferSourcesController', 'NwuiSigningService.setSubmitCb', obj);
										});

										NwuiPageScrollService.open('global-document-signing');
									}, 0);
								}

							},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											// label: 'Account Nickname (Optional)',
											label_i18n: 'nwui.common.input.eft_nickname.label',
											model: '',
											id: 'add-eft-source-form-input-nickname',
											stretch: true,
											required: false,
											// placeholder: "Bank Name/Account Type",
											placeholder_i18n: 'nwui.common.input.eft_nickname.placeholder',
											post_var: 'nickname'
										}
									}
								}]
							}, {
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.common.input.institution_name.label',
											model: '',
											id: 'add-eft-source-form-input-name',
											stretch: true,
											required: true,
											size: 'small',
											post_var: 'bank_name',
											placeholder_i18n: 'nwui.common.input.institution_name.placeholder',
										}
									}
								}, {
									directive: 'input',
									width: 50,
									data: {
										directive: 'text',
										data: {
											// label: 'Inst. Number',
											label_i18n: 'nwui.common.input.institution_number.label',
											model: '',
											id: 'add-eft-source-form-input-institution',
											stretch: true,
											required: true,
											size: 'small',
											mask: 'bank_institution',
											validators: ['bank_institution'],
											// placeholder: "001",
											placeholder_i18n: 'nwui.common.input.institution_number.placeholder',
											post_var: 'bank_institution'
										}
									}
								}, {
									directive: 'input',
									width: 50,
									data: {
										directive: 'text',
										data: {
											// label: 'Transit Number',
											label_i18n: 'nwui.common.input.transit_number.label',
											model: '',
											size: 'small',
											id: 'add-eft-source-form-input-transit',
											stretch: true,
											required: true,
											mask: 'bank_transit',
											validators: ['bank_transit'],
											// placeholder: "01234",
											placeholder_i18n: 'nwui.common.input.transit_number.placeholder',
											post_var: 'bank_branch'
										}
									}
								}, {
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											// label: 'Account Number',
											label_i18n: 'nwui.common.input.account_number.label',
											model: '',
											id: 'add-eft-source-form-input-account',
											stretch: true,
											required: true,
											size: 'small',
											mask: 'bank_account',
											validators: ['bank_account_number'],
											maxlength: 12,
											// placeholder: "01234ABCDE",
											placeholder_i18n: 'nwui.common.input.account_number.placeholder',
											post_var: 'bank_account'
										}
									}
								}]
							}, {
								// description: 'Providing a void cheque is required to validate your transfer&nbsp;source.',
								description_i18n: 'nwui.modal.add_eft_source.void_cheque_info',
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'upload',
										data: {
											id: 'manage-documents-upload-file',
											// label: 'Void Cheque&nbsp;Upload',
											label_i18n: 'nwui.common.input.upload_void_cheque.label',
											placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
											model: '',
											url: '/api/documents',
											required: true,
											// placeholder: 'Choose a file...',
											placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
                          					onUpload: () => NwButtonService.buttons[
                          					    'next-page-button-2-add-eft-source-form'
                          					  ].disabled = true,						
											onSuccess: function(res) {

												uploaded_file = res.data.file_name;

												NwButtonService.buttons[
													'next-page-button-2-add-eft-source-form'
												  ].disabled = false;
												NwuiToastManagerService.register(
													new NwuiToastFactory({
														// text: 'Document upload successful',
														text_i18n: "nwui.common.toast.document_upload",
														icon: {
															name: 'check'
														}
													}
												));

											}
										}
									}
								}]
							}]
						})
					})
				}

				scope.$watch('open', function(new_val) {

					//console.log('nwuiCompoundModalAddEftSource', 'watch open', new_val);
					if(new_val === undefined) return;

					if(new_val === false) {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.add_eft_source.form.name);
							scope.modals.add_eft_source.form.active_step_index = 0;
							uploaded_file = undefined;
						}, 750)
					} else {
						NwuiFormV3Service.focus(scope.modals.add_eft_source.form.name);
					}

					scope.modals.add_eft_source.open = new_val;

				});

			}
		}
	}
]);
