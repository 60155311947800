angular.module('nwui-input-radio-group.factories', [])

.factory('NwuiInputRadioGroupFactory', [function() {
	
	var defaults = {
		options: undefined, //req
		label: '',
		type: '',
		default_value: '',
		required: false,
		error: false,
		notification: {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputRadioGroupFactory");
		if(obj.options === undefined) return console.error("Missing required instantiation object variable 'options' in NwuiInputRadioGroupFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);