nwuiInputCheckboxGroupCheck.factory('NwuiInputCheckboxGroupCheckFactory', [function() {
	
	var defaults = {
		label: undefined, //req
		model: undefined, //req
		value: false,
		default_value: false,
		required: false,
		error: false,
		notification: {},
		tooltip: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputCheckboxGroupCheckFactory");
		if(obj.label === undefined && obj.label_i18n === undefined) return console.error("Missing required instantiation object variable 'label/label_i18n' in NwuiInputCheckboxGroupCheckFactory");
		if(obj.model === undefined) return console.error("Missing required instantiation object variable 'model' in NwuiInputCheckboxGroupCheckFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);