nwuiMap.directive('nwuiMap', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/map/template.html',
        scope: {
	        loaded: '=',
	        address: '='
        },
        link: function(scope, element, attrs) {
			
			var load_img = function(address) {
				
				if(address !== undefined && address != '') {
					scope.style['background-image'] = "url(https://maps.googleapis.com/maps/api/staticmap?center=" + address.replace(/ /g, '+') + ",Canada&markers=icon:http://s3.amazonaws.com/nw-public-images/maps/brand-nw/map-pin.png|" + address.replace(/ /g, '+') + ",Canada&scale=2&zoom=18&size=640x640&maptype=roadmap&style=feature:all|element:all|lightness:50|saturation:-100&key=AIzaSyC1HfxNlfjNfZ5lU47Sg66_36qVbnSLANM)";
					scope.loaded = true;
				} else {
					scope.style['background-image'] = "url(https://maps.googleapis.com/maps/api/staticmap?center=672+Dupont+Street,Toronto,M6G+1Z6,ON,Canada&markers=icon:http://s3.amazonaws.com/nw-public-images/maps/brand-nw/map-pin.png|672+Dupont+Street,Toronto,M6G+1Z6,ON,Canada&scale=2&zoom=13&size=640x640&maptype=roadmap&style=feature:all|element:all|lightness:50|saturation:-100&key=AIzaSyC1HfxNlfjNfZ5lU47Sg66_36qVbnSLANM)";
					scope.loaded = false;
				}
				
			}
			
			scope.style = {}
			
			scope.$watch('address', function(new_val) {	
				load_img(new_val);
			})
			
			load_img();
			
	    }
	}
}]);