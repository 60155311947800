nwuiCompoundClientDocumentsModal.directive('nwuiCompoundClientDocumentsModal', [
	//'$state',
	'$timeout', 
	
	//'UserService',
	//'OnboardingService',
	'DocumentService',
	'NwuiInputService',
	'NwuiToastManagerService',
	'NwuiRegisterService',
	//'NwuiCardHeaderFactory',
	//'NwuiBlindFactory',
	//'NwuiCardTableFactory',
	//'NwuiFormFactory',
	//'NwuiAccordionFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiFormV3Factory',
	function(
		//$state,
		$timeout,
		
		//UserService,
		//OnboardingService,
		DocumentService,
		NwuiInputService,
		NwuiToastManagerService,
		NwuiRegisterService,
		//NwuiCardHeaderFactory,
		//NwuiBlindFactory,
		//NwuiCardTableFactory,
		//NwuiFormFactory,
		//NwuiAccordionFactory,
		NwuiCardHeaderV2Factory,
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiFormV3Factory
	) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/compound/client-documents/modal/template.html',
		scope: {
			client: '=',
			open: '=',
			onUploadCb: '=onUploadCb'
		},
		link: function(scope, element, attrs) {

			scope.modals = {
				document_upload: new NwuiModalV2Factory({
					open: false,
					directive: 'form',
					header: new NwuiCardHeaderV2Factory({
						name: 'Document Upload',
						icon: {
							name: 'file_upload'
						}
					}),
					form: new NwuiFormV3Factory({
						name: 'client-document-upload-form',
						submit_button: false,
						onSubmit: function(data) {},
						onComplete: function() {
							scope.modals.document_upload.open = false;
						},
						steps: [{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'select',
									data: {
										label: 'Document type',
										model: '',
										id: 'document-upload-select',
										options: [],
										stretch: true,
										placeholder: 'Select a type'
									}
								}
							},{
								directive: 'input',
								width: 100,
								data: {
									directive: 'upload',
									data: {
										id: 'document-upload-file',
										label: 'File Upload',
										model: '',
										url: '',
										placeholder: 'Choose a file...',
										onSuccess: function() {
											
											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text: 'Document upload successful',
													icon: {
														name: 'check'
													}
												}
											));
											
											scope.onUploadCb();
											
										}
									}
								}
							}]
						}]
					})
				})
			}
			
			var loadDocumentsList = function() {
				DocumentService.getList().then(function(obj) {
						
					var document_list = obj.data.client_documents;
					//console.log('nwuiCompoundClientDocumentsModal', 'getList', obj.data.client_documents)
					
					NwuiInputService.inputs['document-upload-select'].options = document_list.map(function(document,i) {
						return {
							id: document._id,
							text: document.name,
							value: '/api/admin/users/' + scope.client.uuid + '/documents/' + document._id
						}
					});
					
					//console.log('nwuiCompoundClientDocumentsModal', NwuiInputService.inputs['document-upload-select'].options);
					
				});
			}
			
			scope.$watch('client', function(new_val) {
				
				//console.log('nwuiCompoundClientDocumentsModal', 'watch', 'client', new_val);
				if(new_val !== undefined && 'id' in new_val) {
					$timeout(function() {
						loadDocumentsList();
					}, 1000)
				}
				
			});

			scope.$watch('open', function(new_val) {

				if(new_val === undefined) return;

				if(new_val === false) {

					$timeout(function() {
						NwuiInputService.inputs['document-upload-file'].model = '';
					}, 750);

				}

			});
			
			$timeout(function() {
				
				//watch document select input box change in document type
				scope.$watch("modals.document_upload.form.steps[0].columns[0].data.data.model", function(new_val) {
						
					if(new_val === undefined) return;

					if(NwuiInputService.inputs['document-upload-file'])
						NwuiInputService.inputs['document-upload-file'].url = new_val;
						
				});
			
			}, 250);
						
		}
	}
}]);