nwuiCardTableFooter.directive('nwuiCardTableFooter', [
	function() {
		return {
			restrict: 'E',
	    replace: true,
	    transclude: true,
	    templateUrl: '/card/table/footer/template.html',
	    scope: {
		    total_items: '=totalItems',
				current_page: '=currentPage',
				page_size: '=pageSize'
	    },
	    compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) {},
					post: function(scope, element, attrs) {}
				}
		  }
		}
	}
]);