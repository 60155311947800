nwuiDrawerV1Notification.factory('NwuiDrawerV1NotificationFactory', [function() {
	
	var defaults = {
		read: false,
		icon: undefined,
		important: false,
		type: undefined, //req
  	date: undefined, //req
		data: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiDrawerV1NotificationFactory");
		if(obj.type === undefined) return console.error("Missing required instantiation object variable 'type' in NwuiDrawerV1NotificationFactory");
		if(obj.date === undefined) return console.error("Missing required instantiation object variable 'date' in NwuiDrawerV1NotificationFactory");
		if(obj.data === undefined) return console.error("Missing required instantiation object variable 'data' in NwuiDrawerV1NotificationFactory");

		angular.extend(this, defaults, obj);
	}
	
}]);