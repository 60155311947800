nwuiRecommendation.directive('nwuiRecommendation', [
	'$timeout',
	'$sce',
	'$compile',
	'$parse',
	'NwuiIconV2Factory',
	'NwuiButtonV3Factory',
	function(
		$timeout,
		$sce,
		$compile,
		$parse,
		NwuiIconV2Factory,
		NwuiButtonV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/recommendation/template.html',
			scope: {
				text: '=',
				textFunction: '=textFunction',
				text_i18n: '=textI18n',
				text_i18n_data: '=textI18nData',
				link: '=',
				type: '=',
				icon: '=',
				button: '='
			},
			link: function(scope, element, attrs) {
				
				var locks = {
					icon: false,
					link: false,
					button: false
				}

				scope.$watch('text', function(new_val) {
					
					if(new_val === undefined) return;
					
					scope.safe_text = $sce.trustAsHtml(new_val);

				});

				scope.$watch('link', function(new_val) {
					
					if(new_val === undefined || locks.link) return;
					
					locks.link = true;

					scope.link.safe_text = $sce.trustAsHtml(new_val.text);

					$timeout(function() {
						locks.link = false;	
					}, 100);

				});
				
				scope.$watch('icon', function(new_val) {
					
					if(new_val === undefined || locks.icon) return;
					
					locks.icon = true;
					
					scope.icon = new NwuiIconV2Factory(new_val);
					
					$timeout(function() {
						locks.icon = false;	
					}, 100);
					
				})
				
				scope.$watch('type', function(new_val) {
					
					if(new_val === undefined || scope.button === undefined) return;
					
					scope.button.color = (new_val === 'error') ? 'error' : (new_val === 'warning') ? 'secondary' : '';
					
				})
				
				scope.$watch('button', function(new_val) {
					
					if(new_val === undefined || locks.button) return;
					
					locks.button = true;
					
					//overrides
					new_val.color = (scope.type === 'error') ? 'error' : (scope.type === 'warning') ? 'secondary' : '';
					new_val.size = 'short';
					new_val.icon_only = true;
					new_val.icon = {
						name: 'chat'
					}
					
					scope.button = new NwuiButtonV3Factory(new_val);
					
					$timeout(function() {
						locks.button = false;	
					}, 100);
					
				})
				
			}
		}
	}
]);