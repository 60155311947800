questionPieLegend.factory('QuestionPieLegendFactory', [function() {
	
	var defaults = {
		data: undefined,
		editMode: false,
		hover: function(){ return; }
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for QuestionPieLegendFactory");
        if(obj.data === undefined) return console.error("Missing required instantiation object variable 'data' in QuestionPieLegendFactory");
        
		angular.extend(this, defaults, obj);
	}
	
}]);