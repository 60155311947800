nwuiCardTable.directive('nwuiCardTable', [
	'$timeout', 
	'$window', 
	'NwuiCardTableService',
	function(
		$timeout, 
		$window, 
		NwuiCardTableService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/template.html',
			scope: {
				name: '=',
				footer: '=?',
				empty_text: '=?emptyText',
				empty_text_i18n: '=?emptyTextI18n',
				columns: '=',
				rows: '=',
				loaded: '=?',
				redraw: '=?'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 

						scope.widths = [];
						
						scope.data = {
							loaded: false,
							is_empty: true
						}

						if(angular.isUndefined(scope.empty_text_i18n)) scope.empty_text_i18n = 'nwui.common.table.body.empty';

					},
					post: function(scope, element, attrs) {
							
						var calibrate = function() {
								
							scope.widths = scope.columns.map(function(o,i) {

								if(o.directive == 'checkbox' || o.directive == 'key')
										o.width = 40;

								return o.width

							});
							
							var auto_columns = [];
							var auto_width_allocation = $(element).width() - 1;
							
							//count # of auto columns
							scope.columns.forEach(function(column, i) {
								
								if(column.width == 'auto' && column.directive != 'checkbox' && column.directive != 'key')
									auto_columns.push(column);
								else
									auto_width_allocation -= column.width;
								
							});
							
							//if no columns are auto, manage the extra width in the first column
							if(auto_columns.length == 0) {
								
								var left_over_index = 0;
								
								if(scope.columns[0].directive == 'checkbox' || scope.columns[0].directive == 'key')
									left_over_index++;
									
								auto_columns.push(scope.columns[left_over_index]);
								
							}
								
							//apply the auto width
							scope.columns.forEach(function(column, i) {
								if(column.width == 'auto' && column.directive != 'checkbox' && column.directive != 'key')
									scope.widths[i] = auto_width_allocation/auto_columns.length;
							});

							scope.data.loaded = scope.loaded = true;
							
						}
						
						window.onresize = function() {
							scope.$apply();
						};
						
						scope.$watch(function() {
							return angular.element($window)[0].innerWidth;
						}, function() {
							calibrate();
						});
						
						scope.$watch('rows', function(new_val) {
							
							if(new_val === undefined) return;

							scope.data.is_empty = new_val.length === 0;
							
							if(NwuiCardTableService.tables[scope.name])
								NwuiCardTableService.tables[scope.name].rows = new_val;

							$timeout(function() {
								scope.data.loaded = scope.loaded = true;
							})
							
						});

						scope.$watch('redraw', function(new_val, old_val) {
							if(new_val === old_val || new_val === undefined) return;
							calibrate();
						});

						scope.$watchGroup(['name', 'columns', 'rows'], function(new_val, old_val) {
							var name = new_val[0];
							var columns = new_val[1];
							var rows = new_val[2];

							if(name && columns && rows) {
								NwuiCardTableService.register(name, { columns: columns, rows: rows });
								calibrate();
							}
							
						});

					}
				}
			}
		}
	}
]);