nwuiInputNotification.directive('nwuiInputNotification', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/input/notification/template.html',
        scope:{
	        show: '=',
	        error: '=',
	        success: '=',
	        invert_x: '=invertX',
	        invert_y: '=invertY'
        },
        link: function(scope, element, attrs) {
			
			var timeout = undefined;
			
			scope.$watch('show', function(new_val) {
				
				if(new_val == true) {
					$timeout.cancel(timeout);
					timeout = $timeout(function() {
						scope.show = false;
					}, 5000);
				}
				
			});
	        
        }
	}
}]);