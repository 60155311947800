nwuiTooltip.factory('NwuiTooltipFactory', [function() {
	
	var defaults = {
		text: undefined, //req
		show: false,
		invert: false
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object");
		if(!obj.text) return console.error("Missing required instantiation object variable: 'object.text'");
		
		angular.extend(this, defaults, obj);
	}
	
}]);