nwuiCompoundClientAddress.directive('nwuiCompoundClientAddress', [
	'$timeout',
	'$window',
	
	'NwuiMapFactory',
	'NwuiFormFactory',
	'NwuiToastFactory',
	
	'NwuiToastManagerService',
	'UserProfileService',
	'NwuiInputService',
	'NwuiSession',
	
	'NWUI_SELECT_OPTIONS',
	function(
		$timeout,
		$window,
		
		NwuiMapFactory,
		NwuiFormFactory,
		NwuiToastFactory,
		
		NwuiToastManagerService,
		UserProfileService,
		NwuiInputService,
		NwuiSession,
		
		NWUI_SELECT_OPTIONS
	) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/compound/client-address/template.html',
        scope: {
	        
        },
        link: function(scope, element, attrs) {
			
			scope.version = '';
			
			scope.setTemplate = function() {
				
		        scope.version = 
		        	($(element).width() <= 640) ? 'stacked' :
			        	($(element).width() <= 800) ? 'map-thin' : '';
			        	
	        }
	        
			scope.data = {
				user_profile: undefined
			}
			
			scope.address = {
				map: new NwuiMapFactory({
					address: ''
				}),
				form: new NwuiFormFactory({
			    	name: 'compound-address-form',
			    	onSubmit: function(post_data) {
				    	return UserProfileService.update({ client_id: NwuiSession.user.client_id }, post_data).$promise;
			    	},
			    	onComplete: function(obj) {
				    	scope.address.map.address = NwuiInputService.inputs['compound-address-form-street'].model + ',' + NwuiInputService.inputs['compound-address-form-city'].model + ',' + NwuiInputService.inputs['compound-address-form-postal'].model + ',' + NwuiInputService.inputs['compound-address-form-province'].model;
				    	NwuiToastManagerService.register(
							new NwuiToastFactory({
								text: 'Mailing address updated successfully',
								icon: {
									name: 'check'
								}
							})
						)
			    	},
			    	columns: [{
				        width: 60,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'compound-address-form-street',
						        post_var: 'mailing_address_street',
						        label: 'Street address',
						        model: '',
						        placeholder: '672 Dupont Street',
						        required: true
						    }
					    }
			        },{
				        width: 40,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'compound-address-form-suite',
						        post_var: 'mailing_address_suite',
						        label: 'Unit/suite',
						        model: '',
						        placeholder: 'Suite 310',
						        required: false
						    }
					    }
			        },{
				        width: 30,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'compound-address-form-city',
						        post_var: 'mailing_address_city',
						        label: 'City',
						        model: '',
						        placeholder: 'Toronto',
						        required: true
						    }
					    }
			        },{
				        width: 20,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'compound-address-form-postal',
						        post_var: 'mailing_address_postal',
						        label: 'Postal code',
						        model: '',
						        mask: 'postal',
						        placeholder: 'M6G 1Z6',
						        validators: ['postal'],
						        required: true
						    }
					    }
			        },{
				        width: 30,
				        directive: 'input',
				        data: {
					        directive: 'select',
					        data: {
						        id: 'compound-address-form-province',
						        post_var: 'mailing_address_province',
						        label: 'Province/territory',
						        model: '',
						        options: NWUI_SELECT_OPTIONS.PROVINCES,
						        placeholder: 'Select province/territory...',
						        stretch: true,
						        required: true
						    }
					    }
			        },{
				        width: 20,
				        directive: 'button',
				        data: {
					        id: 'compound-address-form-submit',
					        stretch: true,
					        text: 'Update',
					        onClick: function() { return; }
				        }
			        }]
				})
			}
			
			scope.$watch('data.user_profile', function(new_val) {
			
				if(new_val !== undefined) {
					
					NwuiInputService.inputs['compound-address-form-street'].model = new_val.mailing_address_street || '';
			    	NwuiInputService.inputs['compound-address-form-suite'].model = new_val.mailing_address_suite || '';
			    	NwuiInputService.inputs['compound-address-form-city'].model = new_val.mailing_address_city || '';
			    	NwuiInputService.inputs['compound-address-form-postal'].model = new_val.mailing_address_postal || '';
			    	NwuiInputService.inputs['compound-address-form-province'].model = new_val.mailing_address_province || '';
			    	
			    	scope.address.map.address = '';
			    	
			    	if(
				    	NwuiInputService.inputs['compound-address-form-street'].model.length > 0 ||
				    	NwuiInputService.inputs['compound-address-form-city'].model.length > 0 ||
				    	NwuiInputService.inputs['compound-address-form-postal'].model.length > 0 ||
				    	NwuiInputService.inputs['compound-address-form-province'].model.length > 0
			    	) {
			    	
				    	scope.address.map.address += NwuiInputService.inputs['compound-address-form-street'].model + ',';
				    	scope.address.map.address += NwuiInputService.inputs['compound-address-form-city'].model + ',';
				    	scope.address.map.address += NwuiInputService.inputs['compound-address-form-postal'].model + ',';
				    	scope.address.map.address += NwuiInputService.inputs['compound-address-form-province'].model;
					
					}
					
				} else {
					
					UserProfileService.get({ client_id: NwuiSession.user.client_id }).$promise.then(function(user_profile) {
						scope.data.user_profile = user_profile;
					});
					
				}
				
			});
			
			window.onresize = function() {
				scope.$apply();
			};
			
			scope.$watch(function() {
				return angular.element($window)[0].innerWidth;
			}, function(new_val) {
				$timeout(function() {
					scope.setTemplate();
				}, 0);
			});
						
	    }
	}
}]);