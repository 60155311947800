nwuiCardTableV3HeaderColumn.directive('nwuiCardTableV3HeaderColumn', [
	'$timeout',
	'NwuiInitModuleFactory',
	function(
		$timeout,
		NwuiInitModuleFactory
	) {
		return {
			restrict: 'E',
      replace: true,
      templateUrl: '/card/table/v3/header/column/template.html',
      scope: {
        width: '=',
        name: '<',
        name_i18n: '<nameI18n',
        sort_key: '<sortKey',
        sort_order: '=sortOrder',
        sortCb: '<sortCb',
        directive: '=',
        tooltip: '=',
        disabled: '='
      },
      compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) {

						if(angular.isUndefined(scope.directive)) scope.directive = 'text';
						if(angular.isUndefined(scope.disabled)) scope.disabled = false;

					},
					post: function(scope, element, attrs) {

						scope.toggleSort = function() {

							if(scope.sort_key === undefined || scope.disabled) return;
							
							scope.sort_order = (scope.sort_order == -1)
								? 1
								: -1;
								
							scope.sortCb(scope.sort_key, scope.sort_order);
							
						}
						
						scope.showTooltip = function() {
							if(scope.tooltip)
								scope.tooltip.show = true;
						}
						
						scope.hideTooltip = function() {
							if(scope.tooltip)
								scope.tooltip.show = false;
						}
						
					}
				}
			}
		}
	}
]);