nwuiInputSelectV2.factory('NwuiInputSelectV2Factory', [function() {
	
	var defaults = {
		id: undefined, //req
		model: undefined, //req
		options: [], //req
		default_value: undefined,
		label: undefined,
		placeholder: '',
		post_var: undefined,
		stretch: false,
		size: '',
		color: '',
		required: false,
		notification: {},
		onChange: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputSelectV2Factory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiInputSelectV2Factory");
		if(obj.model === undefined) return console.error("Missing required instantiation object variable 'model' in NwuiInputSelectV2Factory");
		if(obj.options === undefined) return console.error("Missing required instantiation object variable 'options' in NwuiInputSelectV2Factory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);