nwuiHotkeys.service('NwuiHotkeysService', [
	'$window', 
	'$rootScope',
	
	'NwuiHotkeysFactory',
	function(
		$window, 
		$rootScope,
		
		NwuiHotkeysFactory
	) {
	
		var self = this;
		var observers = [];
		var keys_pressed = [];
		
		var arrays_equal = function(a,b) { 
			return !(a<b || b<a); 
		}

		this.clear = function() {
			observers = [];
			keys_pressed = [];
		}
		
		this.keyUp = function() {
			keys_pressed = [];
		}
		
		this.keyPress = function(e) {
			//console.log(e.charCode, e.keyCode, e.keyCode || e.charCode);
			
			var key_num = e.keyCode || e.charCode;
			//console.log(keys_pressed);
			
			if(keys_pressed.indexOf(key_num) == -1)
				keys_pressed.push(key_num);
				
			self.notify();
		}
		
		this.notify = function() {
			angular.forEach(observers, function(observer) {
				if(arrays_equal(observer.keys, keys_pressed))
					observer.cb();
			});
		};
		
		this.register = function(obj) {
			observers.push(
				new NwuiHotkeysFactory(obj)
			);
		}
	    
	}
]);