nwuiCardTableHeader.directive('nwuiCardTableHeader', ['NwuiCardTableService', function(NwuiCardTableService) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/card/table/header/template.html',
        scope: {
	        columns: '=',
	        widths: '='
        },
        link: function(scope, element, attrs) {
        }
	}
}]);