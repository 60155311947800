nwuiToast.directive('nwuiToast', [
	'$sce',
	'$timeout',
	
	'NwuiIconFactory',
	function(
		$sce,
		$timeout,
		
		NwuiIconFactory
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/toast/template.html',
	        scope:{
		        show: '=',
		        icon: '=',
		        text: '=',
		        text_i18n: "=textI18n",
				text_i18n_data: "=?textI18nData",
				type: '='
	        },
	        link: function(scope, element, attrs) {
				
				var locks = {
			        icon: false
			    }
			    
				scope.$watch('show', function(new_val) {
					
					if(new_val !== undefined) {
						
						if(new_val === true) {
							
							$timeout(function() {
								scope.is_showing = true;
							}, 10);
							
							$timeout(function() {
								scope.show = false;
								scope.is_showing = false;
							}, 5000);
						
						}
						
					}
					
				});
				
				scope.$watch('text', function(new_val, old_val) {
		        
			        //console.log('name', new_val)
			        
			    	if(new_val !== undefined) {
				    	scope.safe_text = $sce.trustAsHtml(new_val);
			    	}    
			        
			    });
			    
			    scope.$watch('icon', function(new_val, old_val) {
				    
				    if(new_val !== undefined && !locks.icon) {
					    
					    locks.icon = true;
					    
					    scope.icon = new NwuiIconFactory(scope.icon);
					    
					    $timeout(function() {
							locks.icon = false;    
					    }, 100);
					
					}
				    
			    });
				
		    }
		}
	}
]);