nwuiCompoundMenuTertiaryItem.factory('NwuiCompoundMenuTertiaryItemFactory', [function() {
	
	var defaults = {
		state: undefined, //req
		text: undefined, //req
		active_state: undefined,
		icon: undefined,
		pill: undefined,
		tooltip: {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundMenuTertiaryItemFactory");
		if(obj.state === undefined) return console.error("Missing required instantiation object variable 'state' in NwuiCompoundMenuTertiaryItemFactory");
		if(obj.text === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiCompoundMenuTertiaryItemFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);