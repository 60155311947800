nwuiDrawerV1NotificationLink.directive('nwuiDrawerV1NotificationLink', [
	'$timeout',

	'NwuiButtonV3Factory',
	function(
		$timeout,

		NwuiButtonV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/drawer/v1/notification/link/template.html',
			scope: {
				lead: '=',
				lead_i18n: '=leadI18n',
				button: '=',
				meta: '='
			},
			link: function(scope, element, attrs) {
				
				var locks = {
					button: false
				}

				scope.returnSref = function(state, params) {
					return state + "("+ JSON.stringify(params)+")";
				}

				scope.$watch('button', function(new_val) {

					if(new_val === undefined || locks.button) return;

					locks.button = true;

					new_val.size = 'short'; //override size
					scope.button = new NwuiButtonV3Factory(new_val);

					$timeout(function() {
						locks.button = false;
					}, 100);

				});

			}
		}
	}
]);