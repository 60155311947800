nwuiCardBigNumber.directive('nwuiCardBigNumber', ['$filter', function($filter) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/big-number/template.html',
        scope: {
	        value: '=',
	        filter: '=',
	        delta_percent: '=deltaPercent',
	        delta_dollar: '=deltaDollar',
	        delta_number: '=deltaNumber'
        },
        link: function(scope, element, attrs) {
	        
	        scope.$watchGroup(['value', 'filter'], function(new_val, old_val) {
		        
		        //console.log('big num new_val', new_val);
		        
		        if(new_val[0] !== undefined) {
			        
			        if(new_val[1] !== undefined) {
				        
				        scope.value_text = $filter(new_val[1])(new_val[0]);
				        //console.log('scope.value_text', scope.value_text);
				        
			        } else {
				        
				        scope.value_text = new_val[0];
				        
			        }
			        
		        }
		        
	        });
	        
        }
	}
}]);