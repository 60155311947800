angular.module('nwui-helper.focus-click.directives', [])

.directive('nwuiHelperFocusClick', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				
				var focussed = false;

				element.bind("focus", function(e) {
					//console.log('nwuiHelperFocusClick', 'focus');
					focussed = true;	
				});

				element.bind("blur", function(e) {
					//console.log('nwuiHelperFocusClick', 'blur');
					focussed = false;
				});

				element.bind("mousedown", function(e) {
					//console.log('nwuiHelperFocusClick', 'mousedown');
					e.preventDefault();
				});

				element.bind("keypress", function(event) {

					if(focussed === false) return;

					if(event.which === 13) {

						var method = element.attr('ng-click');
						
						scope.$event = event.originalEvent || event;
						scope.$apply(method);

					}
				});
					
			}
		}
	}
]);