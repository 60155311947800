nwuiAllocation.directive('nwuiAllocation', [
	'$timeout',
	'$filter', 
	'$window',
	function(
		$timeout,
		$filter, 
		$window
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/allocation/template.html',
			scope: {
				name: '=?',
				series: '=',
				decimals: '=?',
				parentSlice: '=?'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						if(angular.isUndefined(scope.name)) { scope.name = undefined; }
						if(angular.isUndefined(scope.series)) { scope.series = []; }
						if(angular.isUndefined(scope.decimals)) { scope.decimals = 0; }
						if(angular.isUndefined(scope.parentSlice)) { scope.parentSlice = null }

						scope.controls = {};
						scope.version = '';

						scope.mouseenter = function(event, item) {
							scope.chart.data.active = item;
						}
						
						scope.setTemplate = function() {
							scope.version = ($(element).width() <= 660) ? 'stacked' : '';
						}

					},
					post: function(scope, element, attrs) {
						
						var width = -1;

						var locks = {
							series: false
						}

						var interval = {
							resize: undefined
						}

						function setLanguageKey(val){
							if(!val) return undefined;

							return val.replace(/[^\w\s]/g , "").replace(/ +/g , "_").toLowerCase();
						}

						var calibrate = function() {
							var temp_series = [];
							var total_count = {};
							var type_count = {};
							scope.series.forEach(function(item,i) {

								var new_item = {
									name_i18n: (item.name) ? 'nwui.questionnaire.portfolio.dimension.' + setLanguageKey(item.name) + '.name' : '',
									i18n_key: setLanguageKey(item.name),
									percent: item.percent || 0,
									type: item.type || 'cash',
									percent_range: item.percent_range || null,
									hide: item.hide || false
								}

								if(new_item.percent > 0) {

									if(total_count[new_item.type] === undefined)
										total_count[new_item.type] = 1;
									else
										total_count[new_item.type]++;

									temp_series.push(new_item);
								}
								if(new_item.percent == 0 && new_item.percent_range) {
									if(total_count[new_item.type] === undefined)
										total_count[new_item.type] = 1;
									else
										total_count[new_item.type]++;
									temp_series.push(new_item);
								}
								if(new_item.hide) {
									scope.parentSlice = new_item;
								}
							});
							
							scope.chart = {
								directive: 'donut',
								data: {
									active: temp_series[0],
									series: temp_series.map(function(datum,i) {

										if(type_count[datum.type] === undefined)
											type_count[datum.type] = 0;
										else
											type_count[datum.type]++;

										var number;
										if(datum.hide || datum.percent_range)
											number = 0;
										else
											number = Math.floor(type_count[datum.type]/total_count[datum.type] * 10);
										datum.type_count = datum.type + '-' + number;
										return datum

									}),
									decimals: scope.decimals || 0
								}
							}

						}

						scope.$watch('name', function(new_val, old_val) {

							if(new_val === undefined) return;
							scope.name_i18n = 'nwui.questionnaire.portfolio.model.' + setLanguageKey(new_val);
							scope.i18n_key = setLanguageKey(new_val);
						});

						scope.$watch('series', function(new_val, old_val) {
							
							if(new_val !== undefined && new_val.length > 0) {

								// locks.series = true;
								calibrate();
								
								// $timeout(function() {
								// 	locks.series = false;    
								// }, 100);
					
							}

						});
							
						scope.$watch('chart.data.active', function(new_val, old_val) {
							if(new_val === undefined || new_val === old_val) return;
							if(new_val.percent_range && scope.parentSlice) {
								scope.controls.active = scope.parentSlice;
							} else {
								scope.controls.active = new_val;
							}
						});

						interval.resize = setInterval(function() {
							if(width === angular.element($window)[0].innerWidth) return;
							width = angular.element($window)[0].innerWidth;
							scope.setTemplate();
							scope.$apply();
						}, 500);

						scope.$on('$destroy', function() {
							if(interval.resize)
								clearInterval(interval.resize);
						});

					}
				}
			}
		}
	}
]);