nwuiInputSubheading.directive('nwuiInputSubheading', [
	'$timeout',
	'NwuiInitModuleFactory',
	'NwuiFormService',
	'NwuiInputService',
	function(
		$timeout,
		NwuiInitModuleFactory,
		NwuiFormService,
		NwuiInputService
	) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		require: '?^nwuiFormV3',
		templateUrl: '/input/subheading/template.html',
	  scope: {
	    step_index: '<stepIndex',
			form_name: '<formName',
			id: '=',
			model: '=',
			label: '<label',
			label_i18n: '<labelI18n',
			hide: '='
	  },
	  link: function(scope, element, attrs, nwuiFormV3Ctrl) {

			if(nwuiFormV3Ctrl)
				nwuiFormV3Ctrl.registerInput(scope.step_index, scope);
			else if(angular.isDefined(scope.form_name)) {
				NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);
			}

			var validate = function() {
				return true;
			}

			NwuiInputService.register(scope, validate, function() {}, function() {});
	    
	  }
	}
}]);