nwuiCardTableBodyRowColumnText.directive('nwuiCardTableBodyRowColumnText', [
	function() {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/body/row/column/text/template.html',
			scope: {
				text: '=',
				text_i18n: '=textI18n',
				text_i18n_data: '=textI18nData'
			},
			link: function(scope, element, attrs) {}
		}
	}
]);