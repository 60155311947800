nwuiCardTableV3.directive('nwuiCardTableV3', [
	'$timeout', 
	'$window', 
	'$q',
	function(
		$timeout,
		$window,
		$q
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/v3/template.html',
			scope: {
				id: '=',
				resource: '=',
				resourceCb: '=resourceCb',
				rowMap: '=rowMap',
				empty_text: '=emptyText',
				empty_text_i18n: '=emptyTextI18n',
				columns: '=',
				sub_columns: '=?subColumns',
				rows: '=',
				fixed_height: '=fixedHeight',
				header: '=?',
				footer: '=?',
				loaded: '=',
				reload: '=?',
        filters: '=?'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						if(angular.isUndefined(scope.columns)) scope.columns = [];
						if(angular.isUndefined(scope.sub_columns)) scope.sub_columns = [];

						if(angular.isUndefined(scope.footer)) scope.footer = {};
						if(angular.isUndefined(scope.footer.query)) scope.footer.query = '';
						if(angular.isUndefined(scope.footer.current_page)) scope.footer.current_page = 1;
						if(angular.isUndefined(scope.footer.page_size)) scope.footer.page_size = 10;

						if(angular.isUndefined(scope.header)) scope.header = {};
						if(angular.isUndefined(scope.header.columns)) scope.header.columns = [];
						if(angular.isUndefined(scope.header.widths)) scope.header.widths = [];
						if(angular.isUndefined(scope.header.sortCb)) scope.header.sortCb = function() {};

						scope.loaded = false;
						scope.is_empty = true;

					},
					post: function(scope, element, attrs) {

						var watchers = {
							resource_group: undefined
						}

						var locks = {
							paging: false
						}

						var sortCb = function(sort_by, sort_order) {
							scope.header.sort_by = sort_by;
							scope.header.sort_order = sort_order;

							if(scope.footer)
								scope.footer.current_page = 1;
						}
						
						var reload = function() {

							var deferred = $q.defer();
							
							//stop watching resource modifiers
							if(watchers.resource_group)
								watchers.resource_group();

							scope.loaded = false;

							var filters = undefined;

							if (scope.filters && scope.filters.appliedFilters) {
							  filters = [];
							  scope.filters.appliedFilters.forEach(function(filter) {
							    filters.push({
                    key: filter.key,
                    op: filter.operator,
                    val: filter.value
                  });
                })
              }

							scope.resource(
								(scope.footer || {}).query, 
								(scope.footer || {}).current_page, 
								(scope.footer || {}).page_size, 
								scope.header.sort_by, 
								scope.header.sort_order,
                filters
							).then(function(obj) {

								scope.rows = obj.data.data.map(scope.rowMap);

								if(scope.sub_columns && scope.sub_columns.length > 0) {
									scope.rows.forEach(function(row, i) {
										row.columns.push({
											icon: {
												name: 'keyboard_arrow_down'
											}
										});
									})									
								}

								scope.is_empty = scope.rows.length === 0;
								scope.loaded = true;

								if(!obj.data.page_data) return;

								if(obj.data.page_data) {
									if(scope.footer) {
										scope.footer.total_items = obj.data.page_data.total_items;
										scope.footer.current_page = obj.data.page_data.current_page;
										scope.footer.page_size = obj.data.page_data.page_size;
									}
									scope.header.sort_by = obj.data.page_data.sort_by;
									scope.header.sort_order = obj.data.page_data.sort_order;
								}

								scope.resourceCb(obj);
								deferred.resolve(obj);

								var watch_group = [
									'header.sort_by', 
									'header.sort_order'
								];

								if(scope.footer) {
									watch_group = watch_group.concat([
										'footer.query', 
										'footer.current_page', 
										'footer.page_size'
									])
								}

								//start watching resource modifiers
								watchers.resource_group = scope.$watchGroup(watch_group, function(new_val, old_val) {
									var changed = false;
									for(var i = 0; i < new_val.length; i++) {
										if(new_val[i] !== old_val[i]) {
											changed = true;
											break;
										}
									}

									if(!changed) return;

									reload();
								});
							});
							
							return deferred.promise;
							
						}
							
						scope.sortCb = sortCb;
						scope.reload = reload;

						scope.$watch('resource', function(new_val, old_val) {

							if(new_val === undefined) return;

							reload();

						});

					}
				}
			}
		}
	}
]);