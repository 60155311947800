nwuiBlind.directive('nwuiBlind', [
	'$timeout', 
	function(
		$timeout
	) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/blind/template.html',
		scope: {
			color: '=',
			show: '=',
			closed_height: '=closedHeight',
			text_open: '=textOpen',
			text_open_i18n: '=textOpenI18n',
			text_close: '=textClose',
			text_close_i18n: '=textCloseI18n'
		},
		link: function(scope, element, attrs) {
			var container = $(element).find('> .inner');
			var contents = $(element).find('> .inner > .content').first();
			var toggle = $(element).find('.toggle');
			
			scope.style_container = {}
			scope.no_transition = false;
			
			scope.toggle = function(force) {
				
				//console.log('nwuiBlind', 'toggle', force, scope.show, contents.outerHeight(true), $(element).find('> .inner > .content').children());

				 //disable initial transition for height set (safari fix)
				scope.no_transition = true;
				
				//set height to current auto height and change tab
				$timeout(function() {
					scope.style_container.height = container.height() + 'px'
				
					//reenable transition for animating to new height (safari fix)
					$timeout(function() {
						scope.no_transition = false;
						
						//toggle expanded
						scope.show = (force === false) 
							? false 
							: scope.show 
								? false 
								: true;
						
						//set new height
						$timeout(function() {
							var to_height = (scope.show)
								? contents.outerHeight(true) + toggle.height() - parseInt(contents.css('padding-bottom'), 10) * 1
								: scope.closed_height;
							
							scope.style_container.height = to_height + 'px'
						}, 150);
					}, 150);
				}, 150);
				
			}
			
			scope.$watch('show', function(new_val) {
				
				if(new_val === undefined) return;
				
				if(new_val === false)
					scope.toggle(false);
				
			});
			
			// scope.$watch('open', function(new_val) {
				
			// });
			
			scope.$watch('closed_height', function(new_val) {
				
				if(new_val !== undefined && !scope.disabled) {
					scope.style_container.minHeight = new_val + 'px'
				} else {
					delete scope.style_container.minHeight;
				}
				
			})
			
		}
	}
}]);