angular.module('nwui-period.directives', [])

.directive('nwuiPeriod', [
	'$timeout', 
	'NwuiIconV2Factory',
	function(
		$timeout,
		NwuiIconV2Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/period/template.html',
			scope: {
				name: '=',
				name_i18n: '=nameI18n',
				percent: '=',
				dollar: '='
			},
			link: function(scope, element, attrs) {
				scope.$watch('dollar', function(new_val, old_val) {
					;
					scope.icon = new NwuiIconV2Factory({
						name: (new_val <= 0) ? 'drag_handle' : 'check',
						color: (new_val <= 0) ? 'warning' : 'success'
					})

				});
			}
		}
	}
]);