angular.module('nwui-compound-modal-assign-user.directives', [])

.directive('nwuiCompoundModalAssignUser', [
	'$timeout',
	'$filter',
	'$stateParams',
	'$state',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiFormV3Factory',
	'NwuiSigningService',
	'NwuiPageScrollService',
	
	'OrganizationV2Service',
	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'DATE_FORMAT',
	function(
		$timeout,
		$filter,
		$stateParams,
		$state,

		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiFormV3Factory,
		NwuiSigningService,
		NwuiPageScrollService,
		
		OrganizationV2Service,
		NwuiToastManagerService,
		NwuiInputService,
		NwuiFormV3Service,
		DATE_FORMAT
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/assign-user/template.html',
			scope: {
				open: '=',
				user: '=',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {

				var submitCb = function(data) {
					scope.submitCb(data);
				}
				
				scope.modal = new NwuiModalV2Factory({
					open: false,
					wide: false,
					header: {
						name_i18n: 'nwui.modal.assign_user.header',
						level: 2,
						icon: {
							name: 'person_add'
						}
					},
					form: new NwuiFormV3Factory({
						name: 'assign-to-user-form',
						onSubmit: function(post_data) {
							return scope.formResource(scope.user.Organization.slug, scope.user.uuid, post_data);
						},
						onComplete: function(obj) {

							scope.open = false;

							if(obj.data.error) {
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.assign_user.messages.failure',
										icon: {
											name: 'close'
										}
									}
								));

								submitCb(obj.data);
							} else {
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.assign_user.messages.success',
										icon: {
											name: 'check'
										}
									}
								));

								submitCb(obj.data);
							}

						},
						steps: [{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'select',
									data: {
										label_i18n: 'nwui.modal.assign_user.labels.assign_to',
										model: '',
										id: 'assign-to-user-form-input-assign-to',
										stretch: true,
										required: true,
										placeholder_i18n: 'nwui.modal.assign_user.labels.assign_to_placeholder',
										options: [],
										post_var: 'ancestor_uuid',
										onChange: function(val) {}
									}
								}
							}]
						}]
					})
				})
				
				scope.$watch('open', function(new_val) {
					
					//console.log('nwuiCompoundModalAssignUser', 'watch', 'open:', new_val);
					if(new_val === undefined) return;
					
					if(new_val === false) {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modal.form.name);
						}, 750)
					} else if(new_val === true) {
						NwuiFormV3Service.focus(scope.modal.form.name);
					}

					scope.modal.open = new_val;
					
				});

				scope.$watch('user', function(new_val) {

					if(new_val == null || !('Role' in new_val)) return;

					OrganizationV2Service.getAssignableUsers($stateParams.org_slug, new_val.Role.id).then(function(obj) {
						if(obj.data && obj.data.length > 0) {
							NwuiInputService.inputs['assign-to-user-form-input-assign-to'].placeholder_i18n = 'nwui.modal.assign_user.labels.select_user_placeholder';
							NwuiInputService.inputs['assign-to-user-form-input-assign-to'].options = obj.data.sort(function(user_a, user_b) {
								if (user_a.last_name.toLowerCase() < user_b.last_name.toLowerCase()) { return -1; }
								if (user_a.last_name.toLowerCase() > user_b.last_name.toLowerCase()) { return 1; }
								return 0;
							}).map(function(user,i) {
								return {
									id: i,
									text: user.last_name + ', ' + user.first_name,
									value: user.uuid
								}
							});
							NwuiInputService.inputs['assign-to-user-form-input-assign-to'].model = new_val.Ancestor ? new_val.Ancestor.uuid : -1;
						} else {
							NwuiInputService.inputs['assign-to-user-form-input-assign-to'].placeholder_i18n = 'nwui.modal.disable_user.labels.assign_to_placeholder';
							NwuiInputService.inputs['assign-to-user-form-input-assign-to'].options = [];
							NwuiInputService.inputs['assign-to-user-form-input-assign-to'].model = -1;
						}
					});

				});

			}
		}
	}
]);