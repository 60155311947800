nwuiCompoundModalSendCommunication.directive('nwuiCompoundModalSendCommunication', [
	'$timeout',
	'$stateParams',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormFactory',
	
	'NwuiToastManagerService',
	'NotificationService',
	'NwuiInputService',
	function(
		$timeout,
		$stateParams,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormFactory,
		
		NwuiToastManagerService,
		NotificationService,
		NwuiInputService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/send-communication/template.html',
			scope: {
				open: '=',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var submitCb = function(data) {
					scope.submitCb(data);
				}
				
				scope.$watch('open', function(new_val) {
					
					if(new_val === undefined) return;
					
					scope.modals.send_communication.open = new_val;
					
				});
				
				scope.modals = {
					send_communication: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: new NwuiCardHeaderV2Factory({
							name: 'Send Communication',
							icon: {
								name: 'send'
							}
						}),
						form: new NwuiFormFactory({
							name: 'tracker-send-communication-form',
							onSubmit: function() {
								var data = {
									subject: NwuiInputService.inputs['modal-input-send-communication-title'].model,
									text: NwuiInputService.inputs['modal-input-send-communication-text'].model,
									email: true//NwuiInputService.inputs[scope.goal_id + 'tracker-input-send-communication-email'].model
								}
								return NotificationService.create($stateParams.uuid, data.subject, data.text, data.email);
							},
							onComplete: function(obj) {
								
								submitCb(obj);
								scope.open = false;
								
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text: 'Communication sent successfully!',
										icon: {
											name: 'check'
										}
									}
								));
							},
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'text',
									data: {
										label: 'Subject',
										model: '',
										id: 'modal-input-send-communication-title',
										stretch: true,
										placeholder: "You're doing great!"
									}
								}
							}, {
								directive: 'input',
								width: 100,
								data: {
									directive: 'textarea',
									data: {
										model: '',
										id: 'modal-input-send-communication-text',
										label: 'Message body',
										placeholder: 'All your goals are on track. Keep up the good work.'
									}
								}
							}, {
								directive: 'button',
								width: 40,
								data: {
									id: 'modal-form-send-communication-button',
									text: 'Send',
									onClick: function() {},
									stretch: true,
									processing: false,
									disabled: false
								}
							}]
						})
					})
				}
	
			}
		}
	}
]);