nwuiCardTableV2Header.directive('nwuiCardTableV2Header', [
	'$timeout',
	
	'NwuiCardTableV2Service', 
	function(
		$timeout,
		
		NwuiCardTableV2Service
	) {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: '/card/table/v2/header/template.html',
	        scope: {
		        columns: '=',
		        widths: '=',
		        sort_options: '=sortOptions',
				sort_size: '=sortSize',
				sort_order: '=sortOrder'
	        },
	        link: function(scope, element, attrs) {
		        
		        var locks = {
			        columns: false
		        }
		        
		        scope.$watch('columns', function(new_val, old_val) {
			       
					
			        
		        });
		        
	        }
		}
	}
]);