nwuiCardTable.factory('NwuiCardTableFactory', ['NwuiCardTableRowFactory', 'NwuiCardTableHeaderColumnFactory', function(NwuiCardTableRowFactory, NwuiCardTableHeaderColumnFactory) {
	
	var defaults = {
		name: '',
		resource: undefined,
		empty_text: undefined,
		empty_text_i18n: 'nwui.common.table.body.empty',
		columns: [], //req		
		rows: undefined,
		paging: undefined,
		loaded: false,
		redraw: undefined
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object for NwuiCardTableFactory");
		if(obj.columns === undefined) return console.error("Missing required instantiation object variable 'columns' in NwuiCardTableRowFactory");
		
		var columns = [];
		for(var i = 0; i < obj.columns.length; i++) {
			columns.push(new NwuiCardTableHeaderColumnFactory(obj.columns[i]));
		}
		
		var rows = [];
		if(obj.rows !== undefined)
			for(var i = 0; i < obj.rows.length; i++) {
				rows.push(new NwuiCardTableRowFactory(obj.rows[i]));
			}
		
		angular.extend(this, defaults, obj, { rows: rows, columns: columns });
	}
	
}]);

nwuiCardTable.factory('NwuiCardTableRowFactory', ['NwuiCardTableRowColumnFactory', function(NwuiCardTableRowColumnFactory) {
	
	var defaults = {
		columns: [] //req
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableRowFactory");
		if(obj.columns === undefined) return console.error("Missing required instantiation object variable 'columns' in NwuiCardTableRowFactory");
		
		var columns = [];
		for(var i = 0; i < obj.columns.length; i++) {
			columns.push(new NwuiCardTableRowColumnFactory(obj.columns[i]));
		}
		
		angular.extend(this, defaults, { columns: columns });
	}
	
}]);

nwuiCardTable.factory('NwuiCardTableHeaderColumnFactory', [function() {
	
	var defaults = {
		name: undefined, //req
		width: 'auto',
		directive: 'text',
		sort: 0,
		sortable: true,
		mobile_title: false,
		clickEvent: undefined
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableHeaderColumnFactory");
		if(obj.name === undefined && obj.name_i18n === undefined) return console.error("Missing required instantiation object variable 'name' in NwuiCardTableHeaderColumnFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);

nwuiCardTable.factory('NwuiCardTableRowColumnFactory', [function() {
	
	var defaults = {
		column: undefined,
		clickEvent: undefined
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableRowColumnFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);