angular.module('nwui-compound-modal-approve-aml-verification').directive('nwuiCompoundModalApproveAmlVerification', [
  '$timeout',
  '$stateParams',
  'NwuiModalV2Factory',
  'NwuiCardHeaderV2Factory',
  'NwuiCardTableFactory',
  'NwuiButtonV3Factory',
  'NwuiToastFactory',
  'NwuiFormV3Factory',
  'NwuiInputFactory',
  'NWUI_SELECT_OPTIONS',
  'OrganizationV2Service',
  'NwButtonService',
  'OnboardingService',
  'NwuiToastManagerService',
  'OrganizationService',
  'NwuiFormV3Service',
  'NwuiInputService',
  'AdvisorsService',
  'NwuiCardTableV2Factory',
  'locale',
  'IdVerificationService',
  'DATE_FORMAT',
  function(
    $timeout,
    $stateParams,
    NwuiModalV2Factory,
    NwuiCardHeaderV2Factory,
    NwuiCardTableFactory,
    NwuiButtonV3Factory,
    NwuiToastFactory,
    NwuiFormV3Factory,
    NwuiInputFactory,
    NWUI_SELECT_OPTIONS,
    OrganizationV2Service,
    NwButtonService,
    OnboardingService,
    NwuiToastManagerService,
    OrganizationService,
    NwuiFormV3Service,
    NwuiInputService,
    AdvisorsService,
    NwuiCardTableV2Factory,
    locale,
    IdVerificationService,
    DATE_FORMAT
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/approve-aml-verification/template.html',
      scope: {
        verificationId: '=',
        uuid: '=',
        clientId: '=',
        id: '=',
        open: '=',
        roles: '=roles',
        submitCb: '=submitCb'
      },
      link: function(scope, element, attrs) {
        scope.table = new NwuiCardTableV2Factory({
          id: 'aml-verification-approval-table',
          empty_text_i18n: 'nwui.modal.approve_aml_verification.empty_text',
          resource: function(){
            return IdVerificationService.getVerificationDocuments(scope.verificationId).then(function(res) {
              return {
                data: {
                  data: res.data.filter(function(datum) {
                    return datum.status === 'submitted';
                  }),
                  page_data: {}
                }
              }
            })
          },
          rowMap: function(document,i) {
            var button_id = 'aml-verification-approve-table-button-' + i;
            return {
              columns: [ {
                text: locale.getString('nwui.common.document.' +document.clientDocument.template.prefix)
              }, {
                button: {
                  id: button_id,
                  icon_only: true,
                  icon: 'download',
                  onClick: function() {
                    IdVerificationService.getDocumentUrl(scope.uuid, scope.id, document.clientDocument.aws_file_reference);
                  }
                }
              },
              {
                input: {
                  directive: 'radio',
                  data: {
                    id: 'aml-verification-approve-table-review' + i,
                    label: '',
                    stretch: true,
                    required: true,
                    model: '',
                    size: '',
                    type: 'inline',
                    options: [{
                      label_i18n: 'nwui.modal.approve_aml_verification.table.approve',
                      value: 'approve'
                    }, {
                      label_i18n: 'nwui.modal.approve_aml_verification.table.reject',
                      value: 'reject'
                    }],
                    onChange: function(val) {
                      scope.reviewedDocuments[document.id] = val;
                    }
                  }
                }
              }]
            }
          },
          columns: [
            {
              name_i18n: 'nwui.modal.approve_aml_verification.table.headings.fileType',
              directive: 'text',
              sortable: false,
            },
            {
              name: "",
              width: 60,
              directive: "button",
              sortable: false
            },
            {
              name: "",
              width: 315,
              directive: "radio",
              sortable: false
            }
          ],
          rows: undefined
        });

        scope.reviewedDocuments = {};

        var submitCb = function(success) {
          scope.submitCb(success);
        }

        scope.submitButton = {
          id: 'aml-verification-approval-submit-button',
          stretch: false,
          color: '',
          text_i18n: 'nwui.modal.approve_aml_verification.submit',
          onClick: function() {
            var documentArray = Object.keys(scope.reviewedDocuments).map(function(key){
              return {
                id: key,
                action: scope.reviewedDocuments[key]
              }
            });

            IdVerificationService.reviewVerificationDocuments(scope.verificationId, documentArray).then(function(data) {
              scope.submitCb();
              scope.open = false;
            }).catch(function(err){
              NwuiToastManagerService.register(
                new NwuiToastFactory({
                  text_i18n: "onboarding.aml_verification.errors." + err.data.nw_error_code,
                  type: 'error',
                  icon: {
                    name: 'close'
                  }
                }
              ));
            });
          }
        };

        scope.modals = {
          approve_aml_verification: new NwuiModalV2Factory({
            open: false,
            wide: true,
            header: new NwuiCardHeaderV2Factory({
              name_i18n: 'nwui.modal.approve_aml_verification.title',
              icon: {
                name: 'check'
              }
            })
          })
        }

        scope.$watch('open', function(new_val) {
          //console.log('open', new_val);
          if(new_val === undefined) return;

          scope.modals.approve_aml_verification.open = new_val;

        });

      }
    }
  }
]);
