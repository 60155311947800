nwuiCardTableV3BodyRowColumnText.directive('nwuiCardTableV3BodyRowColumnText', [
	function() {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/v3/body/row/column/text/template.html',
			scope: {
				text: '=',
				text_i18n: '=textI18n',
        text_color: '=textColor',
        cell_color: '=cellColor'
			},
			link: function(scope, element, attrs) {
      }
		}
	}
]);