angular.module('nwui-analytics.service')
    .constant('NWUI_ANALYTICS_EVENTS', {
        default: {

        },
        nbf_r2: {
            ":login:1": ":login started",
            ":login:2": ":login completed",

            ":questionnaire:step:1": ":onboarding:personal info",
            ":questionnaire:step:2": ":onboarding:investor profile",
            ":questionnaire:step:3": ":onboarding:investor objective",
            ":questionnaire:step:4": ":onboarding:results",
            ":forms:step:1": ":onboarding:account types",
            ":forms:step:2": ":onboarding:personal info",
            ":forms:step:3": ":onboarding:employment",
            ":forms:step:4": ":onboarding:income",
            ":forms:step:5": ":onboarding:sin",
            ":forms:step:6": ":onboarding:fund sources",
            ":forms:step:7": ":onboarding:beneficiary",
            ":forms:step:8": ":onboarding:confirmation",

            ":signature": ":onboarding:signature",

            ":summary": ":dashboard",
            ":transfers:": ":transfers",
            ":transfers:new:chequing:savings": ":transfers:transfer source",
            ":transfers:new:chequing:savings:form": ":transfers:transfer profile"

        }
    })
    .constant('NWUI_ANALYTICS_PPI_KEYS', [
        'dob',
        'first_name',
        'last_name'
    ]);
