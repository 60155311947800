nwuiButtonV3.directive('nwuiButtonV3', [
	'$timeout',
	'$sce',
	'NwuiAnalyticsService',
	'NwButtonService',
	'NwuiInitModuleFactory',
	'NwuiIconV2Factory',
	function(
		$timeout,
		$sce,
		NwuiAnalyticsService,
		NwButtonService,
		NwuiInitModuleFactory,
		NwuiIconV2Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/button/v3/template.html',
			scope:{
				id: '<',
				text: '<',
				text_i18n: '<textI18n',
				icon: '<',
				size: '<',
				processing: '<',
				stretch: '<',
				disabled: '<',
				color: '<',
				hollow: '<',
				onClick: '<',
				icon_only: '<iconOnly',
				icon_only_mobile: '<iconOnlyM',
				tooltip_text: '<tooltipText',
				skip_analytics: '<skipAnalytics',
				tooltip_invert: '<tooltipInvert',
				notification_count: '<notificationCount',
				notification_icon: '<notificationIcon',
				notification_pulse: '<notificationPulse'
			},
			link: function(scope, element, attrs) {
				scope = NwuiInitModuleFactory({
					onClick: undefined, //req
					id: undefined,
					text: undefined,
					text_i18n: undefined,
					icon: undefined,
					size: undefined,
					stretch: false,
					hollow: false,
					processing: false,
					disabled: false,
					color: '',
					icon_only: false,
					icon_only_mobile: false,
					icon_only_tablet: false,
					skip_analytics: false
				}, scope);

				var locks = {
					icon: false
				}

				var setSize = function() {

					scope.ui_size_sm = false;
					scope.ui_size_short = false;
					scope.ui_size_large = false;

					switch(scope.size) {
						case 'sm':
						case 'small':
							scope.ui_size_sm = true;
							break;
						case 'short':
							scope.ui_size_short = true;
							break;
						case 'large':
							scope.ui_size_large = true;
							break;
						default:
							break;
					}

				}

				scope.icon_only_alternate =
					(organization_data && organization_data.theme.button && typeof organization_data.theme.button.icon_only_alternate !== 'undefined')
						? organization_data.theme.button.icon_only_alternate
						: false;
				scope.show_icons =
					(organization_data && organization_data.theme.button && typeof organization_data.theme.button.show_icons !== 'undefined')
						? organization_data.theme.button.show_icons
						: true;

				scope.focussed = false;

				scope.focus = function(e) {
					scope.focussed = true;
				}

				scope.blur = function(e) {
					scope.focussed = false;
				}

				scope.keyPress = function(event) {

					if(scope.focussed === false) return;

					if(event.which === 13)
						scope.click(event);

				}

				scope.click = function(e) {

					if(scope.onClick === undefined) return;

					e.stopPropagation();
					e.preventDefault();

					if(scope.disabled === true) return;

					scope.onClick();
					if(!scope.skip_analytics) {
						NwuiAnalyticsService.track(scope.id);
					}
				}

				scope.mouseover = function() {
					if(scope.processing) return;

					scope.tooltip_show = true;
					scope.notification_pulse = false;
				}

				scope.mouseleave = function() {
					scope.tooltip_show = false;
				}

				scope.mousedown = function(e) {

					e.preventDefault();

				}

				scope.loaded = false;
				scope.tooltip_show = false;

				scope.$watch('text', function(new_val) {

					if(new_val === undefined) return;

					scope.text_safe = $sce.trustAsHtml(new_val);

				});

				scope.$watch('size', function(new_val) {
					setSize();
				});

				scope.$watch('color', function(new_val) {

					if(new_val === undefined) return;
					//console.log('nwuiButtonV3', 'watch', 'color', scope.id, new_val)

					switch(new_val) {
						case 'done':
							scope.icon = {
								name: 'check'
							}
							break;
						case 'waiting':
							scope.icon = {
								name: 'access_time'
							}
							break;
					}

				});

				scope.$watch('processing', function(new_val) {

					if(new_val === true) {
						scope.tooltip_show = false;
						scope.notification_show = false;
					} else {
						scope.notification_show = true;
					}

				});

				scope.$watchGroup(['icon', 'size'], function(new_val, old_val) {

					var icon = new_val[0];
					var size = new_val[1];

					if(icon !== undefined && size !== undefined && !locks.icon) {

						locks.icon = true;

						icon.size = size === 'short'
							? 'small'
							: scope.icon_only === true || size === ''
								? ''
								: 'small';

						scope.icon = new NwuiIconV2Factory(icon);

						$timeout(function() {
							locks.icon = false;
						}, 50);

					}

				});

				$timeout(function() {
					scope.loaded = true;
				}, 0);

				NwButtonService.register(scope);

			}
		}
	}
]);
