nwuiTooltipV2.directive('nwuiTooltipV2', [
	'$sce',
	function(
		$sce
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/tooltip/v2/template.html',
			scope:{
				text: '=',
				text_i18n: '=textI18n',
				show: '=',
				invert: '='
			},
			link: function(scope, element, attrs) {
				
				element[0].onclick = function(e) {
					e.stopPropagation();
				}
				
				scope.$watch('text', function(new_val, old_val) {
				
					//console.log('text', new_val)
					
					if(new_val !== undefined) {
						scope.safe_text = $sce.trustAsHtml(new_val);
					}
					
				});
				
				scope.loaded = true;
				
			}
		}
	}
]);