nwuiBankVerificationsFlinks.directive('nwuiBankVerificationsFlinks', [
  '$sce',
  'locale',
  function(
    $sce,
    locale
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/bank-verifications/flinks/template.html',
      scope: {
        manualClickedCb: '=',
        getAccountsCb: '=',
        widgetDetails: '='
      },
      link: function($scope, element, attrs) {

        function handleWindowMessages(e) {
          if (e.data.loginId) {
            handleLoginAndRequestIdMessage(e.data.loginId);
          }
        }

        function handleLoginAndRequestIdMessage(loginId) {
          if (typeof $scope.getAccountsCb === 'function') {
            $scope.getAccountsCb({
              provider: 'flinks',
              data: {
                loginId: loginId
              }
            });
          }
        }

        $scope.$watch('widgetDetails', function(error) {
          if ($scope.widgetDetails) {
            $scope.iframeUrl = $sce.trustAsResourceUrl($scope.widgetDetails.url);
          }
        });

        $scope.onClickManual = function() {
          if (typeof $scope.manualClickedCb === 'function') {
            $scope.manualClickedCb();
          }
        }

        window.addEventListener('message', handleWindowMessages);

        $scope.$on('$destroy', function() {
          window.removeEventListener('message', handleWindowMessages);
        })
      }
    }

  }
]);