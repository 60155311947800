nwuiCardTableV3BodyRowColumn.directive('nwuiCardTableV3BodyRowColumn', [function() {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/card/table/v3/body/row/column/template.html',
		scope: {
			column: '=',
			column_data: '=columnData',
			width: '=',
			directive: '=',
			clickEvent: '='
		},
		link: function(scope, element, attrs) {}
	}
}]);