nwuiCompoundModalBankVerifications.directive('nwuiCompoundModalBankVerifications', [
  function(
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/bank-verifications/template.html',
      scope: {
        provider: '=',
        open: '=',
        manualClickedCb: '=',
        getAccountsCb: '=',
        singleAccountSelector: '=',
        submitCb: '=',
        needsSigning: '=',
        getWidgetCb: '=',
        signCb: '='
      },
      link: function($scope, element, attrs) {

        $scope.data = {
          mode: 'status',
          status: 'processing',
          widgetDetails: null,
          bankAccounts: []
        };
        
        $scope.onClickManual = function() {
          if (typeof $scope.manualClickedCb === 'function') {
            $scope.manualClickedCb();
          }
        }

        $scope.onClickTryAgain = function() {
          reset();
        }

        $scope.onGetAccounts = function(data) {
          $scope.data.mode = 'status';
          $scope.data.status = 'processing';
          if (typeof $scope.getAccountsCb === 'function') {
            var result = $scope.getAccountsCb(data);
            if (result.then) {
              result.then(onAccountsLoadComplete, onError);
            }
          }
        }

        $scope.onSubmit = function(data) {
          $scope.data.mode = 'status';
          $scope.data.status = 'processing';
          if (typeof $scope.submitCb === 'function') {
            var result = $scope.submitCb(data);
            if (result.then) {
              result.then(onComplete, onError);
            }
          }
        }

        function onAccountsLoadComplete(data) {
          $scope.data.mode = 'account-select';
          $scope.data.bankAccounts = data.data;
        }

        var completedData;

        function onComplete(data) {
          completedData = data;
          if ($scope.needsSigning) {
            $scope.data.status = 'sign';
          } else {
            $scope.open = false;
          }
        }

        function onError(error) {
          $scope.data.status = 'error'; 
          $scope.data.error = error;
        }

        $scope.onClickSign = function() {
          if (typeof $scope.signCb === 'function') {
            $scope.signCb(completedData);
          }
        }

        function reset() {
          completedData = null;
          $scope.data.status = 'processing';
          $scope.data.error = null;
          $scope.data.mode = 'status';
          if($scope.provider) {
            if (typeof $scope.getWidgetCb === 'function') {
              var result = $scope.getWidgetCb($scope.provider);
              if (result.then) {
                result.then(function(data) {
                  $scope.data.widgetDetails = data.data;
                  $scope.data.mode = $scope.provider;
                }, function(error) {
                  if (typeof $scope.manualClickedCb === 'function') {
                    $scope.manualClickedCb();
                  }
                });
              }
            }
          } else {
            if (typeof $scope.manualClickedCb === 'function') {
              $scope.manualClickedCb();
            }
          }
        }

        $scope.$watch('open', function(new_val, old_val) {
          if (new_val && !old_val) {
            reset();
          }
        });
      }
    }

  }
]);