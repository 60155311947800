nwuiCompoundModalRiskProfileApprove.directive('nwuiCompoundModalRiskProfileApprove', [
	'$timeout',
	
	'NwuiModalFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardTableFactory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	
	'NwButtonService',
	'RiskProfilesService',
	'NwuiToastManagerService',
	function(
		$timeout,
		
		NwuiModalFactory,
		NwuiCardHeaderV2Factory,
		NwuiCardTableFactory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		
		NwButtonService,
		RiskProfilesService,
		NwuiToastManagerService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/risk-profile-approve/template.html',
			scope: {
				risk_profiles: '=riskProfiles',
				open: '=',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				var submitCb = function(is_approved, email_sent) {
					scope.submitCb(is_approved, email_sent);
				}
				
				scope.$watch('open', function(new_val) {
					
					if(new_val === undefined) return;
					
					scope.modals.review_risk_profile.open = new_val;
					
					//force redraw on the tables to recalibrate the column widths
					scope.modals.review_risk_profile.table_score.redraw = new_val;
					scope.modals.review_risk_profile.table_mix.redraw = new_val;
					
				});
				
				scope.$watchGroup([
					'risk_profiles',
					'risk_profiles.new.kyc_instance.pm_submitted_date',
					'risk_profiles.new.kyc_instance.pm_approved_date'
				], function(new_val) {
					
					//console.log('risk_profiles watch', new_val);
					
					if(new_val[0] === undefined) return;
					
					var score_rows = [];
					var mix_rows = [];
					
					//check score delta
					if(new_val[0]['new'] !== null && new_val[0]['new'] !== undefined) {
						score_rows.push({
							columns: [{
								text_i18n: 'nwui.modal.review_risk_profile.table.score'
							}, {
								number: new_val[0]['current'].kyc_instance.score.toFixed(2)
							}, {
								number: new_val[0]['new'].kyc_instance.score.toFixed(2)
							}, {
								number: new_val[0]['new'].kyc_instance.score.toFixed(2) - new_val[0]['current'].kyc_instance.score.toFixed(2)
							}]
						});
						
						var aggregate = {
							equity: ['nwui.modal.review_risk_profile.table.equity',0,0],
							fixed: ['nwui.modal.review_risk_profile.table.fixed',0,0],
							cash: ['nwui.modal.review_risk_profile.table.cash',0,0]
						}
						
						// new_val[0]['new'].pie_data.data[0].dimensions.forEach(function(datum,i) {
						// 	var newVal = new_val[0]['current'].pie_data.data[0].dimensions[i];
						
						// 	aggregate[newVal.type][1] += newVal ? newVal.y : 0;
						// 	aggregate[datum.type][2] += datum.y
						// });

						new_val[0]['new'].pie_data.data[0].dimensions.forEach(function(datum,i) {
							aggregate[datum.type][2] += datum.y
						});

						new_val[0]['current'].pie_data.data[0].dimensions.forEach(function(datum,i) {
							aggregate[datum.type][1] += datum.y
						});
						
						for(var key in aggregate) {
							mix_rows.push({
								columns: [{
									text_i18n: aggregate[key][0]
								}, {
									number: aggregate[key][1].toFixed(1) + '%'
								}, {
									number: aggregate[key][2].toFixed(1) + '%'
								}, {
									number: aggregate[key][2].toFixed(1) - aggregate[key][1].toFixed(1)
								}]
							});
						}
						
						scope.modals.review_risk_profile.table_score.rows = score_rows;
						scope.modals.review_risk_profile.table_mix.rows = mix_rows;
					}
					
				});
				
				scope.modals = {
					review_risk_profile: new NwuiModalFactory({
						open: false,
						wide: true,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.review_risk_profile.title',
							icon: {
								name: 'search'
							}
						}),
						table_score: new NwuiCardTableFactory({
							redraw: false,
							name: 'risk-profile-view-review-table-score',
							columns: [{
								name: '',
								directive: 'text',
								sortable: false
							}, {
								name_i18n: 'nwui.modal.review_risk_profile.inputs.current.label',
								width: 100,
								directive: 'number',
								sortable: false
							}, {
								name_i18n: 'nwui.modal.review_risk_profile.inputs.new.label',
								width: 100,
								directive: 'number',
								sortable: false
							}, {
								name_i18n: 'nwui.modal.review_risk_profile.inputs.change.label',
								directive: 'delta',
								sortable: false
							}],
							rows: [],
							loaded: false
						}),
						table_mix: new NwuiCardTableFactory({
							redraw: false,
							name: 'risk-profile-view-review-table-mix',
							columns: [{
								name: '',
								directive: 'text',
								sortable: false
							}, {
								name: '',
								width: 100,
								directive: 'number',
								sortable: false
							}, {
								name: '',
								width: 100,
								directive: 'number',
								sortable: false
							}, {
								name: '',
								directive: 'percent-delta',
								sortable: false
							}],
							rows: [],
							loaded: false
						}),
						copy: {
							buttons: {
								yes: new NwuiButtonV3Factory({
									id: 'review-risk-profile-approve',
									text_i18n: 'nwui.modal.review_risk_profile.buttons.approve',
									color: '',
									icon: {
										name: 'check'
									},
									stretch: true,
									onClick: function() {
										
										NwButtonService.buttons['review-risk-profile-approve'].processing = true;
										
										RiskProfilesService.approveV2(true, scope.risk_profiles['new'].kyc_instance).then(function() {
											
											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: 'nwui.modal.review_risk_profile.toasts.profile_approved',
													icon: {
														name: 'check'
													}
												}
											));
											
											NwButtonService.buttons['review-risk-profile-approve'].processing = false;
											
											scope.modals.review_risk_profile.open = false;
											scope.open = false;
											scope.risk_profiles['new'].kyc_instance.pm_approved = true;
											scope.risk_profiles['current'] = angular.copy(scope.risk_profiles['new']);
											
											submitCb(true, true);
												
										});
								
									}
								}),
								no: new NwuiButtonV3Factory({
									id: 'review-risk-profile-reject',
									text_i18n: 'nwui.modal.review_risk_profile.buttons.reject',
									icon: {
										name: 'close'
									},
									color: 'error',
									stretch: true,
									onClick: function() {
										
										NwButtonService.buttons['review-risk-profile-reject'].processing = true;
										
										RiskProfilesService.rejectV2(false, scope.risk_profiles['new'].kyc_instance).then(function() {
											
											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: 'nwui.modal.review_risk_profile.toasts.profile_rejected',
													icon: {
														name: 'check'
													}
												}
											));
											
											NwButtonService.buttons['review-risk-profile-reject'].processing = false;
											
											scope.modals.review_risk_profile.open = false;
											scope.open = false;
											scope.risk_profiles['new'] = null;
											
											submitCb(false, false);
												
										});
								
									}
								})
							}
						}
					})
				}
	
			}
		}
	}
]);