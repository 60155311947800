nwuiFormV2Column.directive('nwuiFormV2Column', ['$timeout', 'NwuiFormService', 'NwuiInputFactory', 'NwuiButtonFactory', function($timeout, NwuiFormService, NwuiInputFactory, NwuiButtonFactory) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/form/v2/column/template.html',
        scope: { 
	        form_name: '=formName',
	        directive: '=',
	        width: '=',
	        calc_width: '=calcWidth',
	        data: '='
        },
        link: function(scope, element, attrs) {
	        
	        var calibrate = function() {

		        if(scope.directive) {
			        switch(scope.directive) {
				        case 'input':
				        	scope.input = scope.data = new NwuiInputFactory(scope.data);
				        	break;
			        	case 'button':
			        		scope.data.onClick = NwuiFormService.submit;
			        		scope.data.stretch = true; //override
				        	scope.button = scope.data = new NwuiButtonFactory(scope.data);
				        	//console.log('nwui-form-v2-column', 'form_name', scope.form_name, scope.data.id);
				        	NwuiFormService.registerSubmitButton(scope.form_name, scope.button);
				        	break;
				        case 'message':
				        	//TODO
				        	break;
			        }
		        }
		        
	        }
	        
	        scope.style = {};
	        
	        scope.$watch('calc_width', function(new_val) {
		        
		        if(new_val !== 0) {
			        scope.style = {
				        width: new_val
			        }
			    }
			    
		    });
	        
	        scope.$watch('directive', function(new_val) {
		        if(new_val === undefined) return;
		        calibrate();
	        });
	        
	    }
	}
}]);