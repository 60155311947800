nwuiInputSelectV2Option.factory('NwuiInputSelectV2OptionFactory', [function() {
	
	var defaults = {
		id: undefined, //req
		text: undefined, //req
		value: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputSelectV2OptionFactory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiInputSelectV2OptionFactory");
		if(obj.text === undefined && obj.text_i18n === undefined) return console.error("Missing required instantiation object variable 'text/text_i18n' in NwuiInputSelectV2OptionFactory");
		if(obj.value === undefined) return console.error("Missing required instantiation object variable 'value' in NwuiInputSelectV2OptionFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);