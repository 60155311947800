nwuiOverlay.directive('nwuiOverlay', [
	'$timeout', 
	'NwuiRegisterService',
	'NwuiOverlayService',
	function(
		$timeout,
		NwuiRegisterService,
		NwuiOverlayService
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/overlay/template.html',
			scope:{
				id: "=",
				show: "=?",
				icon: "=?",
				message: "=?",
				message_i18n: "=?messageI18n",
				message_i18n_data: "=?messageI18nData",
				block_state_change: "=?blockStateChange",
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 

						scope.is_global = ($(element).parent('body').length === 1);
						if(angular.isUndefined(scope.id)) scope.id = NwuiRegisterService.register();
						if(angular.isUndefined(scope.show)) scope.show = false;
						if(angular.isUndefined(scope.icon)) scope.icon = {};
						if(angular.isUndefined(scope.block_state_change)) scope.block_state_change = false;
						if(angular.isUndefined(scope.message)) scope.message = '...';
						if(angular.isUndefined(scope.message_i18n_data)) scope.message_i18n_data = {};

						NwuiOverlayService.register(scope);

					},
					post: function(scope, element, attrs) {
						
						scope.$watch('id', function(new_val, old_val) {
							if(new_val === old_val && new_val === undefined) return;
							
							NwuiOverlayService.register(scope);
						});

					}
				}	
			}
		}
	}
]);