nwuiCompoundModalEsignCode.directive('nwuiCompoundModalEsignCode', [
	'$q',
	'$sce',
	'$timeout',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	'NwuiFormV3Factory',
	
	'NwuiToastManagerService',
	'NwButtonService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwuiRegisterService',
	function(
		$q,
		$sce,
		$timeout,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,
		NwuiFormV3Factory,
		
		NwuiToastManagerService,
		NwButtonService,
		NwuiInputService,
		NwuiFormV3Service,
		NwuiRegisterService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/esign-code/template.html',
			scope: {
				open: '=',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var seed_id = NwuiRegisterService.register();

				scope.$watch('open', function(new_val) {
					
					if(new_val === false) {
						
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.esign_code.form.name);
						}, 500);

					} else if(new_val === undefined) return;

					scope.modals.esign_code.open = new_val;

				});

				scope.modals = {
					esign_code: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							// name: 'Joint Account Invitation'
							name_i18n: 'nwui.modal.esign_code.title',
							icon: {
								name: 'lock'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'form-' + seed_id,
							// submit_button: false,
							onSubmit: function(post_data) {

								var deferred = $q.defer();
								deferred.resolve(post_data);
								return deferred.promise;

							},
							onComplete: function(obj) {
								scope.submitCb(obj);
							},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.common.input.joint_invitation.label',
											// label: 'Invitation code',
											model: '',
											id: 'form-' + seed_id + '-input-text',
											stretch: true,
											required: true,
											placeholder_i18n: 'nwui.common.input.joint_invitation.placeholder',
											options: [],
											onChange: function(model) {},
											post_var: 'joint_code'
										}
									}
								}]
							}]
						})
					})
				}
			}
		}

	}
]);