nwuiChartDonutV2.factory('NwuiChartDonutV2Factory', [function() {
	
	var defaults = {
		series: undefined, //req
		active: undefined, //req
		decimals: 0
	}
	
	return function(obj) {
		//console.log(obj)
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiChartDonutV2Factory");
		if(obj.series === undefined) return console.error("Missing required instantiation object variable 'series' in NwuiChartDonutV2Factory");
		//if(obj.active === undefined) return console.error("Missing required instantiation object variable 'active' in NwuiChartDonutV2Factory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);