nwuiCardHeader.factory('NwuiCardHeaderFactory', [function() {
	
	var defaults = {
		name: undefined,
		name_i18n: undefined,
		level: 1,
		icon: undefined,
		input: undefined,
		pill: undefined,
		target: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCardHeaderFactory");

		angular.extend(this, defaults, obj);
	}
	
}]);