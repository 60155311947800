angular.module('nwui-compound-modal-remove-advisor-code.directives', [])

.directive('nwuiCompoundModalRemoveAdvisorCode', [
	'$timeout',
	
	'NwuiModalFactory',
	'NwuiCardTableFactory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	
	'NwButtonService',
	'OnboardingService',
	'NwuiToastManagerService',
	'OrganizationV2Service',
	function(
		$timeout,
		
		NwuiModalFactory,
		NwuiCardTableFactory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		
		NwButtonService,
		OnboardingService,
		NwuiToastManagerService,
		OrganizationV2Service
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/remove-advisor-code/template.html',
			scope: {
				user: '=user',
				org_slug: '=orgSlug',
				advisor_code: '=advisorCode',
				open: '=',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var submitCb = function(is_removed) {
					scope.submitCb(is_removed);
				}
				
				scope.$watch('open', function(new_val) {
					
					//console.log('open', new_val);
					if(new_val === undefined) return;
					
					scope.modals.remove_advisor_code.open = new_val;
					
				});
				
				scope.modals = {
					remove_advisor_code: new NwuiModalFactory({
						open: false,
						wide: false,
						header: {
							name_i18n: 'nwui.modal.remove_advisor_code.header',
							level: 2,
							icon: {
								name: 'close'
							}
						},
						copy: {
							buttons: {
								yes: new NwuiButtonV3Factory({
									id: 'remove-advisor-code-button',
									text_i18n: "nwui.modal.remove_advisor_code.buttons.yes",
									color: '',
									icon: {
										name: 'check'
									},
									color: '',
									stretch: true,
									onClick: function() {
										
										NwButtonService.buttons['remove-advisor-code-button'].processing = true;
										
										OrganizationV2Service.deleteAdvisorCode(scope.org_slug, scope.user.uuid, scope.advisor_code.code).then(function(obj) {
											
											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: 'nwui.modal.remove_advisor_code.messages.success',
													icon: {
														name: 'check'
													}
												}
											));
											
											NwButtonService.buttons['remove-advisor-code-button'].processing = false;
											
											scope.open = false;
											
											submitCb(true);
											
										}).catch(function(res) {
											var error_message = "nestkit.errors." ;
											if(res.data.nw_error_code){
												error_message += res.data.nw_error_code;
											}
											else{
												error_message += "generic";
											}
											NwuiToastManagerService.register(
											new NwuiToastFactory({
												text_i18n: error_message,
												type: 'error',
												icon: {
												name: 'close',
												color: 'error'
												}
											}
											));
											NwButtonService.buttons['remove-advisor-code-button'].processing = false;
											submitCb(false);
											scope.open = false;
										});
								
									}
								}),
								no: new NwuiButtonV3Factory({
									id: 'cancel-remove-advisor-code-button',
									text_i18n: 'nwui.modal.remove_advisor_code.buttons.no',
									color: '',
									icon: {
										name: 'close'
									},
									color: 'error',
									stretch: true,
									onClick: function() {
										
										scope.open = false;
										
										submitCb(false);
								
									}
								})
							}
						}
					})
				}
	
			}
		}
	}
]);