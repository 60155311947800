angular.module('nwui-input-radio-group.radio.factories', [])

.factory('NwuiInputRadioGroupRadioFactory', [function() {
	
	var defaults = {
		label: undefined, //req
		value: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputRadioGroupRadioFactory");
		if(obj.label === undefined && obj.label_i18n === undefined) return console.error("Missing required instantiation object variable 'label' in NwuiInputRadioGroupRadioFactory");
		if(obj.value === undefined) return console.error("Missing required instantiation object variable 'value' in NwuiInputRadioGroupRadioFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);