nwuiCardTableV3Body.directive('nwuiCardTableV3Body', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/card/table/v3/body/template.html',
			scope: {
				columns: '=',
				sub_columns: '=subColumns',
				rows: '=',
				widths: '=',
				fixed_height: '=fixedHeight',
				page_size: '=pageSize'
			},
			link: function(scope, element, attrs) {
						
				scope.style = {};
				
				var calibrate = function() {

					var body_height = $(element).find('.sizer').outerHeight(true);

					if(scope.rows && scope.rows.length > 0 && scope.fixed_height === true) {

						if(body_height > 0) {
							scope.style.height = (body_height/scope.rows.length) * scope.page_size + 'px';
						}

					}

				}

				scope.$watchGroup([
					'page_size',
					'rows',
					'fixed_height',
				], function(new_val) {
					
					if(new_val[2] !== true) return;

					$timeout(function() {
						calibrate();
					}, 0);
						
				})
						
			}
		}
	}
]);