nwuiModalBodyInfo.directive('nwuiModalBodyInfo', ['$sce', function($sce) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/modal-body-info/template.html',
        scope: {
	        name: '=',
	        icon: '='
        },
        link: function(scope, element, attrs) {

			scope.name = $sce.trustAsHtml(scope.name);
			
			scope.iconClass = function() {
				
				if(scope.icon)
					return scope.icon;
				
			}

	    }
	}
}]);