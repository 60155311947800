nwuiCardTableV3.factory('NwuiCardTableV3Factory', [
	'NwuiCardTableV3RowFactory',
	function(
		NwuiCardTableV3RowFactory
	) {
		
		return function(obj) {
			var defaults = {
				id: undefined,
				resource: undefined,
				resourceCb: function() {},
				rowMap: undefined,
				empty_text: undefined,
				empty_text_i18n: 'nwui.common.table.body.empty',
				fixed_height: false,
				columns: [], //req		
				rows: undefined,
				reload: undefined,
				// header: {},
				// footer: {},
				loaded: false,
        filters: undefined
			}
		
			if(!obj) return console.error("Missing instantiation object for NwuiCardTableV3Factory");
			if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiCardTableV3RowFactory");
			if(obj.columns === undefined) return console.error("Missing required instantiation object variable 'columns' in NwuiCardTableV3RowFactory");
			
			var rows = [];
			if(obj.rows !== undefined)
				for(var i = 0; i < obj.rows.length; i++) {
					rows.push(new NwuiCardTableV3RowFactory(obj.rows[i]));
				}
			
			angular.extend(this, defaults, obj, { rows: rows });
		}
	
	}
]);

nwuiCardTableV3.factory('NwuiCardTableV3RowFactory', ['NwuiCardTableV3RowColumnFactory', function(NwuiCardTableV3RowColumnFactory) {
	
	var defaults = {
		columns: [] //req
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableV3RowFactory");
		if(obj.columns === undefined) return console.error("Missing required instantiation object variable 'columns' in NwuiCardTableV3RowFactory");
		
		var columns = [];
		for(var i = 0; i < obj.columns.length; i++) {
			columns.push(new NwuiCardTableV3RowColumnFactory(obj.columns[i]));
		}
		
		angular.extend(this, defaults, { columns: columns });
	}
	
}]);

nwuiCardTableV3.factory('NwuiCardTableV3RowColumnFactory', [function() {
	
	var defaults = {
		clickEvent: undefined
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableV3RowColumnFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);