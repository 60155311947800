nwuiButtonV2.factory('NwuiButtonV2Factory', [function() {
	var defaults = {
		onClick: undefined, //req
		id: undefined,
		text: undefined,
		text_i18n: undefined,
		icon: undefined,
    size: undefined,
    stretch: false,
    hollow: false,
    processing: false,
    disabled: false,
    color: '',
    icon_only: false,
		icon_only_mobile: false,
		skip_analytics: false,
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiButtonFactory");
		if(obj.onClick === undefined) return console.error("Missing required instantiation object variable 'onClick' in NwuiButtonFactory");
		//if(obj.text === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiButtonFactory");
		
		angular.extend(this, defaults, obj);
	}
}]);