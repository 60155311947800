nwuiCardSpacer.factory('NwuiCardSpacerFactory', [function() {
	
	var defaults = {
		color: '',
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCardSpacerFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);