angular.module('nwui-validation-error.v2.service', [])

.service('NwuiValidationErrorV2Service', [function() {

	var self = this;

	this.blank = function() {
		return 'nwui.common.error.blank';
	}

	this.password_match = function() {
		return 'nwui.common.error.match';
	}

    this.email_match = function() {
		return 'nwui.common.error.match';
	}

	this.date_future = function() {
		return 'nwui.common.error.date_future';
	}

	this.date_future_plus = function() {
		return 'nwui.common.error.date_future_plus';
	}

	this.date_past = function() {
		return 'nwui.common.error.date_past';
	}

	this.year_past = function() {
		return 'nwui.common.error.year_past';
	}

	this.format = function() {
		return 'nwui.common.error.format';
	}

	this.too_short = function(length) {
		return 'nwui.common.error.too_short';
	}

	this.checkbox = function(label) {
		return 'nwui.common.error.checkbox';
	}

	this.all_check_agree = function(label) {
		return 'nwui.common.error.all_check_agree';
	}

	this.checkbox_one_or_more = function(label) {
		return 'nwui.common.error.checkbox_one_or_more';
	}

	this.upload_required = function(label) {
		return 'nwui.common.error.upload_required';
	}

	this.password = function(label) {
		return 'nwui.common.error.password';
	}

	this.non_zero = function(label) {
		return 'nwui.common.error.non_zero';
	}

	this.alphabetic = function(label) {
		return 'nwui.common.error.alphabetic';
	}

	this.alphanumeric = function(label) {
		return 'nwui.common.error.alphanumeric';
	}

	this.no_special_char = function(label) {
		return 'nwui.common.error.no_special_char';
	}

	this.numeric = function(label) {
		return 'nwui.common.error.numeric';
	}

	this.greater_than = function(label) {
		return 'nwui.common.error.greater_than';
	}

	this.eft_max_amount = function(label) {
		return 'nwui.common.error.eft_max_amount';
	}

	this.withdrawal_min_max_amount = function(label) {
		return 'nwui.common.error.withdrawal_min_max_amount';
	}

	this.over_hundred_percent = function(label) {
		return 'nwui.common.error.over_hundred_percent';
	}

	this.format = function(label) {
		return 'nwui.common.error.format';
	}

	this.future = function(label) {
		return 'nwui.common.error.future';
	}

	this.date_mustbe_future = function() {
		return 'nwui.common.error.date_mustbe_future';
	}

	this.blockNoProgress_jointNotCanResident = function() {
		return 'nwui.common.error.blockNoProgress.jointNotCanResident';
	}

	this.blockNoProgress_notCanResident = function() {
		return 'nwui.common.error.blockNoProgress.notCanResident';
	}

	this.confirm = function(label) {
		return 'nwui.common.error.please_confirm';
	}

	this.horizon = function () {
		return 'nwui.common.error.clientHorizon';
	}

	for(var i = 0; i < 30; i++) {

		(function(i) {
			self['too_short_'+i] = function(label) {
				return self.too_short();
			}

			self['too_soon_'+i] = function(label) {
				return self.too_soon();
			}
		})(i);

	}

}]);
