nwuiInputToggle.directive('nwuiInputToggle', [
	'$timeout', 
	'NwuiInitModuleFactory',
	'NwuiInputService',
	function(
		$timeout, 
		NwuiInitModuleFactory,
		NwuiInputService
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/input/toggle/template.html',
			scope: {
				step_index: '=stepIndex',
				form_name: '=formName',
				id: '=',
				model: '=',
				label: '=',
				label_i18n: "=labelI18n",
				post_var: '=postVar',
				stretch: '=',
				color: '=',
				isDisabled: '=',
				toggle_only_mobile: '<toggleOnlyMobile',
				onChange: '='
			},
			link: function(scope, element, attrs) {
				scope = NwuiInitModuleFactory({
					id: undefined, //req
					onChange: undefined, //req
					label: undefined,
					post_var: undefined,
					model: false,
					stretch: false,
					isDisabled: false,
					// size: '',
					toggle_only_mobile: false,
					color: ''
				}, scope);

				if(angular.isDefined(scope.form_name) && angular.isDefined(scope.step_index))
					NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);

				NwuiInputService.register(scope);
				
				scope.toggle = function() {
					
					if(scope.isDisabled === true) return;

					if(scope.onChange)
						scope.onChange(scope.model);
					
				}
				
				scope.$watch('size', function(new_val) {

					//console.log('nwuiInputToggle', 'watch', 'size', new_val);

					if(new_val === undefined) return;

				});

				scope.$watch('model', function(new_val) {
					if(new_val === undefined) return;
					
				});
				
			}
		}
	}
]);