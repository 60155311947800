nwuiCompoundModalAddGoal.directive('nwuiCompoundModalAddGoal', [
	'$timeout',
	'$filter',
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	
	'NwuiToastManagerService',
	'NwuiInputService',
	function(
		$timeout,
		$filter,
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		
		NwuiToastManagerService,
		NwuiInputService
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/compound/modal/add-goal/template.html',
	        scope: {
		        open: '=',
		        formResource: '=formResource',
		        submitCb: '=submitCb'
	        },
	        link: function(scope, element, attrs) {
				
				var submitCb = function(data) {
					scope.submitCb(data);
				}
				
				scope.$watch('open', function(new_val) {
					
					//console.log('nwuiCompoundModalGoalTargetV2', 'watch open', new_val);
					if(new_val === undefined) return;
					
					if(new_val === true) {
						//console.log('nwuiCompoundModalGoalTarget', 'goal_fields_update');
						NwuiInputService.inputs['add-goal-form-input-name'].model = '';
						NwuiInputService.inputs['add-goal-form-input-target-amount'].model = '';
						NwuiInputService.inputs['add-goal-form-input-yearly-contribution'].model = '';
						NwuiInputService.inputs['add-goal-form-input-target-date'].model = '';
					}
					
					scope.modals.add_goal.form.active_step_index = 0;
					scope.modals.add_goal.open = new_val;
					
				});
				
				scope.modals = {
					add_goal: new NwuiModalV2Factory({
						open: false,
					    wide: false,
					    header: new NwuiCardHeaderV2Factory({
					    	name: 'Create Goal',
					    	icon: {
						    	name: 'create'
					    	}
				        }),
				        form: new NwuiFormV3Factory({
					        name: 'add-goal-form',
					        active_step_index: 0,
					        onSubmit: function(post_data) {
						        
						        return scope.formResource(post_data);
								
					        },
						    onComplete: function(obj) {
							    
							    submitCb(obj.data);
							    scope.open = false;
								
								//show toast
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text: 'Goal created successfully!',
										icon: {
											name: 'check'
										}
									}
								));
								
						    },
						    steps: [{
						        columns: [{
							        directive: 'input',
							        width: 100,
							        data: {
								        directive: 'text',
								        data: {
									        label: 'Goal Name',
									        model: '',
									        required: true,
									        id: 'add-goal-form-input-name',
									        placeholder: "My Retirement",
									        post_var: 'description'
								        }
							        }
						        },{
							   		directive: 'input',
							   		width: 100,
							   		data: {
								   		directive: 'text',
								        data: {
									        label: 'Goal Target',
									        model: '',
									        mask: 'currency',
									        required: true,
									        id: 'add-goal-form-input-target-amount',
									        placeholder: "$225,000",
									        post_var: 'target_amount'
								        }
								   	}
						   		}]
						    }, {
							    columns: [{
							   		directive: 'input',
							   		width: 100,
							   		data: {
								   		directive: 'text',
								        data: {
									        label: 'Estimated Yearly Contribution',
									        model: '',
									        mask: 'currency',
									        required: true,
									        id: 'add-goal-form-input-yearly-contribution',
									        placeholder: "$50,000",
									        post_var: 'yearly_contribution'
								        }
								   	}
						   		},{
							   		directive: 'input',
							   		width: 100,
							   		data: {
								   		directive: 'text',
								        data: {
									        label: 'Target Date',
									        model: '',
									        mask: 'date',
									        validators: ['date_future'],
									        required: true,
									        id: 'add-goal-form-input-target-date',
									        placeholder: moment(new Date()).format('YYYY/MM/DD'),
									        post_var: 'target_date'
								        }
								   	}
						   		}]
							}]
				        })
				    })
				}
				
			}
		}
	}
]);