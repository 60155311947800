nwuiValidation.service('NwuiValidationService', [
	'DATE_FORMAT',
	'SinValidateService',
	'$filter',
	function(
		DATE_FORMAT,
		SinValidateService,
		$filter
	) {

		var self = this;

		var regex = {
			alphabetic: /^[a-z���������������� '-]*$/i,
			alphanumeric: /^[0-9a-zA-Z]+$/,
			numeric: /^[0-9]*$/,
			email_with_plus: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			postal: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
			proper_name: /^[a-zA-Z áàâäèéêëîïíìóôœúùûüÿçÁÀÂÄÈÉÊËÎÏÍÌÓÔŒÚÙÛÜŸÇ '-]*$/i,
			password: new RegExp(organization_data.password_policy.pattern.toString()),
			year: /^\d{4}$/,
			no_special_char: /^[a-zA-ZÀ-Ÿ0-9- ]*$/i,
			horizon: /^((?!(0))[0-9]{1,2})$/
		}

		this.blank = function(input) {

			// //console.log('NwuiValidationService', 'blank', input);

			if(input != null && input !== '') //(input == true || String(input).length > 0))
				return true;
			else
				return 'blank';

		}

		this.email = function(input) {

			if(input == true || regex.email_with_plus.test(input))
				return true;
			else
				return 'format';

		}

		this.proper_name = function(input) {

			if(input == true || regex.proper_name.test(input))
				return true;
			else
				return 'format';

		}

		this.phone = function(input) {

			if(
				input == true || //exists
				input.length == 10 //(###) ###-####
			)
				return true;
			else
				return 'format';

		}

		this.postal = function(input) {
			if(
				input == true || //exists
				input.length == 6 //A1A 1A1
			)
				return true;
			else
				return 'format';

		}

		this.date = function(input) {
			if(
				input !== undefined && //exists
				input.length == 8 && //correct length
				moment(input, 'YYYYMMDD').format('YYYYMMDD') !== 'Invalid date' //YYYY-MM-DD
			)
				return true;
			else
				return 'format';

		}

		this.date_past = function(input) {

			var is_proper_date_format = self.date(input);
			if(is_proper_date_format !== true) return is_proper_date_format;

			var current_date = moment(new Date()).format(DATE_FORMAT.digit);

			if(input < current_date)
				return true;
			else
				return 'date_future';

			return true;

		}

		this.date_future = function(input) {

			var is_proper_date_format = self.date(input);
			if(is_proper_date_format !== true) return is_proper_date_format;

			var current_date = moment(new Date()).format(DATE_FORMAT.digit);

			if(input >= current_date)
				return true;
			else
				return 'date_past';

			return true;

		}

		this.date_future_in_businessdays = function(input) {
			var marketCloseDates = organization_data.market_close_dates;
			var expected_days_apart = organization_data.port_data.transfers.efts.validations.startdate_plusbusinessdays;
			var current_date = moment(new Date());
			var days_apart = expected_days_apart;
			var date_validate;

			while (days_apart > 0) {
				current_date.add(1, 'days');
				if (current_date.isoWeekday() === 6 || current_date.isoWeekday() === 7 ) {
					continue;
				} else {
					date_validate = current_date.format('YYYYMMDD');
					var checkyear = date_validate.slice(0, 4);
					if(marketCloseDates.hasOwnProperty(checkyear)) {
						if (marketCloseDates[checkyear].indexOf(date_validate) >= 0 ) {
							continue;
						} else { days_apart-- }
					} else {
						days_apart -- 
					}
				}
			}

			if (input > date_validate) {
			  return true
			} else {
				if(expected_days_apart > 0){
					return "date_mustbe_future"
				} else {
					return "date_past"
				}
			}
		}

		this.year = function(input) {

			if(
				input == true || //exists
				regex.year.test(input)
			)
				return true;
			else
				return 'format';

			return true;

		}

		this.year_future = function(input) {

			var current_date = moment(new Date()).format(DATE_FORMAT.year);

			if(input > current_date)
				return true;
			else
				return 'year_past';

			return true;

		}

		this.password = function(input) {

			if(
				input == true || //exists
				regex.password.test(input)
			)
				return true;
			else
				return 'password';

		}

		this.institution = this.bank_institution = function(input) {

			if( this.non_zero(input) !== true )
				return 'non_zero';

			if(
				typeof(input) === 'string' && //valid
				input.length >= 3 //3 or more characters
			)
				return true;
			else
				return 'too_short_3';

		}

		this.hundred_percent = function(input) {
			input = input + '';
			if(input.substring(input.length - 1) === '.' || Number(input) > 100) {
				return 'over_hundred_percent';
			} else {
				return true;
			}
		}

		this.block_no_values = function(input, params) {
			if (input === 'No') {
				return 'blockNoProgress_' + params[0].error_slug;
			} else return true;
		}

		this.transit = this.bank_transit = function(input) {

			if( this.non_zero(input) !== true )
				return 'non_zero';

			if(
				typeof(input) === 'string' && //valid
				input.length >= 5 //5 or more characters
			)
				return true;
			else
				return 'too_short_5';

		}

		this.bank_account_number = function(input) {
			var numbers_only_input = input.replace(/[^0-9]/gi, '');
			if( typeof(input) === 'string' && //valid
				numbers_only_input == input  && //if you only have numbers is it the same?
				this.non_zero(numbers_only_input) !== true // check only acccounts that are all numbers
			)
				return 'non_zero';

			if(
				typeof(input) === 'string' && //valid
				input.length < 7 //7 or more characters
			) {
				return 'too_short_7';
			}

			if(
				(typeof(input) === 'string' && //valid
				regex.alphanumeric.test(input)) //alphanumeric
			)
				return true;
			else
				return 'alphanumeric';

		}

		this.alphanumeric = function(input) {

			if(
				(typeof(input) === 'string' && //valid
				regex.alphanumeric.test(input)) //alphanumeric
			)
				return true;
			else
				return 'alphanumeric';

		}

		this.numeric_bank_account_number = function(input) {
			if(isNaN(input)) {
				return 'numeric';
			}
			else if (Number(input) <= 0)
				return 'non_zero';
		}

		this.non_zero = function(input) {
			var inputNum = parseInt(input);
			if( typeof(input) === 'string' && (inputNum === 0 || isNaN(inputNum)))
				return 'non_zero';

			return true;
		}

		this.checkbox = function(input) {

			if(input === true)
				return true;
			else
				return 'checkbox';

		}

		this.upload = function(upload_count) {

			if(upload_count > 0)
				return true;
			else
				return 'upload_required';

		}

		this.alphabetic = function(input) {

			return (!input || regex.alphabetic.test(input))
				? true
				: 'alphabetic';

		}

		this.no_special_char = function(input) {

			return (!input || regex.no_special_char.test(input))
				? true
				: 'no_special_char';

		}

		this.numeric = function(input) {

		return (!input || regex.numeric.test(input))
			? true
			: 'numeric';

	}

		this.length_4 = function(input) {

			if(
				input == true || //exists
				input.length >= 4 //4 or more characters
			)
				return true;
			else
				return 'too_short_4';

		}

		this.length_6 = function(input) {

			if(
				input == true || //exists
				input.length >= 6 //4 or more characters
			)
				return true;
			else
				return 'too_short_6';

		}

		this['3_digit'] = function(input) {

			if(
				input == true || //exists
				input.length >= 3 //3 or more characters
			)
				return true;
			else
				return 'too_short_3';

		}

		this['6_digit'] = function(input) {

			if(
				input == true || //exists
				input.length >= 6 //6 or more characters
			)
				return true;
			else
				return 'too_short_6';

		}

		this['7_digit'] = function(input) {

			if(
				input == true || //exists
				input.length >= 7 //7 or more characters
			)
				return true;
			else
				return 'too_short_7';

		}

		this.accountno = function(input) {

			//IS BLANK
			if(input === undefined || input === '')
				return true;

			// if the whole string is underscores and non-word characters (excludes french characters)
			if(typeof(input) !== 'string' || !regex.alphanumeric.test(input))
				return 'alphanumeric';

			var numbers_only_input = input.replace(/[^0-9]/gi, '');
			if( typeof(input) === 'string' && //valid
				numbers_only_input == input  && //if you only have numbers is it the same?
				this.non_zero(numbers_only_input) !== true // check only acccounts that are all numbers
			)
				return 'non_zero';

			return true;

		}

		this.ssn = function(input) {

			if(input === undefined || input.length === 0)
				return true;

			if(
				input !== undefined && //exists
				input.length == 9//correct length
			)
				return true;
			else
				return 'format';

		}

		this.sin = function(input) {

			if(input === undefined || input.length === 0)
				return true;

			if(
				input !== undefined && //exists
				input.length == 9 &&//correct length
				SinValidateService.isLuhnId(input)
			)
				return true;
			else
				return 'format';

		}

		this.yearandmonth = function(input) {
			if (!input) return true;

			var isCorrectLength = input.length === 6
			var isValidDate = moment(input, 'YYYYMM').format('YYYYMM') !== 'Invalid date'

			if (isCorrectLength && isValidDate) return true;

			return 'format';
		}

		this.yearandmonth_future = function(input) {
			var validYearAndMonth = self.yearandmonth(input)
			if (!validYearAndMonth) return validYearAndMonth;

			if (input > moment().format('YYYYMM')) return true;
			return 'future';
		}

		this.greater_than = function(input, min_value) {
			// coerce comma-separated numbers (eg. 49,99) to decimal, otherwise comparator treats these as NaN
			input = input.replace(/,/g, '.');

			if (input >= min_value) return true;

			return 'greater_than';
		}

		this.eft_max_amount = function(input) {
			if (input < 1000000) {
				return true;
			}

			return 'eft_max_amount';
		}

		this.withdrawal_min_max_amount = function(input) {
			var maximum = organization_data.port_data.withdrawals.range.max_dollar_amount;
			var minimum = organization_data.port_data.withdrawals.range.min_dollar_amount;

			input = input.replace(/,/g, '.');
			if (input >= minimum && input <= maximum) {
				return true;
			}

			return 'withdrawal_min_max_amount';
		}

		this.percentage = function(input) {
			return true;
		}

		this.all_check = function(inputs) {
		      var keys = Object.keys(inputs);
		      for (var i = 0; i < keys.length; i++) {
			  if (!inputs[keys[i]]) {
			      return 'confirm';
			  }
		      }

		      return true;
		}

		this.all_check_agree = function (input) {
			  if (!input) {
			    return 'all_check_agree';
			  }

		      return true;
		}

		this.horizon = function (input) {
			if (
				input == true || // exists
				regex.horizon.test(input)
			)
				return true;
			else
				return 'horizon';

		}

		for(var i = 0; i < 30; i++) {
			(function(self, index) {
				self['date_future_plus_'+index] = function(input) {

					var is_proper_date_format = self.date(input);
					if(is_proper_date_format !== true) return is_proper_date_format;

					var checked_date = moment().add(index, 'day').format(DATE_FORMAT.digit);

					if(input >= checked_date)
						return true;
					else
						return 'date_future_plus';
				}
			})(this, i)
		}

	}
]);
