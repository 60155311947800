nwuiCardHeaderV2.factory('NwuiCardHeaderV2Factory', [function() {
	
	// var defaults = {
	// 	name: undefined, //req,
	// 	level: 1,
	// 	icon: undefined,
	// 	input: undefined,
	// 	pill: undefined,
	// 	target: undefined
	// }
	
	return function(obj) {
		// if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCardHeaderV2Factory");
		// if(obj.name === undefined && obj.name_i18n === undefined) return console.error("Missing required instantiation object variable 'name' in NwuiCardHeaderV2Factory");
		
		// angular.extend(this, defaults, obj);

		return obj;
	}
	
}]);