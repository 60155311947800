nwuiCardTableV3FiltersPill.directive('nwuiCardTableV3FiltersPill', [
  '$timeout',
  '$window',
  'locale',
  'DATE_FORMAT',
  function(
    $timeout,
    $window,
    locale,
    DATE_FORMAT
  ) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: '/card/table/v3/filters/pill/template.html',
      scope: {
        filter: '=?',
        editing: '=?',
        index: '=?',
        onClickEdit: '=?',
        onClickRemove: '=?',
        columns: '=?'
      },
      link: function(scope, element, attrs) {
        scope.$watch('filter', function(filter) {
          if (!filter) {
            return;
          }

          var column = findColumnByKey(filter.key);

          scope.columnName = column.name_i18n ? locale.getString(column.name_i18n) : column.name;
          scope.operatorName = (locale.getString('nwui.common.table.filter.operator.' + filter.operator) || '').toLowerCase();
          scope.value = column.type === 'date' ? moment(filter.value).utc().format(DATE_FORMAT.short) : filter.value;
        });

        function findColumnByKey(key) {
          var result = null;
          scope.columns.forEach(function(column) {
            if (column.key === key) {
              result = column;
            }
          });
          return result;
        }
      }
    }
  }
]);
