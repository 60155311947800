angular.module('nwui-compound-modal-reset-password.directives', [])

.directive('nwuiCompoundModalResetPassword', [
	'$timeout',
	'$sce',
	'$q',
	'locale',
	'NwuiModalV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardTableFactory',
	'NwuiFormV3Factory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	
	'NwButtonService',
	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiValidationErrorService',
	'NwuiFormV3Service',
	function(
		$timeout,
		$sce,
		$q,
		locale,
		NwuiModalV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiCardTableFactory,
		NwuiFormV3Factory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		
		NwButtonService,
		NwuiToastManagerService,
		NwuiInputService,
		NwuiValidationErrorService,
		NwuiFormV3Service
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/reset-password/template.html',
			scope: {
				open: '=',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				scope.$watch('open', function(new_val) {
					
					//console.log('nwuiCompoundModalResetPassword', 'watch', 'open', new_val);
					if(new_val === undefined) return;
					
					if(new_val === true)
						NwuiFormV3Service.focus(scope.modals.reset_password.form.name);
					else {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.reset_password.form.name);
						}, 750)
					}

					scope.modals.reset_password.open = new_val;
					
				});
				
				scope.modals = {
					reset_password: new NwuiModalV2Factory({
						open: false,
						wide: false,
						locked: false,
						header: {
							// name: 'Change Password',
							name_i18n: 'nwui.modal.reset_password.title',
							icon: {
								name: 'lock'
							}
						},
						form: new NwuiFormV3Factory({
							name: 'reset-password-form',
							onSubmit: function(post_data) {

								//console.log('nwuiCompoundModalResetPassword', 'form post_data', post_data);

								//special validation for field match
								if(post_data.new_password != post_data.new_password_repeat) {
					        
					        var deferred = $q.defer();
					        
					        NwuiInputService.inputs['reset-password-form-password-confirm'].error = true;
							    NwuiInputService.inputs['reset-password-form-password-confirm'].notification.error = true;
							    NwuiInputService.inputs['reset-password-form-password-confirm'].notification.text = $sce.trustAsHtml(NwuiValidationErrorService['password_match']());
									NwuiInputService.inputs['reset-password-form-password-confirm'].notification.show = true;
							
									$timeout(function() {
										deferred.resolve(false);
									}, 250);
							
					        return deferred.promise;
					        
				        } else {
				        
					        var data = {
					        	old_password: post_data.old_password,
										new_password: post_data.new_password
									}

									return scope.formResource(data);

								}
								
							},
							onComplete: function(obj) {

								//console.log('nwuiCompoundModalResetPassword', 'onComplete', obj);

								if(obj.email || obj.user) {

									scope.submitCb(obj);
									scope.open = false;

									NwuiToastManagerService.register(
										new NwuiToastFactory({
											// text: 'Password Updated Successfully!',
											text_i18n: 'nwui.modal.reset_password.success',
											icon: {
												name: 'check'
											}
										}
									));

								} else if(obj !== false) {

									NwuiToastManagerService.register(
										new NwuiToastFactory({
											// text: 'Something went wrong.',
											text_i18n: 'nwui.common.error.unknown',
											color: 'error',
											icon: {
												name: 'close'
											}
										}
									));

								}

							},
							onError: function(err) {

								//console.log('nwuiCompoundModalResetPassword', 'onError', err);

								var text_i18n = '';

								switch(err.status) {
									case 400:
										text_i18n = 'nwui.modal.reset_password.error_400';
										break;
									case 500:
									default:
										text_i18n = 'nwui.common.error.unknown';
										break;
								}

								NwuiFormV3Service.focus(scope.modals.reset_password.form.name);
								NwuiFormV3Service.clear(scope.modals.reset_password.form.name);

								NwuiFormV3Service.showMessage(scope.modals.reset_password.form.name, {
									text_i18n: text_i18n,
									icon: {
										name: 'error',
										color: 'error'
									}
								});
								


							},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											// label: 'Current Password',
											label_i18n: 'nwui.common.input.password_current.label',
											model: '',
											type: 'password',
											id: 'reset-password-form-password-current',
											stretch: true,
											required: true,
											validators: [],
											// placeholder: "********",
											placeholder_i18n: 'nwui.common.input.password_current.placeholder',
											post_var: 'old_password'
										}
									}
								},{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											// label: 'New Password',
											label_i18n: 'nwui.common.input.password_new.label',
											model: '',
											type: 'password',
											id: 'reset-password-form-password-new',
											stretch: true,
											required: true,
											validators: ['password'],
											// placeholder: "********",
											placeholder_i18n: 'nwui.common.input.password_new.placeholder',
											post_var: 'new_password'
										}
									}
								},{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											// label: 'Confirm Password',
											label_i18n: 'nwui.common.input.password_confirm.label',
											model: '',
											type: 'password',
											id: 'reset-password-form-password-confirm',
											stretch: true,
											required: true,
											validators: ['password'],
											// placeholder: "********",
											placeholder_i18n: 'nwui.common.input.password_confirm.placeholder',
											post_var: 'new_password_repeat'
										}
									}
								}]
							}]
						})
					})
				}
	
			}
		}
	}
]);