nwuiGoalProgress.directive('nwuiGoalProgress', [
	'$timeout',
	'DATE_FORMAT',
	'$filter',
	function(
		$timeout,
		DATE_FORMAT,
		$filter
	) {
		return {
			restrict: 'E',
      replace: true,
      templateUrl: '/goal-progress/template.html',
      scope: {
        current: '=',
        target: '=',
        delta: '=',
        date: '='
      },
      link: function(scope, element, attrs) {

        scope.data = {
	        current: {
		        style: {},
		        invert: true,
						tall: false
	        },
	        delta: {
		        style: {},
		        invert: true
	        },
	        target: {
		        style: {},
		        invert: false,
						tall: false
	        },
					tall: false
        }
        
        scope.$watch('date', function(new_val) {
	    	
	    	if(new_val === undefined || new_val === null) return;
	    		
	    		//console.log('nwuiGoalProgress', 'date', new Date(new_val.replace(/(\d{4})(\d\d)(\d\d)/g, '$2/$3/$1')));
	    		scope.date_format = moment(new Date(new_val.replace(/(\d{4})(\d\d)(\d\d)/g, '$2/$3/$1'))).format(DATE_FORMAT.short);
	    	 
        });
        
        scope.$watchGroup(['current', 'target', 'delta'], function(new_val) {

	        if(new_val[0] === undefined || new_val[1] === undefined || new_val[2] === undefined) return;
	        
	        var denominator = new_val[2] <= 0 ? new_val[1] : Number(new_val[2]) + Number(new_val[1]);
	        
	        var width = new_val[0]/denominator * 100;
	        
	        scope.data.current.style.right = (100 - width) + '%';

					var currentPercentage = width;
					var targetPercentage = new_val[1]/denominator * 100;

					var diff = Math.abs(currentPercentage - targetPercentage);

					scope.data.current.invert = currentPercentage <= 25 || (currentPercentage < 75 && targetPercentage <= 25);
					scope.data.target.invert = targetPercentage <= 25 || (targetPercentage < 75 && currentPercentage <= 25);

					if (diff <= 20) {
						scope.data.tall = true;
						scope.data.current.tall = scope.data.current.invert
							? currentPercentage <= targetPercentage
							: currentPercentage > targetPercentage;
						scope.data.target.tall = scope.data.current.invert
							? currentPercentage > targetPercentage
							: currentPercentage <= targetPercentage;
					} else {
						scope.data.tall = false;
						scope.data.current.tall = false;
						scope.data.target.tall = false;
					}
	        
        });
        
        scope.$watchGroup(['delta', 'target'], function(new_val) {
	        
	        if(new_val[0] === undefined || new_val[1] === undefined) return;
	        
	        var delta = Number(new_val[0]);
	        var target = Number(new_val[1]);
	        			        
	        scope.projected = target + delta;
	        var width = new_val[0] > 0
						? target/(target + delta) * 100
						: (target + delta)/target * 100;
	        
	        if(new_val[0] > 0) {
		        
		        scope.data.delta.style.width = 100 + '%';
		        scope.data.delta.invert = false;
		        
		        scope.data.target.style.right = (100 - width) + '%';
		        
	        } else {
		    
		        scope.data.delta.style.width = width + '%';
		        scope.data.delta.invert = width <= 25;
		        
		        scope.data.target.style.right = 0;
	           
	        }
	        
        });
		        
	    }
		}
	}
]);