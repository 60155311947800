nwuiInputDropList.directive('nwuiInputDropList', [
	'$sce', 
	'$timeout', 
	
	'NwuiInputDropListService',
	function(
		$sce,
		$timeout,
		
		NwuiInputDropListService
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/input/drop-list/template.html',
	        scope: {
		        active: '=',
		        items: '=',
		        tab_index: '=tabIndex',
		        loading: '=',
		        onSelectCb: '='
	        },
	        link: function(scope, element, attrs) {
		        
		        var block = false;
		        var scroll_data = {
			        height: undefined,
			        item_height: undefined
		        }
		        
		        scope.tab_index = 0;
		        
		        //open the select
		        scope.open = function() {
			        ////console.log('open');
			        
			        //block this select for notify 
			        block = true;
					scope.active = true;
					
					//notify to close all open selects
					NwuiInputDropListService.notify();
					
					//timing issue fix for local/global notify
					$timeout(function() {
						block = false;
					}, 150);

		        }
		        
		        //close the select
		        scope.close = function(apply) {
			        ////console.log('close', block);
			        
			        //prevent closing on active element
			        if(block) return;
			        
			        ////console.log('close');
			        scope.active = false;
			        scope.items = [];
			        
			        //only apply on call coming from outside scope
			        if(apply)
				        scope.$apply();
				        
		        }
		        
		        //register select close with the select service
		        NwuiInputDropListService.register(scope.close);
		        
		        scope.$watch('active', function(new_val, old_val) {
			        
			        if(new_val === undefined || old_val === new_val) return;
			        
			        if(new_val === true) {
				        ////console.log('watch');
				        scope.open();
				    }
			        
		        });
		        
		        scope.$watch('tab_index', function(new_val) {
			       	
			       	if(new_val === undefined) return;
			       	
					var tab_index_top = scroll_data.item_height * new_val;
					var scroll_top = $(element).children('.inner').scrollTop();
					
					var min = scroll_top;
					var max = scroll_top + scroll_data.height;
					
					//console.log('ht', scroll_data.height, scroll_data.item_height, tab_index_top, min, max);
					
					//if above
					if(tab_index_top >= max)
						$(element).children('.inner').scrollTop(tab_index_top - scroll_data.height + scroll_data.item_height)
						
					//if below
					else if(tab_index_top < min)
						$(element).children('.inner').scrollTop(tab_index_top)
			        
		        });
		        
		        scope.$watch('items', function(new_val, old_val) {
			        
					if(new_val === old_val) return;
					
					scope.tab_index = 0;
					
					scroll_data.height = $(element).children('.inner').height();;
					scroll_data.item_height = $(element).children('.inner').children('.nwui-input-drop-list-item').height();
			        
			    });
		        
	        }
		}
	}
]);