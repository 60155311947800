nwuiInputUpload.factory('NwuiInputUploadFactory', [function() {
	
	var defaults = {
		model: undefined, //req
    id: undefined, //req
    url: undefined, //req
    placeholder: undefined,
    label: undefined,
    required: false,
    file_type_pattern: '.pdf,.tiff,.gif,.jpg,.jpeg,.png',
    onSuccess: undefined,
    onError: function() {},
    onUpload: function() {},
    notification: {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputUploadFactory");
		if(obj.model === undefined) return console.error("Missing required instantiation object variable 'model' in NwuiInputUploadFactory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiInputUploadFactory");
		if(obj.url === undefined) return console.error("Missing required instantiation object variable 'url' in NwuiInputUploadFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);