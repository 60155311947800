nwuiNotificationBubble.directive('nwuiNotificationBubble', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/notification-bubble/template.html',
        scope:{
	        show: '=',
	        count: '=',
	        icon: '=',
	        pulse: '='
        },
        link: function(scope, element, attrs) {
			
			scope.active = false;
			scope.loaded = false;
			scope.count_text = {
				one: scope.count,
				two: ''
			}
			
			scope.iconClass = function() {
				
				if(scope.icon)
					return scope.icon;
				
			}
			
			$timeout(function() {
				scope.loaded = true;
			}, 0);
			
			scope.$watchGroup(['count', 'icon'], function(new_val) {
				scope.active = (new_val[0] || new_val[1])
					? true
					: false;
				
				if(new_val[0])
					scope.count_text.one = new_val[0];
			});
			
	    }
	}
}]);