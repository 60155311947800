nwuiButtonV2.directive('nwuiButtonV2', [
	'$timeout', 
	'NwuiInitModuleFactory',
	'NwuiButtonV2Service', 
	'NwuiAnalyticsService',
	function(
		$timeout, 
		NwuiInitModuleFactory,
		NwuiButtonV2Service,
		NwuiAnalyticsService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/button/v2/template.html',
			scope:{
				id: '=',
				text: '<',
				text_i18n: '<textI18n',
				icon: '<',
				size: '<',
				processing: '<',
				stretch: '<',
				disabled: '<',
				color: '<',
				hollow: '<',
				onClick: '=',
				icon_only: '<iconOnly',
				icon_only_mobile: '<iconOnlyM',
				tooltip_text: '<tooltipText',
				tooltip_invert: '<tooltipInvert',
				skip_analytics: '<skipAnalytics',
				notification_count: '<notificationCount',
				notification_icon: '<notificationIcon',
				notification_pulse: '<notificationPulse'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						if(angular.isUndefined(scope.hollow)) scope.hollow = false;
						if(angular.isUndefined(scope.stretch)) scope.stretch = false;
						if(angular.isUndefined(scope.processing)) scope.processing = false;
						if(angular.isUndefined(scope.disabled)) scope.disabled = false;
						if(angular.isUndefined(scope.color)) scope.color = '';
						if(angular.isUndefined(scope.icon_only)) scope.icon_only = false;
						if(angular.isUndefined(scope.icon_only_mobile)) scope.icon_only_mobile = false;
						if(angular.isUndefined(scope.skip_analytics)) scope.skip_analytics = false;

						scope.loaded = false;
						scope.tooltip_show = false;

					},
					post: function(scope, element, attrs) {

						scope.icon_only_alternate =
							(organization_data && organization_data.theme.button && typeof organization_data.theme.button.icon_only_alternate !== 'undefined')
								? organization_data.theme.button.icon_only_alternate
								: false;
						scope.show_icons =
							(organization_data && organization_data.theme.button && typeof organization_data.theme.button.show_icons !== 'undefined')
								? organization_data.theme.button.show_icons
								: true;
						
						scope.iconClass = function() {
							
							if(scope.icon) {
								return 'active ' + scope.icon;
							}
							
						}
						
						scope.click = function(e) {
							if(scope.disabled !== true) {
								scope.onClick();
								if(!scope.skip_analytics) {
									NwuiAnalyticsService.track(scope.id);
								}
								
							}
							e.stopPropagation();
						}
						
						scope.mouseover = function() {
							if(scope.processing) return;
							
							scope.tooltip_show = true;
							scope.notification_pulse = false;
						}
						
						scope.mouseleave = function() {
							scope.tooltip_show = false;
						}
						
						NwuiButtonV2Service.register(scope);

					}
				}
			}
		}
	}
]);