nwuiCompoundModalAdminBtRequestDetails.directive('nwuiCompoundModalAdminBtRequestDetails', [
	'$sce',
	'$timeout',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	'NwuiFormV3Factory',
	
	'NwuiToastManagerService',
	'BTRequestService',
	'NwButtonService',
	'NwuiInputService',
	function(
		$sce,
		$timeout,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,
		NwuiFormV3Factory,
		
		NwuiToastManagerService,
		BTRequestService,
		NwButtonService,
		NwuiInputService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/admin-bt-request-details/template.html',
			scope: {
				open: '=',
				bt_request: '=btRequest',
				submitCb: '=submitCb',
				formResource: '=formResource'
			},
			link: function(scope, element, attrs) {
				
				var submitCb = function(is_set) {
					scope.submitCb(is_set);
				}
				
				scope.$watch('open', function(new_val) {
					
					//console.log('open', new_val);
					if(new_val === undefined) return;
					
					scope.modals.bt_request.open = new_val;

				});
				
				scope.$watch('bt_request', function(new_val) {
					
					if(new_val === undefined) return;
					// addColumns();

					if(NwuiInputService.inputs['admin-bt-request-create-client-email'] === undefined) return;

					NwuiInputService.inputs['admin-bt-request-create-client-email'].model = new_val.Client ? new_val.Client.email : '';
					NwuiInputService.inputs['admin-bt-request-create-client-email'].disabled = new_val.Client ? true : false;

					NwuiInputService.inputs['admin-bt-request-create-client-account'].model = new_val.Account ? new_val.Account.custodian_id : '';
					NwuiInputService.inputs['admin-bt-request-create-client-account'].disabled = new_val.Account ? true : false;

					NwuiInputService.inputs['admin-bt-request-create-client-financial-institution'].model = new_val.financial_institution ? new_val.financial_institution : '';
					NwuiInputService.inputs['admin-bt-request-create-client-financial-institution'].disabled = new_val.financial_institution ? true : false;
					
					NwuiInputService.inputs['set-funding-target-form-amount-input'].model = new_val.amount ? new_val.amount : '';

				});
				
				scope.modals = {
					bt_request: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.admin_bt_request_details.title',
							icon: {
								name: 'my_location'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'set-funding-target-form',
							onSubmit: function(post_data) {
								return scope.formResource(post_data, scope.bt_request).then(function(obj){
									return obj;
								}, function(err){
									return err;
								})					        	
							},
							onComplete: function(obj) {

								if(obj.data.error){
									NwuiInputService.inputs['admin-bt-request-create-client-email'].error = true;
									NwuiInputService.inputs['admin-bt-request-create-client-email'].notification.error = true; 
									NwuiInputService.inputs['admin-bt-request-create-client-email'].notification.text = $sce.trustAsHtml('Invalid Input');	
									NwuiInputService.inputs['admin-bt-request-create-client-email'].notification.show = true;

									NwuiInputService.inputs['admin-bt-request-create-client-account'].error = true;
									NwuiInputService.inputs['admin-bt-request-create-client-account'].notification.error = true; 
									NwuiInputService.inputs['admin-bt-request-create-client-account'].notification.text = $sce.trustAsHtml('Invalid Input');	
									NwuiInputService.inputs['admin-bt-request-create-client-account'].notification.show = true;

								} else {
									scope.open = false;
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.modal.bt_request_update.success',
											icon: {
												name: 'check'
											}
										}
									));
									scope.submitCb(true);
								}
							},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.admin_bt_request_details.inputs.client_email.label',
											placeholder_i18n: "nwui.modal.admin_bt_request_details.inputs.account_number.placeholder",
											model: '',
											disabled: false,
											required: true,
											id: 'admin-bt-request-create-client-email',
											post_var: 'email'
										}
									}
								}, {
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.admin_bt_request_details.inputs.account_number.label',
											model: '',
											disabled: false,
											required: true,
											placeholder_i18n: "nwui.modal.admin_bt_request_details.inputs.account_number.placeholder",
											id: 'admin-bt-request-create-client-account',
											post_var: 'account_number'
										}
									}
								}, {
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.admin_bt_request_details.inputs.fin_inst.label',
											model: '',
											disabled: false,
											required: true,
											placeholder_i18n: "nwui.modal.admin_bt_request_details.inputs.fin_inst.placeholder",
											id: 'admin-bt-request-create-client-financial-institution',
											post_var: 'financial_institution'
										}
									}
								}, {
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.admin_bt_request_details.inputs.amount.label',
											model: '',
											mask: 'currency',
											required: true,
											id: 'set-funding-target-form-amount-input',
											placeholder_i18n: "nwui.modal.admin_bt_request_details.inputs.amount.placeholder",
											post_var: 'amount'
										}
									}
								}]
							}]
						})
					})
				}
			}
		}

	}
]);
