nwuiInput.service('NwuiInputService', [function() {
	
	var self = this;	
	this.inputs = {};
	
	this.unregister = function(input_id) {
		delete self.inputs[input_id];
	}

	this.focus = function(input_id) {
		self.inputs[input_id].focusCb();
	}
	
	this.register = function(input, validateCb, clearCb, focusCb) {
		input.validateCb = validateCb;
		input.clearCb = clearCb;
		input.focusCb = focusCb;
		self.inputs[input.id] = input; 
	}
	
	this.validate = function(input_id) {
		//console.log('NwuiInputService', 'validate', self.inputs, self.inputs[input_id], input_id)
		if(self.inputs[input_id].validateCb)
			return self.inputs[input_id].validateCb();
		else
			return true;
	}
	
}]);