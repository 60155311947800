angular.module('nwui-loading.directives', [])

.directive('nwuiLoading', [
	'$timeout', 
	function(
		$timeout
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/loading/template.html',
			scope: {
				color: '=',
				active: '=',
				determinate: '='
			},
			link: function(scope, element, attrs) {
		
				
					
			}
		}
	}
]);