nwuiCardTableBodyRowColumnButton.directive('nwuiCardTableBodyRowColumnButton', ['$timeout', 'NwuiButtonV2Factory', function($timeout, NwuiButtonV2Factory) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/card/table/body/row/column/button/template.html',
		scope: {
			button: '='
		},
		link: function(scope, element, attrs) {
			
			var lock = false;
			
			scope.$watch('button', function(new_val) {

				if(new_val === undefined) return;

				if(lock === true) return;

				lock = true;
				
				//override
				scope.button = new NwuiButtonV2Factory(new_val);
				
				$timeout(function() {
					lock = false;    
				}, 100);
				
			});
			
		}
	}
}]);