nwuiToastManager.service('NwuiToastManagerService', ['$window', '$rootScope', function($window, $rootScope) {
	
	var self = this;	
	var observers = [];
	this.toasts = [];
	
	this.clear = function() {
		observers = [];
	}
	
	this.pop = function() {
		self.toasts.shift();
	}
	
	this.register = function(toast) {
		self.toasts.push(toast);
		//console.log(self.toasts);
	}
	
	$rootScope.$on('$stateChangeSuccess', function() {
		self.clear();
	});
	
}]);