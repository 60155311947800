nwuiBankVerificationsAccountSelect.directive('nwuiBankVerificationsAccountSelect', [
  '$q',
  'NwuiFormV3Factory',
  'NwuiCardHeaderV2Factory',
	'NwuiToastManagerService',
  'NwuiToastFactory',
  'NwuiInputService',
  function(
    $q,
    NwuiFormV3Factory,
    NwuiCardHeaderV2Factory,
    NwuiToastManagerService,
    NwuiToastFactory,
    NwuiInputService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/bank-verifications/account-select/template.html',
      scope: {
        open: '=',
        needsSigning: '=',
        singleAccountSelector: '=',
        manualClickedCb: '=',
        accounts: '=',
        submitCb: '='
      },
      link: function($scope, element, attrs) {
        $scope.header = new NwuiCardHeaderV2Factory({
          name_i18n: 'nwui.bank_verification.account_select.heading',
          icon: {
            name: 'lock'
          }
        });

        $scope.onClickManual = function() {
          if (typeof $scope.manualClickedCb === 'function') {
            $scope.manualClickedCb();
          }
        }

        $scope.form = new NwuiFormV3Factory({
          name: 'add-bank-accounts-form',
          onComplete: function(post_data) {
            var accountsToAdd = [];
            var validAccounts = $scope.accounts.filter(function(account) {
              return !account.isExist;
            })

            if(Array.isArray(post_data.bank_accounts_new)) {
              post_data.bank_accounts_new.forEach(function(ba) {
                if(ba.value) {
                  accountsToAdd.push(validAccounts[ba.model]);
                }
              });
            } else {
              accountsToAdd.push(validAccounts[post_data.bank_accounts_new]);
            }
            

            if(accountsToAdd.length == 0) {
              NwuiToastManagerService.register(
                new NwuiToastFactory({
                  text: 'No Accounts Selected!',
                  icon: {
                    name: 'error'
                  }
                })
              )
            }
            console.log('onComplete', post_data, accountsToAdd)
            $scope.submitCb(accountsToAdd);
          },
          onSubmit: function(post_data) {

            var deferred = $q.defer();
            deferred.resolve(post_data);
            return deferred.promise;
          },
          submit_button: (getOptions($scope.accounts, false).length === 0 ? false : {
            text_i18n: 'nwui.bank_verification.account_select.buttons.submit'
          }),
          steps: [{
            columns: [{
              directive: 'input',
              width: 100,
              data: {
								directive: (($scope.needsSigning || $scope.singleAccountSelector) ? 'radio' : 'checkbox'),
								data: {
                  id: 'add-bank-accounts-form-options-new',
						      label_i18n: 'nwui.bank_verification.account_select.form.available_accounts', 
									stretch: true,
                  required: true,
                  hide: (getOptions($scope.accounts, false).length === 0),
                  options: getOptions($scope.accounts, false),
									size: '',
                  post_var: 'bank_accounts_new'
								}
							}
            },
            {
              directive: 'input',
              width: 100,
              data: {
								directive: 'checkbox',
								data: {
                  id: 'add-bank-accounts-form-options-exists',
                  label_i18n: 'nwui.bank_verification.account_select.form.existing_accounts',
									stretch: true,
                  disabled: true,
                  hide: (getOptions($scope.accounts, true).length === 0),
                  options: getOptions($scope.accounts, true),
									size: '',
									post_var: 'bank_accounts_existing'
								}
							}
            }]
          }]
        })

        function getOptions(accounts, isExist) {
          return accounts.filter(function(account) {
            return account.isExist === isExist;
          }).map(function(account, index) {
            return {
              label: getAccountName(account),
              model: index,
              value: (isExist ? true : index)
            }
          })
        }

        function getAccountName(account) {
          return [
            account.account_type,
            '(',
            '****',
            account.bank_account.substr(-4),
            ')',
            ' - ',
            account.amount
          ].join('')
        }
      }
    }

  }
]);