nwuiCompoundClientActivities.factory('NwuiCompoundClientActivitiesFactory', [function() {
	
	var defaults = {
		user: undefined, //req
		activities: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundClientActivitiesFactory");
		if(obj.user === undefined) return console.error("Missing required instantiation object variable 'user' in NwuiCompoundClientActivitiesFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);