angular.module('nwui-signing.directives', [])

.directive('nwuiSigning', [
	'$rootScope',
	'$sce',
	'$timeout', 
	'NwuiSigningService',
	'NwuiPageScrollService',
	'NwuiToastManagerService',
	'NwuiOverlayFactory',
	'NwuiToastFactory',
	function(
		$rootScope,
		$sce,
		$timeout,
		NwuiSigningService,
		NwuiPageScrollService,
		NwuiToastManagerService,
		NwuiOverlayFactory,
		NwuiToastFactory
	) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/signing/template.html',
		scope: {
			id: '=',
			open: '=',
			allowCancel: '=',
			styleForNav: '=',
			envelope_id: '=envelopeId',
			signingUrlResource: '=',
			signingPollResource: '=',
			submitCb: '='
		},
		link: function(scope, element, attrs) {

			var poll_timer;

			if(scope.allowCancel) {
				$rootScope.$on('$stateChangeSuccess', function() {
					if(scope.open)
						scope.open = false;
				});
			}

			scope.buttons = {
				signing_cancel : {
					text_i18n: 'nwui.common.modal.close',
					id: 'signing-cancel',
					size: 'short',
					sharp_corners: true,
					icon: {
						name: "close"
					},
					onClick: function() {
						scope.open = false;
						scope.submitCb(false);
					}
				}
			};

			var poll_delay = 5000;

			scope.url = undefined;

			scope.overlay = new NwuiOverlayFactory({
				id: 'global-signing-overlay-' + scope.id,
				block_state_change: true,
				message_i18n: 'nwui.common.doc_sign.loader',
				icon: {
					name: 'gesture'
				}
			})

			var poll = function() {
				//console.log('nwuiSigning', 'poll');

				poll_timer = $timeout(function() {
					scope.signingPollResource(scope.envelope_id).then(function(obj) {
						//console.log('nwuiSigning', 'signingPollResource.then', obj);

						if(obj.is_complete === false && scope.open === true) {
							poll();
						} else if(scope.open === false) {
							//DO NOTHING
						} else {
							page_scroll_service.close();

							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: 'nwui.common.doc_sign.success',
									icon: {
										name: 'check'
									}
								}
							));

							scope.submitCb(obj);
						}

					}, function(err) {

						if(err && err.data && err.data.code) {

							page_scroll_service.close();
							scope.errorCb(err.data);

						} else if(err && err.status === 400) {
							poll();
						}

					});
				}, poll_delay);

			}

			var open = function() {
				//console.log('nwuiSigning', 'open');
				scope.open = true;
			}

			var close = function() {
				//console.log('nwuiSigning', 'close');
				scope.open = false;
				// scope.url = undefined;
				// scope.envelope_id = undefined;

				if(poll_timer)
					$timeout.cancel(poll_timer)
			}

			scope.iframeLoad = function(src) {
				//console.log('nwuiSigning', 'scope.iframeLoad', src);
			}

			scope.close = function() {
				//console.log('nwuiSigning', 'scope.close');
				page_scroll_service.close();
			}

			NwuiSigningService.register(scope);
			var page_scroll_service = NwuiPageScrollService.register(scope.id, open, close);

			scope.$watch('envelope_id', function(new_val, old_val) {
				//console.log('nwuiSigning', 'watch.envelope_id', old_val, '=>', new_val);

				if(new_val === undefined) return;

				scope.signingUrlResource(new_val).then(function(obj) {
					//console.log('nwuiSigning', 'signingUrlResource.then', obj);
					$timeout(function() { //Test for dev
						scope.url = $sce.trustAsResourceUrl(obj.url);
					}, 750);
				})
			});

			scope.onIframeLoaded = function(event) {

        var url = false;

        //try because cross domain
        try {
          url = document.getElementById("nwui-signing-iframe").contentWindow.location.href;
        } catch(e) {
          url = false;
        }

        if(url !== false) {

          if(
            url.indexOf('event=cancel') !== -1 ||
            url.indexOf('event=session_timeout') !== -1 ||
            url.indexOf('event=ttl_expired') !== -1 ||
            url.indexOf('event=decline') !== -1
          ) {
          	if(scope.open)
							scope.open = false;
          }

        }

      }

			scope.$watchGroup(['open', 'url'], function(new_val, old_val) {
				//console.log('nwuiSigning', 'watchGroup[open,url]', old_val, '=>', new_val);

				var open = new_val[0];
				var url = new_val[1];

				if(open !== undefined) {

					if(open === true) {
						page_scroll_service.open();

						$('html, body').animate({
					    scrollTop: 0
						}, 0);
					} else 
						page_scroll_service.close();

				}

				if(open === true && url != null) {
					poll();
				}

			});

		}
	}
}]);