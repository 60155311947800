nwuiFormV2.directive('nwuiFormV2', [
	'$timeout', 
	'$window', 
	
	'NwuiFormService', 
	
	'NwuiFormV2ColumnFactory', 
	function(
		$timeout, 
		$window,
		
		NwuiFormService, 
		
		NwuiFormV2ColumnFactory
	) {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: '/form/v2/template.html',
	        scope: {
		        name: '=',
		        columns: '=',
		        onSubmit: '=',
		        onComplete: '='
	        },
	        link: function(scope, element, attrs) {
		        
		        var lock = false;
		        
		        scope.version = '';
		        
		        scope.setTemplate = function() {
			        scope.version = ($(element).width() <= 450) ? 'stacked' : 
			        	($(element).width() <= 600) ? 'split' : '';
		        }
		        
		        scope.calibrate = function() {
			        
		        	if(!scope.columns) return;

			        //console.log('nwui-form-v2', 'calibrate', scope.columns.length);
			        
			        var form_width = $(element).children('.inner').width() - 1;
			        
			        var widths = [];
			        var remainder = form_width;
			        
			        for(var i = 0; i < scope.columns.length; i++) {
				        
				        //console.log('nwui-form-v2', 'iterate', scope.columns[i].width);
				        
				        //use row remainder
				        if(scope.columns[i].width === undefined || scope.columns[i].width === 'auto') {
					        scope.columns[i].calc_width = remainder + 'px';
					        remainder = form_width;
					    } else {
						    remainder -= scope.columns[i].width;
						    scope.columns[i].calc_width = scope.columns[i].width + 'px';
					    }

			        }
			        
			        ////console.log('nwui-form-v2', 'iterate', scope.columns[i].calc_width);
			        
		        }
		        
		        scope.$watch('name', function(new_val, old_val) {
			        //console.log('form_name', new_val)
			        if(new_val !== undefined)
				        NwuiFormService.registerForm(scope.name, scope.onSubmit, scope.onComplete);
				        
			    });
		        
		        scope.$watch('columns', function(new_val, old_val) {
					
			        if(new_val !== undefined && !lock) {

				        lock = true;
				        
				        var columns = [];
				        angular.forEach(new_val, function(column, i) {
					        //console.log('nwui-form-v2', 'iterate-watch', column)
					        
					        var new_column = new NwuiFormV2ColumnFactory({ 
					        	directive: column.directive,
					        	form_name: scope.name,
					        	//width: column.width,
					        	data: column.data
					    	})
					    	
					    	if('width' in column)
					    		new_column.width = column.width;
					        
					        columns.push(new_column);
				        });
				        
				        ////console.log('nwui-form-v2', 'iterate-watch-post', columns)
				        
				        scope.columns = columns;
				        
				        scope.calibrate();
				        
				        $timeout(function() {
					        lock = false;
				        }, 150);
				        
			        }
			        
		        });
		        
		        window.onresize = function() {
					scope.$apply();
				};
				
				scope.$watch(function() {
					return angular.element($window)[0].innerWidth;
				}, function(new_val) {
					$timeout(function() {
						//scope.setTemplate();
						scope.calibrate();
					}, 0);
				});
		        
		    }
		}
	}
]);