(function(){
angular.module('nwui-filters.filters', [])

.filter('NwuiNumberDelta', ['$filter', '$sce', function($filter, $sce) {
	return function(input, zero_text, postfix, decimals) {
		
		if(!postfix) postfix = '';
		if(decimals === undefined) decimals = 2;
		
		if(input === undefined || input == 0) {
			if(zero_text){
				return $sce.trustAsHtml($filter('i18n')(zero_text));
			}
			else
				return $sce.trustAsHtml($filter("number")(input,decimals) + postfix);
		} else if(input > 0)
			return $sce.trustAsHtml("<span class = 'up'>+" + $filter("number")(input,decimals) + postfix + "</span>");
		else if(input < 0) {
			input *= -1;
			return $sce.trustAsHtml("<span class = 'dn'>-" + $filter("number")(input,decimals) + postfix + "</span>");
		}
		
	};
}])

.filter('NwuiCurrencyWithDelta', [
	'$filter', 
	'$sce', 
	function(
		$filter,
		$sce
	) {
	return function(input, sign, fraction, zero_text) {
		
		if(!input) input = 0;
		if(typeof fraction === "undefined") fraction = 2;
		if (typeof input === 'string') {
			return input;
		}
		
		if(input === undefined || input == 0) {
			if(zero_text) { 
				return $sce.trustAsHtml("<span class = 'nc'>"+$filter('i18n')(zero_text)+"</span>");
			}else
				return $sce.trustAsHtml("<span class = 'nc'>"+$filter('currency')(0.00)+"</span>");
		} else if(input > 0) {
			return $sce.trustAsHtml("<span class = 'up'>+" + $filter('currency')(input,undefined, fraction) + "</span>");
		} else if(input < 0) {
			input *= -1;
			return $sce.trustAsHtml("<span class = 'dn'>-" + $filter('currency')(input,undefined, fraction) + "</span>");
		}
		
	};
}])

.filter('NwuiNumberAbbrFilter', ['$locale', '$filter', function($locale, $filter) {
	return function(input, digits, fraction, stripZeros) {
		if(!input) input = 0;
		if(typeof fraction === "undefined") fraction = 2;
		if(typeof stripZeros === "undefined") stripZeros = true;
 
		var input_abs = Math.abs(input);
		var number = shortenNumber(input_abs, digits, fraction, stripZeros, "number", $locale, $filter);
		return input < 0 ? "-" + number : number;
	};
}])

.filter('NwuiCurrencyAbbrFilter', ['$filter', '$locale', function($filter, $locale) {
	return function(input, digits, fraction, stripZeros) {
		if(!input) input = 0;
		if(typeof fraction === "undefined") fraction = 2;
		if(typeof stripZeros === "undefined") stripZeros = true;

		var input_abs = Math.abs(input);
		var number = shortenNumber(input_abs, digits, fraction, stripZeros, "currency", $locale, $filter);
			
		return input < 0 ? "-" + number : number;
		
	};
}])

.filter('NwuiCurrencyDeltaAbbrFilter', ['$sce','$locale','$filter', function($sce, $locale, $filter) {
	return function(input, digits, fraction, stripZeros) {
	
		if(!input) input = 0;
		if(typeof fraction === "undefined") fraction = 2;
		if(typeof stripZeros === "undefined") stripZeros = true;
		
		input = Number(input);
		
		var input_abs = Math.abs(input);
			
		var number = shortenNumber(input_abs,digits,fraction, stripZeros, "currency",$locale, $filter);
  
		
		 
		if(input === undefined || input == 0) {
			return $sce.trustAsHtml("<span class = 'nc'>"+number+"</span>");
		} else if(input > 0) {
			return $sce.trustAsHtml("<span class = 'up'>+" + number + "</span>");
		} else if(input < 0) {
			input *= -1;
			return $sce.trustAsHtml("<span class = 'dn'>-" + number + "</span>");
		}
		
	};
}])

.filter('NwuiBytesFilter', [function() {
	return function(bytes, precision) {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
		if (typeof precision === 'undefined') precision = 1;
		var units = ['b', 'KB', 'MB', 'GB', 'TB', 'PB'],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
	}
}])

.filter('NwuiIconAutoPicker', [function() {
	return function(string, default_icon) {
		
		//UPDATED SEPT 25
		
		var icon = default_icon;
		
		var keywords = {
			//house
			'home': ['home', 'house', 'mortgage', 'down payment', 'downpayment'],
			//renovation
			//car
			'time_to_leave': ['car', 'auto'],
			//education
			'school': ['education', 'university', 'college', 'school', 'resp'],
			//vacation
			'beach_access': ['vacation'],
			//inn/hotel
			'hotel': ['hotel', 'inn'],
			//trip/vegas
			'local_atm': ['vegas'],
			//trip/flight
			'flight': ['trip', 'flight'],
			//retirement
			'attach_money': ['retirement', 'retiement', 'retire', 'future', 'rrsp', 'riff', 'lira', 'lrsp'],
			//wedding
			//joint
			'people': ['joint'],
			//corporate
			'business': ['inc', 'ltd', 'corporate'],
			//boat
			'directions_boat': ['boat', 'yacht', 'cruise', 'sense 50']
		}
		
		for(var key in keywords) {
			for(var i = 0; i < keywords[key].length; i++) {
				if(string.toLowerCase().indexOf(keywords[key][i]) !== -1) {
					return key;
				}
			}
		}
		
		return icon;
		
	}
}])

.filter('NwuiPercentWithDelta', [
	'$sce', 
	'$filter',
	'$locale', 
	function(
		$sce, 
		$filter,
		$locale
	) {
		return function(input, zero_text) {
			var format = $locale.PERCENT_FORMATS;
			var rounded = ((input*100)/100).toFixed(2);
		
			if(rounded == '0.00' || input == undefined)
				if(zero_text)
					return $sce.trustAsHtml('<span>' + $filter('i18n')(zero_text) + '</span>');
				else
					return $sce.trustAsHtml('<span>' + format.pre + $filter("number")(0.00) + format.post + '</span>');
			else if(rounded > 0)
				return $sce.trustAsHtml("<span class = 'up'>+"+ format.pre + $filter("number")(rounded) + format.post + '</span>');
			else if(rounded < 0)
				return $sce.trustAsHtml("<span class = 'dn'>-" + format.pre + $filter("number")((rounded*-1).toFixed(2)) + format.post + "</span>");
			 
		}
	}
])

.filter('NwuiPercent', [
	'$sce', 
	'$filter',
	'$locale', 
	function(
		$sce, 
		$filter,
		$locale
	) {
		return function(input, fraction, zero_text) {
			var format = $locale.PERCENT_FORMATS;
			var rounded = ((input*100)/100).toFixed(fraction);
			if(zero_text)
				return $filter('i18n')(zero_text);
			else
				return format.pre + $filter("number")(rounded, fraction) + format.post
		}
	}
])

.filter('NwuiTransferFrequency', [
  '$sce',
  '$filter',
  'locale',
  function(
    $sce,
    $filter,
    locale
  ) {
    return function(input) {
      var key = 'nwui.common.form.select.option.frequency.' + setLanguageKey(input);
      return locale.isTranslated(key)
        ? locale.getString(key)
        : input;
    }
  }
])

.filter('NwuiI18nWData', [
	'$sce',
	'$filter',
	'locale',
	function(
		$sce,
	  $filter,
	  locale
	) {
		return function(key, data, fallbackText) {
			var localizedData = localizeI18nData($filter, data);

			return locale.isTranslated(key)
				? locale.getString(key, localizedData)
				: fallbackText;
		}
	}
])

//TEMPORARY
.filter('NwuiOrgSlugToShortName', [
	'$sce', 
	'$filter', 
	function(
		$sce, 
		$filter
	) {
		return function(input) {
			
			var short_name = '';

			switch(input) {
				case 'aligned':
					short_name = 'Aligned Capital Partners';
					break;
				case 'credential':
					short_name = 'Credential InvestWELL';
					break;
				case 'md':
				case 'md-financial':
					short_name = 'MD ExO Direct';
					break;
				case 'nest-wealth':
				case 'nestwealth':
				default:
					short_name = 'Nest Wealth';
			}

			return short_name;
			
		}
	}
]);

function shortenNumber(input, digits, fraction, stripZeros, filter, localeService, filterService){
	var abbr = localeService.ABBR_NUMBER_SYMBOLS[filter],
			post = "",
			pre = "",
			number = input,
			regex;
			shortened = true;
			
	switch(digits) {
		case 1:
			regex = /[0-9]/;
			break;
		case 2:
			regex = /[0-9]\.?[0-9]/;
			break;
		case 3:
		default:
			digits = 3;
			regex = /[0-9]\.?[0-9]\.?[0-9]/;
	}
 
	if(input >= Math.pow(10,9) && abbr.billion) {
		number = String((input/Math.pow(10,9)).toFixed(2)).match(regex)[0];
		pre = abbr.billion.pre;
		post = abbr.billion.post;
	} else if(input >= Math.pow(10,6) && abbr.million) {
		number = String((input/Math.pow(10,6)).toFixed(2)).match(regex)[0];
		pre = abbr.million.pre;
		post = abbr.million.post;
	} else if(input >= Math.pow(10,3) && abbr.thousand) {
		number = String((input/Math.pow(10,3)).toFixed(2)).match(regex)[0];
		pre = abbr.thousand.pre;
		post = abbr.thousand.post;
	} else {
		//shortened = false;
		if(filter == "currency")
			number = filterService(filter)(input.toFixed(fraction), undefined, fraction);
		else 
			number = filterService(filter)(input.toFixed(fraction), fraction);
		shortened = false;
	}
	
	if(shortened && stripZeros)
		number = parseFloat(number);

	if(shortened) {
		var splitNumber = String(number).split('.');
		var decimalPoints;
		if(splitNumber.length == 2) {
			decimalPoints = splitNumber[1].length;
		}

		number = filterService('number')(number, decimalPoints);
	}
  number = pre + number + post;
	return number;
}

function setLanguageKey(val){
  if(!val) return undefined;

  return val.replace(/[^\w\s]/g , "").replace(/ +/g , "_").toLowerCase();
}

function localizeI18nData($filter, data) {
	var newData = {};
	if (!data) data = {};
	for (var key in data) {
		if (data.hasOwnProperty(key)) {
			if (typeof data[key] === 'string') {
				newData[key] = data[key];
			} else if (typeof data[key] === 'object') {
				switch(data[key].type) {
					case 'currency':
						newData[key] = $filter('currency')(data[key].value);
						break;
				}
			}
		}
	}
	return newData;
}

})();