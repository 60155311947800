nwuiCardTableV2.service('NwuiCardTableV2Service', ['NwuiCardTableV2Factory', function(NwuiCardTableV2Factory) {
	
	var self = this;
	var tables = {};
	
	this.register = function(name, obj) {
		tables[name] = new NwuiCardTableV2Factory(obj);
		return tables[name];
	}
    
}]);