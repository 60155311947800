nwuiIcon.directive('nwuiIcon', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/icon/template.html',
		scope:{
			name: '=',
			active: '=',
			clickEvent: '=',
			tooltip: '='
		},
		link: function(scope, element, attrs) {
			
			scope.showTooltip = function() {
				
				if(!scope.tooltip) return;
				
				scope.tooltip.show = true;
				
			}
			
			scope.hideTooltip = function() {
				
				if(!scope.tooltip) return;
				
				scope.tooltip.show = false;
				
			}
						
		}
	}
}]);