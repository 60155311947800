nwuiInputToggle.factory('NwuiInputToggleFactory', [function() {
	
	var defaults = {
		id: undefined, //req
		onChange: undefined, //req
		label: undefined,
		label_i18n: undefined,
		post_var: undefined,
		model: false,
		stretch: false,
		isDisabled: false,
		// size: '',
		toggle_only_mobile: false,
		color: ''
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputToggleFactory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiInputToggleFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);