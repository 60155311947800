nwuiTooltipV2.factory('NwuiTooltipV2Factory', [function() {
	
	var defaults = {
		text: undefined, //req
		show: false,
		invert: false
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiTooltipV2Factory");
		if(obj.text === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiTooltipV2Factory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);