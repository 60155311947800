nwuiCardTabs.directive('nwuiCardTabs', ['$window', '$timeout', 'NwuiFormFactory', 'NwuiAllocationFactory', 'NwuiCardTableFactory', function($window, $timeout, NwuiFormFactory, NwuiAllocationFactory, NwuiCardTableFactory) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/tabs/template.html',
        scope: {
	        name: '=',
	        tabs: '='
        },
        link: function(scope, element, attrs) {
	        
	        //saving selections for later
			var container;
			var tab_contents;
			
			//content style for transitions
			scope.style_container = {};
			
			//currently selected tab
			scope.active_tab = 0;
				
			//change tab
			scope.changeTab = function(index) {
				
				//disable initial transition for height set (safari fix)
				scope.no_transition = true;
				
				$timeout(function() {
					//clear();
				}, 500);
				
				//set height to current auto height and change tab
				$timeout(function() {
					scope.style_container = {
						height: container.height() + 'px',
						overflow: 'hidden'
					}
					
					scope.active_tab = index;
				}, 50);
				
				//reenable transition for animating to new height (safari fix)
				$timeout(function() {
					scope.no_transition = false;
					
					//set new height
					$timeout(function() {
						scope.style_container = {
							height: tab_contents.eq(index).height() + 'px',
							overflow: 'hidden'
						}
						
						$timeout(function() {
							scope.style_container = {}
						}, 1000);
					}, 50);
				}, 100);
			}
			
			//window resize event
			window.onresize = function() {
				scope.$apply();
			};
			
			scope.$watch(function() {
				return angular.element($window)[0].innerWidth;
			}, function() {
				//set height back to auto on window resize/rotate to account for dynamic height change
				scope.style_container = {};
				
				//safari fix
				scope.no_transition = true;
				$timeout(function() {
					scope.no_transition = false;
				}, 50);
			});
			
			scope.$watch('open', function(new_val, old_val) {
				if(new_val != old_val && new_val === true) {
					scope.active_tab = (scope.default_form || 1)-1;
					reset();
				}
			});
			
			scope.$watch('default_tab', function(new_val, old_val) {
				if(new_val != old_val)
					scope.active_tab = (scope.default_form || 1)-1;
			});
			
			scope.$watch('tabs', function(new_val) {
			    if(new_val) {
				    new_val.forEach(function(tab) {
						switch(tab.directive) {
							case 'form':
								tab['form'] = tab.data = new NwuiFormFactory(tab.data);
								break;
							case 'allocation':
								tab['allocation'] = tab.data = new NwuiAllocationFactory(tab.data);
								break;
							case 'table':
								tab['table'] = tab.data = new NwuiCardTableFactory(tab.data);
								//console.log('table table', tab.table);
								break;
						} 
				    });
				    
				    container = $(element).find('.bottom');
					tab_contents = $(element).find('.tab-content');
			    }
		    });
	        
        }
	}
}]);