nwuiDivider.directive('nwuiDivider', [
	'$timeout',
	
	'NwuiIconV2Factory',
	function(
		$timeout,
		
		NwuiIconV2Factory
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/divider/template.html',
	        scope: {
		        name: '=',
						name_i18n: "=nameI18n"
	        },
	        link: function(scope, element, attrs) {
		    }
		}
	}
]);