var nwuiCardTableV3BodyRowColumn = angular.module('nwui-card-table-v3-body-row-column', [
	'nwui-card-table-v3-body-row-column-checkbox',
	'nwui-card-table-v3-body-row-column-button',
	'nwui-card-table-v3-body-row-column-currency',
	'nwui-card-table-v3-body-row-column-currency-delta',
	'nwui-card-table-v3-body-row-column-glyph',
	'nwui-card-table-v3-body-row-column-icons',
	'nwui-card-table-v3-body-row-column-delta',
	'nwui-card-table-v3-body-row-column-number',
	'nwui-card-table-v3-body-row-column-progress',
	'nwui-card-table-v3-body-row-column-symbol',
	'nwui-card-table-v3-body-row-column-text',
	'nwui-card-table-v3-body-row-column-html',
	'nwui-card-table-v3-body-row-column-toggle'
]);