angular.module('nwui-signing.services', [])

.service('NwuiSigningService', [
	function() {

		var self = this;	
		var iframes = {};
		
		this.register = function(iframe) {
			iframes[iframe.id] = iframe;
		}

		this.setEnvelope = function(id, envelope_id) {
			iframes[id].envelope_id = envelope_id;
		}

		this.setSubmitCb = function(id, submitCb) {
			iframes[id].submitCb = submitCb;
		}

		this.setErrorCb = function(id, errorCb) {
			iframes[id].errorCb = errorCb;
		}

	}
]);