nwuiCompoundClientDocumentsModal.factory('NwuiCompoundClientDocumentsModalFactory', [function() {
	
	var defaults = {
		client: undefined,
		open: false,
		onUploadCb: function() {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundClientDocumentsModalFactory");
		if(obj.client === undefined) return console.error("Missing required instantiation object variable 'client' in NwuiCompoundClientDocumentsModalFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);