nwuiCompoundGoalTracker.directive('nwuiCompoundGoalTracker', [
	'$timeout',
	'$filter',
	
	'NwuiButtonV2Factory', 
	'NwuiCardTableFactory',
	'NwuiToastFactory',
	
	'NwuiInputService',
	'NotificationService',
	'NwuiToastManagerService',
	function(
		$timeout, 
		$filter,
		
		NwuiButtonV2Factory,
		NwuiCardTableFactory,
		NwuiToastFactory,
		
		NwuiInputService,
		NotificationService,
		NwuiToastManagerService
	) {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: '/compound/goal-tracker/template.html',
	        scope: {
		        loading: '=',
		        not_set: '=notSet',
		        on_track: '=onTrack',
		        delta: '=',
		        target_amount: '=targetAmount',
		        current_amount: '=currentAmount',
		        adjust_monthly: '=adjustMonthly',
				adjust_lump_sum: '=adjustLumpSum',
				adjust_horizon: '=adjustHorizon'
	        },
	        link: function(scope, element, attrs) {
		        
		        scope.styles = {
			        progress: {
				    	width: 0
			        },
			        projected: {
				        left: 0,
						right: 0
			        },
			        delta: {
				        width: 0
			        }
		        }
		        
		        scope.data = {
			        recommendations: {
				        table: new NwuiCardTableFactory({
					    	name: 'goal-tracker-recommendations-table',
					        empty_text: 'No recommendations',
					        columns: [{
						        name: 'Recommendations',
						        directive: 'html',
								sortable: false
					        }],
					        rows: undefined
				        })
			        }
		        }
		        
		        //scope.modals = {}
		        
		        scope.$watchGroup(['not_set', 'on_track', 'delta', 'adjust_monthly', 'adjust_lump_sum', 'adjust_horizon', 'target_amount', 'current_amount'], function(new_val) {
			        
			        if(new_val[0] !== undefined && new_val[1] !== undefined && new_val[2] !== undefined && new_val[3] !== undefined && new_val[4] !== undefined && new_val[5] !== undefined && new_val[6] !== undefined && new_val[7] !== undefined) {
				        scope.loading = false;
				        
				        //console.log('nwuiGoalTracker', new_val)
				        
				        if(scope.delta/1 <= 0)
					        scope.styles.projected.right = scope.styles.delta.width = (Math.abs(scope.delta)/scope.target_amount * 100) + '%';
					    else
					    	scope.styles.projected.right = scope.styles.delta.width = 0;
					    	
				        scope.styles.projected.left = scope.styles.progress.width = (Math.abs(scope.current_amount)/scope.target_amount * 100) + '%';
				        
				        var recommendations = [];
				        
				        if(scope.adjust_horizon) {
					        recommendations.push({
						        columns: [{
							        html: "Extend horizon by <strong>" + scope.adjust_horizon + " months</strong> to hit goal target&nbsp;value."
						        }]
					        })
				        }
				        
				        if(scope.adjust_monthly) {
					        recommendations.push({
						        columns: [{
							        html: "Increase monthly contribution to <strong>" + $filter('currency')(scope.adjust_monthly, '$', 0) + "</strong> to reach goal by target&nbsp;date."
						        }]
					        })
				        }
				        
				        if(scope.adjust_lump_sum) {
					        recommendations.push({
						        columns: [{
							        html: "Contribute <strong>" + $filter('currency')(scope.adjust_lump_sum, '$', 0) + "</strong> as a lump sum to get back on&nbsp;track."
						        }]
					        })
				        }
				        
				        scope.data.recommendations.table.rows = recommendations;
				        
			        }
			        
		        });
		        
	        }
		}
	}
]);