angular.module('nwui-toolkit').directive('iosDetector', [
	function(
	) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				var userAgent = navigator.userAgent || navigator.vendor || window.opera;

				if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
					angular.element(element).addClass('ios');
				}
			}
		}
	}
]);