nwuiPill.factory('NwuiPillFactory', [function() {
	
	var defaults = {
		size: '',
		text: undefined, //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiPillFactory");
		if(obj.text === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiPillFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);