nwuiCompoundGoalTracker.factory('NwuiCompoundGoalTrackerFactory', [function() {
	
	var defaults = {
		loading: true,
		not_set: undefined,
		on_track: undefined,
		delta: undefined,
		target_amount: undefined,
		current_amount: undefined,
		adjust_monthly: undefined,
		adjust_lump_sum: undefined,
		adjust_horizon: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundGoalTrackerFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);