nwuiCardTableV2BodyRow.directive('nwuiCardTableV2BodyRow', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/v2/body/row/template.html',
        scope: {
	        columns: '=',
	        row: '=',
	        widths: '=',
	        is_odd: '=isOdd'
        },
        link: function(scope, element, attrs) {
	    }
	}
}]);