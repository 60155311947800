angular.module('nwui-modules.v1.services', [])

.service('NwuiModuleV1Service', [
	'$rootScope',
	'$timeout',
	function(
		$rootScope,
		$timeout
	) {
		
		var self = this;	
		
		var modulesContainsModule = function(modules, match) {

			//console.log('NwuiModuleV1Service', 'modulesContainsModule', modules, match);

			for(var i = 0; i < modules.length; i++) {
				var is_match = modules[i].name === match;
				if(is_match === true)
					return true;
			}

			return false;

		}

		var columnsContainsModule = function(columns, match) {

			//console.log('NwuiModuleV1Service', 'columnsContainsModule', columns, match);

			for(var i = 0; i < columns.length; i++) {
				if('modules' in columns[i]) {
					var is_match = modulesContainsModule(columns[i].modules, match);
					if(is_match === true)
						return true;
				}
			}

			return false;

		}

		var stateContainsModule = function(resources, match) {

			//console.log('NwuiModuleV1Service', 'stateContainsModule', resources, match);

			if('columns' in resources) {
				var is_match = columnsContainsModule(resources.columns, match);
				if(is_match === true)
					return true;
			}

			return false;

		}

		var tertiaryNavContainsModule = function(resources, match) {

			//console.log('NwuiModuleV1Service', 'tertiaryNavContainsModule', resources, match);

			for(var key in resources) {
				var is_match = stateContainsModule(resources[key], match);
				if(is_match === true)
					return true;
			}

			return false;

		}

		/*
		 * resource: admin resource object
		 * match: module slug to find
		 * return: boolean
		 */
		this.containsModule = function(resource, match) {
			
			//console.log('NwuiModuleV1Service', 'containsModule', resource, match);

			if(resource === undefined) return false;

			if('tertiaryNav' in resource) {
				var is_match = tertiaryNavContainsModule(resource.tertiaryNav, match);
				if(is_match === true)
					return true;
			}

			return false;

		}
				
	}
]);