nwuiCompoundModalAddEftSourceUpload.directive('nwuiCompoundModalAddEftSourceUpload', [
	'$timeout',
	'$filter',

	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	'NwuiSigningService',
	'NwuiPageScrollService',

	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiFormV3Service',
	function(
		$timeout,
		$filter,

		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		NwuiSigningService,
		NwuiPageScrollService,

		NwuiToastManagerService,
		NwuiInputService,
		NwuiFormV3Service
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/add-eft-source-upload/template.html',
			scope: {
				open: '='
			},
			link: function(scope, element, attrs) {

				var uploaded_file = undefined;

				var submitCb = function(data) {
					scope.submitCb(data);
				}

				scope.modals = {
					add_eft_source_upload: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.add_eft_source_upload.title',
							icon: {
								name: 'file_upload'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'add-eft-source-upload-form',
							active_step_index: 0,
							submit_button: false,
							onSubmit: function(post_data) {},
							onError: function(obj) {},
							onComplete: function(obj) {},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'upload',
										data: {
											id: 'add-eft-source-upload-form-file-input',
											label_i18n: 'nwui.modal.upload_doc.inputs.file_upload.label',
											model: '',
											file_type_pattern: '.csv',
											url: '/api/admin/efts/bulk-import',
											placeholder_i18n: 'nwui.modal.upload_doc.inputs.file_upload.placeholder',
											onSuccess: function() {
												
												NwuiToastManagerService.register(
													new NwuiToastFactory({
														text_i18n: 'nwui.modal.upload_doc.toasts.success',
														icon: {
															name: 'check'
														}
													}
												));
												
												scope.onUploadCb();
												
											}
										}
									}
								}]
							}]
						})
					})
				}

				scope.$watch('open', function(new_val) {

					if(new_val === undefined) return;

					if(new_val === false) {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.add_eft_source_upload.form.name);
							NwuiInputService.inputs['add-eft-source-upload-form-file-input'].model = '';
							uploaded_file = undefined;
						}, 750)
					}

					scope.modals.add_eft_source_upload.open = new_val;

				});

			}
		}
	}
]);
