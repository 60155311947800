nwuiCompoundClientActivities.directive('nwuiCompoundClientActivities', [
	'$timeout',
	'$q',
	'$filter',
	'NwuiCardTableV2Factory',
	'NwuiBlindFactory',
	
	'UserService',
	'ActivityService',
	'NwuiButtonV2Service',
	
	'DATE_FORMAT',
	function(
		$timeout,
		$q,
		$filter,
		NwuiCardTableV2Factory,
		NwuiBlindFactory,
		
		UserService,
		ActivityService,
		NwuiButtonV2Service,
		
		DATE_FORMAT
	) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/compound/client-activities/template.html',
        scope: {
	        user: '=',
	        activities: '=activities'
        },
        link: function(scope, element, attrs) {
			
			scope.data = {
				table: new NwuiCardTableV2Factory({
			        id: 'user-activities-table',
			        resource: UserService.getActivities,
			        rowMap: function(activity,i) {
						
						return {
							columns: [{
								text: activity.activity.template
							}, {
								text: moment(activity.created_at).format(DATE_FORMAT.long)
							}, {
								button: {
									id: 'activities-archive-button-' + i,
									size: 'short',
									stretch: true,
									text: 'Archive',
									onClick: function() {
										
										NwuiButtonV2Service.buttons['activities-archive-button-' + i].processing = true;
										
										ActivityService.setArchive(activity._id, true).then(function(obj) {
											//console.log('client-activities:setArchive', obj)
											
											NwuiButtonV2Service.buttons['activities-archive-button-' + i].processing = false;
											NwuiButtonV2Service.buttons['activities-archive-button-' + i].color = 'done';
											NwuiButtonV2Service.buttons['activities-archive-button-' + i].text = 'Done';
										});
										
									}
								}
							}]
						}
				    	
			        },
			        empty_text: 'No recent activities',
			        columns: [{
				        name: 'Activity',
				        directive: 'text',
						sortable: false
			        }, {
				        name: 'Date',
				        width: 140,
				        directive: 'text',
						sortable: false
			        }, {
				        name: '',
				        width: 100,
				        directive: 'button',
						sortable: false
			        }],
			        footer: {
				    	page_size: 5
			        },
			        rows: undefined
			    })
			}
						
	    }
	}
}]);