nwuiChart.factory('NwuiChartFactory', [function() {
	
	var defaults = {
		directive: undefined, //req
		data: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiChartDonutV2Factory");
		if(obj.directive === undefined) return console.error("Missing required instantiation object variable 'directive' in NwuiChartDonutV2Factory");
		if(obj.data === undefined) return console.error("Missing required instantiation object variable 'data' in NwuiChartDonutV2Factory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);