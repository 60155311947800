nwuiTarget.directive('nwuiTarget', ['$timeout', '$sce', function($timeout, $sce) {
	return {
		restrict: 'E',
    replace: true,
    templateUrl: '/target/template.html',
    scope:{
      status: '=',
      text: '=?',
			text_i18n: '=?textI18n'
    },
    link: function(scope, element, attrs) {}
	}
}]);