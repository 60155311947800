nwuiGridRow.directive('nwuiGridRow', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/grid/row/template.html',
        scope: {},
        link: function(scope, element, attrs) {
	        
	        
	        
	    }
	}
}]);