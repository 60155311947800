angular.module('nwui-modules.v1.directives', [])

.directive('nwuiModulesV1', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/modules/v1/template.html',
			scope: {
				resources: '=',
				data: '='
			},
			link: function(scope, element, attrs) {
				scope.evaluate = function(obj) {
					
					if(obj === undefined) return true;

					var data = scope.data[obj.key];

					if(data && data !== true)
						data = "'" + data + "'";

					return eval(data + obj.op + obj.val);

				}
				
			}
		}
	}
]);