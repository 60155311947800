angular.module('nwui-validation-error.service', [])

.service('NwuiValidationErrorService', [function() {

	var self = this;

	this.blank = function(label) {
		return label + ' cannot be blank';
	}

	this.password_match = function() {
		return 'Passwords do not match';
	}

	this.date_future = function() {
		return 'Date must be in the past';
	}

	this.date_past = function() {
		return 'Date must be in the future';
	}

	this.year_past = function() {
		return 'Year must be in the future';
	}

	this.format = function(label) {
		return 'Invalid format for ' + label.toLowerCase();
	}

	this.too_short = function(length) {
		return ' must be at least ' + length + ' characters';
	}

	this.checkbox = function(label) {
		return 'Please review and then check this box to continue';
	}

	this.upload_required = function(label) {
		return label + ' required';
	}

	this.password = function(label) {
		return 'Your password must be at least 8 characters in length, contain one uppercase, one lowercase, and one non-alphabetical character';
	}

	this.non_zero = function(label) {
		return label + ' must not be all zeros';
	}

	this.alphanumeric = function(label) {
		return label + ' may only contain alphanumeric characters';
	}

	this.date_mustbe_future = function() {
		return 'Date should be atleast 5 business days in the future';
	}

	for(var i = 2; i < 20; i++) {

		(function(i) {
			self['too_short_'+i] = function(label) {
				//console.log('NwuiValidationErrorService', i)
				return label + self.too_short(i);
			}
		})(i);

	}

}]);
