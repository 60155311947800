nwuiInputTextV2.directive('nwuiInputTextV2', [
	'$sce',
	'$timeout',
	'$state',
	'NwuiInitModuleFactory',
	'NwuiIconFactory',
	'NwuiInputDropListFactory',
	
	'NwuiMaskService',
	'NwuiFormService',
	'NwuiInputService',
	'NwuiValidationService',
	'NwuiValidationErrorService',
	'NwuiValidationErrorV2Service',
	function(
		$sce,
		$timeout,
		$state,
		NwuiInitModuleFactory,
		NwuiIconFactory, 
		NwuiInputDropListFactory,
		
		NwuiMaskService, 
		NwuiFormService, 
		NwuiInputService, 
		NwuiValidationService, 
		NwuiValidationErrorService,
		NwuiValidationErrorV2Service
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			require: '?^nwuiFormV3',
			templateUrl: '/input/text/v2/template.html',
			scope: {
				step_index: '<stepIndex',
				form_name: '<formName',
				model: '=',
				id: '<',
				type: '<',
				default_value: '=defaultValue',
				label: '<',
				label_i18n: '<labelI18n',
				post_var: '=postVar',
				is_disabled: '=isDisabled',
				required: '=',
				placeholder: '<',
				placeholder_i18n: '<placeholderI18n',
				error: '=',
				size: '=',
				color: '=',
				icon: '=',
				validators: '=',
				min_val: '=minVal',
				mask: '=',
				hide: '=',
				notification: '=',
				autocomplete_select_cb: '=autocompleteSelectCb',
				autocomplete_resource: '=autocompleteResource',
				autocomplete_resource_formatter: '=autocompleteResourceFormatter',
				autocomplete_item_map: '=autocompleteItemMap',
				drop_list: '=dropList',
				onCut: '=onCut',
				onCopy: '=onCopy',
				onPaste: '=onPaste',
				onChange: '=onChange',
				onBlur: '=onBlur'
			},
			link: function(scope, element, attrs, nwuiFormV3Ctrl) {

				scope = NwuiInitModuleFactory({
					model: undefined, //req
					id: undefined, //req
					type: 'text',
					default_value: '',
					label: undefined,
					disabled: false,
					required: true,
					placeholder: '',
					post_var: undefined,
					size: '',
					color: '',
					icon: undefined,
					validators: [],
					mask: undefined,
					min_val: undefined,
					error: false,
					notification: {},
					drop_list: undefined,
					autocomplete_resource: undefined,
					autocomplete_resource_formatter: undefined,
					autocomplete_item_map: undefined,
					onCut: function() {},
					onCopy: function() {},
					onPaste: function() {},
					onChange: function() {},
					onBlur: function() {}
				}, scope);

				if(nwuiFormV3Ctrl)
					nwuiFormV3Ctrl.registerInput(scope.step_index, scope);
				else if(angular.isDefined(scope.form_name)) {
					NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);
				}

				var input = $(element).find('input');

				var timeouts = {
					change: undefined
				}
				
				var locks = {
					icon: undefined
				}

				var clear = function() {

					scope.model = scope.default_value || '';
					scope.error = false;
					if(!scope.notification) scope.notification = {};
					scope.notification.show = false;

				}

				var focus = function() {
					$(element)
						.find('input')[0]
						.focus();

				}
				
				var mask = function(delay) {

					if(scope.model === undefined) return;

							
					scope.model_text = (scope.mask)
						? NwuiMaskService.mask(scope.mask, scope.model)
						: scope.model;

					scope.model = (scope.mask)
						? NwuiMaskService.unmask(scope.mask, scope.model_text)
						: scope.model_text;
						 
				}
				
				var validate = function() {
					var valid = true;
					var errorI18nData = {};
					errorI18nData['label'] = scope.label_i18n || scope.label;
					if(scope.min_val) {
						valid = NwuiValidationService['greater_than'](scope.model, scope.min_val);
						errorI18nData['min_val'] = scope.min_val;
					}
					if(scope.required)
						valid = NwuiValidationService['blank'](scope.model);
					
					scope.validators.forEach(function(validator,i) {
						if(valid === true)
							valid = NwuiValidationService[validator](scope.model);
					});
				
					if(valid !== true) {
						scope.error = true;
						if(!scope.notification) scope.notification = {};
						scope.notification.error = true;
						
						if(scope.label)
							scope.notification.text = $sce.trustAsHtml(NwuiValidationErrorService[valid](scope.label));
						else if(scope.label_i18n)
							scope.notification.text_i18n = NwuiValidationErrorV2Service[valid]();

						scope.notification.show = true;
					}
					
					return valid;
					
				}
				
				NwuiInputService.register(scope, validate, clear, focus);
				
				scope.focus = function(event) {
					
					scope.clearError(); 
			
					if(scope.autocomplete_resource)
						scope.drop_list.active = true;
					
				}

				scope.blur = function(event) {

					if(scope.onBlur)
						scope.onBlur(scope.model);

				}

				scope.cut = function(event) {

					if(scope.onCut)
						scope.onCut(event);
					
				}

				scope.copy = function(event) {

					if(scope.onCopy)
						scope.onCopy(event);
					
				}

				scope.paste = function(event) {

					if(scope.onPaste)
						scope.onPaste(event);
					
				}
				
				scope.keyPress = function(event) {
					
					if(scope.drop_list && scope.drop_list.active === true && scope.autocomplete_resource && scope.drop_list.items.length > 0) {
					
						if(event.keyCode == 40) {
							if(scope.drop_list.tab_index < scope.drop_list.items.length-1)
								scope.drop_list.tab_index++;
						} else if(event.keyCode == 38) {
							if(scope.drop_list.tab_index > 0)
								scope.drop_list.tab_index--;
						} else if(event.which === 13) {
							scope.drop_list.active = false;
							scope.drop_list.onSelectCb(scope.drop_list.items[scope.drop_list.tab_index].data);
						} else {
							scope.clearError();
						}
							
					} else {
						
						if(event.which === 13) {
							if(nwuiFormV3Ctrl)
								nwuiFormV3Ctrl.enterKeySubmit(scope.id, event);
							else
								NwuiFormService.enterKeySubmit(scope.id, event);
						} else
							scope.clearError();
			
					}
					
				}
				
				scope.change = function(event) {
					
					scope.model = (scope.mask)
						? NwuiMaskService.unmask(scope.mask, scope.model_text)
						: scope.model_text;

					mask();

					if(scope.onChange)
						scope.onChange(scope.model);

					if(scope.autocomplete_resource) {
						$timeout.cancel(timeouts.change);
						timeouts.change = $timeout(function() {
							if(scope.model.length > 0) {
								scope.drop_list.loading = true;
								scope.autocomplete_resource(scope.model).then(function(obj) {
									
								var data = (scope.autocomplete_resource_formatter)
									? scope.autocomplete_resource_formatter(obj)
									: obj;
								
								scope.drop_list.items = [];
								$timeout(function() {
									scope.drop_list.items = data.map(scope.drop_list.item_map);
									scope.drop_list.loading = false;
									
									if(scope.drop_list.items.length > 0) {
										scope.drop_list.active = true;
										scope.drop_list.tab_index = 0;
									}
								},0);
									
								});
							} else {
								scope.drop_list.items = [];
							}
						}, 150);
					}

					if(nwuiFormV3Ctrl)
						nwuiFormV3Ctrl.setDirty(scope.id);
					else
						NwuiFormService.setDirty(scope.id);

				}
				
				scope.clearError = function() {
			
					if(scope.drop_list)
						scope.drop_list.tab_index = 0;
				
					scope.error = false;
					if(!scope.notification) scope.notification = {};
					scope.notification.show = false;
					
				}

				
				scope.$watchGroup(['autocomplete_resource', 'autocomplete_item_map', 'autocomplete_select_cb'], function(new_val) {
					
					if(new_val[0] === undefined || new_val[1] === undefined || new_val[2] === undefined) return;
					
					scope.drop_list = new NwuiInputDropListFactory({
						item_map: new_val[1],
						onSelectCb: new_val[2]
					});
				});
	
				
				scope.$watch('model', function(new_val, old_val) {

					if(new_val !== undefined && new_val !== '') {
						if(nwuiFormV3Ctrl)
							nwuiFormV3Ctrl.setDirty(scope.id);
						else
							NwuiFormService.setDirty(scope.id);
					}

					mask(false);
					
				});
				
				
			}
		}
	}
]);