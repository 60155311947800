nwuiButton.directive('nwuiButton', ['$timeout', 'NwuiButtonService', function($timeout, NwuiButtonService) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/button/template.html',
		scope:{
			id: '=',
			icon: '=',
			size: '=',
			processing: '=',
			stretch: '=',
			disabled: '=',
			color: '=',
			hollow: '=',
			icon_only: '=iconOnly',
			tooltip_text: '=tooltipText',
			tooltip_invert: '=tooltipInvert',
			notification_count: '=notificationCount',
			notification_icon: '=notificationIcon',
			notification_pulse: '=notificationPulse',
			analytics_tag: '=analyticsTag'
		},
		link: function(scope, element, attrs) {
			
			var setSize = function() {
				
				scope.ui_size_sm = false;
				scope.ui_size_short = false;
				
				switch(scope.size) {
					case 'sm':
					case 'small':
						scope.ui_size_sm = true;
						break;
					case 'short':
						scope.ui_size_short = true;
					default:
						break;
				}
				
			}
			
			scope.iconClass = function() {
				
				if(scope.icon) {
					return 'active ' + scope.icon;
				}
				
			}

			scope.icon_only_alternate =
				(organization_data && organization_data.theme.button && typeof organization_data.theme.button.icon_only_alternate !== 'undefined')
					? organization_data.theme.button.icon_only_alternate
					: false;
			scope.show_icons =
				(organization_data && organization_data.theme.button && typeof organization_data.theme.button.show_icons !== 'undefined')
					? organization_data.theme.button.show_icons
					: true;
			
			scope.mouseover = function() {
				if(scope.processing) return;
				
				scope.tooltip_show = true;
			}
			
			scope.mouseleave = function() {
				scope.tooltip_show = false;
			}
			
			scope.loaded = false;
			scope.tooltip_show = false;
			
			scope.$watch('size', function(new_val) {
				setSize();
			});
			
			scope.$watch('processing', function(new_val) {
				
				if(new_val === true) {
					scope.tooltip_show = false;
					scope.notification_show = false;
				} else {
					scope.notification_show = true;
				}
				
			});
			
			$timeout(function() {
				scope.loaded = true;
			}, 0);
			
			NwuiButtonService.register(scope);
			
		}
	}
}]);