nwuiCardTableBodyRow.directive('nwuiCardTableBodyRow', [
	function() {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/card/table/body/row/template.html',
	        scope: {
		        columns: '=',
		        row: '=',
		        widths: '=',
		        is_odd: '=isOdd'
	        },
	        link: function(scope, element, attrs) {
		    }
		}
	}
]);