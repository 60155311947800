nwuiForm.factory('NwuiFormFactory', [function() {
	
	var defaults = {
		name: undefined, //req
		columns: undefined, //req
        onSubmit: undefined, //req
        onComplete: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiFormFactory");
		if(obj.name === undefined) return console.error("Missing required instantiation object variable: 'object.name'");
		if(obj.columns === undefined) return console.error("Missing required instantiation object variable: 'object.columns'");
		if(obj.onSubmit === undefined) return console.error("Missing required instantiation object variable: 'object.onSubmit'");
		if(obj.onComplete === undefined) return console.error("Missing required instantiation object variable: 'object.onComplete'");
		
		angular.extend(this, defaults, obj);
	}
	
}]);