var nwuiCompoundModal = angular.module('nwui-compound-modal', [
	'nwui-compound-modal-add-eft-source',
	'nwui-compound-modal-add-eft-source-upload',
	'nwui-compound-modal-admin-bt-request-details',
	'nwui-compound-modal-add-goal',
	'nwui-compound-modal-archive-user',
	'nwui-compound-modal-assign-user',
	'nwui-compound-modal-create-account-sign-review',
	'nwui-compound-modal-create-account-type-select',
	'nwui-compound-modal-create-advisor-code',
	'nwui-compound-modal-create-eft',
	'nwui-compound-modal-create-notification',
	'nwui-compound-modal-disable-user',
	'nwui-compound-modal-document-upload',
	'nwui-compound-modal-goal-target',
	'nwui-compound-modal-ownership-type-select',
	'nwui-compound-modal-edit-advisor-code',
	'nwui-compound-modal-reset-password',
	'nwui-compound-modal-risk-profile-approve',
	'nwui-compound-modal-remove-advisor-code',
	'nwui-compound-modal-send-communication',
	'nwui-compound-modal-split-goal',
	'nwui-compound-modal-update-admin',
	'nwui-compound-modal-set-password',
	'nwui-compound-modal-edit-banking-info',
	'nwui-compound-modal-archive-esign',
	'nwui-compound-modal-esign-code',
	'nwui-compound-modal-upload-aml-verification',
	'nwui-compound-modal-approve-aml-verification',
	'nwui-compound-modal-bank-verifications',
	'nwui-compound-modal-info-confirm',
	'nwui-compound-modal-accept-household',
]);
