nwuiInputText.factory('NwuiInputTextFactory', [function() {
	
	var defaults = {
		model: undefined, //req
        id: undefined, //req
        label: '',
        type: 'text',
        placeholder: '',
        error: false
	}
	
	return function(obj) {
		//console.log('obj', obj)
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputTextFactory");
		if(typeof obj.model === undefined) return console.error("Missing required instantiation object variable: 'object.model'");
		if(typeof obj.id === undefined) return console.error("Missing required instantiation object variable: 'object.id'");
		
		angular.extend(this, defaults, obj);
	}
	
}]);