nwuiCardTableV3Header.directive('nwuiCardTableV3Header', [
	'$timeout',
	'$window',
	function(
		$timeout,
		$window
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/card/table/v3/header/template.html',
			scope: {
				table_id: '=tableId',
				columns: '=',
				sub_columns: '=subColumns',
				widths: '=',
				sort_by: '=sortBy',
				sort_order: '=sortOrder',
				sortCb: '=sortCb',
        loaded: '=loaded'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 

						scope.data = {
							added_arrow: false,
							widths: []
						}

					},
					post: function(scope, element, attrs) {

						var width = $window.innerWidth;

						var interval = {
							resize: undefined
						}

						var locks = {
							columns: false
						}

						var calibrate = function() {

							if(!scope.columns || scope.columns.length === 0) return;

							width = angular.element($window)[0].innerWidth;

							if(scope.sub_columns && scope.sub_columns.length > 0 && scope.data.added_arrow === false) {
								scope.data.added_arrow = true;
								scope.columns.push({
									name: '',
									directive: 'icon',
									sortable: false
								})
							}

							scope.data.widths = scope.columns.map(function(o,i) {
								if(o.disabled === true)
									o.width = 0;
								if(o.directive == 'checkbox' || o.directive == 'icon')
									o.width = 40;
								if(o.width === undefined)
									o.width = 'auto';
								return o.width;
							});
							
							var auto_columns = [];
							var auto_width_allocation = $(element).width() - 1;
							
							//count # of auto columns
							scope.columns.forEach(function(column, i) {
								
								if(column.width == 'auto' && column.directive != 'checkbox')
									auto_columns.push(column);
								else
									auto_width_allocation -= column.width;
							
							});
							
							//if no columns are auto, manage the extra width in the first column
							if(auto_columns.length == 0) {
								
								var left_over_index = 0;
								
								if(scope.columns[0].directive == 'checkbox')
									left_over_index++;
									
								auto_columns.push(scope.columns[left_over_index]);
								
							}
								
							//apply the auto width
							scope.columns.forEach(function(column, i) {
								if(column.width == 'auto' && column.directive != 'checkbox')
									scope.data.widths[i] = auto_width_allocation/auto_columns.length + 'px';
								else
									scope.data.widths[i] += 'px';
							});

							scope.widths = scope.data.widths;

						}

						interval.resize = setInterval(function() {
							if(width === angular.element($window)[0].innerWidth) return;
							calibrate();
							scope.$apply();
						}, 750);
								
						scope.$watch('sort_by', function(new_val, old_val) {
							if(new_val === undefined || new_val === old_val) return;
							
							scope.columns.forEach(function(column,i) {
								if(column.sort_key != scope.sort_by) {
									column.sort_order = 0;
								} else {
									column.sort_order = -1;
								}
							});
						});
								
						scope.$watch('columns', function(new_val, old_val) {
							if(new_val === undefined) return;
							calibrate();
						});

						scope.$on('$destroy', function() {
							if(interval.resize)
								clearInterval(interval.resize);
						});

					}
				}
			}
		}
	}
]);