nwuiInputText.directive('nwuiInputText', [
	'$timeout',
	function(
		$timeout
	) {
	return {
		restrict: 'E',
	  replace: true,
	  transclude: true,
	  templateUrl: '/input/text/template.html',
	  scope: {
	    model: '=',
	    id: '=',
	    label: '=',
	    type: '=',
	    placeholder: '=',
	    error: '=',
	    input_notification: '=inputNotification'
	  },
	  link: function(scope, element, attrs) {
	    
	  	var locks = {
	  		model: false
	  	}

	  	scope.data = {
	  		model: 'asd'
	  	}

	    scope.clearError = function() {

	    	if(scope.input_notification)
	    		scope.input_notification.show = false;
	      
	    }
	    
	  }
	}
}]);