nwuiInputDropListItem.factory('NwuiInputDropListItemFactory', [
	function() {
	
		var defaults = {
			active: false,
			label: undefined, //req
			data: {}
		}
		
		return function(obj) {
			if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputDropListItemFactory");
			if(obj.label === undefined) return console.error("Missing required instantiation object variable 'label' in NwuiInputDropListItemFactory");
			
			angular.extend(this, angular.copy(defaults), obj);
		}
		
	}
]);