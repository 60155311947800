nwuiTouchEvents.directive('nwuiTouchStart', [function() {
	return function(scope, element, attr) {
	
		var has_touch = Boolean('ontouchstart' in document);
	
		var go = function(event) {
			var method = element.attr('nwui-touch-start');
			
			scope.$event = event.originalEvent || event;
			scope.$apply(method);
		};
		
		if(has_touch)
			element.bind('touchstart', go);

	};
}]);

nwuiTouchEvents.directive('nwuiTouchMove', [function() {
	return function(scope, element, attr) {
	
		var has_touch = Boolean('ontouchstart' in document);
	
		var go = function(event) {
			var method = element.attr('nwui-touch-move');
			
			scope.$event = event.originalEvent || event;
			scope.$apply(method);
		};
		
		if(has_touch)
			element.bind('touchmove', go);

	};
}]);

nwuiTouchEvents.directive('nwuiTouchEnd', [function() {
	return function(scope, element, attr) {
	
		var has_touch = Boolean('ontouchstart' in document);
	
		var go = function(event) {
			var method = element.attr('nwui-touch-end');
			
			scope.$event = event.originalEvent || event;
			scope.$apply(method);
		};
		
		if(has_touch)
			element.bind('touchend', go);

	};
}]);

nwuiTouchEvents.directive('nwuiTouchStartMouseDown', [function() {
	return function(scope, element, attr) {
	
		var has_touch = Boolean('ontouchstart' in document);
	
		var go = function(event) {
			var method = element.attr('nwui-touch-start-mouse-down');
			
			scope.$event = event.originalEvent || event;
			scope.$apply(method);
		};
		
		if(has_touch)
			element.bind('touchstart', go);
		else
			element.bind('mousedown', go);
			
	};
}]);

nwuiTouchEvents.directive('nwuiTouchEndMouseUp', [function() {
	return function(scope, element, attr) {
	
		var has_touch = Boolean('ontouchstart' in document);
	
		var go = function(event) {
			var method = element.attr('nwui-touch-end-mouse-up');
			
			scope.$event = event.originalEvent || event;
			scope.$apply(method);
		};
		
		if(has_touch)
			element.bind('touchend', go);
		else
			element.bind('mouseup', go);

	};
}]);