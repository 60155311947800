nwuiDrawerV1.directive('nwuiDrawerV1', [
	'$timeout',
	'$sce',
	'NwuiIconV2Factory',
	'NwuiOverlayFactory',
	'NwuiButtonV3Factory',
	'NwuiDrawerV1Service',
	'NwuiPageScrollService',
	function(
		$timeout,
		$sce,
		NwuiIconV2Factory,
		NwuiOverlayFactory,
		NwuiButtonV3Factory,
		NwuiDrawerV1Service,
		NwuiPageScrollService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/drawer/v1/template.html',
			scope: {
				id: '=',
				open: '=',
				is_empty: '=isEmpty',
				orientation: '=',
				name: '<',
				name_i18n: '<nameI18n',
				icon: '=',
				z_index: '=zIndex',
				empty_text: '=emptyText',
				empty_text_i18n: '=emptyTextI18n',
				empty_icon: '=emptyIcon'
			},
			link: function(scope, element, attrs) {
						
				var locks = {
					icon: false,
					empty_icon: false
				}

				var open = function() {
					//console.log('nwuiDrawerV1', 'open');
					scope.open = true;

					$(window).on('keypress.nwui-drawer', function(e) {
						e = e || window.event;

						//console.log('onkeypress', e.keyCode);

						//if esc key
						if(e.keyCode === 27) {
							scope.$apply(close);
						}
					});
				}

				var close = function() {
					//console.log('nwuiDrawerV1', 'close');
					scope.open = false;

					$(window).off('keypress.nwui-drawer');
				}

				var service = NwuiPageScrollService.register(scope.id, open, close);

				scope.close = function() {
					//console.log('nwuiDrawerV1', 'scope.close');
					service.close();
				}

				scope.modules = {
					empty_button: new NwuiButtonV3Factory({
						id: 'empty-text-button',
						text_i18n: 'nwui.common.drawer.notification.close',
						color: '',
						size: 'small',
						icon: {
							name: 'close'
						},
						onClick: function() {
							close();
						}
					})
				}

				//console.log(scope.modules.empty_button);

				scope.overlay = new NwuiOverlayFactory({
					id: 'overlay-' + scope.id,
					icon: undefined,
					message: '',
					block_state_change: true
				})

				scope.$watch('open', function(new_val, old_val) {
					//console.log('nwuiDrawerV1', 'watch.open', old_val, '=>', new_val);

					if(new_val === undefined) return;

					if(new_val === true)
						service.open();
					else
						service.close(service.id);

				})

				scope.$watch('empty_text', function(new_val, old_val) {

					if(new_val === undefined) return;

					scope.empty_text_safe = $sce.trustAsHtml(new_val);

				});

				scope.$watch('empty_icon', function(new_val, old_val) {

					if(new_val !== undefined && !locks.empty_icon) {

					locks.empty_icon = true;
						
						scope.empty_icon = new NwuiIconV2Factory({
							name: scope.empty_icon.name,
							size: 'large'
						});
						
						$timeout(function() {
						locks.empty_icon = false;    
						}, 100);

					}

				});

				scope.$watch('icon', function(new_val, old_val) {
					
					if(new_val !== undefined && !locks.icon) {
						
						locks.icon = true;
						
						scope.icon = new NwuiIconV2Factory({
							name: scope.icon.name,
							size: 'small'
						});
						
						$timeout(function() {
						locks.icon = false;    
						}, 100);
				
					}
						
				});

				scope.$watchGroup(['z_index'], function(new_val) {

					if(new_val === undefined) return;

					scope.style = {
						zIndex: scope.z_index
					}

				});

				NwuiDrawerV1Service.register(scope);

			}
		}
	}
]);