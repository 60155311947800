nwuiBreadcrumbs.directive('nwuiBreadcrumbs', [
	'$timeout',
	'$state', 
	'$stateParams',
	
	'NwuiBreadcrumbsItemFactory',
	'NwuiBreadcrumbsService',
	function(
		$timeout,
		$state,
		$stateParams,
		
		NwuiBreadcrumbsItemFactory,
		NwuiBreadcrumbsService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/breadcrumbs/template.html',
			scope: {
				root: '='
			},
			link: function(scope, element, attrs) {
				
				var locks = {
					root: false
				}
				
				scope.current_state = $state;
				scope.breadcrumbs = NwuiBreadcrumbsService.buildBreadcrumbs(
				  'data.breadcrumb_label',
          'data.breadcrumb_proxy',
          'data.breadcrumb_label_i18n'
        );
				
				scope.$watch('root', function(new_val) {
					
					if(new_val === undefined || locks.root) return;
					 
					locks.root = true;
					scope.root = {
						label: new_val.label,
						label_i18n: new_val.label_i18n,
						icon: new_val.icon,
						state: new_val.state
					}
					
					$timeout(function() {
						locks.root = false;
					}, 100);
					 
				});
				
			}
		}
	}
]);