nwuiCardTableV3FiltersInputs.directive('nwuiCardTableV3FiltersInputs', [
  '$timeout',
  '$window',
  function(
    $timeout,
    $window
  ) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: '/card/table/v3/filters/inputs/template.html',
      scope: {
        filters: '=?',
        applyFilter: '=?',
        editing: '=?',
        filter: '=',
        tableId: '='
      },
      link: function(scope, element, attrs) {
        scope.opened = false;

        scope.applyButton = {
          id: scope.tableId + '-apply-filter',
          text_i18n: 'nwui.common.table.filter.apply',
          size: 'small',
          stretch: true,
          onClick: function() {
            applyFilter();
          }
        };

        scope.currentFilter = {
          name: undefined,
          operator: undefined,
          value: undefined
        };

        scope.columnSelect = {
          options: [],
          size: 'small',
          placeholder_i18n: 'nwui.common.table.filter.input.column.placeholder',
          model: null
        };

        scope.operatorSelect = {
          options: [],
          size: 'small',
          placeholder_i18n: 'nwui.common.table.filter.input.column.placeholder',
          model: null
        };

        scope.valueTextInput = {
          size: 'small',
          model: null
        };

        scope.valueDateInput = {
          size: 'small',
          model: null,
          mask: 'date'
        };

        ((scope.filters || {}).columns || []).forEach(function(option) {
          scope.columnSelect.options.push({
            id: option.key,
            value: option.key,
            text: option.name,
            text_i18n: option.name_i18n
          });
        });

        function getOperatorOptionsByColumnType(columnType) {
          var operators = {
            contains: {
              id: 'contains',
              value: 'contains',
              text_i18n: 'nwui.common.table.filter.operator.contains'
            },
            excludes: {
              id: 'excludes',
              value: 'excludes',
              text_i18n: 'nwui.common.table.filter.operator.excludes'
            },
            startsWith: {
              id: 'starts_with',
              value: 'starts_with',
              text_i18n: 'nwui.common.table.filter.operator.starts_with'
            },
            before: {
              id: 'before',
              value: 'before',
              text_i18n: 'nwui.common.table.filter.operator.before'
            },
            after: {
              id: 'after',
              value: 'after',
              text_i18n: 'nwui.common.table.filter.operator.after'
            }
          };

          switch(columnType) {
            case 'string':
              return [
                operators.contains,
                operators.excludes,
                operators.startsWith
              ];
            case 'date':
              return [
                operators.before,
                operators.after
              ];
            default:
              return [];
          }
        }

        function findColumnByKey(key) {
          var result = null;
          scope.filters.columns.forEach(function(column) {
            if (column.key === key) {
              result = column;
            }
          });
          return result;
        }

        scope.onColumnChanged = function(val) {
          var column = findColumnByKey(val);
          scope.operatorSelect.options = getOperatorOptionsByColumnType(column && column.type);
          scope.valueType = column && column.type;
        };

        scope.onValueChanged = function(val) {
          scope.value = val;
        };

        function applyFilter() {
          var key = scope.columnSelect.model;
          var operator = scope.operatorSelect.model;
          var column = findColumnByKey(key);
          var type = column && column.type;
          var value = scope.value;
          clearFilterInputs();
          if (typeof scope.applyFilter === 'function') {
            scope.applyFilter({
              key: key,
              operator: operator,
              type: type,
              value: value
            });
          }
        }

        function clearFilterInputs() {
          scope.value = null;
          scope.valueTextInput.model = null;
          scope.operatorSelect.model = null;
          scope.columnSelect.model = null;
        }

        scope.$watch('filter', function(filter) {
          if (!filter) {
            return;
          }
          scope.columnSelect.model = filter.key;
          scope.operatorSelect.model = filter.operator;
          scope.value = filter.value;
          scope.valueTextInput.model = filter.value;
          scope.valueDateInput.model = filter.value;
        });

        var filterOpenedTimeout = false;
        scope.$watch('filters.open', function (new_val, old_val) {
          if (filterOpenedTimeout) {
            $timeout.cancel(filterOpenedTimeout);
          }
          if (!old_val && new_val) {
            filterOpenedTimeout = $timeout(function() {
              scope.opened = true;
            }, 300); // filter opening animation takes 0.3 s
          } else {
            scope.opened = false;
          }
        });

        scope.$on('$destroy', function() {
          if (filterOpenedTimeout) {
            $timeout.cancel(filterOpenedTimeout);
          }
        });
      }
    }
  }
]);
