nwuiInputTextarea.directive('nwuiInputTextarea', [
	'$sce', 
	'$timeout', 
	'NwuiInitModuleFactory',
	'NwuiFormService', 
	'NwuiInputService', 
	'NwuiValidationService', 
	'NwuiValidationErrorService', 
	function(
		$sce, 
		$timeout, 
		NwuiInitModuleFactory,
		NwuiFormService, 
		NwuiInputService, 
		NwuiValidationService, 
		NwuiValidationErrorService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			require: '?^nwuiFormV3',
			templateUrl: '/input/textarea/template.html',
			scope: {
				step_index: '=stepIndex',
				form_name: '=formName',
				model: '=',
				id: '=',
				label: '=',
				label_i18n: '=labelI18n',
				post_var: '=postVar',
				required: '=',
				placeholder: '=',
				placeholder_i18n: '=placeholderI18n',
				error: '=',
				size: '=',
				color: '=',
				validators: '=',
				notification: '=',
				hide: '=',
				enter_submit: '='
			},
			link: function(scope, element, attrs, nwuiFormV3Ctrl) {
				
				scope = NwuiInitModuleFactory({
					model: undefined, //req
					id: undefined, //req
					label: undefined,
					required: true,
					placeholder: '',
					post_var: undefined,
					size: '',
					color: '',
					validators: [],
					error: false,
					notification: {}
				}, scope);

				if(nwuiFormV3Ctrl)
					nwuiFormV3Ctrl.registerInput(scope.step_index, scope);
				else if(angular.isDefined(scope.form_name))
					NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);

				var locks = {
					icon: undefined   
				}

				var clear = function() {

					//console.log('nwuiInputTextV2', 'clear', scope.model, '=>', scope.default_value);
					scope.model = '';
					scope.error = false;
					if(!scope.notification) scope.notification = {};
					scope.notification.show = false;

				}
				
				var validate = function() {
					
					var valid = true;
					
					if(scope.required)
						valid = NwuiValidationService['blank'](scope.model);
					
					scope.validators.forEach(function(validator,i) {
						if(valid)
							valid = NwuiValidationService[validator](scope.model);
					});
				
					if(!valid) {
						scope.error = true;
						scope.notification.error = true;
						scope.notification.text = $sce.trustAsHtml(NwuiValidationErrorService['blank'](scope.label));
						scope.notification.show = true;
					}
					
					return valid;
					
				}
				
				NwuiInputService.register(scope, validate, clear);
				
				scope.keyPress = function(event) {
					
					if(event.which === 13 && scope.enter_submit) {
						if(nwuiFormV3Ctrl)
							nwuiFormV3Ctrl.enterKeySubmit(scope.id, event);
						else
							NwuiFormService.enterKeySubmit(scope.id, event);
					} else
						scope.clearError();

					if(nwuiFormV3Ctrl)
						nwuiFormV3Ctrl.setDirty(scope.id);
					else
						NwuiFormService.setDirty(scope.id);
					
				}
				
				scope.clearError = function() {

					scope.error = false;
					scope.notification.show = false;
					
				}
				
			}
		}
	}
]);