nwuiDrawerV1NotificationText.factory('NwuiDrawerV1NotificationTextFactory', [function() {
	
	var defaults = {
		lead: undefined, //req
		icon: undefined,
		lead_i18n: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiDrawerV1NotificationTextFactory");

		angular.extend(this, defaults, obj);
	}
	
}]);