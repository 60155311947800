nwuiCardTableV3BodyRowColumnToggle.directive('nwuiCardTableV3BodyRowColumnToggle', ['$timeout', 'NwuiInputToggleFactory', function($timeout, NwuiInputToggleFactory) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/card/table/v3/body/row/column/toggle/template.html',
		scope: {
			toggle: '='
		},
		link: function(scope, element, attrs) {
			
			var lock = false;
			
			scope.$watch('toggle', function(new_val) {
				
				if(new_val === undefined) return;

				new_val.size = 'short'; //override

				if(lock) return;
					
				lock = true;
				
				scope.toggle = new NwuiInputToggleFactory(new_val);
				
				$timeout(function() {
					lock = false;    
				}, 0);
				
			});
			
		}
	}
}]);