nwuiAccordion.directive('nwuiAccordion', [
	'$window', 
	'$timeout', 
	'$sce', 
	'NwuiInitModuleFactory',
	'NwuiIconV2Factory',
	'NwuiTargetFactory', 
	function(
		$window, 
		$timeout, 
		$sce, 
		NwuiInitModuleFactory,
		NwuiIconV2Factory,
		NwuiTargetFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/accordion/template.html',
			scope:{
				group: '<?',
				name: '<',
				name_i18n: '<nameI18n',
				name_i18n_data: '<nameI18nData',
				color: '<?',
				expanded: '=?',
				button: '<?',
				target: '<?'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 

						if(angular.isUndefined(scope.expanded)) scope.expanded = false;
						if(angular.isUndefined(scope.color)) scope.color = '';

						scope.no_transition = false;
						scope.accordion_style = {}

					},
					post: function(scope, element, attrs) {

						//locks
						var locks = {
							target: false,
							button: false
						}
						
						//saving selections for later
						var container = $(element).find('.bottom');
						var contents = $(element).find('.bottom > .inner');
						
						scope.toggle = function() {

							//disable initial transition for height set (safari fix)
							scope.no_transition = true;

							scope.accordion_style = {
								height: scope.expanded
									? contents.outerHeight(true) + 'px'
									: 0
							};
							
							//reenable transition for animating to new height (safari fix)
							$timeout(function() {
								scope.no_transition = false;
								
								//toggle expanded
								scope.expanded = !scope.expanded;
								
								//set new height
								$timeout(function() {
									scope.accordion_style = scope.expanded
										? {}
										: {height: 0}
								}, 50);

							}, 100);
							
						}
						
						//window resize event
						window.onresize = function() {
							scope.$apply();
						};
						
						scope.$watch(function() {
							return angular.element($window)[0].innerWidth;
						}, function() {
							//set height back to auto on window resize/rotate to account for dynamic height change
							scope.accordion_style = {};
							
							//safari fix
							scope.no_transition = true;
							$timeout(function() {
								scope.no_transition = false;
							}, 50);
						});
						
						scope.$watch('name', function(new_val) {
							
							if(new_val === undefined) return;
							scope.safe_name = $sce.trustAsHtml(new_val);
							
						})
						
						scope.$watch('target', function(new_val, old_val) {
							
							if(new_val !== undefined && !locks.target) {
								
								locks.target = true;
								
								scope.target = new NwuiTargetFactory(scope.target);
								
								$timeout(function() {
									locks.target = false;    
								}, 100);
							
							}
							
						});
						
					}
				}
			}
		}
	}
]);