nwuiToastManager.directive('nwuiToastManager', [
	'NwuiToastManagerService',
	function(
		NwuiToastManagerService
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/toast/manager/template.html',
	        scope: {},
	        link: function(scope, element, attrs) {
		        
		        scope.toasts = NwuiToastManagerService.toasts;
		        
		        scope.$watch('toasts', function(new_val) {
			        
			        //console.log('toasts', new_val)
			        
		        });
		        
	        }
		}
	}
]);