angular.module('nwui-signing.factories', [])

.factory('NwuiSigningFactory', [function() {
	
	var defaults = {
		id: undefined, //req
		signingUrlResource: undefined, //req
		signingPollResource: undefined, //req
		open: false,
		envelope_id: undefined,
		submitCb: function() {},
		errorCb: function() {},
		allowCancel: false,
		styleForNav: false
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiSigningFactory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiSigningFactory");
		if(obj.signingUrlResource === undefined) return console.error("Missing required instantiation object variable 'signingUrlResource' in NwuiSigningFactory");
		if(obj.signingPollResource === undefined) return console.error("Missing required instantiation object variable 'signingPollResource' in NwuiSigningFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);