nwuiCardTable.service('NwuiCardTableService', ['NwuiCardTableFactory', function(NwuiCardTableFactory) {
	
	var self = this;
	this.tables = {};
	
	this.register = function(name, obj) {
		self.tables[name] = obj;
		return self.tables[name];
	}
    
}]);