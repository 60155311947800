angular.module('shared-services').service('DataSharingService',[function(){
    this.obj = {};
    this.createVariable = function (key){
        if(!Object.keys(this.obj).includes(key)){
            this.obj[key] = {};
        }
    }
    this.updateValue = function (objectKey, childKey, value){
        if(Object.keys(this.obj).includes(objectKey)){
            this.obj[objectKey][childKey] = value;
        }
    }
    this.getValue = function(key, childKey){
        if(Object.keys(this.obj).includes(key)){
            if(Object.keys(this.obj[key]).includes(childKey)){
                return this.obj[key][childKey];
            }
            return null;
        }
        return null;
    }
    this.getObject = function(objectKey){
        return (Object.keys(this.obj).includes(objectKey)) ? this.obj[objectKey] : null; 
    }
    this.getTotal = function(objectKey){
        var total = 0;
        var keys = Object.keys(this.obj[objectKey]);
        for(var key in keys){
            total += parseInt(!isNaN(this.obj[objectKey][keys[key]]) ? this.obj[objectKey][keys[key]] : 0);
        }
        return total;
    }
    this.removeChild = function(objectKey, childKey){
        delete this.obj[objectKey][childKey];
    }
}]);
