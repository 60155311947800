nwuiInputDropList.factory('NwuiInputDropListFactory', [function() {
	
	var defaults = {
		active: false,
		item_map: undefined, //req
		items: [],
		tab_index: 0,
		loading: false,
		onSelectCb: function() {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputDropListFactory");
		if(obj.item_map === undefined) return console.error("Missing required instantiation object variable 'item_map' in NwuiInputDropListFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);