nwuiCompoundModalUpdateAdmin.directive('nwuiCompoundModalUpdateAdmin', [
	'$timeout',
	'$stateParams',
	'NwuiModalV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardTableFactory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	'NwuiFormV3Factory',
	'NwuiInputFactory',
	
	'OrganizationV2Service',
	'NwButtonService',
	'OnboardingService',
	'NwuiToastManagerService',
	'OrganizationService',
	'NwuiFormV3Service',
	'NwuiInputService',

	'locale',
	function(
		$timeout,
		$stateParams,
		NwuiModalV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiCardTableFactory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		NwuiFormV3Factory,
		NwuiInputFactory,

		OrganizationV2Service,
		NwButtonService,
		OnboardingService,
		NwuiToastManagerService,
		OrganizationService,
		NwuiFormV3Service,
		NwuiInputService,

	  locale
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/update-admin/template.html',
			scope: {
				user: '=user',
				open: '=',
				roles: '=roles',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				scope.send_email = false
				var current_language = locale.getLocale()
				var org_role_options = [];

				var submitCb = function(success) {
					scope.submitCb(success);
				}
				
				var loadRoleOptions = function(){
					var org_roles = [];
					scope.organization.Role.forEach(function(role, i){
						org_roles.push({
							id: i, 
							text: role.name,
							value: role.name
						})
					})
				}

				// var loadFormColumns = function() {

				// 	// scope.modals.update_admin.form.steps.columns = []

				// }

				scope.modals = {
					update_admin: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.update_admin.header',
							icon: {
								name: 'person_add'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'update-admin-user-modal-form',
							onSubmit: function(post_data) {
								
								var role = post_data.role.name;
								var send_email = true; //NwuiInputService.inputs['update-admin-form-role-send-email'].model[0].value ? true : false;

								return OrganizationService.createAdministrator(post_data, role, send_email);

							},
							onComplete: function(){
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.update_admin.messages.success',
										icon: {
											name: 'check'
										}
									}
								));

								submitCb(true);
								scope.open = false;
							},
							onError: function(res) {

								var message = {
									icon: {
										name: 'error',
										color: 'error'
									}
								};

								if(typeof res.data.message != 'undefined')
									message.text = res.data.message;
								else
									message.text_i18n = 'nwui.modal.update_admin.messages.failure';

								NwuiFormV3Service.showMessage('update-admin-user-modal-form', message);

							},
							steps: [{
								columns: [{
								  width: 40,
									directive: 'input',
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.update_admin.labels.first_name',
											model: '', //(scope.user !== undefined) ? scope.user.first_name : '',
											id: 'update-admin-first-name-form-column',
											placeholder_i18n: 'nwui.modal.update_admin.labels.first_name_placeholder',
											disabled: false, //(scope.user !== undefined && scope.user.first_name) ? true : false,
											required: true,
											post_var: "first_name"
										}
									}
							  }, {
									width: 60,
									directive: 'input',
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.update_admin.labels.last_name',
											model: '', //(scope.user !== undefined) ? scope.user.last_name : '',
											id: 'update-admin-last-name-form-column',
											placeholder_i18n: 'nwui.modal.update_admin.labels.last_name_placeholder',
											disabled: false, //(scope.user !== undefined && scope.user.last_name) ? true : false,
											required: true,
											post_var: "last_name"
										}
									}
							  }, {
									width: 60,
									directive: 'input',
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.update_admin.labels.email',
											model: '', //(scope.user !== undefined) ? scope.user.email : '',
											id: 'update-admin-email-form-column',
											placeholder_i18n: 'nwui.modal.update_admin.labels.email_placeholder',
											validators: ['email'],
											disabled: false, //(scope.user !== undefined && scope.user.email) ? true : false,
											required: true,
											post_var: "email"
										}
									}
							  },
								{
									width: 40,
									directive: 'input',
									data : {
										directive: 'select',
										data: {
											model: '', //(scope.user !== undefined) ? scope.user.Role.name : '',
											label_i18n: 'nwui.modal.update_admin.labels.role',
											id: 'update-admin-form-role-select',
											placeholder_i18n: 'nwui.modal.update_admin.labels.role_placeholder',
											options: org_role_options,
											required: true,
											post_var: 'role',
											onChange: function(model) {
												if(model.is_advisor_code_required) {
													NwuiInputService.inputs['update-admin-advisor-code-form-column'].placeholder_i18n = 'nwui.modal.update_admin.labels.required';
													NwuiInputService.inputs['update-admin-advisor-code-form-column'].required = true;
												}else{
													NwuiInputService.inputs['update-admin-advisor-code-form-column'].placeholder_i18n = 'nwui.modal.update_admin.labels.optional';
													NwuiInputService.inputs['update-admin-advisor-code-form-column'].required = false;
												}
												//console.log('nwuiCompoundModalUpdateAdmin', 'select', 'onChange cb', model.id);

												if(model) {

													OrganizationV2Service.getAssignableUsers($stateParams.org_slug, model.id).then(function(obj) {
														//console.log('nwuiCompoundModalUpdateAdmin', 'OrganizationV2Service.getAssignableUsers', obj.data);

														if(obj.data && obj.data.length > 0) {
															NwuiInputService.inputs['update-admin-form-assign-to-select'].disabled = false;
															NwuiInputService.inputs['update-admin-form-assign-to-select'].placeholder_i18n = 'nwui.modal.update_admin.labels.user_placeholder';

															NwuiInputService.inputs['update-admin-form-assign-to-select'].options = obj.data.sort(function(user_a, user_b) {
																if (user_a.last_name.toLowerCase() < user_b.last_name.toLowerCase()) { return -1; }
																if (user_a.last_name.toLowerCase() > user_b.last_name.toLowerCase()) { return 1; }
																return 0;
															}).map(function(user,i) {
																return {
																	id: i,
																	text: user.last_name + ', ' + user.first_name,
																	value: user.uuid
																}
															})
														} else {
															NwuiInputService.inputs['update-admin-form-assign-to-select'].disabled = true;
															NwuiInputService.inputs['update-admin-form-assign-to-select'].placeholder_i18n = 'nwui.modal.update_admin.labels.not_applicable';
															NwuiInputService.inputs['update-admin-form-assign-to-select'].options = [];
														}

														NwuiInputService.inputs['update-admin-form-assign-to-select'].model = -1;
													});

												}
											}
										}
									}
								},
								{
									width: 50,
									directive: 'input',
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.update_admin.labels.advisor_code',
											model: '', //(scope.user !== undefined && scope.user.advisor_code) ? scope.user.advisor_code : '',
											id: 'update-admin-advisor-code-form-column',
											placeholder_i18n: 'nwui.modal.update_admin.labels.optional',
											disabled: false, //(scope.user !== undefined && scope.user.advisor_code) ? true : false,
											required: false,
											post_var: 'advisor_code'
										}
									}
							  }, {
									width: 50,
									directive: 'input',
									data : {
										directive: 'select',
										data: {
											model: '',
											label_i18n: 'nwui.modal.update_admin.labels.assign_to',
											id: 'update-admin-form-assign-to-select',
											placeholder_i18n: 'nwui.modal.update_admin.labels.not_applicable',
											options: [],
											post_var: 'ancestor_uuid',
											required: false,
											disabled: true,
											onChange: function(model) {

											}
										}
									}
								}, {
										width: 50,
										directive: 'input',
										hidden: (
											!organization_data.join_data ||
											!organization_data.join_data.localization ||
											organization_data.join_data.localization.length <= 1
										),
										data: {
											directive: 'select',
											data: {
												label_i18n: 'nwui.common.input.preferred_language.label',
												placeholder_i18n: 'nwui.common.input.preferred_language.placeholder',
												default_value: current_language,
												model: current_language,
												required: (
													organization_data.join_data &&
													organization_data.join_data.localization
												),
												id: 'update-admin-form-language-select',
												post_var: 'language',
												options: (
													organization_data.join_data &&
													organization_data.join_data.localization
												) ? organization_data.join_data.localization.map(function(lang, i) {
													return {
														id: i,
														text_i18n: lang.label,
														value: lang.language
													}
												}) : []
											}
										}
								}, {
									width: 50,
									directive: 'input',
									data : {
										directive: 'checkbox',
										data: {
											label: '',
											id: 'update-admin-form-role-send-email',
											options: [{
												label_i18n: 'nwui.modal.update_admin.labels.send_email',
												model: scope.send_email,
												value: true,
												default_value: true
											}],
									  }
									}
								}]
							}]
						})
					})
				}

					
				scope.$watch('open', function(new_val) {
					
					//console.log('open', new_val);
					if(new_val === undefined) return;
					
					scope.modals.update_admin.open = new_val;

					if(new_val === true) {
						scope.send_email = false;
					} else {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.update_admin.form.name);
						}, 750);
					}

				});
			
				scope.$watch('roles', function(new_val, old_val) {

					if(new_val === undefined || new_val[0] === undefined) return;

					NwuiInputService.inputs['update-admin-form-role-select'].options = new_val.map(function(role, i){
						return {
							id: i,
							text: role.name,
							value: role
						}
					})
		
				});
				
			}
		}
	}
]);