nwuiBreadcrumbs.factory('NwuiBreadcrumbsFactory', [function() {
	
	var defaults = {
		root: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiBreadcrumbsFactory");
		//if(obj.xx === undefined) return console.error("Missing required instantiation object variable 'xx' in NwuiBreadcrumbsFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);

nwuiBreadcrumbs.factory('NwuiBreadcrumbsItemFactory', [function() {
	
	var defaults = {
		label: undefined, //req
		label_i18n: undefined,
		state: undefined, //req
		params: {}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiBreadcrumbsItemFactory");
		if(obj.label === undefined) return console.error("Missing required instantiation object variable 'label' in NwuiBreadcrumbsItemFactory");
		if(obj.state === undefined) return console.error("Missing required instantiation object variable 'state' in NwuiBreadcrumbsItemFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);