nwuiProgressBar.directive('nwuiProgressBar', [
	'$timeout', 
	'$sce', 
	
	'NwuiProgressBarItemFactory',
	function(
		$timeout,
		$sce,
		
		NwuiProgressBarItemFactory
	) {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: '/progress-bar/template.html',
	        scope:{
		        percent: '=',
		        color: '=',
		        invert: '=',
		        item_l: '=itemL',
		        item_r: '=itemR'
	        },
	        link: function(scope, element, attrs) {
		        
		        var locks = {
			        items: false
		        }
		        
		        scope.fill_style = {};
		        
		        scope.$watchGroup(['item_l', 'item_r'], function(new_val) {
			       
			    	if(new_val[0] === undefined || new_val[1] === undefined || locks.items) return;
			    	
			    	locks.items = true;
			    	
			    	//console.log('item_r', new_val[1].value);
			    	
			    	scope.item_l = new NwuiProgressBarItemFactory({
				    	label: new_val[0].label,
				    	value: new_val[0].value
			    	})
			    	
			    	scope.item_r = new NwuiProgressBarItemFactory({
				    	label: new_val[1].label,
				    	value: new_val[1].value,
				    	value_safe: (typeof new_val[1].value === 'string') ? $sce.trustAsHtml('<span>' + new_val[1].value + '</span>') : new_val[1].value
			    	})
			    	
			    	$timeout(function() {
				    	locks.items = false;	
			    	}, 150);
			        
		        });
		        
		        scope.$watchGroup(['percent', 'invert', 'color'], function(new_val) {
			        
			        if(new_val[0] === undefined) return;
			        
			        scope.fill_style.width = new_val[0] + '%';
			        
		        })
		        
	        }
		}
	}
]);