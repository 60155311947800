nwuiProgressBar.factory('NwuiProgressBarFactory', ['NwuiProgressBarItemFactory', function(NwuiProgressBarItemFactory) {
	
	var defaults = {
		item_l: undefined, //req
		item_r: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiProgressBarFactory");
		if(obj.item_l === undefined) return console.error("Missing required instantiation object variable 'item_l' in NwuiProgressBarFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);

nwuiProgressBar.factory('NwuiProgressBarItemFactory', [function() {
	
	var defaults = {}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiProgressBarItemFactory");
	//if(obj.label === undefined) return console.error("Missing required instantiation object variable 'label' in NwuiProgressBarItemFactory");
		if(obj.value === undefined) return console.error("Missing required instantiation object variable 'value' in NwuiProgressBarItemFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);