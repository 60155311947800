nwuiInput.factory('NwuiInputFactory', [function() {
	
	var defaults = {
		directive: undefined, //req
		form_name: undefined,
		data: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiInputFactory");
		if(obj.directive === undefined) return console.error("Missing required instantiation object variable 'directive' in NwuiInputFactory");
		if(obj.data === undefined) return console.error("Missing required instantiation object variable 'data' in NwuiInputFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);