nwuiModalBodyInfo.factory('NwuiModalInfoFactory', [function() {
	
	var defaults = {
		name: undefined, //req
		locked: false,
		open: false,
		wide: false,
		close_text: 'close'
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object for NwuiModalFactory");
		if(!obj.name) return console.error("Missing required instantiation object variable: 'object.name'");
		
		angular.extend(this, defaults, obj);
	}
	
}]);