nwuiCardTableV3BodyRowColumnHtml.directive('nwuiCardTableV3BodyRowColumnHtml', ['$sce', function($sce) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/v3/body/row/column/html/template.html',
        scope: {
	        html: '='
        },
        link: function(scope, element, attrs) {
	        
	        scope.$watch('html', function(new_val) {
		       
		    	if(new_val !== undefined) {
			    	
			    	scope.html_sce = new_val;
			    	
		    	}
		        
	        });
	        
        }
	}
}]);