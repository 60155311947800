nwuiDrawerV1.factory('NwuiDrawerV1Factory', [function() {
	
	var defaults = {
		id: undefined, //req
		open: false,
		is_empty: false,
		empty_text: '',
		empty_text_i18n: '',
		empty_icon: {},
		orientation: 'left',
		name: undefined,
		icon: undefined,
		z_index: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiDrawerV1Factory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiDrawerV1Factory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);