nwuiChartHorizontalBar.factory('NwuiChartHorizontalBarFactory', [function() {
	
	var date = new Date();
	
	var defaults = {
		threshold: 3,
		series: []
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiChartHorizontalBarFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);