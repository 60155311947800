nwuiCardTableBodyRowColumnSymbol.directive('nwuiCardTableBodyRowColumnSymbol', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/body/row/column/symbol/template.html',
        scope: {
	        text: '='
        },
        link: function(scope, element, attrs) {}
	}
}]);