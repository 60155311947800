nwuiCompoundModal.directive('nwuiCompoundModalArchiveEsign', [
	'$timeout',
	
	'NwuiModalFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardTableFactory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	
	'NwButtonService',
	'UserService',
	'NwuiToastManagerService',
	'EsignDataService',
	function(
		$timeout,
		
		NwuiModalFactory,
		NwuiCardHeaderV2Factory,
		NwuiCardTableFactory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		
		NwButtonService,
		UserService,
		NwuiToastManagerService,
		EsignDataService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/archive-esign/template.html',
			scope: {
				document: '=document',
				open: '=',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var submitCb = function(is_archived) {
					scope.submitCb(is_archived);
				}
				
				scope.$watch('open', function(new_val) {
					
					//console.log('open', new_val);
					if(new_val === undefined) return;
					
					scope.modals.archive_esign.open = new_val;
					
				});
				
				scope.modals = {
					archive_esign: new NwuiModalFactory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.archive_esign.title',
							icon: {
								name: 'archive'
							}
						}),
						copy: {
							buttons: {
								yes: new NwuiButtonV3Factory({
									id: 'archive-esign-button',
									text_i18n: 'nwui.modal.archive_esign.buttons.yes',
									color: '',
									icon: {
										name: 'check'
									},
									color: '',
									stretch: true,
									onClick: function() {
										
										NwButtonService.buttons['archive-esign-button'].processing = true;
										var doc_id = scope.document._id;

										EsignDataService.archiveEsignEvent(scope.document.user.uuid, scope.document._id, true).then(function(obj) {

											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: 'nwui.modal.archive_esign.success',
													icon: {
														name: 'check'
													}
												}
											));
											
											NwButtonService.buttons['archive-esign-button'].processing = false;
											
											scope.open = false;
											
											submitCb(true);
											
										}, function() {

											NwuiToastManagerService.register(
												new NwuiToastFactory({
													type: 'error',
													text_i18n: 'nwui.modal.archive_esign.error',
													icon: {
														name: 'close'
													}
												}
											));

											NwButtonService.buttons['archive-esign-button'].processing = false;
											
											scope.open = false;

											submitCb(true);

										});
								
									}
								}),
								no: new NwuiButtonV3Factory({
									id: 'cancel-archive-esign-button',
									text_i18n: 'nwui.modal.archive_esign.buttons.no',
									color: '',
									icon: {
										name: 'close'
									},
									color: 'error',
									stretch: true,
									onClick: function() {
										
										scope.open = false;
										
										submitCb(false);
								
									}
								})
							}
						}
					})
				}
	
			}
		}
	}
]);