nwuiCompoundModalCreateNotification.directive('nwuiCompoundModalCreateNotification', [
	'$timeout',
	'$filter',
	'$stateParams',
	'$state',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	
	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwuiRegisterService',
	'DATE_FORMAT',
	function(
		$timeout,
		$filter,
		$stateParams,
		$state,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		
		NwuiToastManagerService,
		NwuiInputService,
		NwuiFormV3Service,
		NwuiRegisterService,
		DATE_FORMAT
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/create-notification/template.html',
			scope: {
				open: '=',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {

				var uuid = NwuiRegisterService.register();

				var submitCb = function(data) {
					scope.submitCb(data);
				}
				
				scope.modals = {
					create_notification: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.send_notification.title',
							icon: {
								name: 'send'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'create-notification-form-' + uuid,
							onSubmit: function(post_data) {

								//console.log('nwuiCompoundModalCreateNotification', 'form post_data', post_data);
								return scope.formResource(post_data);
								
							},
							onComplete: function(obj) {

								submitCb(obj.data);
								scope.open = false;

								if(obj.data.error) {
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.modal.send_notification.toasts.success',
											icon: {
												name: 'clear'
											}
										}
									));
								} else {
									//ERROR
								}

							},
							steps: [
								{
									columns: [{
										directive: 'input',
										width: 100,
										data: {
											directive: 'textarea',
											data: {
												model: '',
												id: 'create-notification-form-text-' + uuid,
												label_i18n: 'nwui.modal.send_notification.inputs.message.label',
												placeholder_i18n: 'nwui.modal.send_notification.inputs.message.placeholder',
												required: true,
												post_var: 'message'
											}
										}
									}, 
									// {
									// 	directive: 'input',
									// 	width: 100,
									// 	data: {
									// 		directive: 'checkbox',
									// 		data: {
									// 			label: 'Send notification to:',
									// 			type: 'inline',
									// 			model: [],
									// 			id: 'create-notification-form-notification-methods-' + uuid,
									// 			stretch: true,
									// 			required: true,
									// 			options: [{
									// 				label: "Notification panel",
									// 				model: "notification_panel",
									// 				value: true,
									// 				default_value: true,
									// 				required: false
									// 			}, {
									// 				label: "Email",
									// 				model: "email",
									// 				value: true,
									// 				default_value: true,
									// 				required: false
									// 			}],
									// 			size: '',
									// 			post_var: 'notification_methods'
									// 		}
									// 	}
									// }
								]
							}]
						})
					})
				}
				
				scope.$watch('open', function(new_val, old_val) {
					
					//console.log('nwuiCompoundModalCreateNotification', 'watch open', old_val, '=>', new_val);

					if(new_val === undefined) return;
					
					if(new_val === false) {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.create_notification.form.name);
						}, 750)
					}

					scope.modals.create_notification.open = new_val;
					
				});

			}
		}
	}
]);