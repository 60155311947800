nwuiCardTableBodyRowColumn.directive('nwuiCardTableBodyRowColumn', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/body/row/column/template.html',
        scope: {
        	column: '=',
	        width: '=',
	        directive: '=',
	        clickEvent: '='
        },
        link: function(scope, element, attrs) {
			
			scope.$watch('width', function(new_val) {
				if(new_val) {
					scope.style = {
						width: new_val + 'px'
					}
				}
			});
			
	    }
	}
}]);