nwuiChart.directive('nwuiChart', ['$timeout', 'NwuiChartDonutV2Factory', function($timeout, NwuiChartDonutV2Factory) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/chart/template.html',
        scope: {
	        directive: '=',
	        data: '='
        },
        link: function(scope, element, attrs) {
	        
	        var locks = {
		        data: false
	        }
	        
	        scope.$watchGroup(['directive', 'data'], function(new_val) {
		        
		        if(new_val[0] !== undefined && new_val[1] !== undefined && new_val[1].series.length > 0 && !locks.data) {
				    
				    locks.data = true;
				    
			        switch(new_val[0]) {
				        case 'donut':
				        	scope.data = scope.donut = new NwuiChartDonutV2Factory(new_val[1]);
				        	break;
			        }
			        
			        $timeout(function() {
						locks.data = false;    
				    }, 100);
			        
		        }
		        
	        });
	        
        }
	}
}]);