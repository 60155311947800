nwuiCompoundClientAddress.factory('NwuiCompoundClientAddressFactory', [function() {
	
	var defaults = {
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundClientAddressFactory");
		//if(obj.user === undefined) return console.error("Missing required instantiation object variable 'user' in NwuiCompoundClientActivitiesFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);