//select input directive
nwuiInputSelect.directive('nwuiInputSelect', ['$timeout', 'NwuiInputSelectService', function($timeout, NwuiInputSelectService) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/input/select/template.html',
        scope: {
	        options: '=',
	        key: '=',
	        selected: '='
        },
        link: function(scope, element, attrs) {
	        
	        var block = false;
			
			scope.loaded = false;
	        scope.active = false;
	        scope.style_outer = {};
	        scope.style_inner = {};
	        scope.selected_text = {
		        val: '',
		        one: '',
		        two: '',
		        toggle: false
	        }
	        
	        //change select input value
	        scope.change = function(selected) {
		        
		        if(!scope.selected) {
			        scope.selected = (scope.key) 
			        	? { key: '' }
			        	: '';
		        }
		        
		        scope.transition = true;
		        
		        scope.style_outer = {
			    	width: $(element).outerWidth(true) + 'px'
		        }
		        
		        scope.style_inner = {
			        position: 'absolute'
			    }
		        
		        //update ui text
		        scope.selected_text.val = (scope.key) 
		        	? selected[scope.key]
		        	: selected;
		        
		        scope.selected_text.toggle = (scope.selected_text.toggle) ? false : true;
		        
		        if(scope.selected_text.toggle)
				    scope.selected_text.one = scope.selected_text.val;
			    else
				    scope.selected_text.two = scope.selected_text.val;
				    	
		        //update selected item object
		        scope.selected = selected;
		        
		        $timeout(function() {
			        scope.style_outer = {
				        width: $(element).children('.inner').width() + 2 + 'px'
				    }
			    	
				    //console.log('$(element).children(\'.inner\').width()', $(element).children('.inner').width() + 2)
		        }, 30);
		        
		        $timeout(function() {
			        scope.style_inner = {}
			        scope.style_outer = {}
			        scope.transition = false;
			    }, 750);
		        
	        };
	        
	        //close the select
	        scope.close = function(apply) {
		        
		        //prevent closing on active element
		        if(block) return;
		        
		        scope.active = false;
		        
		        //only apply on call coming from outside scope
		        if(apply)
			        scope.$apply();
			        
	        }
	        
	        //toggle open/closed the select
	        scope.toggle = function() {
		        
		        //block this select for notify 
		        block = true;
				scope.active = (scope.active) ? false : true;
				
				//notify to close all open selects
				NwuiInputSelectService.notify();
				
				//timing issue fix for local/global notify
				$timeout(function() {
					block = false;
				}, 0)
	        }
	        
	        //register select close with the select service
	        NwuiInputSelectService.register(scope.close);
	        
	        $timeout(function() {
		        //watch for updates to the select box content
		        scope.$watchGroup(['options', 'selected'], function(new_val) {
			        
			        //if options array not empty
					if(new_val[0] && new_val[1]) {
						scope.loaded = true;
					} else {
						scope.loaded = false;
					}
					
		        });
		        
		        scope.$watch('selected', function(new_val) {
			        if(new_val) {
				        
				        if(scope.options) { 
					        
					        if(scope.options.length > 0 && scope.options.indexOf(new_val) != -1) {
					        	scope.change(new_val);
						    } 
						    else if(typeof scope.options === 'object') {
								
								for(key in scope.options) {
									if(scope.options[key] == new_val)
										scope.change(new_val);
								}
								
							}
				        	
				        }
				        
			        }
		        });
		    }, 0);
	        
        }
	}
}]);