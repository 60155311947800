nwuiBankVerificationsStatus.directive('nwuiBankVerificationsStatus', [
  '$sce',
  function(
    $sce
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/bank-verifications/status/template.html',
      scope: {
        status: '=',
        error: '=',
        signCb: '=',
        tryAgainCb: '=',
        manualCb: '='
      },
      link: function($scope, element, attrs) {
        $scope.onClickSign = function() {
          if (typeof $scope.signCb === 'function') {
            $scope.signCb();
          }
        }

        $scope.onClickManual = function() {
          if (typeof $scope.manualCb === 'function') {
            $scope.manualCb();
          }
        }

        $scope.onClickTryAgain = function() {
          if (typeof $scope.tryAgainCb === 'function') {
            $scope.tryAgainCb();
          }
        }


        $scope.errorIcon = {
          name: 'close',
          color: 'error'
        }

        $scope.$watch('error', function(error) {
          $scope.errorTextI18n = null;
          if (error) {
            $scope.errorTextI18n = getErrorTextI18n(error);
          }
        });

        function getErrorTextI18n(error) {
          if (error.data && error.data.nw_error_code === 4813) {
            return 'nwui.bank_verification.status.error.account_exists';
          }
          if (error.data && error.data.nw_error_code == 4810) {
            return 'nwui.bank_verification.status.error.missing_information';
          }
          return 'nwui.bank_verification.status.error.generic';
        }
      }
    }
  }
]);