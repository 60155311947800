nwuiTooltip.directive('nwuiTooltip', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/tooltip/template.html',
        scope:{
	        invert: '=',
	        show: '='
        },
        link: function(scope, element, attrs) {
			
			element[0].onclick = function(e) {
				e.stopPropagation();
			}
			
			scope.loaded = true;
			
	    }
	}
}]);