nwuiCardTableV3BodyRowColumnCurrencyDelta.directive('nwuiCardTableV3BodyRowColumnCurrencyDelta', ['$filter', function($filter) {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/v3/body/row/column/currency-delta/template.html',
        scope: {
	        number: '='
        },
        link: function(scope, element, attrs) {
	        
	        scope.$watch('number', function(new_val) {
		        
		        if(new_val !== undefined) {
			        
			        scope.number_text = $filter('NwuiCurrencyWithDelta')(new_val);
			        
		        }
		        
	        })
	        
        }
	}
}]);