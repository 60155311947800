nwuiRecommendation.factory('NwuiRecommendationFactory', [function() {
	
	var defaults = {
		text: undefined, //req,
		text_i18n: undefined,
		text_i18n_data: undefined,
		type: '',
		link: undefined,
		icon: undefined,
		button: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiRecommendationFactory");
		//if(obj.text === undefined) return console.error("Missing required instantiation object variable 'text' in NwuiRecommendationFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);