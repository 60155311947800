nwuiIconV2.directive('nwuiIconV2', [
	'$timeout',
	'NwuiInitModuleFactory',
	function(
		$timeout,
		NwuiInitModuleFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/icon/v2/template.html',
			scope: {
				name: '<',
				size: '<',
				active: '<',
				clickEvent: '&',
				tooltip: '=',
				color: '<'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 

						if(angular.isUndefined(scope.name)) scope.name = 'help';
						if(angular.isUndefined(scope.size)) scope.size = '';
						if(angular.isUndefined(scope.clickEvent)) scope.clickEvent = function() {};
						if(angular.isUndefined(scope.active)) scope.active = false;
						if(angular.isUndefined(scope.color)) scope.color = '';

					},
					post: function(scope, element, attrs) {

						scope.showTooltip = function() {
							
							if(!scope.tooltip) return;
							
							scope.tooltip.show = true;
							
						}
						
						scope.hideTooltip = function() {
							
							if(!scope.tooltip) return;
							
							scope.tooltip.show = false;
							
						}

					}
				}
			}
		}
	}
]);