nwuiCardTableV2.directive('nwuiCardTableV2', [
	'$timeout', 
	'$window', 
	'$q',
	
	'NwuiCardTableV2Service', 
	
	'NwuiCardTableV2HeaderColumnFactory',
	'NwuiCardTableV2FooterFactory', 
	function(
		$timeout,
		$window,
		$q,
		
		NwuiCardTableV2Service, 
		
		NwuiCardTableV2HeaderColumnFactory,
		NwuiCardTableV2FooterFactory
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/card/table/v2/template.html',
	        scope: {
		        id: '=',
		        resource: '=',
		        resourceCb: '=resourceCb',
		        rowMap: '=rowMap',
		        empty_text: '=emptyText',
						empty_text_i18n: '=emptyTextI18n',
		        columns: '=',
		        rows: '=',
		        footer: '=',
		        loaded: '=',
						reload: '=?',
						hideFooter: '='
	        },
	        link: function(scope, element, attrs) {
				scope.widths = [];
				
				var reload = function() {
					var deferred = $q.defer();
					
					locks.paging = true;
					scope.loaded = false;
					scope.resource(scope.footer.query, scope.footer.current_page, scope.footer.page_size, scope.footer.sort_by, scope.footer.sort_order).then(function(obj) {
						scope.rows = obj.data.data.map(scope.rowMap);
						scope.footer.total_items = obj.data.page_data.total_items;
						scope.footer.current_page = obj.data.page_data.current_page;
						scope.footer.page_size = obj.data.page_data.page_size;
						scope.footer.sort_by = obj.data.page_data.sort_by;
						scope.footer.sort_order = obj.data.page_data.sort_order;
						scope.footer.sort_options = obj.data.page_data.sort_options;
						
						scope.resourceCb(obj);
						scope.loaded = true;
						locks.paging = false;
						calibrate();
						deferred.resolve(obj);
					});
					
					return deferred.promise;
					
				}
				
				var locks = {
					columns: false,
			        footer: false,
			        paging: false
			    }
			        
				var calibrate = function() {
						
			        scope.widths = scope.columns.map(function(o,i) {
				        ////console.log('o.directive', 'checkbox' == o.directive)
				        if(o.directive == 'checkbox')
					    	o.width = 40;
				        return o.width
			        });
			        
			        var auto_columns = [];
			        var auto_width_allocation = $(element).width() - 1;
			        
			        //count # of auto columns
			        scope.columns.forEach(function(column, i) {
				        
				        //console.log('width col', column.width);
				        
				        if(column.width == 'auto' && column.directive != 'checkbox')
					        auto_columns.push(column);
					    else
					    	auto_width_allocation -= column.width;
					    
			        });
			        
			        //if no columns are auto, manage the extra width in the first column
			        if(auto_columns.length == 0) {
				        
				        var left_over_index = 0;
				        
				        if(scope.columns[0].directive == 'checkbox')
				        	left_over_index++;
				        	
				        auto_columns.push(scope.columns[left_over_index]);
				        
				    }
				        
				    //apply the auto width
			        scope.columns.forEach(function(column, i) {
				        if(column.width == 'auto' && column.directive != 'checkbox')
					    	scope.widths[i] = auto_width_allocation/auto_columns.length;
			        });
			        
		        }
		        
		        $timeout(function() {
			        scope.reload = reload;
				}, 0);
		        
		        window.onresize = function() {
					scope.$apply();
				};
				
				scope.$watch(function() {
					return angular.element($window)[0].innerWidth;
				}, function() {
					calibrate();
				});
				
				scope.$watch('footer', function(new_val) {
					
					if(!locks.footer) {
					    
					    locks.footer = true;
						
						scope.footer.table_id = scope.id;
						scope.footer = new NwuiCardTableV2FooterFactory(scope.footer);
						
						$timeout(function() {
							locks.footer = false;
					    }, 100);
					    
					}
					
				});
				
				scope.$watch('columns', function(new_val) {
					
					////console.log('columns', new_val)
					
					if(new_val !== undefined && !locks.columms) {
						
						locks.columms = true;
						
						var columns = [];
						scope.columns.forEach(function(column,i) {
							columns.push(new NwuiCardTableV2HeaderColumnFactory(column));
						});
						
						scope.columns = columns;
						
						calibrate();
							
						$timeout(function() {
							locks.columms = false;
					    }, 100);
						
					}
					
				});
				
				scope.$watch('rows', function(new_val) {
					
					if(new_val !== undefined) {
						$timeout(function() {
							scope.loaded = true;
							calibrate();
						}, 0);
					}
					
				});
				
				scope.$watchGroup(['resource', 'footer.query', 'footer.current_page', 'footer.page_size'], function(new_val) {
					
					//console.log(new_val);
					
					if(new_val[0] !== undefined && !locks.paging) {
						reload();
					}
					
				});
	
				scope.$watchGroup(['name', 'columns', 'rows'], function(new_val, old_val) {
					
					if(new_val[0] && new_val[1] && new_val[2])
						NwuiCardTableV2Service.register(new_val[0], { columns: new_val[1], rows: new_val[2] });
					
				});
				
		    }
		}
	}
]);