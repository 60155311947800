nwuiCardSpacer.directive('nwuiCardSpacer', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/spacer/template.html',
        scope: {
	        color: '='
        },
        link: function(scope, element, attrs) {
	        
	        
	                
        }
	}
}]);