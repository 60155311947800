nwuiInputLabel.directive('nwuiInputLabel', ['$sce', function($sce) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/input/label/template.html',
		scope: {
			id: '=',
			label: '=',
			label_i18n: '=labelI18n',
			sub_label_i18n: '=subLabelI18n'
		},
		link: function(scope, element, attrs) {
			
			scope.$watch('label', function(new_val) {
				if(new_val !== undefined) {
					scope.safe_label = $sce.trustAsHtml(new_val);
				}
			})
			
		}
	}
}]);