nwuiChartHorizontalBar.directive('nwuiChartHorizontalBar', [
	'$window', 
	'$timeout', 
	function(
		$window, 
		$timeout
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/chart/horizontal-bar/template.html',
	        scope: {
		        series: '=',
		        threshold: '='
	        },
	        link: function(scope, element, attrs) {
		        
		        var get_max = function() {
			        
			        if(scope.threshold) return scope.threshold * 2;
			        
			        var max = 1;
			        scope.series.forEach(function(item,i) {
				        var value = Math.abs(item.value) * 100;
				        max = (value > max) ? Math.ceil(value) : max;
			        });
			        
			        //console.log('nwuiChartHorizontalBar', 'get_max result', max);
			        return max;
			        
		        }
		        
		        var calibrate = function() {
			        
			        var max = get_max();
			        
			        var tick_points = [1,2,3];
			        
			        var ticks = [];
			        tick_points.forEach(function(point,i) {
				        ticks.push({
					        label: (-max + ((max * 0.5) * point)) + '%',
					        style: {
						        left: (point * 25) + '%'
					        }
				        });
			        });
			        
			        scope.ticks = ticks;
			        
			        var bars = []
			        scope.series.forEach(function(item,i) {
				        bars.push({
					        label: item.label,
					        type: item.type,
					        value: item.value * 100,
					        abs_value: Math.abs(item.value * 100),
					        style: {
						        width: ((Math.abs(item.value) * 100) / max * 50) + '%'
					        }
				        })
				    });
				    
				    scope.bars = bars;
			        
		        }
		        
		        scope.style = {};
		        
		        scope.ticks = []
		        
		        scope.bars = []
		        
		        scope.$watch('series', function(new_val) {
			        
			        //console.log('nwuiChartHorizontalBar', 'series watch', new_val);
			        if(new_val === undefined || new_val.length == 0) return;
			        
			        calibrate();
			        
		        })
		        
	        }
		}
	}
]);