nwuiCompoundClientTodos.factory('NwuiCompoundClientTodosFactory', [function() {
	
	var defaults = {
		client: undefined, //req
		onboarding_status_detail: undefined,
		has_blind: true,
		has_header_pill: true
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundClientTodosFactory");
		if(obj.client === undefined) return console.error("Missing required instantiation object variable 'client' in NwuiCompoundClientTodosFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);