nwuiAuth.factory('NwuiAuthInterceptorFactory', ['$rootScope', '$q', '$cookies', 'NwuiSession', 'NWUI_AUTH_EVENTS', function($rootScope, $q, $cookies, NwuiSession, NWUI_AUTH_EVENTS) {
		return {
			responseError: function ( response ) {
				$rootScope.$emit( {
					401: NWUI_AUTH_EVENTS.notAuthenticated,
					403: NWUI_AUTH_EVENTS.notAuthorized,
					419: NWUI_AUTH_EVENTS.sessionTimeout,
					440: NWUI_AUTH_EVENTS.sessionTimeout,
					499: NWUI_AUTH_EVENTS.sessionTimeout
				}[response.status], response );
				
				return $q.reject ( response );
			},
			request: function(request){

				if(!!NwuiSession.user.token){
					if(request.url.indexOf('api/') != -1){
						//request.params = {TOKEN: NwuiSession.user.token }
					}
				}
				return $q.when(request)

			}
		};
	}
]);