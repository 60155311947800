nwuiCardBigNumber.factory('NwuiCardBigNumberFactory', [function() {
	
	var defaults = {
		value: undefined,
		filter: undefined,
		delta_percent: undefined,
		delta_dollar: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCardBigNumberFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);