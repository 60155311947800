nwuiCardTableV3Footer.directive('nwuiCardTableV3Footer', [
	'NwButtonService',
	'NwuiRegisterService',
	function(
		NwButtonService,
		NwuiRegisterService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/v3/footer/template.html',
			scope: {
				// table_id: '<tableId',
				total_items: '=totalItems',
				query: '=',
				current_page: '=currentPage',
				page_size: '=pageSize'
			},
			compile: function(element, attrs) {
				return {
					pre: function() { },
					post: function(scope, element, attrs) {

						var seed_id = NwuiRegisterService.register();
						
						scope.total_pages = '?';

						scope.table_id = seed_id;
						// if(angular.isUndefined(scope.query)) { scope.query = ''; }
						// if(angular.isUndefined(scope.current_page)) { scope.current_page = 1; }
						// if(angular.isUndefined(scope.page_size)) { scope.page_size = 10; }
						
						scope.hasPrev = function() {
							return (scope.current_page || 1) > 1;
						}
							
						scope.hasNext = function() {
							return (scope.current_page || 1) < scope.total_pages;
						}
						
						scope.buttons = {
							prev_page: {
								id: scope.table_id + '-prev-page-button',
								text_i18n: 'nwui.common.table.footer.button_previous',
								size: 'short',
								stretch: true,
								onClick: function() {
									
									if(NwButtonService.buttons[scope.table_id + '-prev-page-button'].processing || !scope.hasPrev()) return;
									
									NwButtonService.buttons[scope.table_id + '-prev-page-button'].processing = true;
									
									scope.current_page--;
										
								}
							},
							next_page: {
								id: scope.table_id + '-next-page-button',
								text_i18n: 'nwui.common.table.footer.button_next',
								size: 'short',
								stretch: true,
								onClick: function() {
									
									if(NwButtonService.buttons[scope.table_id + '-next-page-button'].processing || !scope.hasNext()) return;
									
									NwButtonService.buttons[scope.table_id + '-next-page-button'].processing = true;

									scope.current_page++;
										
								}
							}
						}
						
						scope.$watch('current_page', function(new_val) {
							if(new_val === undefined) return;

							checkButtons();
						});
						
						scope.$watchGroup(['total_items', 'page_size'], function(new_val) {
							if(new_val[0] === undefined || new_val[1] === undefined) return;

							scope.total_pages = Math.ceil(new_val[0]/new_val[1]);
							checkButtons();
						});

						var checkButtons = function () {
							if(NwButtonService.buttons[scope.table_id + '-prev-page-button']) {
								NwButtonService.buttons[scope.table_id + '-prev-page-button'].processing = false;
								NwButtonService.buttons[scope.table_id + '-next-page-button'].processing = false;
								NwButtonService.buttons[scope.table_id + '-prev-page-button'].disabled = !scope.hasPrev();
								NwButtonService.buttons[scope.table_id + '-next-page-button'].disabled = !scope.hasNext();
							}
						}

					}
				}
    	},
			// link: function(scope, element, attrs) {}
		}
	}
]);