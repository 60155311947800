nwuiModalBodyAuth.directive('nwuiModalBodyAuth', [
	'$sce', 
	'$cookies', 
	'$timeout', 
	'$window', 
	'$compile',
	'$location',
	'$rootScope',
	'locale',
	'NwuiAuthService', 
	'NwuiValidationService', 
	'NwuiToastManagerService',
	'NwuiSession', 
	'NwuiFormV3Service',
	'NwuiInputService',
	'NWUI_AUTH_EVENTS', 
	'NwuiFormV3Factory',
	'NwuiToastFactory',
	'NwuiButtonV3Factory',
	'NwuiRecommendationFactory',
	'NWUI_SELECT_OPTIONS',
	function(
		$sce, 
		$cookies, 
		$timeout, 
		$window, 
		$compile,
		$location,
		$rootScope,
		locale,
		NwuiAuthService, 
		NwuiValidationService, 
		NwuiToastManagerService,
		NwuiSession, 
		NwuiFormV3Service,
		NwuiInputService,
		NWUI_AUTH_EVENTS,
		NwuiFormV3Factory,
		NwuiToastFactory,
		NwuiButtonV3Factory,
		NwuiRecommendationFactory,
		NWUI_SELECT_OPTIONS
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/modal-body-auth/template.html',
			scope: {
				open: '=',
				hs_form: '=hsForm',
				default_form: '=defaultForm',
				show_signup: '=showSignup',
				show_referral_source: '=showReferralSource',
				show_company_signup: '=showCompanySignup',
				show_language_preference: '=showLanguagePreference'
			},
			link: function(scope, element, attrs) {

				var timeouts = {
					reset: undefined
				}

				var referral_sources = NWUI_SELECT_OPTIONS.REFERRAL_SOURCES
					.filter(function(source) {
						return !source.nestwealth_only || organization_data.slug === 'nestwealth' || organization_data.slug === 'nw'
					});

				//v2
				var defaultTfaRecommendation = function() {
					return {
						text_i18n: 'nwui.modal.auth.form.two_factor.message.check_email',
						icon: {
							name: 'info',
							color: 'alternate'
						},
						link: {
							text_i18n: 'nwui.modal.auth.form.two_factor.message.link',
							onClick: function() {
								
								generateTwoFactorAuthCode();

							}
						}
					}
				}

				var resetForms = function(delay) {
					timeouts.reset = $timeout(function() {
						NwuiFormV3Service.clear('auth-login-form');
						NwuiFormV3Service.clear('auth-signup-form');
						NwuiFormV3Service.clear('auth-forgot-form');
						NwuiFormV3Service.clear('auth-validate-form');
					}, delay);
				}

				scope.message = {
					icon: {
						name: 'close',
						color: 'error'
					},
					text: undefined
				}

				scope.modals = {
					auth: {
						tabs: {
							login: {
								message: {
									no_transition: false,
									style: {},
									text: '',
									type: '',
									icon: {
										name: 'check'
									}
								},
								form: new NwuiFormV3Factory({
									name: 'auth-login-form',
									active_step_index: 0,
									submit_button: {
										text_i18n: 'nwui.modal.auth.form.login.button.text',
										color: (organization_data && organization_data.theme.form.submit.auth_color),
										icon: {
											name: 'lock'
										}
									},
									onSubmit: function(post_data) {

									  scope.modals.auth.tabs.login.form.processing = true;
										return NwuiAuthService.login(post_data);
								
									},
									onError: function(res) {

										//console.log('nwuiModalBodyAuth', 'login form', 'onError', res);
                    scope.modals.auth.tabs.login.form.processing = false;

										var message = undefined;
										var message_i18n = "nwui.common.error.unknown";
										var message_i18n_data = {};
										var link = undefined;

										if(res.message) {
											message = res.message;
											message_i18n = undefined;
										}
											
										if(res.nw_error_code) {
											message = res.nw_error_message;
											message_i18n = undefined;
											switch(res.nw_error_code) {
												case 4429:
													message_i18n = 'nwui.modal.auth.form.login.status.error_4429';
													message_i18n_data = {
														waitTime : moment.duration({ seconds: res.i18n_data.waitTimeSeconds }).humanize()
													}
													NwuiInputService.inputs['validate-email'].model = NwuiInputService.inputs['login-email'].model;
													link = {
														text_i18n: 'nwui.modal.auth.form.login.status.error_4429_link',
														onClick: function() {
															scope.changeTab(3)
														}
													}
													break;
												case 4510:
													message_i18n = 'nwui.modal.auth.form.login.status.error_4510';
													NwuiInputService.inputs['validate-email'].model = NwuiInputService.inputs['login-email'].model;
													link = {
														text: '',
														text_i18n: 'nwui.modal.auth.form.login.status.error_4510_link',
														onClick: function() {
															scope.changeTab(3)
														}
													}
													break;
												case 4501:
													message_i18n = 'nwui.modal.auth.form.login.status.error_4501';
													NwuiInputService.inputs['reset-email'].model = NwuiInputService.inputs['login-email'].model;
													link = {
														text: '',
														text_i18n: 'nwui.modal.auth.form.login.status.error_4501_link',
														onClick: function() {
															scope.changeTab(2)
														}
													}
													break;
												case 4505:
													scope.changeTab(4);
													break;
												case 4555: 
													scope.form.login.show_account_locked = true;
													break;
												default:
													break;
											}
										}
										NwuiFormV3Service.showMessage('auth-login-form', {
											text: message,
											text_i18n: message_i18n,
											text_i18n_data: message_i18n_data,
											link: link,
											icon: {
												name: 'error',
												color: 'error'
											}
										})

									},
									onComplete: function(obj) {
										
										//console.log('nwuiModalBodyAuth', 'login form', 'onComplete', obj);

										NwuiFormV3Service.showMessage('auth-login-form', {
											text: '',
											text_i18n: 'nwui.modal.auth.form.login.status.success',
											icon: {
												name: 'check',
												color: ''
											}
										})
									
									},
									steps: [{
										columns: [{
											directive: 'input',
											width: 100,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.email.label',
													model: '',
													id: 'login-email',
													stretch: true,
													required: true,
													size: '',
													mask: '',
													validators: ['email'],
													placeholder: "",
													placeholder_i18n: 'nwui.common.input.email.placeholder',
													post_var: 'email'
												}
											}
										}, {
											directive: 'input',
											width: 100,
											data: {
												directive: 'text',
												data: {
													type: 'password',
													label: '',
													label_i18n: 'nwui.common.input.password.label',
													model: '',
													size: '',
													id: 'login-password',
													stretch: true,
													required: true,
													mask: '',
													validators: [],
													placeholder: "",
													placeholder_i18n: 'nwui.common.input.password.placeholder',
													post_var: 'password'
												}
											}
										}]
									}],
                  disable_automatic_button_processing: true,
                  processing: false
								})
							},
							signup: {
								form: new NwuiFormV3Factory({
									name: 'auth-signup-form',
									active_step_index: 0,
									submit_button: {
										text_i18n: 'nwui.modal.auth.form.sign_up.button.text',
										color: (organization_data && organization_data.theme.form.submit.auth_color),
										icon: {
											name: 'person'
										}
									},
									onSubmit: function(post_data) {
										if(scope.show_company_signup === true) {
											
											return NwuiAuthService.companySignup({
												FirstName: post_data.first_name,
												LastName: post_data.last_name,
												Email: post_data.email,
												Company: post_data.company,
												Language: (scope.show_language_preference === true) ? post_data.preferred_lang : $cookies.get('nw_i18n_lang') ? $cookies.get('nw_i18n_lang') : undefined,
												events: ['companySignUp']
											})

										} else {

											var form = scope.hs_form && hubspot_forms[scope.hs_form]
												? hubspot_forms[scope.hs_form]
												: hubspot_forms['port'];

											var from_url = $location.search();

											var obj = {
												first_name: post_data.first_name,
												last_name: post_data.last_name,
												email: post_data.email,
												referral_source: post_data.referral_source,
												preferred_lang: post_data.preferred_lang,
												language: $cookies.get('nw_i18n_lang'),
												reason: NwuiSession.user.reason || { id: null, val: null },
												age: NwuiSession.user.age || null,
												horizon: NwuiSession.user.horizon || null,
												principle: NwuiSession.user.principle || null,
												yearly_contribution: NwuiSession.user.yearly_contribution || null,
												nest_wealth_advantage: NwuiSession.user.nest_wealth_advantage || null,
												typical_mutual_fund_value: NwuiSession.user.typical_mutual_fund_value || null,
												nest_wealth_value: NwuiSession.user.nest_wealth_value || null,
												join_stage: form.stage,
												user_saved: false,
												hubspot: {
													form_id: form.id,
													token: $cookies.get('hubspotutk'),
													page_url: window.location.href,
													page_name: document.title
												},
												growsumo: (typeof growsumo !== "undefined") ? growsumo.data : undefined,
												events: hubspot_forms[scope.hs_form].data_layer.signup
											}

											if(from_url.rrep)
												obj.rrep = from_url.rrep;

											if(from_url.referring_branch)
												obj.referring_branch = from_url.referring_branch;

											if(from_url.referring_employee)
												obj.referring_employee = from_url.referring_employee;

											if(from_url.fi)
												obj.fi = from_url.fi;
											
											if(from_url.source)
												obj.source = from_url.source;

											return NwuiAuthService.signup(obj);

										}
								
									},
									onError: function(res) {

										var message = undefined;
										var message_i18n = "nwui.common.error.unknown";
										var link = undefined;

										if(res.message) {
											message = res.message;
											message_i18n = undefined;
										}
											
										if(res.nw_error_code) {
											message = res.nw_error_message;
											message_i18n = undefined;
											
											switch(res.nw_error_code) {
												case 4500:
													message = undefined;
													message_i18n = 'nwui.modal.auth.form.sign_up.status.error_4500';
													NwuiInputService.inputs['login-email'].model = NwuiInputService.inputs['signup-email'].model;
													link = {
														text_i18n: 'nwui.modal.auth.form.sign_up.status.error_4500_link',
														onClick: function() {
															scope.changeTab(0)
														}
													}
													break;
												case 4554:
													message = undefined;
													message_i18n = 'nwui.modal.auth.form.sign_up.status.error_4554';
													NwuiInputService.inputs['login-email'].model = NwuiInputService.inputs['signup-email'].model;
													link = {
														text_i18n: 'nwui.modal.auth.form.sign_up.status.error_4500_link',
														onClick: function() {
															scope.changeTab(0)
														}
													}
												default:
													break;
											}
										}

										NwuiFormV3Service.showMessage('auth-signup-form', {
											text: message,
											text_i18n: message_i18n,
											link: link,
											icon: {
												name: 'error',
												color: 'error'
											}
										})

									},
									onComplete: function(obj) {

										NwuiFormV3Service.showMessage('auth-signup-form', {
											text: 'Sign up successful!',
											text_i18n: 'nwui.modal.auth.form.sign_up.status.success',
											icon: {
												name: 'check',
												color: ''
											}
										})

										resetForms(5000);
									
									},
									steps: [{
										columns: [{
											directive: 'input',
											width: 50,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.first_name.label',
													model: '',
													id: 'signup-firstname',
													stretch: true,
													required: true,
													size: '',
													mask: 'proper_name',
													validators: [],
													placeholder: "",
													placeholder_i18n: 'nwui.common.input.first_name.placeholder',
													post_var: 'first_name'
												}
											}
										},{
											directive: 'input',
											width: 50,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.last_name.label',
													model: '',
													id: 'signup-lastname',
													stretch: true,
													required: true,
													size: '',
													mask: 'proper_name',
													validators: [],
													placeholder_i18n: 'nwui.common.input.last_name.placeholder',
													post_var: 'last_name'
												}
											}
										},{
											directive: 'input',
											width: 100,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.email.label',
													model: '',
													id: 'signup-email',
													stretch: true,
													required: true,
													size: '',
													mask: '',
													validators: ['email'],
													placeholder_i18n: 'nwui.common.input.email.placeholder',
													post_var: 'email'
												}
											}
										},{
											directive: 'input',
											hidden: scope.show_referral_source === false ? true : false,
											width: 100,
											data: {
												directive: 'select',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.referral_source.label',
													model: '',
													id: 'signup-referral-source',
													stretch: true,
													required: false,
													size: '',
													mask: '',
													hide: scope.show_referral_source === false ? true : false,
													options: referral_sources,
													validators: [],
													placeholder: "",
													placeholder_i18n: 'nwui.common.input.referral_source.placeholder',
													post_var: 'referral_source'
												}
											}
										},{
											directive: 'input',
											hidden: scope.show_company_signup === true ? false : true,
											width: 100,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.company.label',
													model: '',
													id: 'signup-company',
													stretch: true,
													required: true,
													size: '',
													mask: '',
													hide: scope.show_company_signup === true ? false : true,
													validators: [],
													placeholder_i18n: 'nwui.common.input.company.placeholder',
													post_var: 'company'
												}
											}
										},{
											directive: 'input',
											hidden: scope.show_language_preference === true ? false : true,
											width: 100,
											data: {
												directive: 'select',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.preferred_language.label',
													model: '',
													id: 'signup-preferred-language',
													stretch: true,
													required: true,
													size: '',
													mask: '',
													hide: scope.show_language_preference === true ? false : true,
													options: organization_data.join_data.localization.map(function(locale) {
														return {
															text_i18n: locale.label,
															value: locale.language
														}
													}),
													validators: [],
													placeholder_i18n: 'nwui.common.input.preferred_language.placeholder',
													post_var: 'preferred_lang'
												}
											}
										}]
									}]
								})
							},
							forgot: {
								form: new NwuiFormV3Factory({
									name: 'auth-forgot-form',
									active_step_index: 0,
									submit_button: {
										text_i18n: 'nwui.modal.auth.form.forgot.button.text',
										color: (organization_data && organization_data.theme.form.submit.auth_color),
										icon: {
											name: 'email'
										}
									},
									onSubmit: function(post_data) {
										
										return NwuiAuthService.forgotPassword(post_data);
								
									},
									onError: function(res) {

										//console.log('nwuiModalBodyAuth', 'forgot form', 'onError', res);

										var message = undefined;
										var message_i18n = 'nwui.common.error.unknown';

										if(res.message) {
											message = res.message;
											message_i18n = undefined;
										}
											
										if(res.nw_error_code) {
											switch(res.nw_error_code) {
												default:
													message = res.nw_error_message;
													message_i18n = undefined;
											}
										}

										NwuiFormV3Service.showMessage('auth-forgot-form', {
											text: message,
											text_i18n: message_i18n,
											icon: {
												name: 'error',
												color: 'error'
											}
										})

									},
									onComplete: function(obj) {
										
										//console.log('nwuiModalBodyAuth', 'forgot form', 'onComplete', obj);
										
										locale.ready('nwui.modal.auth.form.forgot.status').then(function() {

											NwuiInputService.inputs['login-email'].model = NwuiInputService.inputs['reset-email'].model;
											NwuiFormV3Service.showMessage('auth-forgot-form', {
												text: locale.getString('nwui.modal.auth.form.forgot.status.success', { 
													email: NwuiInputService.inputs['reset-email'].model 
												}),
												link: {
													onClick: function() {
														scope.changeTab(0);
													},
													text_i18n: "nwui.modal.auth.form.forgot.status.success_link"
												},
												icon: {
													name: 'check',
													color: ''
												}
											})

										});
									
									},
									steps: [{
										columns: [{
											directive: 'input',
											width: 100,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.email.label',
													model: '',
													id: 'reset-email',
													stretch: true,
													required: true,
													size: '',
													mask: '',
													validators: ['email'],
													placeholder_i18n: 'nwui.common.input.email.placeholder',
													post_var: 'email'
												}
											}
										}]
									}]
								})
							},
							validate: {
								form: new NwuiFormV3Factory({
									name: 'auth-validate-form',
									active_step_index: 0,
									submit_button: {
										text_i18n: 'nwui.modal.auth.form.revalidate.button.text',
										color: (organization_data && organization_data.theme.form.submit.auth_color),
										icon: {
											name: 'email'
										}
									},
									onSubmit: function(post_data) {
										
										return NwuiAuthService.validateEmail(post_data);
								
									},
									onError: function(res) {

										//console.log('nwuiModalBodyAuth', 'validate form', 'onError', res);

										var message = undefined;
										var message_i18n = "nwui.common.error.unknown";

										if(res.data.message) {
											message = res.data.message;
											message_i18n = undefined;
										}
											
										if(res.data.nw_error_code) {
											switch(res.data.nw_error_code) {
												default:
													message = res.data.nw_error_message;
													message_i18n = undefined;
											}
										}

										NwuiFormV3Service.showMessage('auth-validate-form', {
											text: message,
											text_i18n: message_i18n,
											icon: {
												name: 'error',
												color: 'error'
											}
										})

									},
									onComplete: function(obj) {
										
										//console.log('nwuiModalBodyAuth', 'validate form', 'onComplete', obj);

										NwuiInputService.inputs['login-email'].model = NwuiInputService.inputs['validate-email'].model;

										locale.ready('nwui.modal.auth.form.revalidate.status').then(function() {

											NwuiFormV3Service.showMessage('auth-validate-form', {
												text: locale.getString('nwui.modal.auth.form.revalidate.status.success', { 
													email: NwuiInputService.inputs['validate-email'].model 
												}),
												link: {
													onClick: function() {
														scope.changeTab(0);
													},
													text_i18n: 'nwui.modal.auth.form.revalidate.status.success_link'
												},
												icon: {
													name: 'check',
													color: ''
												}
											})

										});
									
									},
									steps: [{
										columns: [{
											directive: 'input',
											width: 100,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.email.label',
													model: '',
													id: 'validate-email',
													stretch: true,
													required: true,
													size: '',
													mask: '',
													validators: ['email'],
													placeholder: "",
													placeholder_i18n: 'nwui.common.input.email.placeholder',
													post_var: 'email'
												}
											}
										}]
									}]
								})
							},
							tfa: {
								recommendation: defaultTfaRecommendation(),
								form: new NwuiFormV3Factory({
									name: 'auth-tfa-form',
									active_step_index: 0,
									submit_button: {
										text_i18n: 'nwui.modal.auth.form.two_factor.button.text',
										color: (organization_data && organization_data.theme.form.submit.auth_color),
										icon: {
											name: 'verified_user'
										}
									},
									onSubmit: function(post_data) {

										post_data.email = NwuiInputService.inputs['login-email'].model;
										post_data.remember_computer = post_data.remember_computer[0].value;

										return NwuiAuthService.validateTwoFactorAuthCode(post_data);

									},
									onError: function(res) {

										//console.log('nwuiModalBodyAuth', 'onError', res);
										locale.ready('nwui.modal.auth.form.two_factor.status').then(function() {

											var message = undefined;
											var message_i18n = 'nwui.common.error.unknown';
											var link = undefined;

											if(res.data.nw_error_code) {
												message = res.data.nw_error_message;
												message_i18n = undefined;
												switch(res.data.nw_error_code) {
													case 4509:
														message = undefined;
														message_i18n = 'nwui.modal.auth.form.two_factor.status.error_4509';
														link = {
															onClick: function() {
																generateTwoFactorAuthCode();
															},
															text_i18n: 'nwui.modal.auth.form.two_factor.status.error_4509_link'
														}
														break;
													case 4555:
														message = locale.getString('nwui.modal.auth.form.two_factor.status.error_4555', {
															orgEmail: (organization_data && organization_data.email)
														});
														break;
													default: 
														break;
												}
											}

											NwuiFormV3Service.showMessage('auth-tfa-form', {
												text: message,
												text_i18n: message_i18n,
												link: link,
												icon: {
													name: 'error',
													color: 'error'
												}
											})

										});

									},
									onComplete: function(obj) {

										//console.log('nwuiModalBodyAuth', 'tfa form', 'onComplete', obj);

										NwuiFormV3Service.showMessage('auth-tfa-form', {
											text: '',
											text_i18n: 'nwui.modal.auth.form.two_factor.status.success',
											icon: {
												name: 'check',
												color: ''
											}
										})

										NwuiAuthService.login({
											email: NwuiInputService.inputs['login-email'].model,
											password: NwuiInputService.inputs['login-password'].model
										}).then(function() {
											resetForms(5000);
										}, function() {
											scope.changeTab(0);
										});

									},
									steps: [{
										columns: [{
											directive: 'input',
											width: 100,
											data: {
												directive: 'text',
												data: {
													label: '',
													label_i18n: 'nwui.common.input.auth_code.label',
													model: '',
													id: 'tfa-code',
													stretch: true,
													required: true,
													size: '',
													mask: '',
													validators: [],
													placeholder: "",
													placeholder_i18n: 'nwui.common.input.auth_code.placeholder',
													post_var: 'code'
												}
											}
										},{
											directive: 'input',
											width: 100,
											data: {
												directive: 'checkbox',
												data: {
													label: '',
													id: 'tfa-rememeber',
													stretch: true,
													required: false,
									        options: [{
														label: "",
														label_i18n: 'nwui.common.input.remember_device.check_0',
														model: "remember_computer",
														value: true
													}],
													post_var: 'remember_computer'
												}
											}
										}]
									}]
								})
							}
						}
					}
				}

				var generateTwoFactorAuthCode = function() {

					var obj = {
						email: NwuiInputService.inputs['login-email'].model
					}

					NwuiInputService.inputs['tfa-code'].model = '';

					scope.modals.auth.tabs.tfa.recommendation.icon.name = 'autorenew';
					scope.modals.auth.tabs.tfa.recommendation.icon.color = 'warning';
					scope.modals.auth.tabs.tfa.recommendation.text_i18n = 'nwui.modal.auth.form.two_factor.message.processing';

					NwuiFormV3Service.hideMessage('auth-tfa-form');
					NwuiAuthService.generateTwoFactorAuthCode(obj).then(function() {

						locale.ready('nwui.modal.auth.form.two_factor.status').then(function() {

							NwuiFormV3Service.showMessage('auth-tfa-form', {
								text: '',
								text_i18n: locale.getString('nwui.modal.auth.form.two_factor.status.email_sent', {
									email: NwuiInputService.inputs['login-email'].model
								}),
								icon: {
									name: 'email',
									color: ''
								}
							})

						});

						scope.modals.auth.tabs.tfa.recommendation = defaultTfaRecommendation();

					}, function(res) {

						locale.ready('nwui.modal.auth.form.two_factor.message').then(function() {

							var message = undefined;
							var message_i18n = 'nwui.common.error.unknown';

							if(res.data.nw_error_code){
								message = res.data.nw_error_message;
								message_i18n = undefined;
								switch(res.data.nw_error_code){
									case 4555:
										message = undefined;
										message_i18n = locale.getString('nwui.modal.auth.form.two_factor.message.error_locked', {
											orgEmail: (organization_data && organization_data.email)
										});
										scope.modals.auth.tabs.tfa.recommendation.link = undefined;
										break;
									default: 
										break;
								}

							}	

							scope.modals.auth.tabs.tfa.recommendation.text = message;
							scope.modals.auth.tabs.tfa.recommendation.text_i18n = message_i18n;
							scope.modals.auth.tabs.tfa.recommendation.icon.color = 'error';
							scope.modals.auth.tabs.tfa.recommendation.icon.name = 'error';

						});

					});

				}

				//content style for transitions
				scope.style_container = {};
				
				//currently selected tab
				scope.active_tab = (scope.default_form || 1)-1;
				
				var focus_input = function(tab_index) {

					//console.log('nwuiModalBodyAuth', 'focus_input', tab_index);
					$timeout(function() {
						$(element)
							.find('.tab-content')
							.eq(tab_index)
							.find('input')
							.eq(0)
							.focus();
					}, 750);

				}

				//hubspot integration using forms api for user traffic aggregation
				var hubspot_forms = {
					com: {
						id: 'com',
						stage: 'com_email_collected',
						data_layer: {
							signup: ['emailSignUp', 'comEmailSignUp']
						}
					},
					join: {
						id: 'join',
						stage: 'email_collected',
						data_layer: {
							signup: ['emailSignUp', 'calculatorCompleted']
						}
					},
					port: {
						id: 'port',
						stage: 'port_email_collected',
						data_layer: {
							signup: ['emailSignUp', 'portEmailSignUp']
						}
					}
				}
				
				//content style for transitions
				scope.style_container = {};
				
				//currently selected tab
				scope.active_tab = (scope.default_form || 1)-1;

				//saving selections for later
				var container = $(element).find('.bottom');
				var tab_contents = $(element).find('.tab-content');
					
				//change tab
				scope.changeTab = function(index) {
					
					//
					var previous_tab = scope.active_tab;

					//if login -> forgot
					if(previous_tab === 0 && index === 2) {
						NwuiInputService.inputs['reset-email'].model = NwuiInputService.inputs['login-email'].model;
					}

					//console.log('changeTab', index, scope.active_tab);

					//disable initial transition for height set (safari fix)
					scope.no_transition = true;
					
					$timeout(function() {
						NwuiFormV3Service.hideMessage('auth-login-form');
						NwuiFormV3Service.hideMessage('auth-signup-form');
						NwuiFormV3Service.hideMessage('auth-validate-form');
						NwuiFormV3Service.hideMessage('auth-tfa-form');
						NwuiInputService.inputs['tfa-code'].model = '';

						if(index !== 2)
							NwuiFormV3Service.hideMessage('auth-forgot-form');

						//Keep if FTA
						//if(index !== 4)
							//NwuiInputService.inputs['login-password'].model = '';

						scope.modals.auth.tabs.tfa.recommendation = defaultTfaRecommendation();
					}, 500);
					
					//set height to current auto height and change tab
					$timeout(function() {
						scope.style_container = {
							height: container.height() + 'px',
							overflow: 'hidden'
						}
						
						scope.active_tab = index;
					}, 50);
					
					//reenable transition for animating to new height (safari fix)
					$timeout(function() {
						scope.no_transition = false;
						
						//set new height
						$timeout(function() {
							scope.style_container = {
								height: tab_contents.eq(index).height() + 'px',
								overflow: 'hidden'
							}
							
							$timeout(function() {
								scope.style_container = {}
							}, 1000);
						}, 50);
					}, 100);
				}
				
				//window resize event
				window.onresize = function() {
					scope.$apply();
				};
				
				scope.$watch(function() {
					return angular.element($window)[0].innerWidth;
				}, function() {
					//set height back to auto on window resize/rotate to account for dynamic height change
					scope.style_container = {};
					
					//safari fix
					scope.no_transition = true;
					$timeout(function() {
						scope.no_transition = false;
					}, 50);
				});
				
				scope.$watch('open', function(new_val, old_val) {
 
					if(new_val != old_val && new_val === true) {
						scope.active_tab = (scope.default_form || 1)-1;

						$timeout.cancel(timeouts.reset);
						focus_input(scope.active_tab)
					}

					if(new_val != old_val && new_val === false) {
						resetForms(5000);
					}

				});
				
				scope.$watch('default_form', function(new_val, old_val) {
					//console.log('nwuiModalBodyAuth', 'default_form watch', old_val, '=>', new_val)

					if(new_val != old_val)
						scope.active_tab = (new_val || 1)-1;
				});

				scope.$watch('active_tab', function(new_val, old_val) {
					//console.log('nwuiModalBodyAuth', 'active_tab watch', new_val, scope.open)

					if(new_val === undefined || scope.open === false) return;

					if(scope.show_signup === false && new_val === 1) {
						scope.active_tab = 0;
					} else {
						focus_input(new_val)
					}

				})

        $rootScope.$on('$stateChangeSuccess', function() {
          resetForms(0);
        });
				
			}
		}
	}
]);

nwuiModalBodyAuth.directive('nwuiModalBodyAuthSumbit', ['$timeout', function($timeout) {
	return {
		restrict: 'A',
		link: function(scope, element, attrs) {
			
			element.on("click",function() {
				document.getElementById(attrs['nwuiModalBodyAuthSumbit']).click();
			});
			
		}
	}
}]);