nwuiAuth.service('NwuiSession', [
	'$rootScope',
	'$timeout',
	function(
		$rootScope,
		$timeout
	) {
	
	var inactivity_timers = {
		reset: undefined,
		warning: undefined,
		timeout: undefined 
	}
	
	var self = this;
	
	this.user = {};
	this.userData = {};
	
	this.create = function(user, userData) {
    
		if(user.userRole === undefined)
			user.userRole = 'user';
		if(user.role_id === undefined)
			user.role_id = 0;
		
		this.user = {};
		angular.merge(this.user, user);
		this.userData = {};
		angular.merge(this.userData, userData);
		
		$timeout.cancel(inactivity_timers.timeout);
		
	}
	
	this.destroy = function(){
		this.user = {};
	}
	
	this.isFinancialPlanner = function() {
		
		return (self.user.organization_id == 1 && self.user.Role.name === 'Financial Planner')
		
	}

	this.isAdvisorImpersonating = function() {
		if(!self.user || !self.user.Role) return false;
		return self.user.Role.name !== 'Client' && self.userData && self.userData.user.Role.name === 'Client';
	}
	
}]);

nwuiAuth.service('NwuiAuthService', [
	'$rootScope', 
	'$http', 
	'$cookies', 
	'$q',
	'$timeout',
	//'$intercom', 
	'NwuiSession', 
	'NWUI_AUTH_EVENTS', 
	// 'INTERCOM_APPID',
	'PORTFOLIO_WEBSERVER',
	'$localStorage',
	"ToggleLangService",
	'NwuiAnalyticsService',
	function(
		$rootScope, 
		$http, 
		$cookies, 
		$q, 
		$timeout,
		//$intercom, 
		NwuiSession, 
		NWUI_AUTH_EVENTS,
		// INTERCOM_APPID,
		PORTFOLIO_WEBSERVER,
		$localStorage,
		ToggleLangService,
		NwuiAnalyticsService
	) {

		var self = this;
		this.signup = function(credentials) {
			
			var self = this
			var deferred = $q.defer();
			
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/user/create', credentials).then(function(res, status) {
				//console.log("NwuiAuthService post /api/user/create")
				//console.log(res.data);
				
				if(res.data.error)
					return deferred.reject({ message: res.data.error });
				
				NwuiSession.destroy();
        NwuiSession.create(res.data.user, res.data.data);
				NwuiAnalyticsService.alias(res.data.user.uuid)

				if(res.data.user.redirect_domain == window.location.origin) {
					$rootScope.$broadcast(NWUI_AUTH_EVENTS.loginSuccess, {});
					deferred.resolve(res.data);
				} else {
					//delay for GA
					$timeout(function() {
						window.location.href = res.data.user.redirect_domain + res.data.user.redirect_url;
					}, 500);
				}
				
			}, function(res, status) {
				deferred.reject(res.data);
			});
			
			return deferred.promise;
			
		}

		this.companySignup = function(credentials) {
			
			var deferred = $q.defer();
			
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/companies', credentials).then(function(res, status) {
				
				if(res.data.error)
					return deferred.reject({ message: res.data.error });
				
				NwuiSession.destroy();
				NwuiSession.create(res.data.user, res.data.data);
				NwuiAnalyticsService.alias(res.data.user.uuid);

				ToggleLangService.toggle(res.data.organization.language);
				
				if(res.data.user.redirect_domain == window.location.origin) {
					$rootScope.$broadcast(NWUI_AUTH_EVENTS.loginSuccess, {});
					deferred.resolve(res.data);
				} else {
					//delay for GA
					$timeout(function() {
						window.location.href = res.data.user.redirect_domain + res.data.user.redirect_url;
					}, 500);
				}
				
			}, function(res, status) {
				deferred.reject(res.data);
			});
			
			return deferred.promise;

		}
		
		this.login = function(credentials) {
			var self = this
			var deferred = $q.defer();
			
			var credentials = credentials ? credentials : {};

			if(PORTFOLIO_WEBSERVER.is_app === true) {
				credentials.app_token = $localStorage.app_token;
				credentials.device_id = $localStorage.device_uuid;
			}

			credentials.source = "NW";

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/login', credentials).then(function(res, status) {
				
				if(res.data.error)
					return deferred.reject({ message: res.data.error });

				NwuiSession.destroy();
				NwuiSession.create(res.data.user, res.data.data);
				
				NwuiAnalyticsService.alias(res.data.user.uuid)
				
				var need_switch = (res.data.user.language && $cookies.get('nw_i18n_lang') !== res.data.user.language)
				var query_params = (need_switch) ? '?lang='+res.data.user.language : '';

				if(res.data.user.redirect_domain == window.location.origin || PORTFOLIO_WEBSERVER.is_app) {
					$rootScope.$broadcast(NWUI_AUTH_EVENTS.loginSuccess, { query_params: query_params });
					deferred.resolve(res.data.user);
				} else if (
					(org_slug === 'nestwork' || org_slug === 'nbfwork') &&
					res.data.user.redirect_domain.indexOf('admin') > -1
				) { //TODO: rip the bandaid off
					window.location.href = res.data.user.redirect_domain + '/participants';
				} else {
					window.location.href = res.data.user.redirect_domain + res.data.user.redirect_url + query_params;
				}
				
			}, function(res, status) {
				deferred.reject(res.data);
			});

			return deferred.promise;
		}

		this.logout = function(credentials) {
			var deferred = $q.defer();
			
			$http.get(PORTFOLIO_WEBSERVER.app + '/logout?isHXR=true').then(function(res, status) {
				if(res.data && res.data.redirect) {
					window.location = res.data.url;
				}
				deferred.resolve(res.data);
				NwuiSession.destroy();
			}, function(res, status) {
				deferred.reject(res.data);
			});

			return deferred.promise;

		}
		
		this.forgotPassword = function(credentials) {
			
			var deferred = $q.defer();
			
			$http.post(PORTFOLIO_WEBSERVER.app + '/forgot-password', credentials).then(function(res, status) {
				deferred.resolve(res.data);
			}, function(res, status) {
				deferred.reject(res.data);
			});
			
			return deferred.promise;
			
		}
		
		this.isTokenValid = function(){
			var deferred = $q.defer()
			//var token = $cookies.get('token')
			//if(token) {
			//	//console.log('checking token')
			$http.post (PORTFOLIO_WEBSERVER.app + '/api/check_auth').then ( function ( res ) {
				//console.log('have result', res)

				var need_switch = (res.data.user.language && $cookies.get('nw_i18n_lang') !== res.data.user.language)
				var query_params = (need_switch) ? '?lang='+res.data.user.language : '';

				if(need_switch) {
					$http.put(PORTFOLIO_WEBSERVER.app + '/api/user', JSON.stringify({
						language: $cookies.get('nw_i18n_lang')
					})).then(function() {})
				}

				var user;

				if(
					res.data.data &&
					res.data.data.user &&
					organization_data.url_bases &&
					window.location.origin === organization_data.url_bases.port[0]
				) {
					user = res.data.data.user;
					user.intercom_hash = res.data.user.intercom_hash;
					user.isImpersonating = res.data.user.isImpersonating;
				} else {
					user = res.data.user;
				}

				NwuiSession.destroy();
				NwuiSession.create(user, res.data.data)
				//$rootScope.$broadcast(NWUI_AUTH_EVENTS.loginSuccess);
				deferred.resolve(user);

				// $intercom.update({
				// 	id: res.data.user.id,
				// 	email: res.data.user.email,
				// 	name: res.data.user.first_name + " " + res.data.user.last_name
				// });

				// if(window.Intercom) {
				// 	window.Intercom('update', {
				// 		id: res.data.user.id,
				// 		email: res.data.user.email,
				// 		name: res.data.user.first_name + " " + res.data.user.last_name
				// 	});
				// }

			},function(error){
				//console.log('bc in isToken valid',NWUI_AUTH_EVENTS.notAuthenticated)
				$rootScope.$broadcast ( NWUI_AUTH_EVENTS.notAuthenticated );
				deferred.reject()
			})
			/*} else {
				//console.log('bc in isToken valid',NWUI_AUTH_EVENTS.notAuthenticated)
				$rootScope.$broadcast ( NWUI_AUTH_EVENTS.notAuthenticated );
				deferred.reject()

			}*/
			return deferred.promise
		}
		
		this.isAuthenticated = function () {
			
			//console.log('isAuthenticated()', NwuiSession)

			//if is app, has no app token and real device
			// if(PORTFOLIO_WEBSERVER.is_app === true && !$localStorage.app_token && $localStorage.device_uuid) {
				// self.createAppToken().then(function(data) {
				// 	//console.log('app token', data.token)
				// }, function(res) {
				// 	//console.log('app token reject', res)
				// });
			// }


			
			//var token = $cookies.get('token');
			var deferred = $q.defer()
			
			//
			/*if(!token) {
				//console.log('no token')
				deferred.reject(!!NwuiSession.user.uuid)
			}
			*/

			if(!NwuiSession.user.uuid) {
				//console.log('no user id')
				self.isTokenValid().then(function(user){
					$rootScope.$broadcast(NWUI_AUTH_EVENTS.loginSuccess, {});

					if(PORTFOLIO_WEBSERVER.is_app === true && !$localStorage.app_token && $localStorage.device_uuid) {

						self.createAppToken().then(function(data) {
							//console.log('app token', data.token)
							$localStorage.app_token = data.token;
							deferred.resolve(user)
						}, function(res) {
							//console.log('app token reject', res)
						});

					} else {

						deferred.resolve(user)

					}
				}, function(){
					//console.log('not authenticated in authservice')
					deferred.reject(!!NwuiSession.user.uuid)
				})
			} 
			
			else {
				//console.log('have user id')

				//if not authenticated, and in app, check with app token
				if(PORTFOLIO_WEBSERVER.is_app === true && !$localStorage.app_token && $localStorage.device_uuid) {

					self.createAppToken().then(function(data) {
						//console.log('app token', data.token)
						$localStorage.app_token = data.token;
						deferred.resolve(!!NwuiSession.user.uuid)
					}, function(res) {
						//console.log('app token reject', res)
					});

				} else {
					deferred.resolve(!!NwuiSession.user.uuid)	
				}
				
			}
			return deferred.promise
		}
		
		this.isAuthorized = function (authorizedRoles) {
			
			//console.log('isAuthorized()', NwuiSession.user.userRole, authorizedRoles)
			
			if(!angular.isArray(authorizedRoles))
				authorizedRoles = [authorizedRoles];
				
			return (authorizedRoles.indexOf(NwuiSession.user.userRole) !== -1);
			
		}
		
		this.validateEmail = function(credentials) {
			
			var deferred = $q.defer();
			
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/user/revalidate', credentials).then(function(res, status) {
				deferred.resolve(res.data);
			}, function(data, status) {
				deferred.reject(data);
			});

			return deferred.promise;
			
		}

		this.generateTwoFactorAuthCode = function(credentials){
			var deferred = $q.defer()
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/two-factor-auth/generate-code', credentials).then(function(res, status){
				deferred.resolve(res.data);
			}, function(data, status){
				deferred.reject(data)
			});

			return deferred.promise;
		}

		this.validateTwoFactorAuthCode = function(credentials){
			var deferred = $q.defer();
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/two-factor-auth/validate-code', credentials).then(function(res, status){
				deferred.resolve(res.data);
			}, function(data, status){
				deferred.reject(data);
			});   

			return deferred.promise;
		}

		this.createAppToken = function() {

			var deferred = $q.defer();

			var body = {
				device_id: $localStorage.device_uuid
			}
			
			$http.put(PORTFOLIO_WEBSERVER.app + '/api/app', body).then(function(res){
				//console.log('app response', JSON.stringify(res.data))
				deferred.resolve(res.data);
			}, function(res){
				//console.log('app reject', res.data)
				deferred.reject(res);
			});   

			return deferred.promise;

		}

		this.isAppAuthenticated = function() {

			var deferred = $q.defer();

			var body = {
				device_id: $localStorage.device_uuid,
				app_token: $localStorage.app_token
			}

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/login', body).then(function(data) {
				deferred.resolve(data);
			}, function(data, status) {
				deferred.reject(data);
			});

			return deferred.promise;

		}

	}
]);