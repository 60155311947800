nwuiAccordion.factory('NwuiAccordionFactory', [function() {
	var defaults = {
		name: undefined, //req
		color: '',
		group: undefined,
		expanded: false,
		button: undefined,
		target: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiAccordionFactory");
		if(obj.name === undefined && obj.name_i18n === undefined) return console.error("Missing required instantiation object variable 'name' in NwuiAccordionFactory");
		
		angular.extend(this, defaults, obj);
	}
}]);