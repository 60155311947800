nwuiGridRowColumn.directive('nwuiGridRowColumn', ['$timeout', '$window', function($timeout, $window) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/grid/row/column/template.html',
		scope: {
			column_span: '=columnSpan',
			column_span_t: '=columnSpanT',
			column_span_m: '=columnSpanM'
		},
		link: function(scope, element, attrs) { }
	}
}]);