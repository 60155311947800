nwuiChartDonut.directive('nwuiChartDonut', ['$window', '$timeout', '$filter', '$rootScope', 'DataSharingService', function($window, $timeout, $filter, $rootScope, DataSharingService) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/chart/donut/template.html',
		scope:{
			data: "=",
			selected: "=",
			hovered: "=",
			decimals: "=",
			hover_invert: '=hoverInvert'
		},
		link: function(scope, element, attrs) {

			var d3 = $window.d3 || false;
			if(!d3) {
				console.error('d3.js script not included!');
			}
			
			var data = scope.data;
			var decimals = scope.decimals;
			//var raw_svg;
			var svg;
			var width;
			var height;
			var donut_width;
			var show_text_value = 0;
			var outerRadius;
			var inner_radius;
			var current_slice_num = 0;
			var colors = {
				'equity': {
					count: 0,
					color: '124,201,141'
				},
				'fixed': {
					count: 0,
					color: '79,110,129'
				},
				'cash': {
					count: 0,
					color: '74,74,76'
				},
				'random': {
					count: 0,
					color: '74,74,76'
				},
				'unallocated': {
					count: 0,
					color: '74,74,76'
				}
			}
			
			var color_range = []
			
			var color;
			var arc;
			var pie;
			var path;
			var label;
			
			function selectSlice() {
				
				data.forEach(function(o,i) {
					if(scope.selected == o)
						toggle(i, outerRadius, 350)
				});
				
			}
			
			function mouseenter(i, outerRadius, duration, is_returned) {
				
				if(is_returned) {
					return function() {
						scope.selected = data[i];
						scope.hovered = data[i];
						scope.$apply();
					}
				} else {
					scope.selected = data[i];
					scope.hovered = data[i];
					scope.$apply();
				}
				
			}
			
			function toggle(i, outerRadius, duration) {
					
				var new_slice_num = i;
				
				if(current_slice_num != -1) {
					svg
						.selectAll('path.active')
						.each(function(d, i) {
							arcTween(d3.select(this), outerRadius-10, 150, duration, false)
						})
				}
					
				current_slice_num = new_slice_num;
				arcTween(svg.select('#slice-'+new_slice_num), outerRadius, 0, duration, true)
				
			}
			
			function arcTween(obj, outerRadius, delay, duration, is_showing) {
				
				var text = svg.select('text');
				
				obj.transition().duration(duration).delay(delay).attrTween("d", function(d) {
					var i = d3.interpolate(d.outerRadius, outerRadius);
					
					if(d == scope.selected)
						return;
					
					if(is_showing)
						obj.attr('class', 'active ' + obj.attr('class'))
					// else {
					// 	// obj.attr('class', d.data.type)
					// }
					
					if(outerRadius > d.outerRadius) {
						var old_value = show_text_value;
						show_text_value = d.value;
					}
					
					return function(t) {
						
						if(is_showing && outerRadius > d.outerRadius) {

							text.text(function() {  
								var val = $filter('NwuiPercent')((old_value + (d.value - old_value)*t).toFixed(decimals), decimals);
								return val;
							});
							
						}
						
						d.outerRadius = i(t);
						return arc(d);
						
					};
				});

			}
			
			var svg = d3.select(element[0])
				.select('.resp')
				.append("svg");
			
			scope.loaded = false;
			
			scope.render = function() {
				
				if(!data) return;
				
				colors.equity.count = 0;
				colors.fixed.count = 0;
				colors.cash.count = 0;
				color_range = [];
				for(var i = 0; i < data.length; i++) {
					data[i].type = data[i].type && colors[data[i].type] ? data[i].type : 'random';
					color_range.push('rgba(' + colors[data[i].type].color + ',' + (1 - colors[data[i].type].count*0.15) + ')');
					colors[data[i].type].count++;
				}
				
				color = d3.scale.ordinal().range(color_range);

				width = $(element).width();
				height = width;
				
				var donut_base = (width < height) ? width : height;
				var pad_angle = 20/(Math.PI*width);
				donut_width = 40 * (donut_base/165);
				outerRadius = Math.min(width, height)/2;
				inner_radius = outerRadius - donut_width;
				
				svg
					.attr('width', width)
					.attr('height', height)
					.attr("viewBox", "0 0 " + width + " " + height)
					.attr('xmlns', "http://www.w3.org/2000/svg")
					.attr('version', "1.1")
					.attr('xmlns:xlink', "http://www.w3.org/1999/xlink")
					.selectAll('*')
					.remove();
					
				arc = d3.svg.arc()
					.innerRadius(inner_radius)
				pie = d3.layout.pie()
					.value(function(d) { return d.percent; })
					.sort(null)
					.padAngle(function(d) { 
						// Remove the gap between the segments if the whole pie is one segment
						for(var i = 0; i < d.length; i++) {
							if (d[i].percent === 100) {
								return 0;
							}
						}
						return pad_angle 
					})
				
				var type_count = {};

				path = svg
					.append('g')
					.attr('class', 'slices')
					.attr('transform', 'translate(' + (width / 2) +  ',' + (height / 2) + ')')
					.selectAll('path')
					.data(pie(scope.checkForUnallocated()))
					.enter()
					.append('path')
					.each(function(d, i) { 
						if(type_count[d.data.type] === undefined)
							type_count[d.data.type] = 0;
						else
							type_count[d.data.type]++;
						var number;
						if(d.data.hide || d.data.percent_range)
							number = 0;
						else
							number = Math.floor(type_count[d.data.type]/colors[d.data.type].count * 10);
						//NOTE(DWicks): the way i'm doing this deciding on what type of equity it is feels not great
						// would love suggestions on a better way to do this from someone who knows
						// the top->bottom of this flow better
						var equityTypeFromi18Key = d.data.i18n_key? d.data.i18n_key.split("_")[0] : null;
						var equityTypeClass = d.data.type==='equity' && equityTypeFromi18Key? "equity-"+equityTypeFromi18Key: '';
						
						d3.select(this).attr('id', 'slice-' + i)
						d3.select(this).attr('class', 
							`${d.data.type} ${d.data.type}-${number} ${equityTypeClass}`
						)
						d3.select(this).on("mouseover", mouseenter(i, outerRadius, 350, true))
						d.outerRadius = outerRadius - 10;
					})
					.attr('d', arc)
					//.style("fill", function(d) { return color(d.data.name); })
				.on("mouseout", function() {
					scope.hovered = null;
					scope.$apply();
				})
					
				label = svg
					.append('g')
					.attr('class', 'labels')
					.attr('transform', 'translate(' + (width / 2) +  ',' + (height / 2) + ')')
					.append('text')
					.attr('class', 'center-text')
					.attr('y', function(d) { return 5; })
					.text(function(d) { return ""; })
				
				scope.loaded = true;
				
				selectSlice();
				
			}
			
			scope.checkForUnallocated = function() {
				var tempData = JSON.parse(JSON.stringify(data));
				for(var i=0; i < tempData.length; i++){
					if(tempData[i].type === 'unallocated' && tempData[i].percent === 0)
					tempData.splice(i, 1);
				}
				return tempData;
			}
			window.onresize = function() {
				scope.$apply();
			};
			
			scope.$watch(function() {
				return angular.element($window)[0].innerWidth;
			}, function() {
				scope.render();
			});
			
			scope.$watch('data', function(new_val, old_val) {
				
				data = scope.data;
				scope.render();
				
			})
			
			scope.$watch('selected', function(new_val, old_val) {
				if(!data) return;
				selectSlice();
			});
			$rootScope.$on('pieDataUpdate', function(event){
				var newPieData = DataSharingService.getValue('rpq_pie_data', 'pie_data');
				scope.data = newPieData;
				scope.render();
			});
			
		}
	}
}]);