nwuiGoalProgress.factory('NwuiGoalProgressFactory', [function() {
	
	var defaults = {
		current: undefined, //req
		target: undefined, //req
        delta: undefined, //req
        date: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiGoalProgressFactory");
		if(obj.current === undefined) return console.error("Missing required instantiation object variable 'current' in NwuiGoalProgressFactory");
		if(obj.target === undefined) return console.error("Missing required instantiation object variable 'target' in NwuiGoalProgressFactory");
		if(obj.delta === undefined) return console.error("Missing required instantiation object variable 'delta' in NwuiGoalProgressFactory");
		if(obj.date === undefined) return console.error("Missing required instantiation object variable 'date' in NwuiGoalProgressFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);