nwuiCardTableV3BodyRowColumnButton.directive('nwuiCardTableV3BodyRowColumnButton', [
	'$timeout', 
	'NwuiButtonV2Factory',
	'NwuiButtonV3Factory',
	function(
		$timeout, 
		NwuiButtonV2Factory,
		NwuiButtonV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/card/table/v3/body/row/column/button/template.html',
			scope: {
				button: '<'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						//just in case
						if(angular.isUndefined(scope.button)) scope.button = {};

						//overrides
						scope.button.size = 'short';
						scope.button.stretch = true;

					},
					post: function(scope, element, attrs) {}
				}
			}
		}
	}
]);