nwuiCutout.directive('nwuiCutout', ['$timeout', '$window', function($timeout, $window) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/cutout/template.html',
		scope: {},
		link: function(scope, element, attrs) {
			scope.svg = {
				cont: {
					width: 0,
					height: 0
				},
				rect: {
					width: 0,
					height: 0
				}
			}
			
			var calibrate = function() {
				scope.svg.cont.width = $(element).width();
				scope.svg.cont.height = $(element).height();
				scope.svg.rect.width = Math.max(scope.svg.cont.width - 2, 0);
				scope.svg.rect.height = Math.max(scope.svg.cont.height - 2,0);
				
			}
			
			window.onresize = function() {
				scope.$apply();
			};
			
			scope.$watch(function() {
				return angular.element($window)[0].innerWidth;
			}, function(new_val) {
				$timeout(function() {
					calibrate();
				}, 0);
			});

			calibrate()
			
		}
	}
}]);