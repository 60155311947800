nwuiBankVerificationsNbmx.directive('nwuiBankVerificationsNbmx', [
  '$sce',
  function(
    $sce
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/bank-verifications/nbmx/template.html',
      scope: {
        manualClickedCb: '=',
        getAccountsCb: '=',
        widgetDetails: '='
      },
      link: function($scope, element, attrs) {

        function getIframe() {
          return document.getElementById("nbmx-iframe")
        }

        function handleWindowMessages(evt) {
          var ifm = getIframe();
          if (evt.source === ifm.contentWindow) {
            var payload = JSON.parse(evt.data);
            if (!payload || !payload.guid) {
              payload = {
                guid: 'MBR-94270be3-691b-464a-822a-07d94d1a51df'
              }
            }
            console.log('payload', payload);
            if(payload && payload.guid) {
              handleLoginAndRequestIdMessage(payload.guid);
            }
          }
        }

        function handleLoginAndRequestIdMessage(guid) {
          if (typeof $scope.getAccountsCb === 'function') {
            $scope.getAccountsCb({
              provider: 'nbmx',
              data: {
                loginId: guid
              }
            });
          }
        }

        function postMessageToMX() {
          var ifm = ifm = getIframe();
          var url = $scope.widgetDetails.url;
          var baseUrlPattern = /^https?:\/\/[^\/]+/i;
          var widgetBaseUrl = url && url.match(baseUrlPattern) ? url.match(baseUrlPattern)[0] : null;
          var postMessageOrigin = widgetBaseUrl || 'https://widgets.moneydesktop.com';
          var opts = {
            type: 'clientConfig',
            data: {
              language: $scope.widgetDetails.language.toLowerCase(),
              connect: {
                mode: 'verification'
              }
            }
          };

          console.log('sending options to mx iframe', opts);
          ifm.contentWindow.postMessage(JSON.stringify(opts), postMessageOrigin);
        }

        window.addEventListener('message', handleWindowMessages);

        $scope.$watch('widgetDetails', function(error) {
          if ($scope.widgetDetails) {
            $scope.iframeUrl = $sce.trustAsResourceUrl($scope.widgetDetails.url);
            postMessageToMX()
          }
        });

        $scope.onClickManual = function() {
          if (typeof $scope.manualClickedCb === 'function') {
            $scope.manualClickedCb();
          }
        }

        $scope.$on('$destroy', function() {
          window.removeEventListener('message', handleWindowMessages);
        })
      }
    }

  }
]);