nwuiAuth.constant('NWUI_AUTH_EVENTS', {
    loginSuccess: 'auth-login-success',
    loginFailed: 'auth-login-failed',
    logoutSuccess:'auth-logout-success',
    sessionTimeout:'auth-session-timeout',
    authDone: 'auth-done',
    notAuthenticated: 'auth-not-authenticated',
    notAuthorized: 'auth-not-authorized',
    authInactivityWarning: 'auth-inactivity-warning',
    authInactivityTimeout: 'auth-inactivity-timeout'
});

nwuiAuth.constant('NWUI_AUTH_ROLES', {
	all:'*',
    admin:'admin',
    guest:'guest',
    user:'user'
});