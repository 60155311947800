nwuiCompoundModalCreateAccountTypeSelect.directive('nwuiCompoundModalCreateAccountTypeSelect', [
	'$q',
	'$sce',
	'$timeout',

	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	'NwuiFormV3Factory',

	'NwuiToastManagerService',
	'NwButtonService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwuiRegisterService',
	function(
		$q,
		$sce,
		$timeout,

		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,
		NwuiFormV3Factory,

		NwuiToastManagerService,
		NwButtonService,
		NwuiInputService,
		NwuiFormV3Service,
		NwuiRegisterService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/create-account-type-select/template.html',
			scope: {
				open: '=',
				accountTypesResource: '=accountTypesResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {

				var seed_id = NwuiRegisterService.register();

				var single_account_select = (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.accounts &&
					organization_data.port_data.accounts.create_single
				)

				var showAccounts = function(value) {

					var options = [];
					for (var i = 0; i < scope.data.ownership_types.length; i++) {
						if (scope.data.ownership_types[i].type === value) {
							for (var j = 0; j < scope.data.ownership_types[i].accounts.length; j++) {
								var ownershipType = scope.data.ownership_types[i];
								var label = ownershipType.custodian_slug ?
									'nwui.forms.' + ownershipType.custodian_slug + '.questions.accounts.options.' + scope.data.ownership_types[i].accounts[j].model :
									'nwui.forms.questions.accounts.options.' + scope.data.ownership_types[i].accounts[j].model;
								if (single_account_select) {
									options.push({
										id: 'create-account-type-select-form-' + seed_id + '-account-types-' + i + '-' + j,
										label_i18n: label,
										value: scope.data.ownership_types[i].accounts[j].model,
										description_content_i18n: (scope.data.ownership_types[i].accounts[j].description_i18n) ? scope.data.ownership_types[i].accounts[j].description_i18n + '.content' : undefined
									})
								} else {
									options.push({
										label_i18n: label,
										model: scope.data.ownership_types[i].accounts[j].model,
										default_value: false,
										value: false
									})
								}
							}
						}
					}

					NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-account-types'].options = options;

				}

				scope.$watch('open', function(new_val) {

					if (new_val === false) {

						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.ownership_type_select.form.name);
						}, 500);

					} else if (new_val === undefined) return;

					scope.modals.ownership_type_select.open = new_val;

				});

				scope.$watch('accountTypesResource', function(new_val) {

					if (new_val === undefined) return;

					scope.accountTypesResource().then(function(res) {
						var ownership_types = res.data;
						scope.data.ownership_types = ownership_types;

						var options = [];
						for (var i = 0; i < ownership_types.length; i++) {
							options.push({
								id: 'create-account-type-select-form-' + seed_id + '-ownership-types-' + i,
								value: ownership_types[i].type,
								label_i18n: "nwui.ownership_types." + ownership_types[i].type + ".label"
							})
						}

						NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-ownership-types'].options = options;
						NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-ownership-types'].model = options[0].value;
						NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-ownership-types'].hide = (options.length === 1);

						showAccounts(options[0].value)

					});

				})

				scope.modules = {
					buttons: {
						submit: {
							id: 'create-account-type-select-' + seed_id + '-submit-button',
							text_i18n: 'nwui.common.form.button.submit',
							color: 'warning',
							stretch: true,
							icon: {
								name: 'check'
							},
							onClick: function() {

								var valid = NwuiFormV3Service.validate(scope.modals.ownership_type_select.form.name)
								if (valid !== true) return;

								var data = NwuiFormV3Service.getFormData(scope.modals.ownership_type_select.form.name)

								var obj = {
									ownershipType: NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-ownership-types'].model
								}

								if (single_account_select) {
									obj.accountTypes = [NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-account-types'].model];
								} else {
									obj.accountTypes = NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-account-types'].options.filter(function(option) {
										return option.value === true
									})
								}

								scope.submitCb(obj, false);

							}
						},
						schedule: {
							id: 'create-account-type-select-' + seed_id + '-schedule-button',
							text_i18n: 'nwui.modal.create_account_type_select.schedule.button',
							color: 'warning',
							stretch: true,
							icon: {
								name: 'schedule'
							},
							onClick: function() {

								var obj = {
									ownershipType: NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-ownership-types'].model
								}

								if (single_account_select) {
									obj.accountTypes = [NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-account-types'].model];
								} else {
									obj.accountTypes = NwuiInputService.inputs['create-account-type-select-form-' + seed_id + '-account-types'].options.filter(function(option) {
										return option.value === true
									})
								}

								scope.submitCb(obj, false);

							}
						}
					}
				}

				scope.data = {
					ownership_types: [],
					show_scheduler: false
				}

				scope.modals = {
					ownership_type_select: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.create_account_type_select.title',
							icon: {
								name: 'create'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'create-account-type-select-form-' + seed_id,
							submit_button: false,
							onSubmit: function(post_data) {

								var deferred = $q.defer();
								deferred.resolve(post_data);
								return deferred.promise;

							},
							onComplete: function(obj) {
								scope.submitCb(obj);
							},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'radio',
										data: {
											label_i18n: 'nwui.common.input.ownership_type.label',
											model: '',
											id: 'create-account-type-select-form-' + seed_id + '-ownership-types',
											stretch: true,
											required: true,
											options: [],
											onChange: function(model) {

												if (model)
													showAccounts(model)

											},
											post_var: 'ownershipType'
										}
									}
								}, {
									directive: 'input',
									width: 100,
									data: {
										directive: single_account_select ? 'radio' : 'checkbox',
										data: {
											label_i18n: 'nwui.common.input.account_type.label',
											model: '',
											id: 'create-account-type-select-form-' + seed_id + '-account-types',
											stretch: true,
											required: true,
											options: [],
											onChange: function(model) {

												for (var i = 0; i < scope.data.ownership_types.length; i++) {
													for (var j = 0; j < scope.data.ownership_types[i].accounts.length; j++) {
														if (scope.data.ownership_types[i].accounts[j].model === model) {
															if (scope.data.ownership_types[i].accounts[j].offline) {
																scope.data.show_scheduler = true;
															} else {
																scope.data.show_scheduler = false;
															}
														}
													}
												}

											},
											post_var: 'accountTypes'
										}
									}
								}]
							}]
						})
					})
				}
			}
		}

	}
]);