nwuiInputDropList.service('NwuiInputDropListService', [
	'$window', 
	'$rootScope', 
	function(
		$window, 
		$rootScope
	) {
	
		var self = this;
		var observers = [];
		
		this.clear = function() {
			observers = [];
		}
		
		this.notify = function(apply) {
			angular.forEach(observers, function(cb) {
				cb(apply);
			});
		};
		
		this.register = function(cb) {
			observers.push(cb);
		}
		
		$(window).on('click', function() {
			self.notify(true);
		});
		
		$rootScope.$on('$stateChangeSuccess', function() {
			self.notify();
		});
	    
	}
]);