questionPieLegend.directive('questionpielegend', [
	function() {
		return {
			restrict: "E",
			replace: true,
			scope: {
				'data': '=data',
				'hover': '=hover',
				'editMode': '=editMode'
			},
			templateUrl: '/question-pie-legend/template.html',
			link: function(scope, element, attrs) {

				scope.$watch('data', function(new_val) {
					if(new_val === undefined) return;

					var total_count = {};
					scope.data.forEach(function(datum, i) {
						if(total_count[datum.type] === undefined)
							total_count[datum.type] = 1;
						else
							total_count[datum.type]++;
					})

					var count = 0;
					var type_count = {};
					scope.data.forEach(function(datum, i) {
						if(datum.value !== -1) {
							datum.type = new_val[count].type;
							count++;

							if(type_count[datum.type] === undefined)
								type_count[datum.type] = 0;
							else
								type_count[datum.type]++;

							datum.type_count = 'type-' + datum.type + '-' + Math.floor(type_count[datum.type]/total_count[datum.type]*10);
						}
					});
					
				});
			
			}
		}
	}
]);