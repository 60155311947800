angular.module('nwui-compound-modal-ownership-type-select.directives', [])

.directive('nwuiCompoundModalOwnershipTypeSelect', [
	'$timeout',
	'$sce',
	'$q',
	'locale',
	'NwuiModalV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardTableFactory',
	'NwuiFormV3Factory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	
	'NwButtonService',
	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiValidationErrorService',
	'NwuiFormV3Service',
	function(
		$timeout,
		$sce,
		$q,
		locale,
		NwuiModalV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiCardTableFactory,
		NwuiFormV3Factory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		
		NwButtonService,
		NwuiToastManagerService,
		NwuiInputService,
		NwuiValidationErrorService,
		NwuiFormV3Service
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/ownership-type-select/template.html',
			scope: {
				open: '=',
				ownershipTypeResource: '=ownershipTypeResource',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var accounts_array = [];

				scope.$watch('open', function(new_val) {
					
					if(new_val === undefined) return;
					
					if(new_val === true)
						NwuiFormV3Service.focus(scope.modal.form.name);
					else {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modal.form.name);
						}, 750)
					}

					scope.modal.open = new_val;
					
				});

				scope.$watch('ownershipTypeResource', function(new_val) {

					if(new_val === undefined) return;

					new_val().then(function(res) {

						NwuiInputService.inputs['ownership-type-select-form-radio-ownership-types'].options = res.data.map(function(ownership_type, i) {
							ownership_type.accounts.forEach(function(account, i) {
								accounts_array.push({
									ownership_type: ownership_type.type,
									text: account.text,
									model: account.model
								})
							})

							return {
								id: 'ownership-type-select-form-radio-ownership-types-option-' + i,
								value: ownership_type.type,
								label_i18n: "nwui.ownership_types." + ownership_type.type + ".label"
							}
						});
	
						NwuiInputService.inputs['ownership-type-select-form-radio-ownership-types'].model = NwuiInputService.inputs['ownership-type-select-form-radio-ownership-types'].options[0].value

						NwuiInputService.inputs['ownership-type-select-form-check-account-types'].options = accounts_array.filter(function(account) {
							return account.ownership_type === NwuiInputService.inputs['ownership-type-select-form-radio-ownership-types'].model
						}).map(function(account, i) {
							return {
								id: 'ownership-type-select-form-check-account-types-option-' + i,
								value: false,
								model: account.model,
								label_i18n: "nwui.forms.questions.accounts.options." + account.model
							}
						})

					}, function(err) {
						console.log('err', err)
					})

				});
				
				scope.modal = new NwuiModalV2Factory({
					open: false,
					wide: false,
					locked: false,
					header: {
						name_i18n: 'nwui.modal.ownership_type_select.title',
						icon: {
							name: 'create'
						}
					},
					form: new NwuiFormV3Factory({
						name: 'ownership-type-select-form',
						onSubmit: function(post_data) {

							var data = {
								ownershipType: post_data.ownershipType,
								accounts: [],
								type: 'AccountOpening'
							}

							for(var i = 0; i < post_data.accountTypes.length; i++) {
								if(post_data.accountTypes[i].value === true)
									data.accounts.push(post_data.accountTypes[i].model);
							}

							console.log(data);

							return scope.formResource(data);
							
						},
						onComplete: function(obj) {

							if(obj.data._id) {

								scope.submitCb(obj.data);
								scope.open = false;

								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.ownership_type_select.success',
										icon: {
											name: 'check'
										}
									}
								));

							} else {

								NwuiToastManagerService.register(
									new NwuiToastFactory({
										// text: 'Something went wrong.',
										text_i18n: 'nwui.common.error.unknown',
										color: 'error',
										icon: {
											name: 'close'
										}
									}
								));

							}

						},
						onError: function(err) {

							console.log(err);
							var text_i18n = undefined;
							var text = undefined;

							switch(err.status) {
								case 400:
									text = err.data.message;
									break;
								case 500:
								default:
									text_i18n = 'nwui.common.error.unknown';
									break;
							}

							NwuiFormV3Service.focus(scope.modal.form.name);
							NwuiFormV3Service.clear(scope.modal.form.name);

							NwuiFormV3Service.showMessage(scope.modal.form.name, {
								text: text,
								text_i18n: text_i18n,
								icon: {
									name: 'error',
									color: 'error'
								}
							});
							
						},
						steps: [{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'radio',
									data: {
										label_i18n: 'nwui.common.input.ownership_type.label',
										model: '',
										id: 'ownership-type-select-form-radio-ownership-types',
										stretch: true,
										required: true,
										options: [],
										onChange: function(model) {
											
											NwuiInputService.inputs['ownership-type-select-form-check-account-types'].model = [];
											NwuiInputService.inputs['ownership-type-select-form-check-account-types'].options = accounts_array.filter(function(account) {
												return account.ownership_type === model
											}).map(function(account, i) {
												return {
													id: 'ownership-type-select-form-check-account-types-option-' + i,
													value: false,
													model: account.model,
													label_i18n: "nwui.forms.questions.accounts.options." + account.model
												}
											})

										},
										post_var: 'ownershipType'
									}
								}
							}, {
								directive: 'input',
								width: 100,
								data: {
									directive: 'checkbox',
									data: {
										label_i18n: 'nwui.common.input.account_type.label',
										model: '',
										id: 'ownership-type-select-form-check-account-types',
										stretch: true,
										required: true,
										options: [],
										post_var: 'accountTypes'
									}
								}
							}]
						}]
					})
				})
	
			}
		}
	}
]);