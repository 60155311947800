nwuiRipple.directive('nwuiRipple', ['$window', '$timeout', function($window, $timeout) {
	return {
		restrict: 'E',
		replace: true,
		transclude: true,
		templateUrl: '/ripple/template.html',
		scope:{
			light: "=",
			overflow: "=",
			max_size: "=maxSize"
		},
		link: function(scope, element, attrs) {
			
			var offset;
			var ripple_obj = {
				style: {},
				timeout: undefined,
				touch: false,
				release: false
			};

			var release = function() {
				//console.log('nwuiRipple', 'release');

				if(scope.queue.length == 0) return;
				
				var ripple = scope.queue[scope.queue.length-1];
				ripple.timeout = $timeout(function() {
					//console.log('nwuiRipple', 'release', 'delay', 150);
					ripple.release = true;
					
					$timeout(function() {
						scope.queue.splice(0,1);
					}, 2000);
				}, 150);

				window.removeEventListener('touchend', release);
				window.removeEventListener('mouseup', release);
				
			}

			var render = function() {

				var w = element[0].offsetWidth/2 * 0.85;
				var h = element[0].offsetHeight/2 * 0.85;

				var size = (w >= h) ? w : h;
				var max_size = scope.max_size || size;
				
				size = size > max_size ? max_size : size;
				
				scope.spread_style = {
					top: -size + 'px',
					left: -size + 'px',
					right: -size + 'px',
					bottom: -size + 'px',
				}
				
			}
			
			//size of spread
			scope.spread_style = {};
			scope.queue = [];
			scope.origin_style = {};
			//scope.active = false;
			
			scope.touch = function(e) {
				//console.log('nwuiRipple', 'touch', e);

				render();
				var ripple = angular.copy(ripple_obj);

				if(e.type == 'mousedown') {
					
					ripple.style.top = e.offsetY + 'px';
					ripple.style.left = e.offsetX + 'px';

					window.addEventListener('mouseup', release);
					
				} else {
					
					var offset = $(element)[0].getBoundingClientRect();
					
					ripple.style.top = e.touches[0].pageY - offset.top + 'px';
					ripple.style.left = e.touches[0].pageX - offset.left + 'px';
					
					window.addEventListener('touchend', release);

				}
				
				scope.queue.push(ripple);
				
				//timeout to animate to touch
				$timeout(function() {
					ripple.touch = true;
					// schedule(scope.queue[scope.queue.length-1]);
				});
				
			}
			
		}
	}
}]);