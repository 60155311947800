angular.module('nwui-form-v3.controller', [])

.controller('nwuiFormV3Ctrl', [
	'$scope',
	'$timeout',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwuiAnalyticsService',
	function(
		$scope,
		$timeout,
		NwuiInputService,
		NwuiFormV3Service,
		NwuiAnalyticsService
	) {

		var self = this;
		var steps = [];
		var inputs = [];
		var submit_button = undefined;
		var submit_button_config = {};

		this.getFormData = function() {

			var data = {}
				
			for(var i = 0; i < inputs.length; i++) {

				//is a group (checkboxes probably)
				if(
					inputs[i].options && 
					inputs[i].options.length > 0 &&
					inputs[i].options[0].model
				) {

					inputs[i].options.forEach(function(option, j) {
						if(inputs[i].model && inputs[i].model[j])
							data[option.model] = inputs[i].model[j].value;
					})

				}

				if(inputs[i].post_var) {
					data[inputs[i].post_var] = inputs[i].model;
				}

			}

			return data;

		}

		this.focus = function() {

			$timeout(function() {
				//focus if first input is a text input
				$('#' + steps[$scope.active_step_index].inputs[0].id).focus();
			}, 1250);

		}

		this.clear = function() {

			self.hideMessage();

			//iterate form steps
			for(var j=0; j < steps.length; j++) {
				
				//iterate step inputs
				for(var k=0; k < steps[j].inputs.length; k++) {

					if(NwuiInputService.inputs[steps[j].inputs[k].id].clearCb) {
						NwuiInputService.inputs[steps[j].inputs[k].id].clearCb();
					} else {
						NwuiInputService.inputs[steps[j].inputs[k].id].model = '';
					}
				}
			}

			$scope.active_step_index = 0;
			if(steps[steps.length-1] && steps[steps.length-1].button && submit_button_config.disabled !== false)
				steps[steps.length-1].button.disabled = true;

		}

		this.setDirty = function(input_id) {

			if(steps.length > 0 && steps[steps.length-1].button)
				steps[steps.length-1].button.disabled = false;

		}

		this.enterKeySubmit = function(input_id, event) {
					
			//iterate form steps
			for(var j=0; j < steps.length; j++) {
				//iterate step inputs
				for(var k=0; k < steps[j].inputs.length; k++) {
					//check for matching form step input id
					if(input_id == steps[j].inputs[k].id) {

						if(event)
							event.preventDefault();
						self.submitStep();
						return false;

					}
				}
			}
			
		}

		this.hideMessage = function() {
			
			if(steps[$scope.active_step_index] === undefined || steps[$scope.active_step_index].message === undefined) return; 

			steps[$scope.active_step_index].message.expanded = false;
			steps[$scope.active_step_index].message.recommendation = undefined;

		}

		this.showMessage = function(recommendation) {

			steps[$scope.active_step_index].message.expanded = true;
			steps[$scope.active_step_index].message.recommendation = recommendation;

		}

		this.goToNextStep = function(current_step_index) {

			$scope.active_step_index = current_step_index + 1;
			this.focus();

		}

		this.goToPrevStep = function(current_step_index) {

			$scope.active_step_index = current_step_index - 1;

		}

		this.registerInput = function(step_index, input) {
			if(steps[step_index])
				steps[step_index].inputs.push(input);
			
			inputs.push(input);
		}

		this.registerStep = function(step) {
			
			submit_button_config = step.submit_button;

			steps[step.step_index] = {
				button: {},
				onSubmit: step.onSubmit,
				onSecondary: step.onSecondary,
				onComplete: step.onComplete,
				onError: step.onError,
				message: step.message,
				inputs: []
			}

		}

		this.registerStepSubmitButton = function(step_index, button) {
			
			if(steps[step_index])
				steps[step_index].button = button || {};

		}

		this.validate = function(step_inputs) {

			var is_valid = true;

			if(!step_inputs) {
				step_inputs = inputs
			}

			step_inputs.filter(function(input, i) {
				return input.hide !== true
			}).forEach(function(input,i) {
				if(is_valid === true) {
					is_valid = NwuiInputService.validate(input.id);
					if(is_valid !== true);
				}
			});

			return is_valid;

		}

		this.submitStep = function() {
			

			var postSubmitSuccess = function(form_data) {
				if(steps[$scope.active_step_index].onComplete)
					steps[$scope.active_step_index].onComplete(form_data);

				if (!$scope.disableAutomaticButtonProcessing)
				  steps[$scope.active_step_index].button.processing = false;
				self.goToNextStep($scope.active_step_index);
			}

			var postSubmitError = function(form_data) {
				if(steps[$scope.active_step_index].onError)
					steps[$scope.active_step_index].onError(form_data);

        if (!$scope.disableAutomaticButtonProcessing)
				  steps[$scope.active_step_index].button.processing = false;
			}

			if(!steps[$scope.active_step_index].button)
				steps[$scope.active_step_index].button = {};

			if(
				steps[$scope.active_step_index].button.processing || 
				steps[$scope.active_step_index].button.disabled
			) return;

			self.hideMessage()
			
			var is_valid = true;

      if (!$scope.disableAutomaticButtonProcessing)
			  steps[$scope.active_step_index].button.processing = true;

			is_valid = this.validate(steps[$scope.active_step_index].inputs);

			// steps[$scope.active_step_index].inputs.filter(function(input, i) {
			// 	return input.hide !== true
			// }).forEach(function(input,i) {
			// 	if(is_valid === true)
			// 		is_valid = NwuiInputService.validate(input.id);
			// });
			
			if(is_valid === true) {
				if($scope.active_step_index === steps.length - 1)
					self.submit();
				else {
					var form_data = self.getFormData();

					if(steps[$scope.active_step_index].onSubmit) {
						steps[$scope.active_step_index].onSubmit(form_data).then(function(obj) {
							NwuiAnalyticsService.track(self.name + '-submit', form_data);
							postSubmitSuccess(obj);
						}, function(obj) {
							postSubmitError(obj);
						});
					} else {
						postSubmitSuccess(form_data);
					}
				}
			} else {
        if (!$scope.disableAutomaticButtonProcessing)
				  steps[$scope.active_step_index].button.processing = false;
			}

		}

		this.submit = function() {
			
			self.hideMessage();

			var is_valid = true;
			
			is_valid = this.validate();
			
			if(is_valid === true) {
				var data = self.getFormData();
				
				$timeout(function() {
					$('#submit-button-' + self.name).click();
				}, 0);
				
				$scope.onSubmit(data).then(function(obj) {
					NwuiAnalyticsService.track(self.name + '-submit', data);
          if (!$scope.disableAutomaticButtonProcessing)
					  steps[$scope.active_step_index].button.processing = false;
					if($scope.onComplete)
						$scope.onComplete(obj);
				}, function(obj) {
          if (!$scope.disableAutomaticButtonProcessing)
					  steps[$scope.active_step_index].button.processing = false;
					if($scope.onError)
						$scope.onError(obj);
				});
			} else {
				$timeout(function() {
          if (!$scope.disableAutomaticButtonProcessing)
					  steps[$scope.active_step_index].button.processing = false;
					if($scope.onError)
						$scope.onError({ error: is_valid })
				}, 500);
			}

		}

		this.secondaryStep = function () {

			self.hideMessage();

			var is_valid = this.validate();

			if (is_valid === true) {
				var data = self.getFormData();
				$scope.onSubmit(data).then(function (obj) {
					NwuiAnalyticsService.track(self.name + '-submit', data);
					if (!$scope.disableAutomaticButtonProcessing)
						steps[$scope.active_step_index].button.processing = false;
					if ($scope.onComplete)
						$scope.onSecondaryAction(obj);
				}, function (obj) {
					if (!$scope.disableAutomaticButtonProcessing)
						steps[$scope.active_step_index].button.processing = false;
					if ($scope.onError)
						$scope.onError(obj);
				});
			} else {
				if (!$scope.disableAutomaticButtonProcessing)
					steps[$scope.active_step_index].button.processing = false;
				if ($scope.onError)
					$scope.onError({
						error: is_valid
					})
			}
		}
		
		$scope.$watch('name', function(new_val, old_val) {
			if(new_val === undefined) return;

			self.name = new_val;
			inputs = [];
			NwuiFormV3Service.register(self);

		});

		$scope.$watch('manualButtonProcessing', function(new_val, old_val) {
		  if (new_val === undefined) return;

		  if (
		    $scope.disableAutomaticButtonProcessing &&
        steps[$scope.active_step_index]
      ) {
        steps[$scope.active_step_index].button.processing = new_val;
      }
    })

	}
]);