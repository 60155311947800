nwuiCompoundMenuSecondary.directive('nwuiCompoundMenuSecondary', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/compound/menu/secondary/template.html',
	        scope: {},
	        link: function(scope, element, attrs) {
				
				
	
		    }
		}
	}
]);