nwuiAllocation.factory('NwuiAllocationFactory', [function() {
	
	return function(obj) {
		var defaults = {
			name: undefined,
			name_i18n: undefined,
			series: [],
			decimals: 0
		}

		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiAllocationFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);