nwuiTabs.directive('nwuiTabs', ['NwuiAnalyticsService', function(NwuiAnalyticsService) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: '/tabs/template.html',
    scope: {
      "selected": "=",
      "options": "<"
    },
    link: function($scope, element, attrs) {
      $scope.onClickOption = function(option) {
        NwuiAnalyticsService.track(option);
        $scope.selected = option.value;
      }
    }
  }
}]);