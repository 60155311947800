angular.module('nwui-toolkit').service('ToggleLangService', [
	'DomainParserService',
	"$location",
	"$cookies",
	"locale",
	"tmhDynamicLocale",
	"NwuiLocalizeService",
	function (
		DomainParserService,
		$location,
		$cookies,
		locale,
		tmhDynamicLocale,
		NwuiLocalizeService
	) {


		this.toggle = function (langParam) {
			var domainParam = DomainParserService.parse(window.location.href);
			if (typeof langParam === 'undefined') {
				//priorize url param over current cookie
				if ($location.$$search && $location.$$search.lang) {
					langParam = $location.$$search.lang;
				} else {
					langParam = $cookies.get(localeConf.cookieName);
				}
			}
			
			locale.setLocale(langParam, domainParam);
			moment.locale(locale.getLocale().toLowerCase());
			tmhDynamicLocale.set(locale.getLocale());
			NwuiLocalizeService.setLang(locale.getLocale());
		}

	}
]);