angular.module("nwui-toolkit").factory('NwuiModalFactory', [function() {
	
	var defaults = {
		directive: 'custom',
		data: undefined,
		locked: false,
        open: false,
        wide: false,
        close_text: 'Close'
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object");
		
		angular.extend(this, defaults, obj);
	}
	
}]);