nwuiDrawerV1.service('NwuiDrawerV1Service', [
	'$rootScope',
	'$timeout',
	function(
		$rootScope,
		$timeout
	) {
		
		var self = this;	
		this.drawers = {};
		
		this.unregister = function(id) {
			delete self.drawers[id];
		}
		
		this.register = function(drawer) {
			//console.log('NwuiDrawersV1Service', 'register', drawer);
			
			self.drawers[drawer.id] = drawer;
		}

		this.close = function() {

			for(var id in self.drawers) {
				if(self.drawers[id].open === true) {
					self.drawers[id].open = false;
				}
			}

		}
		
		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			$timeout(function() {
				self.close();
			}, 750);
		});
				
	}
]);