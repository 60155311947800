nwuiIconV2.factory('NwuiIconV2Factory', [function() {
	
	var defaults = {
		name: undefined, //req
		size: '',
		clickEvent: function() { return; },
		tooltip: undefined,
		active: false,
		color: ''
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiIconV2Factory");
		if(obj.name === undefined) return console.error("Missing required instantiation object variable 'name' in NwuiIconV2Factory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);