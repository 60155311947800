nwuiCardTableBodyRowColumnCheckbox.directive('nwuiCardTableBodyRowColumnCheckbox', [
	'NwuiRegisterService',
	function(
		NwuiRegisterService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/card/table/body/row/column/checkbox/template.html',
			scope: {
				input: '=',
				onChange: '=onChange'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						var seed_id = NwuiRegisterService.register();

						scope.input = {
							label: '',
							model: '',
							id: 'nwui-card-table-body-row-column-checkbox-'+seed_id,
							stretch: true,
							required: true,
							options: [{
								label: '',
								model: 'checkbox',
								value: false
							}],
							post_var: 'group'
						}

					},
					post: function(scope, element, attrs) {

						scope.$watch('onChange', function(new_val) {

							if(new_val === undefined) return;

							scope.input.onChange = new_val;
							
						});

					}
				}
			}
		}
	}
]);