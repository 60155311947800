nwuiCompoundModalAcceptHousehold.directive('nwuiCompoundModalAcceptHousehold', [
    'NwuiModalV2Factory',
    'NwuiCardHeaderV2Factory',
    'NwuiButtonV3Factory',
    function (
        NwuiModalV2Factory,
        NwuiCardHeaderV2Factory,
        NwuiButtonV3Factory
    ) {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            templateUrl: '/compound/modal/accept-household/template.html',
            scope: {
                open: '=',
                primaryMember: '=',
                householdName: '=',
                memberType: '='
            },
            link: function (scope, element, attrs) {
                scope.household = {};
                scope.modals = {
                    accept_household: new NwuiModalV2Factory({
                        open: false,
                        wide: true,
                        header: new NwuiCardHeaderV2Factory({
                            name_i18n: 'nwui.modal.accept_household.header.title',
                            level: 2
                        }),
                        button: new NwuiButtonV3Factory({
                            id: 'continue-to-profile-button',
                            text_i18n: 'nwui.modal.accept_household.button.text',
                            color: 'primary',
                            stretch: false,
                            onClick: function() {
                                scope.modals.accept_household.open = false;
                            }
                          })
                    })
                }

                scope.$watchCollection('[open, primaryMember, householdName, memberType]', function (new_val) {
                    if (new_val === undefined) return;
                    scope.modals.accept_household.open = new_val[0];
                    if(new_val[1] && new_val[2]){
                        scope.primaryMember = new_val[1];
                        scope.primaryMemberName = scope.primaryMember.WebsiteUser.first_name + ' ' + scope.primaryMember.WebsiteUser.last_name;
                        scope.householdName = new_val[2];
                        scope.memberType = new_val[3];
                        scope.household.message = 'nwui.modal.accept_household.body.text.';
                        scope.household.message += (scope.memberType === true) ? 'primary_member': 'secondary_member';
                        scope.i18n_data = {
                            householdName: scope.householdName, 
                            primaryMemberName: scope.primaryMemberName,
                            primaryMemberName1: scope.primaryMemberName
                        };
                    }
                });
            }
        }
    }
]);
