nwuiCompoundClientGoal.factory('NwuiCompoundClientGoalFactory', [function() {
	
	var defaults = {
		user_uuid: undefined, //req
		client_id: undefined, //req
		goal_id: undefined, //req
		has_blind: true,
		has_header_pill: true
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundClientGoalFactory");
		if(obj.user_uuid === undefined) return console.error("Missing required instantiation object variable 'user_uuid' in NwuiCompoundClientGoalFactory");
		if(obj.client_id === undefined) return console.error("Missing required instantiation object variable 'client_id' in NwuiCompoundClientGoalFactory");
		if(obj.goal_id === undefined) return console.error("Missing required instantiation object variable 'goal_id' in NwuiCompoundClientGoalFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);