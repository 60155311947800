nwuiCompoundModalCreateAccountSignReview.directive('nwuiCompoundModalCreateAccountSignReview', [
	'$q',
	'$sce',
	'$timeout',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	'NwuiFormV3Factory',
	
	'NwuiSigningService',
	'NwuiPageScrollService',
	'NwuiToastManagerService',
	'NwButtonService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwuiRegisterService',
	'ApiUserService',
	'ApiEsignEventService',
	function(
		$q,
		$sce,
		$timeout,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,
		NwuiFormV3Factory,
		
		NwuiSigningService,
		NwuiPageScrollService,
		NwuiToastManagerService,
		NwButtonService,
		NwuiInputService,
		NwuiFormV3Service,
		NwuiRegisterService,
		ApiUserService,
		ApiEsignEventService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/create-account-sign-review/template.html',
			scope: {
				open: '=',
				active_esign_event: '=activeEsignEvent',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var seed_id = NwuiRegisterService.register();
				
				scope.modal = new NwuiModalV2Factory({
					open: false,
					wide: false,
					header: new NwuiCardHeaderV2Factory({
						name_i18n: 'nwui.modal.create_account_sign_review.title',
						icon: {
							name: 'gesture'
						}
					}),
					buttons: {
						yes: new NwuiButtonV3Factory({
							id: 'create-account-sign-review-button',
							text_i18n: 'nwui.modal.create_account_sign_review.button.sign',
							color: '',
							icon: {
								name: 'check'
							},
							color: '',
							stretch: true,
							onClick: function() {
								
								NwButtonService.buttons['create-account-sign-review-button'].processing = true;
								
								ApiUserService.getEsignEventSign(scope.active_esign_event._id).then(function(obj) {
									
									NwButtonService.buttons['create-account-sign-review-button'].processing = false;
									
									$timeout(function() {
										NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelopeId);
										NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {

											scope.open = false;
											scope.submitCb(true);

										});
										
										NwuiSigningService.setErrorCb('global-document-signing', function(err) {

											var text_i18n = 'nwui.common.error.unknown';

											if(err && err.code) {
												switch(err.code) {
													case 4311: 
														text_i18n = 'manage.risk_profiles.edit.review.error.4310';
														break;
												}
											}

											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: text_i18n,
													type: 'error',
													icon: {
														name: 'close'
													}
												}
											));

											scope.open = false;
											scope.submitCb(false);

										});

										NwuiPageScrollService.open('global-document-signing');

									}, 0);
									
								}, function(err) {

									NwButtonService.buttons['create-account-sign-review-button'].processing = false;
									
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.common.error.unknown',
											type: 'error',
											icon: {
												name: 'close'
											}
										}
									));

									scope.open = false;
									scope.submitCb(false);

								});
						
							}
						}),
						no: new NwuiButtonV3Factory({
							id: 'create-account-sign-review-archive-button',
							text_i18n: 'nwui.modal.create_account_sign_review.button.archive',
							color: '',
							icon: {
								name: 'close'
							},
							color: 'warning',
							stretch: true,
							onClick: function() {
								
								NwButtonService.buttons['create-account-sign-review-archive-button'].processing = true;

								ApiEsignEventService.archiveEsignEvent(scope.active_esign_event._id).then(function(obj) {
									
									scope.open = false;
									scope.submitCb(true);

									NwButtonService.buttons['create-account-sign-review-archive-button'].processing = false;

								}, function(err) {

									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.common.error.unknown',
											type: 'error',
											icon: {
												name: 'close'
											}
										}
									));

									scope.open = false;
									scope.submitCb(false);

									NwButtonService.buttons['create-account-sign-review-archive-button'].processing = false;

								});
						
							}
						})
					}
				})
				
			}
		}

	}
]);