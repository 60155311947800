questionPieLegendItem.factory('QuestionPieLegendItemFactory', [function() {
	
	var defaults = {
        data: undefined,
        type: undefined,
        index: undefined,
        disabled: false,
        editMode: false
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for QuestionPieLegendItemFactory");
        if(obj.data === undefined) return console.error("Missing required instantiation object variable 'data' in QuestionPieLegendItemFactory");
        if(obj.type === undefined) return console.error("Missing required instantiation object variable 'type' in QuestionPieLegendItemFactory");
        if(obj.index === undefined) return console.error("Missing required instantiation object variable 'index' in QuestionPieLegendItemFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);