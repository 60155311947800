nwuiCardTableV2BodyRowColumnButton.directive('nwuiCardTableV2BodyRowColumnButton', ['$timeout', 'NwuiButtonV2Factory', function($timeout, NwuiButtonV2Factory) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/card/table/v2/body/row/column/button/template.html',
        scope: {
	        button: '='
        },
        link: function(scope, element, attrs) {
	        
		    var lock = false;
	        
	        scope.$watch('button', function(new_val) {

		        if(!lock && new_val !== undefined) {
		        	
		        	lock = true;
		        	
		        	new_val.stretch = true; //override
		        	new_val.size = 'short'; //override
		        	scope.button = new NwuiButtonV2Factory(new_val);
		        	
			        $timeout(function() {
				    	lock = false;    
			        }, 100);
			    
			    }
		        
	        });
	        
        }
	}
}]);