angular.module('nwui-form-v3.factories', [])

.factory('NwuiFormV3Factory', [function() {
	
	var defaults = {
		name: undefined, //req
		steps: undefined, //req
		onSubmit: undefined, //req
		onComplete: undefined, //req
		onSecondaryAction: undefined, //req
		onError: undefined,
		submit_button: {},
		secondary_button: undefined,
		active_step_index: 0,
		disable_automatic_button_processing: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiFormV3Factory");
		if(obj.name === undefined) return console.error("Missing required instantiation object variable: 'object.name'");
		if(obj.steps === undefined) return console.error("Missing required instantiation object variable: 'object.steps'");
		if(obj.onSubmit === undefined) return console.error("Missing required instantiation object variable: 'object.onSubmit'");
		if(obj.onComplete === undefined) return console.error("Missing required instantiation object variable: 'object.onComplete'");
		
		angular.extend(this, defaults, obj);
	}
	
}]);