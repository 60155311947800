nwuiFormColumn.directive('nwuiFormColumn', [
	'$timeout', 
	'NwuiFormService', 
	'NwuiInputFactory', 
	'NwuiButtonV3Factory', 
	function(
		$timeout, 
		NwuiFormService, 
		NwuiInputFactory, 
		NwuiButtonV3Factory
	) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/form/column/template.html',
        scope: { 
	        form_name: '=formName',
	        directive: '=',
	        width: '=',
	        data: '='
        },
        link: function(scope, element, attrs) {
	        
	        var calibrate = function() {

		        if(scope.directive) {
			        switch(scope.directive) {
				        // case 'input':
				        // 	scope.input = scope.data = new NwuiInputFactory(scope.data);
				        // 	break;
			        	case 'button':
			        		// scope.data.onClick = NwuiFormService.submit;
			        		// scope.data.stretch = true; //override
				        	scope.button = scope.data = new NwuiButtonV3Factory(scope.data);
				        	scope.button.onClick = function() {
										NwuiFormService.submit(scope.form_name)
									};
				        	NwuiFormService.registerSubmitButton(scope.form_name, scope.button);
				        	break;
				        // case 'message':
				        // 	//TODO
				        // 	break;
			        }
		        }
		        
	        }

	        if (scope.button){
						scope.button.onClick = function() {
							NwuiFormService.submit(scope.form_name)
						};
						scope.button.stretch = true;
					}

	        
	        scope.style = {};
	        
	        scope.$watch('width', function(new_val) {
		        
		        if(new_val !== 0) {
			        scope.style = {
				        width: new_val + '%'
			        }
			    }
			    
		    });
	        
	        scope.$watch('directive', function(new_val) {
		        calibrate();
	        });
	        
	    }
	}
}]);