nwuiCardTableV2HeaderColumn.directive('nwuiCardTableV2HeaderColumn', ['$timeout', 'NwuiCardTableV2Service', function($timeout, NwuiCardTableV2Service) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/card/table/v2/header/column/template.html',
        scope: {
	        width: '=',
	        name: '=',
					name_i18n: '=nameI18n',
	        sort: '=',
	        sortable: '=',
	        directive: '=',
	        tooltip: '='
        },
        link: function(scope, element, attrs) {
			
			$timeout(function() {
				/*scope.width = (element[0].clientWidth <= scope.width)
					? scope.width 
					: element[0].clientWidth;*/
			}, 0)
			
			scope.toggleSort = function() {
				
				if(scope.sortable === false) return;
				
				scope.sort = (scope.sort == -1)
					? 1
					: -1;
					
				//TODO: sorting update to NwuiCardTableV2Service
				
			}
			
			scope.showTooltip = function() {
				if(scope.tooltip)
					scope.tooltip.show = true;
			}
			
			scope.hideTooltip = function() {
				if(scope.tooltip)
					scope.tooltip.show = false;
			}
			
			scope.$watch('width', function(new_val) {
				
				//console.log('width', new_val)
				
				if(new_val !== undefined) {
					scope.style = {
						width: new_val + 'px'
					}
					/*
					var calc_w = element[0].clientWidth;
					var w = (calc_w <= new_val) ? new_val : calc_w;
					
					scope.style = {
						width: w + 'px'
					}
					*/
				}
			});
			
	    }
	}
}]);