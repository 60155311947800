nwuiCardTableV2BodyRowColumnCheckbox.directive('nwuiCardTableV2BodyRowColumnCheckbox', [function() {
	return {
		restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: '/card/table/v2/body/row/column/checkbox/template.html',
        scope: {
	        id: '=',
	        model: '='
        },
        link: function(scope, element, attrs) {}
	}
}]);