nwuiOverlay.factory('NwuiOverlayFactory', [function() {
	
	var defaults = {
		id: undefined, //req
		show: false,
		type: '',
		message: 'Loading...',
		message_i18n: undefined,
		message_i18n_data: {},
		block_state_change: false,
		color: '',
		icon: {
			name: 'autorenew',
			size: 'large'
		}
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiOverlayFactory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiOverlayFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);