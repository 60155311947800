nwuiCompoundModalGoalTargetV2.directive('nwuiCompoundModalGoalTargetV2', [
	'$timeout',
	'locale',
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	
	'NwuiToastManagerService',
	//'ClientService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'DATE_FORMAT',
	'$state',
	function(
		$timeout,
		locale,
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		
		NwuiToastManagerService,
		//ClientService,
		NwuiInputService,
		NwuiFormV3Service,
		DATE_FORMAT,
		$state
	) {

		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/goal-target/v2/template.html',
			scope: {
				open: '=',
				goal: '=',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var submitCb = function(data) {
					scope.submitCb(data);
				}

				var hideTransfers = (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.hide_transfers
				);
				
				scope.$watch('open', function(new_val) {
					if(new_val === undefined) return;
					
					if(scope.goal && new_val) {
						NwuiInputService.inputs['set-goal-target-form-input-name'].model = scope.goal.localize ? locale.getString(scope.goal.description) : scope.goal.description;
						
						if(!scope.goal.tracking.not_set) {
							NwuiInputService.inputs['set-goal-target-form-input-target-amount'].model = scope.goal.target_amount;
							NwuiInputService.inputs['set-goal-target-form-input-yearly-contribution'].model = scope.goal.yearly_contribution;
							NwuiInputService.inputs['set-goal-target-form-input-target-date'].model = scope.goal.target_date;
						} else {
							NwuiInputService.inputs['set-goal-target-form-input-target-amount'].model = '';
							NwuiInputService.inputs['set-goal-target-form-input-yearly-contribution'].model = '';
							NwuiInputService.inputs['set-goal-target-form-input-target-date'].model = '';
						}
					}

					if(new_val === true) {
						NwuiFormV3Service.focus(scope.modals.goal_target.form.name);
					}
					
					scope.modals.goal_target.open = new_val;

					if(new_val === false) {
						$timeout(function() {
							NwuiFormV3Service.clear(scope.modals.goal_target.form.name);
							scope.modals.goal_target.form.active_step_index = 0;
						}, 750);
					}
				});

				scope.modals = {
					goal_target: new NwuiModalV2Factory({
						open: false,
						wide: true,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.modify_goal.title',
							icon: {
								name: 'create'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'set-goal-target-form',
							secondary_button: {
								text_i18n: 'summary.goal.balance.button_contribute_save',
								hollow: true
							},
							active_step_index: 0,
							submit_button: hideTransfers ? false : {
								text_i18n: 'summary.goal.balance.button_contribute_start',
							},
							onSubmit: function(post_data) {
								
								post_data.goal_id = scope.goal.id;
								
								return scope.formResource(post_data);
								
							},
							onSecondaryAction: function(obj) {
								submitCb(obj.data);
								scope.open = false;
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.modify_goal.toasts.goal_updated',
										icon: {
											name: 'check'
										}
									}
								));
							},
							onComplete: function(obj) {
								
								submitCb(obj.data);
								scope.open = false;
								
								//show toast
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.modify_goal.toasts.goal_updated',
										icon: {
											name: 'check'
										}
									}
								));
								
								$state.go('app.transfers.overview');
							},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.modify_goal.inputs.name.label',
											model: '',
											required: true,
											id: 'set-goal-target-form-input-name',
											placeholder_i18n: "nwui.modal.modify_goal.inputs.name.placeholder",
											post_var: 'description'
										}
									}
								},{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.modify_goal.inputs.target.label',
											model: '',
											mask: 'currency',
											required: true,
											id: 'set-goal-target-form-input-target-amount',
											placeholder_i18n: "nwui.modal.modify_goal.inputs.target.placeholder",
											post_var: 'target_amount'
										}
									}
								}]
							}, {
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.modify_goal.inputs.contrib.label',
											model: '',
											mask: 'currency',
											required: true,
											id: 'set-goal-target-form-input-yearly-contribution',
											placeholder_i18n: "nwui.modal.modify_goal.inputs.contrib.placeholder",
											post_var: 'yearly_contribution'
										}
									}
								},{
									directive: 'input',
									width: 100,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.modal.modify_goal.inputs.target_date.label',
											model: '',
											mask: 'date',
											validators: ['date_future'],
											required: true,
											id: 'set-goal-target-form-input-target-date',
											placeholder_i18n: 'nwui.modal.modify_goal.inputs.target_date.placeholder',
											post_var: 'target_date'
										}
									}
								}]
							}]
						})
					})
				}
	
			}
		}
	}
]);