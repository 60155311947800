nwuiCompoundMenuBurger.directive('nwuiCompoundMenuBurger', [
	'$timeout',
	function(
		$timeout
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/compound/menu/burger/template.html',
	        scope: {
		        open: '=',
		        color: '='
	        },
	        link: function(scope, element, attrs) {
				
				
	
		    }
		}
	}
]);