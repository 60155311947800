nwuiForm.service('NwuiFormService', [
	'$timeout', 
	'NwuiInputService',
	'NwuiAnalyticsService',
	function(
		$timeout, 
		NwuiInputService,
		NwuiAnalyticsService
	) {
	
		var self = this;	
		var forms = {};
		this.forms = forms;
		
		var getFormByInputId = function(input_id) {

			for(var key in forms) {
				
				//if has step structure
				if(forms[key].steps.length > 0) {
					
					//iterate form steps
					for(var j=0; j < forms[key].steps.length; j++) {
						//iterate step inputs
						for(var k=0; k < forms[key].steps[j].inputs.length; k++) {
							//check for matching form step input id
							if(input_id == forms[key].steps[j].inputs[k].id)
								return key;
						}
					}
					
				} else {
				
					for(var j=0; j < forms[key].inputs.length; j++) {	
						if(input_id == forms[key].inputs[j].id)
							return key;
					}
				
				}
			}

			return false;

		}

		this.setStepIndex = function(form_id, step_index) {
			//console.log('NwuiFormService', 'setStepIndex', form_id, step_index);

			if(step_index > forms[form_id].active_step_index)
				self.focus(form_id);

			forms[form_id].active_step_index = step_index;
		}

		this.focus = function(form_id) {

			//console.log('NwuiFormService', 'focus', form_id, forms[form_id].active_step_index, $(forms[form_id].steps[forms[form_id].active_step_index].inputs[0].id));

			$timeout(function() {
				//focus if first input is a text input
				$('#' + forms[form_id].steps[forms[form_id].active_step_index].inputs[0].id).focus();
			}, 1250);

		}

		this.showMessage = function(form_id, recommendation) {

			//console.log('NwuiFormService', 'showMessage', form_id, recommendation);

			forms[form_id].steps[forms[form_id].active_step_index].message.expanded = true;
			forms[form_id].steps[forms[form_id].active_step_index].message.recommendation = recommendation;

		}

		this.hideMessage = function(form_id) {

			//if no steps
			if(forms[form_id].steps.length === 0) return; 

			forms[form_id].steps[forms[form_id].active_step_index].message.expanded = false;
			forms[form_id].steps[forms[form_id].active_step_index].message.recommendation = undefined;

		}

		this.clear = function(form_id) {
	
			//console.log('NwuiFormService', 'clear', form_id, forms[form_id]);			
			if(forms[form_id] === undefined) return;
			
			self.hideMessage(form_id);
			forms[form_id].submit_button.disabled = true;

			//if has step structure
			if(forms[form_id].steps.length > 0) {
				
				//iterate form steps
				for(var j=0; j < forms[form_id].steps.length; j++) {
					
					//iterate step inputs
					for(var k=0; k < forms[form_id].steps[j].inputs.length; k++) {

						if(NwuiInputService.inputs[forms[form_id].steps[j].inputs[k].id].clearCb) {
							NwuiInputService.inputs[forms[form_id].steps[j].inputs[k].id].clearCb();
						} else {
							NwuiInputService.inputs[forms[form_id].steps[j].inputs[k].id].model = '';
						}
					}
				}
				
				forms[form_id].goToStartStepCb();
				
			} else {
			
				for(var j=0; j < forms[form_id].inputs.length; j++) {	
					NwuiInputService.inputs[forms[key].inputs[j].id].model = '';
				}
			
			}

			forms[form_id].active_step_index = 0;
			forms[form_id].submit_button.disabled = true;
			
		}

		this.setDirty = function(input_id) {

			//console.log('NwuiFormService', 'setDirty', input_id);
			var form_id = getFormByInputId(input_id);

			if(form_id && forms[form_id] && forms[form_id].submit_button)
				forms[form_id].submit_button.disabled = false;

		}
		
		this.unregisterForm = function(form_id) {
			delete forms[form_id];
		}
		
		this.registerForm = function(form_id, onSubmitCb, onCompleteCb, onErrorCb, goToStartStepCb, goToNextStepCb) {
			//console.log('NwuiFormService', 'registerForm', form_id, onSubmitCb, onCompleteCb, onErrorCb, goToNextStepCb);
			
			forms[form_id] = {
				inputs: [],
				steps: [],
				active_step_index: 0,
				submit_button: {},
				onSubmitCb: onSubmitCb,
				onCompleteCb: onCompleteCb,
				onErrorCb: onErrorCb,
				goToStartStepCb: goToStartStepCb,
				goToNextStepCb: goToNextStepCb
			}
		}
		
		this.registerStep = function(form_id, step) {
			//console.log('NwuiFormService', 'registerStep', form_id, step.step_index);
			
			if(!forms[form_id]) {
				//console.log('NwuiFormService', 'registerStep', 'no form found for id');
				return;
			}
			
			forms[form_id].steps[step.step_index] = {
				submit_button: {},
				message: step.message,
				inputs: []
			}
		}

		this.registerValidate = function(form_id, validate) {
			forms[form_id].validate = validate;
		}
		
		this.registerInput = function(form_id, step_index, input) {
			//console.log('NwuiFormService', 'registerInput', form_id, step_index, input);
			
			if(!forms[form_id]) {
				//console.log('NwuiFormService', 'registerInput', 'no form found for id');
				return;
			}
			
			if(step_index !== undefined)
				forms[form_id].steps[step_index].inputs.push(input);
			
			forms[form_id].inputs.push(input);
		}
		
		this.registerStepSubmitButton = function(form_id, step_index, button) {
			//console.log('NwuiFormService', 'registerStepSubmitButton', form_id, step_index, button);
			
			forms[form_id].steps[step_index].submit_button = button || {};
			
			if(step_index == forms[form_id].steps.length-1)
				forms[form_id].submit_button = button || {};
		}

		this.registerSubmitButton = function(form_id, button) {
			//console.log('NwuiFormService', 'registerSubmitButton', form_id, button);
			forms[form_id].submit_button = button || {};
		}
		
		this.enterKeySubmit = function(input_id, event) {
			
			for(var key in forms) {
				
				//if has step structure
				if(forms[key].steps.length > 0) {
					
					//iterate form steps
					for(var j=0; j < forms[key].steps.length; j++) {
						//iterate step inputs
						for(var k=0; k < forms[key].steps[j].inputs.length; k++) {
							//check for matching form step input id
							if(input_id == forms[key].steps[j].inputs[k].id) {

								//if last step
								if(forms[key].steps.length-1 == j) {
									if(event)
										event.preventDefault();
									self.submitForm(key);
									return false;
								} else {
									if(event)
										event.preventDefault();
									self.submitStep(key, j);
									return false;
								}
							}
						}
					}
					
				} else {
				
					for(var j=0; j < forms[key].inputs.length; j++) {	
						if(input_id == forms[key].inputs[j].id)
							self.submit(key);
					}
				
				}
			}
			
		}
		
		this.submitStep = function(form_id, step_index) {
			//console.log('NwuiFormService', 'submitStep', form_id, step_index, forms[form_id].steps[step_index].submit_button);
			
			if(!forms[form_id].steps[step_index].submit_button)
				forms[form_id].steps[step_index].submit_button = {}
			
			if(forms[form_id].steps[step_index].submit_button.processing) return;

			self.hideMessage(form_id)
			
			var is_valid = true;
			
			forms[form_id].steps[step_index].submit_button.processing = true;

			forms[form_id].steps[step_index].inputs.forEach(function(input,i) {
				if(is_valid === true)
					is_valid = NwuiInputService.validate(input.id);
			});
			
			$timeout(function() {
				if(is_valid === true) {
					forms[form_id].goToNextStepCb();
				}
				
				forms[form_id].steps[step_index].submit_button.processing = false;
			}, 500);
		}

		this.getFormData = function(form_id) {
			//console.log('NwuiFormService', 'getFormData', form_id, forms[form_id])

			var data = {}
				
			for(var i=0; i<forms[form_id].inputs.length; i++) {
				if(forms[form_id].inputs[i].post_var)
					data[forms[form_id].inputs[i].post_var] = forms[form_id].inputs[i].model;
			}

			//console.log('NwuiFormService', 'getFormData', data)

			return data;

		}

		this.validate = function(form_id) {
			return forms[form_id].validate();
		}
		
		this.submit = this.submitForm = function(form_id) {

			console.log('NwuiFormService', 'submit/submitForm', form_id)
			
			if(forms[form_id].submit_button.processing || forms[form_id].submit_button.disabled) return;
			
			self.hideMessage(form_id);

			var is_valid = true;
			
			forms[form_id].submit_button.processing = true;
			
			forms[form_id].inputs.forEach(function(input,i) {
				if(is_valid === true) {
					is_valid = NwuiInputService.validate(input.id);
				}
			});
			
			if(is_valid === true) {
				var data = self.getFormData(form_id);
				
				$timeout(function() {
					$('#submit-button-' + form_id).click();
				}, 0);
				
				forms[form_id].onSubmitCb(data).then(function(obj) {
					NwuiAnalyticsService.track(form_id + '-submit', data);
					forms[form_id].submit_button.processing = false;
					if(forms[form_id].onCompleteCb)
						forms[form_id].onCompleteCb(obj);
				}, function(obj) {
					forms[form_id].submit_button.processing = false;
					if(forms[form_id].onErrorCb)
						forms[form_id].onErrorCb(obj);
				});
			} else {
				$timeout(function() {
					forms[form_id].submit_button.processing = false;
				}, 500);
			}
		};
	
	}
]);