nwuiCompoundClientTodos.directive('nwuiCompoundClientTodos', [
	'$timeout', 
	
	'UserService',
	'OnboardingService',
	'NwuiInputService',
	
	'NwuiCardHeaderV2Factory',
	'NwuiBlindFactory',
	'NwuiCardTableFactory',
	'NwuiFormFactory',
	
	'NwuiButtonV2Service',
	function(
		$timeout,
		
		UserService,
		OnboardingService,
		NwuiInputService,
		
		NwuiCardHeaderV2Factory,
		NwuiBlindFactory,
		NwuiCardTableFactory,
		NwuiFormFactory,
		
		NwuiButtonV2Service
	) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/compound/client-todos/template.html',
		scope: {
			client: '=',
			permissions: '=',
			onboarding_status_detail: '=onboardingStatusDetail',
			has_blind: '=hasBlind',
			has_header_pill: '=hasHeaderPill'
		},
		link: function(scope, element, attrs) {
			
			var locks = {
				onboarding_status_detail: false
			}
			
			var loadTodos = function() {
				
				scope.cards.todos.table.loaded = false;
				
				OnboardingService.getUserDetail(scope.client.id).then(function(obj) {

					locks.onboarding_status_detail = false;
					scope.onboarding_status_detail = obj.data.onboarding_status_detail;
					
				});
				
			}
			
			scope.data = {
				header_pill: undefined
			};
			
			scope.cards = {
				todos: {
					header: new NwuiCardHeaderV2Factory({
						name_i18n: 'users.sections.overview.todo_card.title',
						icon: {
							name: 'assignment_turned_in'
						}
					}),
					blind: new NwuiBlindFactory({
						open: false,
						closed_height: 233,
						text_open: 'Show more items',
						text_close: 'Show fewer items'
					}),
					table: new NwuiCardTableFactory({
						name: 'client-detail-table-todos',
						empty_text_i18n: 'users.sections.overview.todo_card.empty_message',
						columns: [{
							name_i18n: 'users.sections.overview.todo_card.subtitle',
							directive: 'text',
							sortable: false
						}, {
							name: '',
							width: 72,
							directive: 'toggle',
							sortable: false
						}],
						rows: undefined
					})
				}
			}
			
			scope.modals = {}
			
			$timeout(function() {
				
				scope.$watch('client', function(new_val) {
					
					if(new_val !== undefined && 'id' in new_val) {
						loadTodos();
					}
					
				});
				
				scope.$watchGroup(['has_header_pill', 'data.header_pill'], function(new_val, old_val) {
					
					if(new_val[0] === true) {
						scope.cards.todos.header.pill = angular.copy(new_val[1]);
					} else {
						delete scope.cards.todos.header.pill;
					}
					
				})
				
				scope.$watch('onboarding_status_detail', function(new_val, old_val) {
					
					//console.log('onboarding_status_detail child:todos', new_val);
					
					if(new_val !== undefined && !locks.onboarding_status_detail) {
						
						locks.onboarding_status_detail = true;
							
						var onboarding_status_detail = new_val;
							
						if(onboarding_status_detail.onboarding_stages.length == 0)
							scope.cards.todos.table.rows = [];
						else {
							scope.cards.todos.table.rows = onboarding_status_detail.onboarding_stages.map(function(stage,i) {							
								return {
									columns: [{
										text: stage.onboarding_stage.title
									}, {
										toggle: {
											id: 'client-detail-todo-' + stage.onboarding_stage.name,
											model: (stage.is_complete),
											size: 'short',
											disabled: scope.permissions && scope.permissions.write === false,
											onChange: function() {
												//alert(NwuiInputService.inputs['client-detail-todo-' + stage.onboarding_stage.name].model);
												
												OnboardingService.setStage(
													stage.onboarding_stage.name, 
													NwuiInputService.inputs['client-detail-todo-' + stage.onboarding_stage.name].model, 
													scope.client.uuid
												).then(function() {
													$timeout(function() {
														loadTodos();	
													}, 1000)
												});
											}
										}
									}]
								}						
							});
						}
						
						$timeout(function() {
							locks.onboarding_status_detail = false;
						}, 4000);
						
					}
					
				});
			
			}, 250);
						
		}
	}
}]);