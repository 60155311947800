nwuiCompoundMenuTertiary.directive('nwuiCompoundMenuTertiary', [
	'$timeout',
	'$rootScope',
	'$window',
	'NwuiCompoundMenuTertiaryItemFactory',
	function(
		$timeout,
		$rootScope,
		$window,
		NwuiCompoundMenuTertiaryItemFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/menu/tertiary/template.html',
			scope: {
				section: '=',
				section_i18n: '=sectionI18n',
				items: '=',
				open: '='
			},
			link: function(scope, element, attrs) {

				var calibrate = function() {
					
					scope.items.forEach(function(item,i) {
						
						//console.log('cal', angular.element($window)[0].innerWidth, $(window).width());

						if(item.pill)
							item.pill.size = (scope.open || angular.element($window)[0].innerWidth >= 1860) ? '' : 'small';
							
					});
					
				}
				
				var close = function() {
					scope.open = false;
					calibrate();
				}
				
				//console.log('nwuiCompoundMenuTertiary', 'modules', scope.modules);
				
				scope.toggle = function() {
					scope.open = scope.open ? false : true;
					
					calibrate();
				}
				
				scope.close = function() {
					close();
				}
				
				scope.$watch('items', function(new_val) {
					
					if(new_val !== undefined) {

						new_val.forEach(function(obj,i) {
							obj.pill = {
								text: 0,
								size: 'small'
							};
						});
						
					}
					
				}, true);

				// Commenting this out to test in UAT - MD's header in UAT isn't working due to
				// the isAuthenticated endpoint being called repeatedly.
				/*
				scope.customHeader = !!(organization_data.port_data && organization_data.port_data.custom_header);

				var onWindowScroll = function(){
					var mainRow = document.getElementsByClassName("mdm--header__mainRow")[0];
					var utilityRow = document.getElementsByClassName("mdm--header__utilityRow")[0];
					var headerB = document.getElementById("header-b");
					var menu = document.getElementsByClassName("menu")[0];
					var totalHeight = (headerB.offsetHeight+ utilityRow.offsetHeight + mainRow.offsetHeight)
					if(window.pageYOffset === 0)
						menu.style.top = totalHeight + "px";
					else if ( window.pageYOffset >= (totalHeight - utilityRow.offsetHeight)){
						menu.style.top = utilityRow.offsetHeight + "px";
					}
					else{
						menu.style.top = (totalHeight - window.pageYOffset) + "px"; 
					}
				}
				
				
				if(scope.customHeader){
					angular.element($window).on('scroll', onWindowScroll);
					$rootScope.$on('$destroy', function () {
						angular.element($window).off('scroll', onWindowScroll);
					});
				}
				*/
				//close on state change
				$rootScope.$on('$stateChangeSuccess', close)

				//close on resize
				window.onresize = function() {
					scope.$apply();
				};
				
				scope.$watch(function() {
					return angular.element($window)[0].innerWidth;
				}, function() {
					//calibrate();
					close();
				});
				
			}
		}
	}
]);