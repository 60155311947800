nwuiButtonV2.service('NwuiButtonV2Service', [function() {
	
	var self = this;	
	this.buttons = {};
	
	this.unregister = function(button_id) {
		delete self.buttons[button_id];
	}
	
	this.register = function(button) {
		//console.log('NwuiButtonV2Service.register', button.id);
		
		if(button.id)
			self.buttons[button.id] = button;
	}
	
}]); 