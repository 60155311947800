angular.module('nwui-compound-modal-edit-banking-info').directive('nwuiCompoundModalEditBankingInfo', [
	'$timeout',
	'$sce',
	'$q',
	'NwuiModalV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardTableFactory',
	'NwuiFormV3Factory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',

	'NwuiFormV3Service',
	'NwButtonService',
	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiValidationErrorService',
	function (
		$timeout,
		$sce,
		$q,
		NwuiModalV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiCardTableFactory,
		NwuiFormV3Factory,
		NwuiButtonV3Factory,
		NwuiToastFactory,

		NwuiFormV3Service,
		NwButtonService,
		NwuiToastManagerService,
		NwuiInputService,
		NwuiValidationErrorService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/edit-banking-info/template.html',
			scope: {
				open: '=',
				getData: '=getData',
				formResource: '=formResource',
				submitCb: '=submitCb'
			},
			link: function (scope, element, attrs) {
				scope.modals = {
					edit_banking_info: undefined
				};

				function loadForm() {
					scope.getData().then(function (obj) {
						NwuiInputService.inputs['edit-banking-info-name'].model = obj.data.bank_name;
						NwuiInputService.inputs['edit-banking-info-inst'].model = obj.data.bank_institution;
						NwuiInputService.inputs['edit-banking-info-branch'].model = obj.data.bank_branch;
						NwuiInputService.inputs['edit-banking-info-account'].model = obj.data.bank_account;

						NwuiFormV3Service.focus(scope.modals.edit_banking_info.form.name);
					});
				}

				var columns = [{
					directive: 'input',
					width: 100,
					data: {
						directive: 'text',
						data: {
							label_i18n: 'nwui.common.input.institution_name.label',
							model: '',
							id: 'edit-banking-info-name',
							stretch: true,
							required: true,
							loading: true,
							validators: ['blank'],
							placeholder_i18n: 'nwui.common.input.institution_name.placeholder',
							post_var: 'bank_name'
						}
					}
				}, {
					directive: 'input',
					width: 100,
					data: {
						directive: 'text',
						data: {
							label_i18n: 'nwui.common.input.institution_number.label',
							model: '',
							id: 'edit-banking-info-inst',
							stretch: true,
							required: true,
							loading: true,
							validators: ['bank_institution'],
							placeholder_i18n: 'nwui.common.input.institution_number.placeholder',
							post_var: 'bank_institution'
						}
					}
				}, {
					directive: 'input',
					width: 100,
					data: {
						directive: 'text',
						data: {
							label_i18n: 'nwui.common.input.transit_number.label',
							model: '',
							id: 'edit-banking-info-branch',
							stretch: true,
							required: true,
							loading: true,
							validators: ['bank_transit'],
							placeholder_i18n: 'nwui.common.input.transit_number.placeholder',
							post_var: 'bank_branch'
						}
					}
				}, { 
					directive: 'input',
					width: 100,
					data: {
						directive: 'text',
						data: {
							label_i18n: 'nwui.common.input.bank_account_number.label',
							model: '',
							id: 'edit-banking-info-account',
							stretch: true,
							required: true,
							loading: true,
							validators: ['numeric_bank_account_number'],
							placeholder_i18n: 'nwui.common.input.bank_account_number.placeholder',
							post_var: 'bank_account'
						}
					}
				}]


				scope.modals = {
					edit_banking_info: new NwuiModalV2Factory({
						open: false,
						wide: false,
						locked: false,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.edit_banking_info.title',
							icon: {
								name: 'account_balance'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'edit-banking-info-form',
							submit_button: {
								color: organization_data.theme.form.submit.auth_color
							},
							onSubmit: function (data) {
								return scope.formResource(data);
							},
							onComplete: function (obj) {
								if (obj !== false) {
									scope.submitCb(obj);
									scope.open = false;

									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.modal.edit_banking_info.toasts.updated',
											icon: {
												name: 'check'
											}
										}
										));
								}
							},
							onError: function (res) {
								NwuiFormV3Service.showMessage('edit-banking-info-form', {
									text_i18n: (res && res.message) ? res.message : 'nwui.common.error.unknown',
									icon: {
										name: 'error',
										color: 'error'
									}
								});
		
							},
							steps: [{
								columns: columns
							}]
						})
					})
				}

				scope.$watch('open', function (new_val) {

					if (new_val === undefined) return;

					if (new_val == true) {
						loadForm();
					}


				});

			}
		}
	}
]);