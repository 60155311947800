nwuiCardTableBody.directive('nwuiCardTableBody', [function() {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: '/card/table/body/template.html',
        scope: {
	        columns: '=',
	        rows: '=',
	        widths: '='
        },
        link: function(scope, element, attrs) {}
	}
}]);