angular.module('nwui-period.factories', [])

.factory('NwuiPeriodFactory', [function() {
	
	var defaults = {
		name: undefined,
		name_i18n: undefined,
		dollar: undefined,
		percent: undefined 
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiPeriodFactory");
		//if(obj.name === undefined) return console.error("Missing required instantiation object variable 'name' in NwuiPeriodFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);