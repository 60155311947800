nwuiIcon.factory('NwuiIconFactory', [function() {
	
	var defaults = {
		name: undefined, //req
		clickEvent: function() { return; },
		tooltip: undefined,
		active: false
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiIconFactory");
		if(obj.name === undefined) return console.error("Missing required instantiation object variable 'name' in NwuiIconFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);