angular.module('nwui-form-v3.service', [])

.service('NwuiFormV3Service', [
	'$timeout',
	'NwuiInputService',
	function(
		$timeout,
		NwuiInputService
	) {

		var self = this;
		var forms = {};

		this.register = function(form) {

			//console.log('NwuiFormV3Service', 'register', form);

			if(form === undefined || form.name === undefined) return;

			forms[form.name] = form;

		}

		this.focus = function(form_id) {

			//console.log('NwuiFormV3Service', 'focus', form_id, forms[form_id]);

			if(forms[form_id] === undefined) return;

			forms[form_id].focus();

		}

		this.clear = function(form_id) {

			//console.log('NwuiFormV3Service', 'clear', form_id);

			if(forms[form_id] === undefined) return;
			
			forms[form_id].clear();

		}

		this.hideMessage = function(form_id) {

			//console.log('NwuiFormV3Service', 'hideMessage', form_id);

			if(forms[form_id] === undefined) return;
			
			forms[form_id].hideMessage();

		}

		this.showMessage = function(form_id, recommendation) {

			//console.log('NwuiFormV3Service', 'showMessage', form_id, recommendation);

			if(forms[form_id] === undefined) return;
			
			forms[form_id].showMessage(recommendation);

		}

		this.validate = function(form_id) {

			//console.log('NwuiFormV3Service', 'register', form);

			if(forms[form_id] === undefined) return;
			
			return forms[form_id].validate();

		}

		this.getFormData = function(form_id) {
			
			if(forms[form_id] === undefined) return;
			
			return forms[form_id].getFormData();

		}

		this.submit = function(form_id) {

			//console.log('NwuiFormV3Service', 'register', form);

			if(forms[form_id] === undefined) return;
			
			return forms[form_id].submit();

		}

	}
]);