nwuiInputTextarea.factory('NwuiInputTextareaFactory', [function() {
	
	var defaults = {
		model: undefined, //req
    id: undefined, //req
    label: undefined,
    label_i18n: undefined,
    required: true,
    placeholder: '',
    placeholder_i18n: '',
    post_var: undefined,
    size: '',
    color: '',
    validators: [],
    error: false,
    notification: {},
		enter_submit: true
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for nwuiInputTextareaFactory");
		if(obj.model === undefined) return console.error("Missing required instantiation object variable 'model' in nwuiInputTextareaFactory");
		if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in nwuiInputTextareaFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);