nwuiLoader.directive('nwuiLoader', [
	'$timeout', 
	function(
		$timeout
	) {
		return {
			restrict: 'E',
	    replace: true,
	    templateUrl: '/loader/template.html',
	    scope:{
	      show: '=',
	      color: '=?',
	      type: '=?'
	    },
	    compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) { 
						
						if(angular.isUndefined(scope.show)) scope.show = false;
						if(angular.isUndefined(scope.color)) scope.color = '';
						if(angular.isUndefined(scope.type)) scope.type = '';

					},
					post: function(scope, element, attrs) {

						
						
					}
				}
			}
		}
	}
]);