nwuiBlind.factory('NwuiBlindFactory', [function() {
	
	var defaults = {
		show: false,
		color: undefined,
		closed_height: 30,
		text_open: undefined,
		text_open_i18n : "nwui.common.blind.open",
		text_close: undefined,
		text_close_i18n : "nwui.common.blind.close"
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiBlindFactory");
		//if(obj.xxx === undefined) return console.error("Missing required instantiation object variable 'xxx' in NwuiBlindFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);