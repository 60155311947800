nwuiCompoundModalArchiveUser.directive('nwuiCompoundModalArchiveUser', [
	'$timeout',
	
	'NwuiModalFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardTableFactory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	
	'NwButtonService',
	'OnboardingService',
	'NwuiToastManagerService',
	function(
		$timeout,
		
		NwuiModalFactory,
		NwuiCardHeaderV2Factory,
		NwuiCardTableFactory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		
		NwButtonService,
		OnboardingService,
		NwuiToastManagerService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/archive-user/template.html',
			scope: {
				user: '=user',
				open: '=',
				submitCb: '=submitCb'
			},
			link: function(scope, element, attrs) {
				
				var submitCb = function(is_archived) {
					scope.submitCb(is_archived);
				}
				
				scope.$watch('open', function(new_val) {
					
					//console.log('open', new_val);
					if(new_val === undefined) return;
					
					scope.modals.archive_user.open = new_val;
					
				});
				
				scope.$watchGroup(['user', 'user.first_name'], function(new_val) {
					
					if(new_val[0] === undefined) return;
					
				});
				
				scope.modals = {
					archive_user: new NwuiModalFactory({
						open: false,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.archive_user.title',
							icon: {
								name: 'archive'
							}
						}),
						copy: {
							buttons: {
								yes: new NwuiButtonV3Factory({
									id: 'archive-user-button',
									text_i18n: 'nwui.modal.archive_user.buttons.yes',
									color: '',
									icon: {
										name: 'check'
									},
									color: '',
									stretch: true,
									onClick: function() {
										
										NwButtonService.buttons['archive-user-button'].processing = true;
										
										OnboardingService.setArchive(scope.user.onboarding_status_detail._id, true).then(function(obj) {
											
											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: 'nwui.modal.archive_user.success',
													icon: {
														name: 'check'
													}
												}
											));
											
											NwButtonService.buttons['archive-user-button'].processing = false;
											
											scope.open = false;
											
											submitCb(true);
											
										});
								
									}
								}),
								no: new NwuiButtonV3Factory({
									id: 'cancel-archive-user-button',
									text_i18n: 'nwui.modal.archive_user.buttons.no',
									color: '',
									icon: {
										name: 'close'
									},
									color: 'error',
									stretch: true,
									onClick: function() {
										
										scope.open = false;
										
										submitCb(false);
								
									}
								})
							}
						}
					})
				}
	
			}
		}
	}
]);