nwuiCompoundMenuTertiary.factory('NwuiCompoundMenuTertiaryFactory', [function() {
	
	var defaults = {
		section: 'Close',
		items: [],
		open: false
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundMenuTertiaryFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);