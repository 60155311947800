nwuiCompoundModalDocumentUpload.directive('nwuiCompoundModalDocumentUpload', [
	'$timeout',
	
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	
	'NwuiToastManagerService',
	'OnboardingService',
	'NwuiInputService',
	'DocumentService',
	function(
		$timeout,
		
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		
		NwuiToastManagerService,
		OnboardingService,
		NwuiInputService,
		DocumentService
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/modal/document-upload/template.html',
			scope: {
				open: '=',
				user: '=',
				onUploadCb: '=onUploadCb'
			},
			link: function(scope, element, attrs) {
				
				scope.modals = {
					document_upload: new NwuiModalV2Factory({
						open: scope.open,
						wide: false,
						header: new NwuiCardHeaderV2Factory({
							name_i18n: 'nwui.modal.upload_doc.title',
							icon: {
								name: 'file_upload'
							}
						}),
						form: new NwuiFormV3Factory({
							name: 'compound-document-upload-form',
							submit_button: false,
							onSubmit: function(data) {},
							onComplete: function() {
								scope.modals.document_upload.open = false;
							},
							steps: [{
								columns: [{
									directive: 'input',
									width: 100,
									data: {
										directive: 'select',
										data: {
											label_i18n: 'nwui.modal.upload_doc.inputs.doc_type.label',
											model: '',
											id: 'document-upload-select-input',
											options: [],
											stretch: true,
											placeholder_i18n: 'nwui.modal.upload_doc.inputs.doc_type.placeholder',
											onChange: function() {
												
												//console.log('nwui-compound-modal-document-upload', 'select-change', NwuiInputService.inputs['document-upload-select-input'].model);
												if(NwuiInputService.inputs['document-upload-file-input'] === undefined) return;
												
												NwuiInputService.inputs['document-upload-file-input'].url 
													= NwuiInputService.inputs['document-upload-select-input'].model;
							
											}
										}
									}
								},{
									directive: 'input',
									width: 100,
									data: {
										directive: 'upload',
										data: {
											id: 'document-upload-file-input',
											label_i18n: 'nwui.modal.upload_doc.inputs.file_upload.label',
											model: '',
											url: '',
											placeholder_i18n: 'nwui.modal.upload_doc.inputs.file_upload.placeholder',
											onSuccess: function() {
												
												NwuiToastManagerService.register(
													new NwuiToastFactory({
														text_i18n: 'nwui.modal.upload_doc.toasts.success',
														icon: {
															name: 'check'
														}
													}
												));
												
												scope.onUploadCb();
												
											}
										}
									}
								}]
							}]
						})
					})
				}
				
				var loadDocumentsList = function() {
					//console.log('nwui-compound-modal-document-upload', 'loadDocumentsList');
					DocumentService.getList().then(function(obj) {
							
						//console.log('nwui-compound-modal-document-upload', 'DocumentService.getList', obj)
						var document_list = obj.data.client_documents;
						
						NwuiInputService.inputs['document-upload-select-input'].options = document_list.map(function(document,i) {
							return {
								id: document._id,
								text: document.name,
								value: '/api/admin/users/' + scope.user.uuid + '/documents/' + document._id
							}
						});
						
					});
				}
				
				scope.$watch('user', function(new_val) {
					
					//console.log('nwui-compound-modal-document-upload', 'user-watch', new_val);
					
					if(new_val !== undefined && 'uuid' in new_val) {
						loadDocumentsList();
					}
					
				});

				scope.$watch('open', function(new_val) {

					if(new_val === undefined) return;

					if(new_val === false) {

						$timeout(function() {
							NwuiInputService.inputs['document-upload-file-input'].model = '';
						}, 750);

					}

				});
				
			}
		}
	}
]);