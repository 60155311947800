angular.module('nwui-icon-v3.directives', [])

.directive('nwuiIconV3', ['$timeout', function($timeout) {
	return {
		restrict: 'E',
    replace: true,
    templateUrl: '/icon/v3/template.html',
    scope: {
      name: '=',
      size: '=',
      active: '=',
      clickEvent: '=',
      tooltip: '=',
      color: '='
    },
    link: function(scope, element, attrs) {
	
			scope.showTooltip = function() {
				
				if(!scope.tooltip) return;
				
				scope.tooltip.show = true;
				
			}
			
			scope.hideTooltip = function() {
				
				if(!scope.tooltip) return;
				
				scope.tooltip.show = false;
				
			}

	  }
	}
}]);