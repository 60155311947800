nwuiCompoundGoalSummaryV1.factory('NwuiCompoundGoalSummaryV1Factory', [function() {
	
	var defaults = {}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiCompoundGoalSummaryV1Factory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);