//select input directive
nwuiInputSelectV2.directive('nwuiInputSelectV2', [
	'$sce', 
	'$filter',
	'$timeout', 
	'NWUI_KEY_CODES',
	'NwuiInitModuleFactory',
	'NwuiInputService', 
	'NwuiValidationService', 
	'NwuiInputSelectV2Service', 
	'NwuiValidationErrorService',
	'NwuiValidationErrorV2Service',
	'NwuiFormService',
	'NwuiIconV2Factory',
	'NwuiInputSelectV2OptionFactory', 
	function(
		$sce,
		$filter,
		$timeout, 
		NWUI_KEY_CODES,
		NwuiInitModuleFactory,
		NwuiInputService, 
		NwuiValidationService, 
		NwuiInputSelectV2Service, 
		NwuiValidationErrorService,
		NwuiValidationErrorV2Service,
		NwuiFormService,
		NwuiIconV2Factory,
		NwuiInputSelectV2OptionFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/input/select/v2/template.html',
			require: '?^nwuiFormV3',
			scope: {
				step_index: '=stepIndex',
				form_name: '=formName',
				options: '=',
				model: '=',
				notification: '<',
				id: '<name',
				label: '<',
				hide: '<',
				label_i18n: '<labelI18n',
				default_value: '<defaultValue',
				post_var: '<postVar',
				placeholder: '<',
				placeholder_i18n: '<placeholderI18n',
				required: '<',
				stretch: '<',
				color: '<',
				size: '<',
				onChange: '<',
				hide: '='
			},
			link: function(scope, element, attrs, nwuiFormV3Ctrl) {
				
				scope = NwuiInitModuleFactory({
					id: undefined, //req
					model: undefined, //req
					options: [], //req
					default_value: undefined,
					label: undefined,
					placeholder: '',
					post_var: undefined,
					stretch: false,
					size: '',
					color: '',
					required: false,
					notification: {},
					onChange: undefined
				}, scope);

				if(nwuiFormV3Ctrl)
					nwuiFormV3Ctrl.registerInput(scope.step_index, scope);
				else if(angular.isDefined(scope.form_name)) {
					NwuiFormService.registerInput(scope.form_name, scope.step_index, scope);
				}

				var block = false;
				
				var locks = {
					model: false
				}

				var scroll_data = {
					height: undefined,
					item_height: undefined
				}

				var clear = function() {

					//console.log('nwuiInputSelectV2', 'clear', scope.model, '=>', scope.default_value);
					scope.model = scope.default_value || null;
					scope.error = false;
					if(!scope.notification) scope.notification = {};
					scope.notification.show = false;

				}

				var blur = function() {
					//console.log('nwuiInputSelectV2', 'blur', $('#' + scope.id));
					$(element).blur();
				}

				var getSelectedIndex = function() {
					for(var i = 0; i < scope.options.length; i++) {
						if(scope.options[i].value === scope.model)
							return i;
					}

					return 0;
				}
				
				var validate = function() {
					
					var valid = true;
					
					if(scope.required)
						valid = NwuiValidationService['blank'](scope.model);
				
					if(valid !== true) {
						scope.error = true;
						if(!scope.notification) scope.notification = {};

						if(scope.label)
							scope.notification.text = $sce.trustAsHtml(NwuiValidationErrorService[valid](scope.label));
						else if(scope.label_i18n)
							scope.notification.text_i18n = NwuiValidationErrorV2Service[valid]();

						scope.notification.show = true;
					}
					
					return valid;
					
				}
				
				NwuiInputService.register(scope, validate, clear);
				
				scope.error = false;
				scope.loaded = false;
				scope.active = false;
				scope.focussed = false;
				scope.drop_tab_index = 0;
				scope.is_placeholder = true;
				scope.style_outer = {};
				scope.style_inner = {};
				scope.query = undefined;
				scope.selected = undefined;
				scope.selected_text = {
					val: '',
					one: scope.placeholder_i18n || scope.placeholder,
					two: scope.placeholder_i18n || scope.placeholder,
					toggle: false
				}
				scope.caret_icon = new NwuiIconV2Factory({
					name: 'arrow_drop_down',
					color: 'secondary'
				});

				scope.onClickOption = function(event, selected) {

					$timeout(function() {
						scope.change(selected, false);
					}, 500);

					event.preventDefault();
					event.stopPropagation();

				}
						
				//change select input value
				scope.change = function(selected, is_init) {
					
					//console.log('nwuiInputSelectV2', 'change', 'start', selected, is_init);
					scope.is_placeholder = (selected === undefined) ? true : false;
					//console.log('nwuiInputSelectV2', 'is_placeholder', scope.is_placeholder);

					if(is_init !== true) {
						if(nwuiFormV3Ctrl)
							nwuiFormV3Ctrl.setDirty(scope.id);
						else
							NwuiFormService.setDirty(scope.id);
					}
					
					scope.transition = true;
					
					if(!scope.stretch) {
						scope.style_outer = {
							width: $(element).outerWidth(true) + 'px'
						}
					
						scope.style_inner = {
							position: 'absolute'
						}
					}

					//update ui text
					scope.selected_text.val = scope.is_placeholder 
						? scope.placeholder_i18n || scope.placeholder 
						: selected.text_i18n || selected.text;

					scope.selected_text.toggle = (scope.selected_text.toggle) ? false : true;
					
					if(scope.selected_text.toggle) {
						scope.selected_text.one = scope.selected_text.val;

						if(!scope.is_placeholder && selected.text_i18n) {
							scope.selected_text.one_i18n = scope.selected_text.val;
							scope.selected_text.one_i18n_data = selected.text_i18n_data;
						}
					} else {
						scope.selected_text.two = scope.selected_text.val;

						if(!scope.is_placeholder && selected.text_i18n) {
							scope.selected_text.two_i18n = scope.selected_text.val;
							scope.selected_text.two_i18n_data = selected.text_i18n_data;
						}
					}
								
					locks.model = true;
							
					//update selected item object
					scope.model = (scope.is_placeholder) ? '' : selected.value;
					scope.native_model = (scope.is_placeholder) ? '' : selected;
				
					if(!scope.stretch) {
						$timeout(function() {
							scope.style_outer = {
								width: $(element).children('.select').children('.inner').width() + 2 + 'px'
							}
						}, 30);
					}
					
					$timeout(function() {
						locks.model = false;
					}, 100);
					
					$timeout(function() {
						scope.style_inner = {}
						scope.style_outer = {}
						scope.transition = false;
					}, 750);
						
					if(scope.onChange)
						scope.onChange(scope.model);

					scope.drop_tab_index = 0

					$timeout(function() {
						scope.close();
					}, 0);

				}
						
				//close the select
				scope.close = function(apply) {
					
					//console.log('nwuiInputSelectV2', 'close', scope.id, block);
					
					//prevent closing on active element
					if(block || scope.active === false) return;
					
					scope.active = false;

					//only apply on call coming from outside scope
					if(apply)
						scope.$apply();
						
				}
						
				//toggle open/closed the select
				var toggle = function(event, force_value) {

					//console.log('nwuiInputSelectV2', 'toggle', scope.id, force_value);

					if(block === true) return;

					//block this select for notify 
					block = true;
					scope.active = (force_value !== undefined) ? force_value : (scope.active) ? false : true;
					if(!scope.notification) scope.notification = {};
					scope.notification.show = false;
					scope.error = false;
					
					//notify to close all open selects
					NwuiInputSelectV2Service.notify();

					//if click, no drop highlight
					if(event && event.type === 'click') {
						scope.drop_tab_index = -1;
					} else {
						scope.drop_tab_index = 0;
					}

					calibrate();
					
					//timing issue fix for local/global notify
					$timeout(function() {
						block = false;
					}, 150)

					event.stopPropagation();
				
				}

				scope.toggle = toggle;

				scope.keyPress = function(event) {

					//console.log('nwuiInputSelectV2', 'keyPress', scope.id);

					if(scope.focussed) {
						
						//console.log('nwuiInputSelectV2', 'key', scope.drop_tab_index, event.keyCode, event.which)
						
						//down arrow key
						if(event.keyCode == 40) {
							if(scope.active === false)
								scope.active = true;
							else if(scope.drop_tab_index < scope.options.length-1)
								scope.drop_tab_index++;

							event.stopPropagation();
							event.preventDefault();
						} 

						//up arrow key
						else if(event.keyCode == 38) {
							if(scope.active === false)
								scope.active = true;
							else if(scope.drop_tab_index > 0)
								scope.drop_tab_index--;

							event.stopPropagation();
							event.preventDefault();
						} 

						//enter key
						else if(event.which === 13) {
							if(scope.active === true)
								scope.change(scope.options[scope.drop_tab_index], false)
							else {
								if(nwuiFormV3Ctrl)
									nwuiFormV3Ctrl.enterKeySubmit(scope.id, event);
								else
									NwuiFormService.enterKeySubmit(scope.id, event);
							}

							scope.active = false;
							// blur();
						} 

						//esc key
						else if(event.keyCode === 27) {
							scope.toggle(event);
							scope.active = false;
							blur();
						}

						//tab key
						else if(event.keyCode === 9) {
							if(scope.active === true && scope.drop_tab_index !== -1)
								scope.change(scope.options[scope.drop_tab_index], false)

							scope.active = false;
							// blur();
						}

						//regular user input
						else {
							scope.notification.show = false;
						}
							
					} else {
						
						if(event.which === 13) {
							if(nwuiFormV3Ctrl)
								nwuiFormV3Ctrl.enterKeySubmit(scope.id, event);
							else
								NwuiFormService.enterKeySubmit(scope.id, event);
						} else
							scope.notification.show = false;
			
					}

				}

				scope.onFocus = function(event) {

					//console.log('nwuiInputSelectV2', 'onFocus', scope.id);
					// toggle(event, true);
					scope.focussed = true;

				}

				scope.onBlur = function(event) {

					//console.log('nwuiInputSelectV2', 'onBlur', scope.id);
					// toggle(event, false);
					scope.focussed = false;

				}
						
				//register select close with the select service
				NwuiInputSelectV2Service.register(scope.close);
					
				//$timeout(function() {
						
				scope.options.forEach(function(option,i) {
					option = new NwuiInputSelectV2OptionFactory(option);
				});
							
				//watch for updates to the select box content
				scope.$watch('options', function(new_val, old_val) {
				
					////console.log('nwui-input-select-v2', 'updated', new_val);
						
					//if options array not empty
					if(new_val) {
						scope.loaded = true;
							
						scroll_data.height = $(element).find('.drop').height();
						scroll_data.item_height = $(element).find('.drop ul li').height();

						if(new_val != old_val) {
							scope.options.forEach(function(option,i) {
								option = new NwuiInputSelectV2OptionFactory(option);
							});
						}
					} else {
						scope.loaded = false;
					}
						
				});
							
				scope.$watch('model', function(new_val) {
						
					if(new_val === undefined || locks.model) return;
						 
					//console.log('nwuiInputSelectV2', 'change', 'watch', new_val, scope.options.length)   
					
					if(scope.options) {
						
						if(scope.options.length > 0) {
							
							for(var i = 0; i < scope.options.length; i++) {
								if(scope.options[i].value == new_val)
								return scope.change(scope.options[i], true)
							}
							
						} else if(typeof scope.options === 'object') {
							
							for(key in scope.options) {
								if(scope.options[key] == new_val)
									return scope.change(new_val, true);
							}
							
						}
						
					}
						
					//console.log('nwuiInputSelectV2', 'change', 'no match', new_val, scope.options.length);
					scope.change(undefined, true);
						
				});

				scope.$watch('is_placeholder', function(new_val) {

					//console.log('nwuiInputSelectV2', 'change', 'watch', new_val);

				});

				scope.$watch('active', function(new_val, old_val) {

					if(new_val === undefined) return;

					if(new_val === true) {
						// scope.drop_tab_index = 0;
					}

					if(new_val === false) {
						scope.query = undefined;
					}

					scope.caret_icon.name = (new_val) ? 'arrow_drop_up' : 'arrow_drop_down';

				});

				var calibrate = function() {

					//console.log('ht', scope.drop_tab_index)

					if(scope.drop_tab_index === undefined || scope.drop_tab_index < 0 || scope.drop_tab_index > scope.options.length-1) return;

					var item_offset = $(element).find('.drop ul li')[scope.drop_tab_index].offsetTop;
					var item_height = $(element).find('.drop ul li').eq(scope.drop_tab_index).height();
					var scroll_top = $(element).find('.drop ul').scrollTop();
					var drop_height = $(element).find('.drop ul').height();
					
					var min = scroll_top;
					var max = scroll_top - item_height;
					
					//check if falls in view range (scroll_top to scroll_top+drop_height)
					if(
						item_offset >= scroll_top &&
						item_offset+item_height <= scroll_top+drop_height
					) {
						//console.log('ht', scroll_top, '>=', item_offset, '<=', scroll_top+drop_height);
					}

					//if below
					else if(
						item_offset+item_height > scroll_top+drop_height
					) {
						//console.log('ht', 'below');
						$(element).find('.drop ul').scrollTop(item_offset - drop_height + item_height)
					}

					//if above
					else if(
						item_offset < scroll_top
					) {
						//console.log('ht', 'above');
						$(element).find('.drop ul').scrollTop(item_offset);
					}

				}

				scope.$watchGroup(['drop_tab_index', 'options'], function(new_val) {

					//console.log('nwuiInputSelectV2', 'ht', 'watchGroup', new_val);

					var drop_tab_index = new_val[0];
					var options = new_val[1];

					calibrate();
							
				});
						
			}
		}
	}
]);