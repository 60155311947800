nwuiMap.factory('NwuiMapFactory', [function() {
	
	var defaults = {
		loaded: false,
		address: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiMapFactory");
		if(obj.address === undefined) return console.error("Missing required instantiation object variable 'address' in NwuiMapFactory");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);