angular.module('nwui-page-title.services', [])

.service('NwuiPageTitleService', [
	'locale',
	function(
		locale
	) {
	
		//self
		var self = this;
		var observerCallbacks = [];
		
		// this.title = "";
		// this.site_title = "";
		this.data = {
			pageName: '',
			orgNameShort: organization_data.name_short
		}
		/* public methods */
		
		//register an observer
		this.register = function(cb) {
			// this.site_title = site_title;
			observerCallbacks.push(cb);

			// this.data.projectName = site_title;
		}
		
		this.set = function(title) {
			//console.log('NwuiPageTitleService', 'setPageTitle', title);

			async.series([
				function(callback) {
					locale.ready('nwui.common.page_title').then(function() { callback(); })
				},
				function(callback) {
					locale.ready('nav').then(function() { callback(); })
				}
			], function(err, results) {
				self.data.pageName = locale.getString(title);
				document.title = locale.getString('nwui.common.page_title.name', self.data);
			});
				
			notifyObservers();

		}
		
		/* private methods */
		
		//notify observers
		var notifyObservers = function() {
			for(var i = 0; i < observerCallbacks.length; i++) {
				observerCallbacks[i]();
			}
		}
		
	}
]);