questionPieLegendItem.directive('questionpielegenditem', ['$timeout', '$sce', function($timeout, $sce) {
	
    return {
        restrict: "E",
        replace: true,
        scope: {
	        'data': '=data',
	        'type': '=type',
			'index': '=index',
			'disabled': '=disabled',
			'editMode': '=editMode'
        },
        templateUrl: '/question-pie-legend-item/template.html',
		link: function(scope, element, attrs) {

			var duration = 750;
			var timeouts = [];
			var steps = duration/20;
			var clearTimeouts = function() {
				
				angular.forEach(timeouts, function(timeout) {
					$timeout.cancel(timeout);
				});
				
			}
			
			var timeout = function(i, value, delay) {
				
				timeouts[i] = $timeout(function() {
					scope.value = value;
					scope.style_fill = {
						//background: scope.data.color,
						width: value + '%'
					}
				}, delay);
				
			}
			
			scope.value = 0;
			scope.style_perc = {
				//color: scope.data.color
			}
			scope.style_fill = {
				//background: scope.data.color,
				width: scope.value + '%'
			}
			
			scope.$watch('data.percent', function(newVal, oldVal) {
				clearTimeouts();
                scope.style_perc = {
                    //color: scope.data.color
                }
                scope.style_fill = {
                    //background: scope.data.color,
                    width: scope.value + '%'
                }
				
				var oldVal = scope.value;
				
				var diff = (newVal - oldVal);
				
				for(var i = 1; i <= steps; i++) {
					
					var perc = i/steps;
					var t = i; 
						t /= steps; 
						t--;
					var ease = Math.pow(t,5) + 1;
					
					timeout(i, Math.round(oldVal + (diff * ease)), duration*perc);
				
				}
				
			});


			
	    },
	    controller: ['$scope', function($scope) {
		    
	    }]
    }
    
}]);