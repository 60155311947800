nwuiToolkit.constant('RESPONSIVE', {})

.constant('DATE_FORMAT', {
	'digit': 'YYYYMMDD',
	'short': 'YYYY/MM/DD',
	'long': 'LT YY/MM/DD',
	'dashed': 'YYYY-MM-DD',
	'human': 'MMMM D, YYYY',
	'year': 'YYYY',
	'time': 'LTS'
})

.constant("NWUI_SELECT_OPTIONS", {
	FREQUENCY: [{
		id: 0,
		// text: 'One Time',
		text_i18n: "nwui.common.form.select.option.frequency.0",
		value: 'one time'
	}, {
		id: 1,
		// text: 'Bi-Weekly',
		text_i18n: "nwui.common.form.select.option.frequency.1",
		value: 'bi-weekly'
	}, {
		id: 2,
		// text: 'Monthly',
		text_i18n: "nwui.common.form.select.option.frequency.2",
		value: 'monthly'
	}, {
		id: 3,
		// text: 'Quarterly',
		text_i18n: "nwui.common.form.select.option.frequency.3",
		value: 'quarterly'
	}, {
		id: 4,
		// text: 'Semi-annually',
		text_i18n: "nwui.common.form.select.option.frequency.4",
		value: 'semi-annual'
	}, {
		id: 5,
		// text: 'Annually',
		text_i18n: "nwui.common.form.select.option.frequency.5",
		value: 'yearly'
	}],
	TIME_RANGES: [{
		id: 0,
		// text: 'This Month',
		text_i18n: "nwui.common.form.select.option.time_range.0",
		value: 0
	},{
		id: 1,
		// text: 'Last Month',
		text_i18n: "nwui.common.form.select.option.time_range.1",
		value: 1
	},{
		id: 2,
		// text: 'Last 6 Months',
		text_i18n: "nwui.common.form.select.option.time_range.2",
		value: 2
	},{
		id: 3,
		// text: 'This Year',
		text_i18n: "nwui.common.form.select.option.time_range.3",
		value: 3
	},{
		id: 4,
		// text: 'All Time',
		text_i18n: "nwui.common.form.select.option.time_range.4",
		value: 4
	}],
	PROVINCES: [{
		id  : 0,
		value: "AB",
		text_i18n: "nwui.common.form.select.option.province.0"
	},
	{
		id  : 1,
		value: "BC",
		text_i18n: "nwui.common.form.select.option.province.1"
	},
	{
		id  : 2,
		value: "MB",
		text_i18n: "nwui.common.form.select.option.province.2"
	},
	{
		id  : 3,
		value: "NB",
		text_i18n: "nwui.common.form.select.option.province.3"
	},
	{
		id  : 4,
		value: "NL",
		text_i18n: "nwui.common.form.select.option.province.4"
	},
	{
		id  : 5,
		value: "NS",
		text_i18n: "nwui.common.form.select.option.province.5"
	},
	{
		id  : 6,
		value: "ON",
		text_i18n: "nwui.common.form.select.option.province.6"
	},
	{
		id  : 7,
		value: "PE",
		text_i18n: "nwui.common.form.select.option.province.7"
	},
	{
		id  : 8,
		value: "QC",
		text_i18n: "nwui.common.form.select.option.province.8"
	},
	{
		id  : 9,
		value: "SK",
		text_i18n: "nwui.common.form.select.option.province.9"
	},
	{
		id  : 10,
		value: "NT",
		text_i18n: "nwui.common.form.select.option.province.10"
	},
	{
		id  : 11,
		value: "YT",
		text_i18n: "nwui.common.form.select.option.province.11"
	},
	{
		id  : 12,
		value: "NU",
		text_i18n: "nwui.common.form.select.option.province.12"
	}],
	REFERRAL_SOURCES: [{
		id  : 0,
		value: "Word of mouth",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.0"
	}, {
		id  : 1,
		value: "Google search",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.1"
	}, {
		id  : 2,
		value: "Social media",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.2"
	}, {
		id  : 3,
		value: "Article/newspaper",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.3"
	}, {
		id  : 4,
		value: "Online advertisement",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.4"
	}, {
		id  : 5,
		value: "Radio",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.5"
	}, {
		id  : 6,
		value: "TV",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.6"
	}, {
		id  : 7,
		value: "Attended an event",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.7"
	}, {
		id  : 8,
		value: "Financial planner",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.8"
	}, {
		id  : 10,
		value: "National Post",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.10",
		nestwealth_only: true
	}, {
		id  : 11,
		value: "Running Room",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.11",
		nestwealth_only: true
	}, {
		id  : 9,
		value: "Other",
		text: "",
		text_i18n: "nwui.common.form.select.option.referral_source.9"
	}],
	ASSET_TYPES: [
		{
			id  : 0,
			value: "cash",
			text_i18n: "nwui.common.form.select.option.asset_type.cash"
		}, {
			id  : 1,
			value: "gic",
			text_i18n: "nwui.common.form.select.option.asset_type.gic"
		}, {
			id  : 2,
			value: "asset",
			text_i18n: "nwui.common.form.select.option.asset_type.asset"
		}
	]
})

.constant("NWUI_KEY_CODES", {
	// 'backspace' : '8',
	// 'tab' : '9',
	// 'enter' : '13',
	// 'shift' : '16',
	// 'ctrl' : '17',
	// 'alt' : '18',
	// 'pause_break' : '19',
	// 'caps_lock' : '20',
	// 'escape' : '27',
	// 'page_up' : '33',
	// 'page down' : '34',
	// 'end' : '35',
	// 'home' : '36',
	// 'left_arrow' : '37',
	// 'up_arrow' : '38',
	// 'right_arrow' : '39',
	// 'down_arrow' : '40',
	// 'insert' : '45',
	// 'delete' : '46',
	'0' : '48',
	'1' : '49',
	'2' : '50',
	'3' : '51',
	'4' : '52',
	'5' : '53',
	'6' : '54',
	'7' : '55',
	'8' : '56',
	'9' : '57',
	'a' : '65',
	'b' : '66',
	'c' : '67',
	'd' : '68',
	'e' : '69',
	'f' : '70',
	'g' : '71',
	'h' : '72',
	'i' : '73',
	'j' : '74',
	'k' : '75',
	'l' : '76',
	'm' : '77',
	'n' : '78',
	'o' : '79',
	'p' : '80',
	'q' : '81',
	'r' : '82',
	's' : '83',
	't' : '84',
	'u' : '85',
	'v' : '86',
	'w' : '87',
	'x' : '88',
	'y' : '89',
	'z' : '90'
})
.constant("NWUI_MASK", {
		"en-CA" : {
			"currency" : {
				pre: ["$", /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g],
				post: function(input) {
					return input.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
				}
			},
			"currency_decimal": {
				post: function(input) {
					input = input + '';
					var matches = input.match(/^\d{1,7}\.?\d{0,2}/);
					var number = matches ? matches[0] : null;
					return number
						? '$' + number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
						: '';
				}
			},
			"percentage" : {
				pre: [/[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, '%'],
				post: function(input) {
					return input + '%'
				}
			},
			"percentage_decimal": {
				post: function(input) {
					input = input + '';
					var matches = input.match(/^\d{1,3}\.?\d{0,2}/);
					var number = matches ? matches[0] : null;
					return number ? number + '%' : '';
				}
			},
			"four_decimals": {
				post: function(input) {
					input = input + '';
					var matches = input.match(/^\d{1,7}\.?\d{0,4}/);
					var number = matches ? matches[0] : null;
					return number ? number : '';
				}
			}
		},
		"fr-CA" : {
			"currency" : {
				pre: [/[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, " ","$"],
				post: function(input) {
					return input.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + " $";
				}
			},
			"currency_decimal": {
				post: function(input) {
					input = input + '';
					var matches = input.match(/^\d{1,7}\,?\d{0,2}/);
					var number = matches ? matches[0] : null;
					return number
						? number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + " $"
						: '';
				}
			},
			"percentage" : {
				pre: [/[^0-9]+/g, /[^0-9]+/g, /[^0-9]+/g, " ", "%"],
				post: function(input) {
					return input + ' %'
				}
			},
			"percentage_decimal": {
				post: function(input) {
					input = input + '';
					var matches = input.match(/^\d{1,3}\.?\d{0,2}/);
					var number = matches ? matches[0] : null;
					return number ? number + ' %' : '';
				}
			},
			"four_decimals": {
				post: function(input) {
					input = input + '';
					var matches = input.match(/^\d{1,7}\.?\d{0,4}/);
					var number = matches ? matches[0] : null;
					return number ? number : '';
				}
			}
		}
})
