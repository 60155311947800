angular.module('nwui-logo.directives', [])

.directive('nwuiLogo', [
	'$timeout', 
	function(
		$timeout
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/logo/template.html',
			scope:{
				name: '='
			},
			link: function(scope, element, attrs) {
		
				
					
			}
		}
	}
]);