angular.module('shared-services')

.service('FundFactsService', [
	'$q',
	'ApiAccountsService',
	function($q, ApiAccountsService) {
		
		var self = this;

		this.getFundFactArray = function(accountId, riskProfileId) {
			return ApiAccountsService.getAssetMix(accountId, riskProfileId).then(function(res) {
				var fundsToIgnore = organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.efts &&
					organization_data.port_data.transfers.efts.fund_facts && 
					organization_data.port_data.transfers.efts.fund_facts.funds_to_ignore ? organization_data.port_data.transfers.efts.fund_facts.funds_to_ignore : [];
				var cusipArray = res.data.assets
					.filter(function(asset){
						return asset.value > 0 
						&& typeof asset.asset.cusip !== 'undefined' 
						&& fundsToIgnore.indexOf(asset.asset.cusip) <= -1;					
					})
					.map(function(asset) {
						return asset.asset.cusip;
					});
				return cusipArray;
			});
 		}
    
    
		this.populateFundFactStepInForm = function(allocRes, form) {
			//in case we ever add steps after the fund fact step
			var fundFactStepIndex;
			//check to see if we have already added the step we need
			var fundFactStepAdded = form.steps.some(function(step, index) {
				fundFactStepIndex = index;
				return step.id === 'fundFactStep';
			});
			var cusipArray = [];
			var shouldAddStep = true;

			Object.keys(allocRes.data).forEach(function(key) {
				var fundsToIgnore = organization_data &&
									organization_data.port_data &&
									organization_data.port_data.transfers &&
									organization_data.port_data.transfers.efts &&
									organization_data.port_data.transfers.efts.fund_facts && 
									organization_data.port_data.transfers.efts.fund_facts.funds_to_ignore ? organization_data.port_data.transfers.efts.fund_facts.funds_to_ignore : [];
				if(key !== 'cash') {
					var assetsObj = allocRes.data[key].assets;
					Object.keys(assetsObj).forEach(function(assetKey) {
						var cusip = assetsObj[assetKey].cusip;
						if(fundsToIgnore.indexOf(cusip) <= -1) {
							cusipArray.push(cusip);
						} else {
							shouldAddStep = false;
						}
					});
				}
			});

			if(fundFactStepAdded && !shouldAddStep) {
				form.steps.splice(fundFactStepIndex, 1);
			}
			else if(!fundFactStepAdded && shouldAddStep) {
				form.dynamicStepAdded = true;
				form.steps.push({
					id: 'fundFactStep',
					description_i18n:  "nwui.modal.create_eft.fund_facts_desc",
					descriptionFunction: function() {
						cusipArray.forEach(function(cusip) {
							window.open(locale.getString('nwui.common.asset.fund.' + cusip + '.facts_url'));
						})
					},
					columns: [{
						directive: 'input',
						width: 100,
						data: {
							directive: 'checkbox',
							data: {
								id: 'create-eft-form-input-fundfact',
								stretch: true,
								required: true,
								options: [{
									label_i18n: 'nwui.common.input.eft_fund_fact_read.check_0',
									model: "fundfact",
									value: 0,
									required: true
								}],
								size: '',
								post_var: 'user_fundfact_confirm'
							}
						}
					}]
				})
			}
		}
	}
]);