nwuiInputSelectV2.service('NwuiInputSelectV2Service', [
	'$timeout',
	'$window', 
	'$rootScope', 
	function(
		$timeout,
		$window, 
		$rootScope
	) {
	
		var self = this;
		var observers = [];
		
		this.clear = function() {
			observers = [];
		}
		
		this.notify = function(apply) {
			for(var i = 0; i < observers.length; i++) {
				observers[i](apply);
			}
		};
		
		this.register = function(cb) {
			observers.push(cb);
		}
		
		$(window).on('click', function() {
			$timeout(function() {
				self.notify(true);
			}, 0);
		});
		
		$rootScope.$on('$stateChangeStart', function() {
			self.clear();
		});

	}
]);