nwuiDrawerV1NotificationLink.factory('NwuiDrawerV1NotificationLinkFactory', [function() {
	
	var defaults = {
		lead: undefined, //req
		meta: [],
		button: undefined //req
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiDrawerV1NotificationLinkFactory");
		if(obj.lead === undefined && obj.lead_i18n === undefined) return console.error("Missing required instantiation object variable 'lead' in NwuiDrawerV1NotificationLinkFactory");

		angular.extend(this, defaults, obj);
	}
	
}]);