angular.module('nwui-compound-modal-upload-aml-verification').directive('nwuiCompoundModalUploadAmlVerification', [
  '$timeout',
  '$stateParams',
  'NwuiModalV2Factory',
  'NwuiCardHeaderV2Factory',
  'NwuiCardTableFactory',
  'NwuiButtonV3Factory',
  'NwuiToastFactory',
  'NwuiFormV3Factory',
  'NwuiInputFactory',
  'NWUI_SELECT_OPTIONS',
  'OrganizationV2Service',
  'NwButtonService',
  'OnboardingService',
  'NwuiToastManagerService',
  'OrganizationService',
  'NwuiFormV3Service',
  'NwuiInputService',
  'NwuiCardTableV2Factory',
  'locale',
  '$q',
  'IdVerificationService',
  'DATE_FORMAT',
  function(
    $timeout,
    $stateParams,
    NwuiModalV2Factory,
    NwuiCardHeaderV2Factory,
    NwuiCardTableFactory,
    NwuiButtonV3Factory,
    NwuiToastFactory,
    NwuiFormV3Factory,
    NwuiInputFactory,
    NWUI_SELECT_OPTIONS,
    OrganizationV2Service,
    NwButtonService,
    OnboardingService,
    NwuiToastManagerService,
    OrganizationService,
    NwuiFormV3Service,
    NwuiInputService,
    NwuiCardTableV2Factory,
    locale,
    $q,
    IdVerificationService,
    DATE_FORMAT
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/upload-aml-verification/template.html',
      scope: {
        verificationId: '=',
        uuid: '=',
        open: '=',
        roles: '=roles',
        submitCb: '=submitCb'
      },
      link: function(scope, element, attrs) {
        scope.submitButton = {
          id: 'aml-verification-upload-submit-button',
          stretch: false,
          color: '',
          text_i18n: 'nwui.modal.upload_aml_verification.submit',
          onClick: function() {
            // var post_data = {};
            // post_data.documentIds = scope.documents.map(function(document) {
            // 		return document.id;								
            // 	})
            
            return IdVerificationService.requestVerificationApproval(scope.verificationId).then(function(){
              NwuiToastManagerService.register(
                new NwuiToastFactory({
                  text_i18n: 'nwui.modal.upload_aml_verification.success',
                  icon: {
                    name: 'check'
                  }
                }
              ));

              submitCb(true);
              scope.open = false;
            })
            .catch(function(err){
              NwuiToastManagerService.register(
                new NwuiToastFactory({
                  text_i18n: "onboarding.aml_verification.errors." + err.data.nw_error_code,
                  type: 'error',
                  icon: {
                    name: 'close'
                  }
                }
              ));
            });
          }
        };

        function filterDropdownOptions() {
          scope.availableDocumentTypes = scope.availableDocumentTypes.filter(function(type) {
            return scope.documents.every(function(document){
              return document.clientDocument.template._id != type.id;
            });
          })
          if(NwuiInputService.inputs['aml-verification-select-file-type']) {
            NwuiInputService.inputs['aml-verification-select-file-type'].model = '';
            NwuiInputService.inputs['aml-verification-select-file-type'].options = mapToDropdownOptions(scope.availableDocumentTypes);
          }
        }

        function mapToDropdownOptions(input) {
          return input.map(function(type, ind){
            return {
              id: type.id,
              text_i18n: 'nwui.common.document.' + type.slug,
              value: type.id
            }
          })
        }


        function createTable() {
          scope.table = new NwuiCardTableV2Factory({
          id: 'aml-verification-upload-table',
          empty_text_i18n: 'nwui.modal.upload_aml_verification.empty_text',
          resource: function(){
            return IdVerificationService.getVerificationDocuments(scope.verificationId).then(function(res) {
              return {
                data: {
                  data: res.data.filter(function(datum){
                    return datum.status !== 'rejected'
                  }),
                  page_data: {}
                }
              }
            })
          },
          resourceCb: function(obj) {
            scope.documents = obj.data.data;
            filterDropdownOptions();
          },
          rowMap: function(document,i) {

            var button_id = 'aml-verification-upload-table-' + i;

            return {
              columns: [ {
                text: locale.getString('nwui.common.document.' +document.clientDocument.template.prefix)
              }, {
                text: locale.getString(document.status)
              }, {
                text: moment(document.clientDocument.uploadedAt).format(DATE_FORMAT.short)
              }, {
                button: {
                  id: button_id,
                  icon_only: true,
                  color: 'error',
                  icon: 'close',
                  onClick: function() {
                    
                    IdVerificationService.deleteDocument(document.id).then(function(res) {
                      //add document type back to available once the user has deleted it 
                      scope.availableDocumentTypes.push({
                        id: document.clientDocument.template._id,
                        slug: document.clientDocument.template.prefix
                      });
                      submitCb(true);
                      scope.table.reload();
                    })
                    .catch(function(err){
                      NwuiToastManagerService.register(
                        new NwuiToastFactory({
                          text_i18n: "onboarding.aml_verification.errors." + err.data.nw_error_code,
                          type: 'error',
                          icon: {
                            name: 'close'
                          }
                        }
                      ));
                    });
                  }
                }
              }]
            }
          },
          columns: [
            {
              name_i18n: 'nwui.modal.upload_aml_verification.table.headings.fileType',
              directive: 'text',
              sortable: false,
            },
            {
              name_i18n: 'nwui.modal.upload_aml_verification.table.headings.status',
              directive: 'text',
              sortable: false
            },
            {
              name_i18n: 'nwui.modal.upload_aml_verification.table.headings.dateUploaded',
              directive: 'text',
              sortable: false
            },
            {
              name: "",
              directive: "button",
              width: 60,
              sortable: false
            }
          ],
          rows: undefined
        });
        }
        
        scope.documents = [];
        scope.availableDocumentTypes = [];

        var current_language = locale.getLocale()
        var org_role_options = [];

        var submitCb = function(success) {
          scope.submitCb(success);
        }

        function getDocumentTypes() {
          return IdVerificationService.getDocumentTypes().then(function(res){
            scope.availableDocumentTypes = res.data;
            filterDropdownOptions();
            return res.data;
          });
        }

        function createForm() {

          return new NwuiFormV3Factory({
            name: 'update-admin-user-modal-form',
            submit_button: false,
            onSubmit: function() {},
            onComplete: function() {},
            onError: function(res) {

              var message = {
                icon: {
                  name: 'error',
                  color: 'error'
                }
              };

              if(res.data.nw_error_message)
                message.text = res.data.nw_error_message;
              else
                message.text_i18n = 'nwui.modal.update_admin.messages.failure';

              NwuiFormV3Service.showMessage('update-admin-user-modal-form', message);

            },
            steps: [{
              columns: [{
                directive: 'input',
                width: 100,
                data: {
                  directive: 'select',
                  data: {
                    id: 'aml-verification-select-file-type',
                    label_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.label',
                    placeholder_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.placeholder',
                    model: '',
                    placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
                    options: mapToDropdownOptions(scope.availableDocumentTypes),
                    onChange: function(selectedType) {
                      if(selectedType) {
                        NwuiInputService.inputs['aml-verification-upload-file'].hide = false;
                        NwuiInputService.inputs['aml-verification-upload-file'].url = '/api/admin/users/' + scope.uuid + '/id-verifications/' + scope.verificationId + '/documents/' + selectedType;
                      }
                    }
                  }
                }
              }, {
                directive: 'input',
                width: 100,
                data: {
                  directive: 'upload',
                  data: {
                    id: 'aml-verification-upload-file',
                    label_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.label',
                    placeholder_i18n: 'nwui.modal.upload_aml_verification.inputs.idUpload.placeholder',
                    model: '',
                    hide: true,
                    url: '',
                    placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
                    onSuccess: function(res) {
                      filterDropdownOptions();
                      scope.table.reload();
                      NwuiToastManagerService.register(
                        new NwuiToastFactory({
                          text_i18n: "nwui.common.toast.document_upload",
                          icon: {
                            name: 'check'
                          }
                        }
                      ));

                    }
                  }
                }
              }]
            }]
          });
        }

        scope.modals = {
          upload_aml_verification: new NwuiModalV2Factory({
            open: false,
            wide: true,
            header: new NwuiCardHeaderV2Factory({
              name_i18n: 'nwui.modal.upload_aml_verification.title',
              icon: {
                name: 'cloud_upload'
              }
            }),
            form: undefined
          })
        }

          
        scope.$watch('open', function(new_val) {
          
          //console.log('open', new_val);
          if(new_val === undefined) return;
          
          scope.modals.upload_aml_verification.open = new_val;

        });

        scope.$watch('verificationId', function(id) {
          if(id) {
            getDocumentTypes().then(function(res) {
              scope.modals.upload_aml_verification.form = createForm();
              createTable();
            });
          }
        });

        
      }
    }
  }
]);