var nwuiCompound = angular.module('nwui-compound', [
	'nwui-compound-client-activities',
	'nwui-compound-client-address',
	'nwui-compound-client-documents',
	'nwui-compound-client-goal',
	'nwui-compound-client-todos',
	'nwui-compound-goal-risk-profile',
	'nwui-compound-goal-tracker',
	'nwui-compound-risk-profile-editor',
	'nwui-compound-menu',
	'nwui-compound-modal'
]);