nwuiCardTableV2.factory('NwuiCardTableV2Factory', [
	'NwuiCardTableV2RowFactory',
	function(
		NwuiCardTableV2RowFactory
	) {
	
		var defaults = {
			id: undefined,
			resource: undefined,
			resourceCb: function() {},
			rowMap: undefined,
			empty_text: undefined,
			empty_text_i18n: "nwui.common.table.body.empty",
			columns: [], //req		
			rows: undefined,
			reload: undefined,
			footer: {}, 
			loaded: false
		}
		
		return function(obj) {
			if(!obj) return console.error("Missing instantiation object for NwuiCardTableV2Factory");
			if(obj.id === undefined) return console.error("Missing required instantiation object variable 'id' in NwuiCardTableV2RowFactory");
			if(obj.columns === undefined) return console.error("Missing required instantiation object variable 'columns' in NwuiCardTableV2RowFactory");
			
			var rows = [];
			angular.forEach(obj.rows, function(row, i) {
				rows.push(new NwuiCardTableV2RowFactory(row));
			});
			
			angular.extend(this, defaults, obj, { rows: rows });
		}
	
	}
]);

nwuiCardTableV2.factory('NwuiCardTableV2RowFactory', ['NwuiCardTableV2RowColumnFactory', function(NwuiCardTableV2RowColumnFactory) {
	
	var defaults = {
		columns: [] //req
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableV2RowFactory");
		if(obj.columns === undefined) return console.error("Missing required instantiation object variable 'columns' in NwuiCardTableV2RowFactory");
		
		var columns = [];
		angular.forEach(obj.columns, function(column, i) {
			columns.push(new NwuiCardTableV2RowColumnFactory(column));
		});
		
		angular.extend(this, defaults, { columns: columns });
	}
	
}]);

nwuiCardTableV2.factory('NwuiCardTableV2RowColumnFactory', [function() {
	
	var defaults = {
		clickEvent: undefined
	}
	
	return function(obj) {
		if(!obj) return console.error("Missing instantiation object NwuiCardTableV2RowColumnFactory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);