nwuiFormV2Column.factory('NwuiFormV2ColumnFactory', [function() {
	
	var defaults = {
		form_name: undefined, //req
		directive: undefined, //req
        data: undefined, //req
        width: 'auto',
        calc_width: undefined
	}
	
	return function(obj) {
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiFormV2ColumnFactory");
		if(obj.form_name === undefined) return console.error("Missing required instantiation object variable: 'object.form_name'");
		if(obj.directive === undefined) return console.error("Missing required instantiation object variable: 'object.directive'");
		if(obj.data === undefined) return console.error("Missing required instantiation object variable: 'object.data'");
		
		angular.extend(this, angular.copy(defaults), obj);
	}
	
}]);