nwuiChartDonutV3.factory('NwuiChartDonutV3Factory', [function() {
	
	var defaults = {
		series: undefined, //req
		active: undefined,
		decimals: 0
	}
	
	return function(obj) {
		//console.log(obj)
		if(typeof obj === undefined) return console.error("Missing instantiation object for NwuiChartDonutV3Factory");
		if(obj.series === undefined) return console.error("Missing required instantiation object variable 'series' in NwuiChartDonutV3Factory");
		
		angular.extend(this, defaults, obj);
	}
	
}]);