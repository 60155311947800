nwuiRiskProfileVisualizer.directive('nwuiRiskProfileVisualizer',['DataSharingService', 'NwuiInputService', '$rootScope', '$q', '$http', 'NwuiModalV2Factory', 'NwuiCardHeaderV2Factory', 'NwuiFormV3Factory', 'locale', 'NwuiSession', 
    function(DataSharingService, NwuiInputService, $rootScope, $q, $http, NwuiModalV2Factory, NwuiCardHeaderV2Factory, NwuiFormV3Factory, locale, NwuiSession) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/risk-profile-visualizer/template.html',
            scope: {
                pieData: "=pieData",
                colors: "=colors",
                hover: "=hover",
                pieView: "=pieView",
                assets: "=assets",
                riskid:"=riskid"
            },
            link: function(scope){

                var URL_BASE = 'api/risk-profile/';
                var URL_ASSET = 'api/assets';
                var URL_PORTFOLIO = '/portfolio';
                var URL_RESET = '/portfolio/reset';

                var pieDataAssetIndex = 0;
                var getPieDataAssetIndex = function(){
                    scope.pieData.forEach(function(pie_asset){
                        if(pie_asset.name > pieDataAssetIndex){
                            pieDataAssetIndex = pie_asset.name + 1;
                        }
                    });
                    return pieDataAssetIndex;
                }
                
                scope.editMode = false;
                scope.editAllocationsPermission = organization_data && organization_data.join_data && organization_data.join_data.questionnaire && organization_data.join_data.questionnaire.edit_portfolio && NwuiSession.isAdvisorImpersonating();
                scope.assetsChanged = false;
                scope.showDisclaimer = organization_data.join_data.questionnaire.show_disclaimer;
                DataSharingService.createVariable('rpq');
                DataSharingService.createVariable('rpq_pie_data');
                scope.pieData.push({name:getPieDataAssetIndex(), percent:0, type:"unallocated", key:"unallocated", i18n_key:"unallocated", description:""});
                DataSharingService.updateValue('rpq_pie_data', 'pie_data', scope.pieData);

                var assets_map = [];
                if(scope.assets) {
                    scope.assets.forEach(function(asset){
                        if(scope.pieData.some(function(pie_asset){
                            return asset.key === pie_asset.key;
                        })){
                            assets_map.push({ 
                                label: locale.getString('nwui.questionnaire.portfolio.dimension.' + asset.key + '.name'),
                                model: asset.key,
                                value: true,
                                required: false
                            });
                        } else {
                            assets_map.push({ 
                                label: locale.getString('nwui.questionnaire.portfolio.dimension.' + asset.key + '.name'),
                                model: asset.key,
                                value: false,
                                required: false
                            });
                        };
                    });
                
                    var arrayToObject = function(array) {
                        return array.reduce(function(obj, item) {
                                obj[item.key] = item;
                                return obj;
                        }, {});
                    };
                    scope.assets = arrayToObject(scope.assets);
                }
                // Reset trigger
                scope.reset = {
                    id: 'reset',
                    icon: {
                        name: 'autorenew'
                    },
                    text: 'Reset',
                    onClick: function(){
                        var URL_RETRIEVE = URL_BASE + scope.riskid + URL_RESET;
                        $http.post(URL_RETRIEVE) 
                        .then(function(res) {
                            scope.pieData = [];
                            var resetData = res.data.pie_data.data[0].dimensions;
                            resetData.map(function(asset, index) {
                                scope.pieData.push({
                                    name:index,
                                    percent:asset.y, 
                                    type:asset.type, 
                                    key:asset.key, 
                                    i18n_key: !!asset.i18n_key ? asset.i18n_key : asset.key, 
                                    description: asset.description
                                });
                                NwuiInputService.inputs['manage_asset_classes'].options[index].value = true;
                            });
                            var sliderData = DataSharingService.getObject('rpq');
                            Object.keys(sliderData).map(function(slider, index){
                                resetData.map(function(resetPieData){
                                    if(slider === resetPieData.key){
                                        DataSharingService.updateValue('rpq', slider, resetPieData.y);
                                    }
                                });
                            })
                            scope.assetsChanged = false;
                            scope.pieData.push({name:getPieDataAssetIndex(), percent:0, type:"unallocated", key:"unallocated", i18n_key:"unallocated", description:""});
                            DataSharingService.updateValue('rpq_pie_data', 'pie_data', scope.pieData);
                            scope.$emit('pieDataUpdate');
                            scope.$emit('pieDataReset');
                        });
                    }
                }

                // Save allocation trigger
                scope.saveAllocation = {
                    id: 'save_allocation',
                    icon: {
                        name: 'edit'
                    },
                    text: 'Edit Allocation',
                    onClick: function(){
                        var pieData = DataSharingService.getValue('rpq_pie_data', 'pie_data');
                        for(var pie in pieData){
                            if(pieData[pie].i18n_key === 'unallocated'){
                                if (pieData[pie].percent !== 0)
                                return;
                            }
                        }
                        if(scope.editMode){
                            scope.modal.saveAllocation.open = true;
                            scope.modal.saveAllocation.data = DataSharingService.getValue('rpq_pie_data', 'pie_data');
                        }
                        else if(!scope.editMode){
                            scope.saveAllocation.text = "Save Allocation";
                            scope.saveAllocation.icon = {name: 'save'};
                        }
                        scope.editMode = !scope.editMode;
                    }
                }

                // Manage trigger
                scope.manage = {
                    id: 'manage',
                    icon: {
                        name: 'build'
                    },
                    text: 'Manage',
                    onClick: function(){
                        scope.modal.manage.open = true;
                    }
                }
                
                scope.modal = {
                    manage: new NwuiModalV2Factory({
                        open: false,
                        wide: false,
                        header: new NwuiCardHeaderV2Factory({
                            name: 'Manage Asset Classes',
                            icon: {
                                name: 'edit'
                            }
                        }),
                        form: new NwuiFormV3Factory({
                            name: 'manage-asset-classes-form',
                            onSubmit: function() {
                                var unallocated = 0;
                                var checkboxes = NwuiInputService.inputs['manage_asset_classes'].options;
                                for (var i = 0; i < checkboxes.length; i++) {
                                    var asset = checkboxes[i];
                                    var indexOfAsset = -1;
                                    for (var j = 0; j < scope.pieData.length; j++) {
                                        if (scope.pieData[j].key === asset.model) {
                                            indexOfAsset = j;
                                            break;
                                        }
                                    }

                                    if(asset.value){
                                        if(indexOfAsset === -1){
                                            var assetString = asset.model.toString();
                                            DataSharingService.updateValue('rpq', assetString, 0);
                                            scope.pieData.push({name:getPieDataAssetIndex(), percent:0, type:scope.assets[assetString].type, key:assetString, i18n_key:assetString, description:assetString});
                                        }
                                    } else {
                                        if(indexOfAsset > -1){
                                            unallocated += scope.pieData[indexOfAsset].percent;
                                            DataSharingService.removeChild('rpq', scope.pieData[indexOfAsset]['i18n_key']);
                                            scope.pieData.splice(indexOfAsset, 1);
                                        }
                                    }
                                }

                                scope.pieData.forEach(function(pie){
                                    if(pie['i18n_key'] === 'unallocated'){
                                        pie.percent += unallocated;                                
                                    };
                                })
                                
                                DataSharingService.updateValue('rpq_pie_data', 'pie_data', scope.pieData);
                                scope.$emit('pieDataUpdate');
                                return $q.resolve();
                            },
                            onComplete: function(res) {
                                scope.modal.manage.open = false;
                            },
                            onError: function(res) {
                            },
                            submit_button: {
                                text: 'Save',
                                icon: {
                                    name: 'save'
                                }
                            },
                            steps: [{
                                columns: [{
                                    directive: 'input',
                                    width: 100,
                                    data: {
                                        directive: 'checkbox',
                                        data: {
                                            id: 'manage_asset_classes',
                                            label: "",
                                            stretch: true,
                                            required: false,
                                            options: assets_map,
                                            size: '',
                                            onChange: function(val) {}
                                        }
                                    }
                                }]
                            }]
                        })
                    }),
                    saveAllocation: new NwuiModalV2Factory({
                        open: false,
                        wide: true,
                        header: new NwuiCardHeaderV2Factory({
                            name: 'Confirm New Asset Allocation',
                            icon: {
                                name: 'edit'
                            }
                        }),
                        form: new NwuiFormV3Factory({
                            name: 'save-allocation-form',
                            onSubmit: function() {
                                scope.saveAllocation.text = "Edit Allocation";
                                scope.saveAllocation.icon = {name: 'edit'};

                                var text = NwuiInputService.inputs['risk-profile-visualizer-input-save-allocation-text'].model;
                                var data = { 
                                    asset_map: DataSharingService.getValue('rpq_pie_data', 'pie_data'),
                                    reason: text
                                }

                                data.asset_map = data.asset_map.map(function(data){
                                    if (data.percent === 0) {
                                        return;
                                    }
                                    return [ data.key, {ticker: data.key, cusip: data.key, allocation: data.percent/100 } ]
                                })
                                .filter(function(data) { 
                                    return !!data;
                                })

                                var URL_RETRIEVE = URL_BASE + scope.riskid + URL_PORTFOLIO;
                                return $http.post(URL_RETRIEVE, data)
                                .then(function(res) {
                                }, function(e){
                                    console.error(e);
                                });
                            },
                            onComplete: function(res) {
                                NwuiInputService.inputs['risk-profile-visualizer-input-save-allocation-text'].model = '';
                                scope.assetsChanged = false;
                                scope.modal.saveAllocation.open = false;
                            },
                            onError: function(res) {
                            },
                            submit_button: {
                                text: 'Save',
                                icon: {
                                    name: 'save'
                                }
                            },
                            steps: [{
                                columns: [{
                                    directive: 'input',
							        width: 100,
					                data: {
						                directive: 'textarea',
						                data: {
							                model: '',
							                id: 'risk-profile-visualizer-input-save-allocation-text',
							                label: 'Please write a reason for this customized asset allocation:',
							                placeholder: ''
							            }
							        }
                                }]
                            }]
                        })
                    })
                }
                scope.select_all = false;
                $rootScope.$on('pieDataUpdate', function(event){
                    var pieData = DataSharingService.getValue('rpq_pie_data', 'pie_data');
                    for(var pie in pieData){
                        if(pieData[pie].i18n_key === 'unallocated'){
                            if (pieData[pie].percent !== 0) {
                                scope.saveAllocation.disabled = true;
                                scope.assetsChanged = true;
                            }
                            else
                                scope.saveAllocation.disabled = false;
                        }
                    }
                });
            }
        }
    }
]);
