nwuiInputDropListItem.directive('nwuiInputDropListItem', [
	'$sce', 
	'$state',
	'$timeout', 
	function(
		$sce,
		$state,
		$timeout
	) {
		return {
			restrict: 'E',
	        replace: true,
	        transclude: true,
	        templateUrl: '/input/drop-list/item/template.html',
	        scope: {
		        active: '=',
		        label: '=',
		        data: '=',
		        onSelectCb: '='
	        },
	        link: function(scope, element, attrs) {
		        
		        scope.current_state = $state;
		        
		        scope.onClick = function() {
			        //console.log('nwuiInputDropListItem', 'onClick', scope.data);
			        scope.onSelectCb(scope.data);
		        }
		        
	        }
		}
	}
]);